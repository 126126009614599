import { Divider, TypoGraph } from "components";
import { fontStyle } from "pages/receivables/util";
import colors from "theme/colors";

interface ICustomDividerProps {
  title?: string;
  titleColor?: string;
  marginTopCustom?: number;
}

const CustomDivider = (props: ICustomDividerProps) => {
  const { title = "", marginTopCustom = 0 } = props;
  return (
    <>
      <TypoGraph
        sx={fontStyle.subtitle1}
        variant="subtitle1"
        content={title}
        color={props.titleColor == "dark" ? colors.dark : undefined}
        mb={0}
        mt={4}
      />
      <Divider sx={{ marginTop: marginTopCustom }} />
    </>
  );
};

export default CustomDivider;
