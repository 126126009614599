import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
} from "react-hook-form";
import { isDate } from "moment";
import DatePicker, { IDatePickerProps } from "../controllers/datePicker";
import { formatDateToMMDDYYYY } from "tools/format";
import { useEffect, useState } from "react";
export interface IDatePickerFieldProps extends IDatePickerProps {
  name: string;
  required?: boolean;
  maxLength?: number | { value: number; message: string };
  minLength?: number | { value: number; message: string };
  pattern?: ValidationRule<RegExp> | undefined;
  defaultValue?: string | number | undefined;
  validate?: Validate<any> | Record<string, Validate<any>> | undefined;
}

const SelfManagedDatePickerFormField: React.FC<IDatePickerFieldProps> = (
  props
) => {
  const {
    name,
    required = false,
    maxLength,
    minLength,
    pattern,
    defaultValue,
    validate,
  } = props;
  const { control, getValues, setValue, watch } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    if (getValues(name)) {
      let dateString = getValues(name);
      const month = parseInt(dateString.substr(0, 2), 10) - 1; // Months are 0-based
      const day = parseInt(dateString.substr(2, 2), 10);
      const year = parseInt(dateString.substr(4, 4), 10);

      setDate(new Date(year, month, day));
    }
  }, [watch(name)]);
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required: isRequired,
        validate: validate,
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
      }}
      render={({
        field: { onChange, onBlur, value = "", ref },
        fieldState: { error },
      }) => {
        return (
          <DatePicker
            id={name}
            value={date}
            onChange={(val: any) => {
              isDate(val) && setValue(name, formatDateToMMDDYYYY(val));
            }}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            defaultValue={defaultValue}
            {...props}
          />
        );
      }}
    />
  );
};

export default SelfManagedDatePickerFormField;
