import { Container, CustomDivider, UserInfoForm } from "components";
import {
  InputFormField,
  DropdownFormField,
  DatePickerFormField,
} from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { amountIsValid, formatDate } from "tools/validators";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { DropdownOptions } from "interfaces";
import { DATE_FORMAT_YYYY_MM_DD, todayDate } from "constants/datePicker";
import { isDate } from "moment";
import { formatDateToMMDDYYYY } from "tools/format";
import {
  commissionEarnedRoles,
  earnestBroker,
} from "constants/dropdownOptions";
import { PropertyForm } from "components";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";

interface CommissionEarnedComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const CommissionEarnedComponent: React.FC<CommissionEarnedComponentProps> = (
  props
) => {
  const { statesOptions, customLabels } = props;
  const { getValues, setValue } = useFormContext();
  const [closeDate, setCloseDate] = useState<Date>(new Date());

  return (
    <Container className={styles.transactionDetailContainer}>
      <CustomDivider title="Your Info" />
      <UserInfoForm />
      <CustomDivider title="Transaction Info" />
      <Container className={styles.transactionInfoContainer}>
        <DropdownFormField
          name={"trrole"}
          label={"I am the"}
          options={commissionEarnedRoles}
          required
        ></DropdownFormField>
        <InputFormField name="closeattorney" label="Closing Attorney Name" />
        <DropdownFormField
          name={"emholder"}
          label={"Who was holding Earnest Money?"}
          options={earnestBroker}
          required
        />
        <InputFormField
          name="emamountInDollars"
          label="Earnest Money Amount"
          maxLength={13}
          defaultValue={
            getValues("emamountInDollars")
              ? getValues("emamountInDollars")
              : "0"
          }
          currency
        />

        <DatePickerFormField
          name={"closedate"}
          onChange={(e: React.ChangeEvent<any> | null) => {
            isDate(e) && setCloseDate(e);
            isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
          }}
          minDate={todayDate}
          inputFormat={DATE_FORMAT_YYYY_MM_DD}
          value={
            getValues("closedate")
              ? new Date(formatDate(getValues("closedate")))
              : closeDate
          }
          label={"Closing Date"}
          defaultValue={formatDateToMMDDYYYY(todayDate)}
        />
      </Container>
      <CustomDivider title="Property Details" />
      <PropertyForm
        statesOptions={statesOptions}
        customLabels={customLabels?.address}
      />
    </Container>
  );
};

export default CommissionEarnedComponent;
