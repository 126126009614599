import React, { useEffect, useState } from "react";
import {
  Blade,
  Button,
  DataGrid,
  Tabs,
  Container,
  TabPanel,
  Toast as toast,
  TypoGraph,
  Icon,
  SubmitDialog,
  Loader,
} from "components";
import { receivableAccountDetailBlade } from "constants/tableData";
import colors from "theme/colors";
import styles from "./receivableAccountDetailBlade.module.scss";
import strings from "strings";
import { FormProvider, useForm } from "react-hook-form";
import { InputFormField } from "components/hookForm";
import { IReceivableAccountResponse } from "interfaces";
import { getReceivableAccountById, updateReceivableAccount } from "api";
import ReceivableAccountAudit from "./receivableAccountAudit";
import { IReceivableAccountUpdateRequest } from "interfaces/IDisbursementAccountModel";

interface IReceivableAccountData {
  showBlade: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  handleUpdate: () => void;
  selectedReceivableAccountId?: number;
  handleReceivableAccount: (
    receivableAccount: IReceivableAccountResponse
  ) => void;
}

const ReceivableAccountDetailBlade: React.FC<IReceivableAccountData> = (
  props
) => {
  const {
    showBlade = false,
    handleClose,
    handleSuccess,
    handleUpdate,
    selectedReceivableAccountId,
    handleReceivableAccount,
  } = props;
  const methods = useForm();
  const { handleSubmit, reset, watch, getValues } = methods;
  const [requiresVerification, setRequiresVerification] =
    useState<boolean>(false);
  const [transValue1isValid, setTransValue1isValid] = useState<boolean>(true);
  const [transValue2isValid, setTransValue2isValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeactivateDialog, setShowDeactivateDialog] =
    useState<boolean>(false);
  const [showActivateDialog, setShowActivateDialog] = useState<boolean>(false);
  const [receivableAccountData, setReceivableAccountData] =
    useState<IReceivableAccountResponse>();
  const [bladeLoading, setBladeLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!selectedReceivableAccountId) {
        return;
      }
      setBladeLoading(true);
      setReceivableAccountDetailTab(0);
      getReceivableAccountById(selectedReceivableAccountId)
        .then((response) => {
          handleReceivableAccount(response.data);
          setReceivableAccountData(response.data);
          const formValues = {
            accountName: response.data.accountName,
          };
          reset(formValues);
        })
        .finally(() => {
          setBladeLoading(false);
        });
      //Currently, receivable accounts have no "Status", only active/inactive field.
      // if (receivableAccountData.status == "Pending") {
      //   setRequiresVerification(true);
      // } else {
      //   setRequiresVerification(false);
      // }
    })();
  }, [selectedReceivableAccountId]);

  const [submitBtnLoader] = useState<boolean>(false);

  const [receivableAccountDetailTab, setReceivableAccountDetailTab] =
    useState<number>(0);

  const updateSubmit = (data: any) => {};

  const verifySubmit = (data: any) => {
    setTransValue1isValid(true);
    setTransValue2isValid(true);
    if (+data.transactionValue1 === 0 || +data.transactionValue2 === 0) {
      toast({
        type: "error",
        title: "Both transaction values must be greater than 0",
      });
      if (+data.transactionValue1 === 0) {
        setTransValue1isValid(false);
      }
      if (+data.transactionValue2 === 0) {
        setTransValue2isValid(false);
      }
      return;
    }
    setRequiresVerification(false);
  };

  const DetailsContent = () => {
    return (
      <Container>
        <FormProvider {...methods}>
          <InputFormField
            name="accountName"
            label={"Account name"}
            disabled
            required
          />
        </FormProvider>
        <Container className={styles.companyContainer}>
          <TypoGraph content={"Company"} />
          <TypoGraph
            content={receivableAccountData?.companyName}
            color={colors.grey}
          />
        </Container>

        <TypoGraph
          content={"Bank Account Details"}
          className={styles.divider}
        />
        <DataGrid
          greyColumns={true}
          columns={receivableAccountDetailBlade.bankAccountColumns}
          data={receivableAccountData}
        />
        <br />
        <TypoGraph content={"Account Limits"} className={styles.divider} />
        <DataGrid
          greyColumns={true}
          columns={receivableAccountDetailBlade.accountLimitsColumns}
          data={receivableAccountData}
        />

        {requiresVerification && (
          <Container>
            <TypoGraph content={"Verification"} className={styles.divider} />
            <Container className={styles.verificationCard}>
              <Icon name="info" size={24} iconClassName={styles.infoIcon} />
              <p>{strings.RECEIVABLE_ACCOUNT_VERIFICATION_NEEDED}</p>
            </Container>

            <FormProvider {...methods}>
              <Container className={styles.transForm}>
                <TypoGraph content={"Transaction value"} />
                <InputFormField
                  className={styles.transInput}
                  name="transactionValue1"
                  label=""
                  maxLength={13}
                  error={!transValue1isValid}
                  defaultValue={getValues("amount") ? getValues("amount") : "0"}
                  currency={true}
                  required
                />
              </Container>
              <Container className={styles.transForm}>
                <TypoGraph content={"Transaction value"} />
                <InputFormField
                  className={styles.transInput}
                  name="transactionValue2"
                  label=""
                  maxLength={13}
                  defaultValue={getValues("amount") ? getValues("amount") : "0"}
                  error={!transValue2isValid}
                  currency={true}
                  required
                />
              </Container>
            </FormProvider>
          </Container>
        )}
      </Container>
    );
  };

  const BladeContent = () => {
    return (
      <>
        <Loader loading={bladeLoading} />
        <Container className={styles.bladeContainer}>
          <Tabs
            tabData={[
              {
                label: "Details",
              },
              {
                label: "Audit",
              },
            ]}
            value={receivableAccountDetailTab}
            handleChange={(_e, val) => setReceivableAccountDetailTab(val)}
          />
          <TabPanel value={receivableAccountDetailTab} index={0}>
            <DetailsContent></DetailsContent>
          </TabPanel>
          <TabPanel value={receivableAccountDetailTab} index={1}>
            {receivableAccountData && (
              <ReceivableAccountAudit
                receivableAccountData={receivableAccountData}
              />
            )}
          </TabPanel>
        </Container>
      </>
    );
  };

  const setAccountActive = (active: boolean) => {
    if (!receivableAccountData) {
      return;
    }
    setLoading(true);
    const request: IReceivableAccountUpdateRequest = {
      ...receivableAccountData,
      active: active,
    };
    request.paymentMethods = request.paymentMethods.filter(
      (paymentMethod) => paymentMethod.active === true
    );
    request.externalDepositPaymentMethods =
      request.externalDepositPaymentMethods.filter(
        (externalPaymentMethod) => externalPaymentMethod.active === true
      );
    request.paymentTypes = request.paymentTypes.filter(
      (paymentType) => paymentType.active === true
    );
    updateReceivableAccount(request, receivableAccountData.id)
      .then((response) => {
        toast({
          title: active
            ? "Receivable account successfully activated"
            : "Receivable account successfully deactivated",
        });
        setShowActivateDialog(false);
        setShowDeactivateDialog(false);
        handleSuccess();
      })
      .finally(() => setLoading(false));
  };

  const BladeFooter = () => {
    return (
      <Container>
        {receivableAccountDetailTab === 0 && !requiresVerification && (
          <Container className={styles.actionContainer}>
            {receivableAccountData?.active ? (
              <Button
                id={"deactivate"}
                variant="outlined"
                label={"Deactivate"}
                onClick={() => setShowDeactivateDialog(true)}
              />
            ) : (
              <Button
                id={"activate"}
                variant="outlined"
                label={"Activate"}
                onClick={() => setShowActivateDialog(true)}
              />
            )}
            <Button
              id={strings.UPDATE}
              variant="contained"
              label={"Update Account"}
              loading={submitBtnLoader}
              onClick={handleUpdate}
            />
          </Container>
        )}

        {receivableAccountDetailTab === 0 && requiresVerification && (
          <Container className={styles.actionContainer}>
            <Button
              id={"verifyAccount"}
              variant="outlined"
              label={"Verify Account"}
              onClick={handleSubmit((data) => verifySubmit(data))}
            />
            <Button
              id={strings.UPDATE}
              variant="contained"
              disabled
              label={"Update Account"}
              loading={submitBtnLoader}
              onClick={handleSubmit((data) => updateSubmit(data))}
            />
          </Container>
        )}
        {receivableAccountDetailTab === 1 && (
          <Container className={styles.actionContainer}>
            <Button
              id={"downloadCVS"}
              variant="outlined"
              label={"Download CVS"}
              onClick={handleClose}
            />
            <Button
              id={"done"}
              variant="contained"
              label={"Done"}
              loading={submitBtnLoader}
              onClick={handleClose}
            />
          </Container>
        )}
      </Container>
    );
  };

  return (
    <>
      <Blade
        show={showBlade}
        title={"Receivable Account"}
        handleClose={handleClose}
        content={<BladeContent />}
        footerContent={<BladeFooter />}
        headerLine
      />
      <SubmitDialog
        type="warning"
        title={"Deactivate Account"}
        open={showDeactivateDialog}
        body1={`Are you sure you'd like to update the status of the account ${receivableAccountData?.accountName} from active to inactive?`}
        handleClose={() => setShowDeactivateDialog(false)}
        primaryBtnProps={{
          id: strings.DEACTIVATE,
          label: strings.DEACTIVATE,
          onClick: () => setAccountActive(false),
          loading: loading,
          color: "error",
        }}
      />
      <SubmitDialog
        type="warning"
        title={"Activate Account"}
        open={showActivateDialog}
        body1={`Are you sure you'd like to update the status of the account ${receivableAccountData?.accountName} from inactive to active?`}
        handleClose={() => setShowActivateDialog(false)}
        primaryBtnProps={{
          id: strings.ACTIVATE,
          label: strings.ACTIVATE,
          onClick: () => setAccountActive(true),
          loading: loading,
          color: "success",
        }}
      />
    </>
  );
};

export default ReceivableAccountDetailBlade;
