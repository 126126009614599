import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TypoGraph } from "components";
import Tooltip from "@mui/material/Tooltip";

export interface IRadioProps {
  id?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: string;
  value?: boolean;
  inputRef?: any;
  radioGroupData: IRadioGroupData[];
  className?: string;
  tooltipText?: string;
}

export interface IRadioGroupData {
  value: string | number;
  label: string;
  labelVariant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "overline";
  labelPlacement?: "bottom" | "top" | "end" | "start";
  disabled?: boolean;
  tooltipText?: string | "";
}

const RadioField: React.FunctionComponent<IRadioProps> = (props) => {
  const { id, onChange, defaultChecked, value, radioGroupData, className } =
    props;
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={defaultChecked}
      name={id}
      onChange={onChange}
      value={value}
      className={className}
    >
      {radioGroupData?.map((item, index) =>
        !item.disabled ? (
          <FormControlLabel
            key={`key-${index}`}
            value={item.value}
            control={<Radio />}
            label={
              <TypoGraph
                variant={item?.labelVariant || "body1"}
                content={item.label}
              />
            }
            labelPlacement={item?.labelPlacement || "end"}
            disabled={item.disabled ? item.disabled : false}
          />
        ) : (
          <Tooltip
            title={item.tooltipText || ""}
            placement="bottom-start"
            arrow
          >
            <FormControlLabel
              key={`key-${index}`}
              value={item.value}
              control={<Radio />}
              label={
                <TypoGraph
                  variant={item?.labelVariant || "body1"}
                  content={item.label}
                />
              }
              labelPlacement={item?.labelPlacement || "end"}
              disabled={item.disabled ? item.disabled : false}
            />
          </Tooltip>
        )
      )}
    </RadioGroup>
  );
};

export default RadioField;
