import { IPayment, IPaymentImageResponse } from "interfaces";
import currencyFormatter from "tools/currencyFormatter";
import { Buffer } from "buffer";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import {
  paymentDetailsColumn,
  paymentRequestDetailsColumn,
} from "constants/tableData";
import { useCallback, useEffect, useState } from "react";
import { dcFailStatusById } from "constants/status";
import { PDFDatagrid, pdfStyles } from "components/dataGrid/pdfDataGrid";
import { paymentMethods } from "constants/paymentMethods";
import { maskPropertyAddress } from "tools/format";
import { passportIds, paymentStatus } from "constants/paymentStatus";

interface IPDFComponentProps {
  payment: IPayment;
  paymentImage?: IPaymentImageResponse;
}

const ReceivablePdfComponent = (props: IPDFComponentProps) => {
  const { payment, paymentImage } = props;
  const [frontImageBuffer, setFrontImageBuffer] = useState<
    Buffer | undefined
  >();
  const [backImageBuffer, setBackImageBuffer] = useState<Buffer | undefined>();
  const paymentTypeId = (payment?.typeId ||
    0) as keyof typeof paymentDetailsColumn.details;
  const isDcNotFailed =
    (payment?.statusId || payment?.statusId === paymentStatus.NEW) &&
    !dcFailStatusById.includes(payment?.statusId);
  const paymentRequireAddress = payment?.typeId !== 5 && payment?.typeId !== 11;
  const isPaymentRequested = payment?.statusId === paymentStatus.REQUESTED;
  const showPayerAccountInfo =
    payment?.paymentMethodId === paymentMethods.ELECTRONIC_PAYMENT;
  const showReceivableAccount =
    payment?.statusId && passportIds.includes(payment.statusId);

  useEffect(() => {
    loadImages();
  }, [payment, isDcNotFailed]);

  const loadImages = useCallback(async () => {
    if (!isDcNotFailed || !paymentImage) {
      return;
    }
    try {
      let front = paymentImage.images.find(
        (i) => i.imageIndex === "FRONT"
      )?.base64Image;
      let back = paymentImage.images.find(
        (i) => i.imageIndex === "BACK"
      )?.base64Image;
      if (front && back) {
        setFrontImageBuffer(Buffer.from(front, "base64"));
        setBackImageBuffer(Buffer.from(back, "base64"));
      }
    } catch (e) {
      console.log(e);
    }
  }, [payment, isDcNotFailed]);

  const requestedPaymentDetails = (
    <>
      <Text style={pdfStyles.sectionTitle}>REQUEST DETAILS</Text>
      <PDFDatagrid
        columns={paymentRequestDetailsColumn.requestDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />

      <Text style={pdfStyles.sectionTitle}>COMPANY</Text>
      <PDFDatagrid
        columns={paymentRequestDetailsColumn.companyDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />

      <Text style={pdfStyles.sectionTitle}>PAYER DETAILS</Text>
      <PDFDatagrid
        columns={paymentRequestDetailsColumn.payerDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />

      {paymentRequireAddress && (
        <>
          <Text style={pdfStyles.sectionTitle}>PROPERTY</Text>
          <PDFDatagrid
            columns={paymentRequestDetailsColumn.propertyDetails[0]}
            data={maskPropertyAddress(payment) || {}}
            hideIfEmpty={false}
          />
        </>
      )}
      <Text style={pdfStyles.sectionTitle}>TRANSACTION DETAILS</Text>
      <PDFDatagrid
        columns={paymentRequestDetailsColumn.transactionDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      {payment.prNote && (
        <>
          <Text style={pdfStyles.sectionTitle}>NOTE AND ATTACHMENT</Text>
          <PDFDatagrid
            columns={[
              {
                name: "prNote",
                label: "Note",
              },
            ]}
            data={payment || {}}
            hideIfEmpty={true}
          />
        </>
      )}
    </>
  );

  return (
    <Document>
      <Page size="A4">
        <Text style={pdfStyles.title}>{`${payment.id}, ${
          payment.lastName
        }, ${currencyFormatter(payment.amount)}`}</Text>

        {isPaymentRequested ? (
          requestedPaymentDetails
        ) : (
          <>
            {paymentRequireAddress && (
              <>
                <Text style={pdfStyles.sectionTitle}>PROPERTY</Text>
                <PDFDatagrid
                  columns={paymentDetailsColumn.address}
                  data={payment.address}
                />
              </>
            )}
            <Text style={pdfStyles.sectionTitle}>DETAILS</Text>
            <PDFDatagrid
              columns={
                paymentDetailsColumn.details[paymentTypeId] ||
                paymentDetailsColumn.details.default
              }
              data={payment}
            />
            {frontImageBuffer && backImageBuffer && (
              <View style={pdfStyles.rowContainer}>
                <Image
                  style={{ padding: 10 }}
                  source={{ data: frontImageBuffer, format: "jpg" }}
                ></Image>
                <Image
                  style={{ padding: 10 }}
                  source={{ data: backImageBuffer, format: "jpg" }}
                ></Image>
              </View>
            )}
            {showPayerAccountInfo && (
              <>
                <Text style={pdfStyles.sectionTitle}>
                  PAYER ACCOUNT INFORMATION
                </Text>
                <PDFDatagrid
                  columns={paymentDetailsColumn.payerAccountInformation}
                  data={payment || {}}
                  hideIfEmpty={false}
                />
              </>
            )}
            {showReceivableAccount ? (
              <>
                <Text style={pdfStyles.sectionTitle}>RECEIVABLE ACCOUNT</Text>
                <PDFDatagrid
                  columns={paymentDetailsColumn.receivableAccount}
                  data={payment || {}}
                  hideIfEmpty={false}
                />
              </>
            ) : (
              <>
                <Text style={pdfStyles.sectionTitle}>RECEIVING COMPANY</Text>
                <PDFDatagrid
                  columns={paymentDetailsColumn.receivingCompany}
                  data={payment || {}}
                  hideIfEmpty={false}
                />
              </>
            )}
          </>
        )}
      </Page>
    </Document>
  );
};

export default ReceivablePdfComponent;
