import {IDecodedToken} from "interfaces/IdecodedToken";
import jwt_Decode from "jwt-decode";
import {getBillingAccountsForCardholder, getCurrentUserDetails} from "api";

export enum Actions {
    ALL,
    DEPOSIT,
    CHANGE_AMOUNT,
    HOLD,
    REJECT,
    CREATE,
    READ,
    UPDATE,
    DELETE,
    WRITE,
    GENERATE_MAINTENANCE_REPORT,
    OVERRIDE_STATUS,
    RELEASE_HELD,
    GENERATE_TRANSACTION_REPORT,
    SUBMIT,
    RETURN_ITEM,
    RESET_2FA,
    CHANGE_FILE_NUMBER,
    REJECT_OWN_DRAFT,
    REJECT_ANY_DRAFT,
    REVIEW,
    UNLOCK,
    REACTIVATE,
    VOID,
    SEND_REMINDER
}

export enum Resources {
    ALL,
    COMPANY,
    ITEM,
    USER,
    ACCOUNT,
    ITEM_REMITTANCE_REPORT,
    ITEM_DAILY_REPORT,
    ITEM_DAILY_STATUS,
    ITEM_DAILY_ROLLUP,
    MY_BILLING_ACCOUNTS,
    BILLING_ACCOUNT,
    SETTINGS,
    DISBURSEMENT_PAYMENTS,
    DISBURSEMENT_ACCOUNTS,
    PAYMENT_REQUEST,
    PROFILE,
    HELP,
    REPORTS,
    RECEIVABLES_ACCOUNTS = 18
}

export enum PrivilegeLevels {
    RECEIVABLES_REQUESTER = 30,
    BROKER = 40,
    BROKER_PLUS = 45,
    SUPERADMIN = 100,
    OWNER = 120,
}

export enum IndividualPrivileges {
    DISBURSEMENT_MANAGER = 1,
    DISBURSEMENT_CREATOR = 2,
    RECEIVABLES_APPROVER = 4,
    RECEIVABLE_MANAGER = 5,
}

export function privilegeChecker(userPriv: number, privileges: number[]) {
    return privileges.includes(userPriv);
}

export function userCan(actions: Actions, resources: Resources) {
    const userPriv = defineUserPriv();
    const userIsCardholder = defineIfUserIsCardholder();
    const {RECEIVABLES_REQUESTER, BROKER, BROKER_PLUS, SUPERADMIN, OWNER} = PrivilegeLevels;
    const {
        READ,
        DEPOSIT,
        CHANGE_AMOUNT,
        REJECT,
        WRITE,
        HOLD,
        RELEASE_HELD,
        RESET_2FA,
    } = Actions;
    const {
        COMPANY,
        ITEM,
        USER,
        ACCOUNT,
        MY_BILLING_ACCOUNTS,
        DISBURSEMENT_PAYMENTS,
        DISBURSEMENT_ACCOUNTS,
        PAYMENT_REQUEST,
        PROFILE,
        HELP,
        REPORTS,
        RECEIVABLES_ACCOUNTS
    } = Resources;

    if (userPriv === OWNER) {
        if (resources === MY_BILLING_ACCOUNTS && actions === READ) {
            return userIsCardholder;
        }
        if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.READ && !(userHasAnyPriv([IndividualPrivileges.DISBURSEMENT_MANAGER, IndividualPrivileges.DISBURSEMENT_CREATOR]))) {
            return false;
        }
        if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.CREATE && !(userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
            return false;
        }
        if (resources === DISBURSEMENT_ACCOUNTS && actions === Actions.READ && !(userHasAnyPriv([IndividualPrivileges.DISBURSEMENT_MANAGER, IndividualPrivileges.DISBURSEMENT_CREATOR]))) {
            return false;
        }
        if (resources === DISBURSEMENT_ACCOUNTS && actions === Actions.CREATE && !(userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
            return false;
        }
        if (resources === DISBURSEMENT_ACCOUNTS && actions === Actions.DELETE && !(userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
            return false;
        }
        if (resources === DISBURSEMENT_ACCOUNTS && actions === Actions.VOID && !(userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
            return false;
        }
        if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.GENERATE_TRANSACTION_REPORT && !(userHasAnyPriv([IndividualPrivileges.DISBURSEMENT_MANAGER, IndividualPrivileges.DISBURSEMENT_CREATOR]))) {
            return false;
        }
        if (resources === PAYMENT_REQUEST && actions === Actions.READ) {
            return false;
        }
        return true;
    }
    if (userPriv === SUPERADMIN && resources === USER) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === DEPOSIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === RELEASE_HELD && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === USER && actions === RESET_2FA) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === CHANGE_AMOUNT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === COMPANY && actions === READ) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === READ && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === REJECT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === HOLD && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === WRITE && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === COMPANY) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ACCOUNT) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === Actions.SUBMIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === Resources.ITEM_DAILY_STATUS && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === Resources.ITEM_REMITTANCE_REPORT && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === Resources.ITEM_DAILY_ROLLUP && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === Resources.ITEM_DAILY_REPORT && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === MY_BILLING_ACCOUNTS && actions === READ) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === ITEM && actions === Actions.CHANGE_FILE_NUMBER && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === Resources.SETTINGS) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === DISBURSEMENT_PAYMENTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER) || userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === DISBURSEMENT_PAYMENTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.DELETE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === USER && actions === Actions.UNLOCK) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === REPORTS && actions === READ) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === RECEIVABLES_ACCOUNTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === RECEIVABLES_ACCOUNTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === DISBURSEMENT_PAYMENTS && actions === Actions.VOID && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === SUPERADMIN && resources === PAYMENT_REQUEST && actions === Actions.SEND_REMINDER) {
        return true;
    }

    if (userPriv === BROKER && resources === ITEM && actions === DEPOSIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === READ && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === REJECT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === HOLD && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === CHANGE_AMOUNT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === WRITE && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === Actions.SUBMIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === MY_BILLING_ACCOUNTS && actions === READ && userIsCardholder) {
        return true;
    }
    if (userPriv === BROKER && resources === COMPANY && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER && resources === Resources.ITEM_DAILY_REPORT && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER && resources === Resources.ITEM_DAILY_STATUS && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER && resources === USER && actions === RESET_2FA) {
        return true;
    }
    if (userPriv === BROKER && resources === ITEM && actions === Actions.CHANGE_FILE_NUMBER && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER && resources === Resources.SETTINGS) {
        return true;
    }
    if (userPriv === BROKER && resources === Resources.COMPANY && actions === Actions.READ) {
        return true;
    }
    if (userPriv === BROKER && resources === DISBURSEMENT_PAYMENTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER) || userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === BROKER && resources === DISBURSEMENT_PAYMENTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === BROKER && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR) || userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.DELETE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER && resources === REPORTS && actions === READ) {
        return true;
    }
    if (userPriv === BROKER && resources === RECEIVABLES_ACCOUNTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER && resources === RECEIVABLES_ACCOUNTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER && resources === DISBURSEMENT_PAYMENTS && actions === Actions.VOID && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER && resources === PAYMENT_REQUEST && actions === Actions.SEND_REMINDER) {
        return true;
    }

    if (userPriv === BROKER_PLUS && resources === ITEM && actions === DEPOSIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === READ && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === REJECT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === HOLD && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === CHANGE_AMOUNT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === WRITE && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === Actions.SUBMIT && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === MY_BILLING_ACCOUNTS && actions === READ) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === Resources.SETTINGS) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === Resources.COMPANY && actions === Actions.READ) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === COMPANY && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userHasAnyPriv([IndividualPrivileges.DISBURSEMENT_CREATOR, IndividualPrivileges.DISBURSEMENT_MANAGER]) && resources === DISBURSEMENT_PAYMENTS && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === Resources.ITEM_DAILY_REPORT && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === Resources.ITEM_DAILY_STATUS && actions === Actions.GENERATE_TRANSACTION_REPORT) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === Resources.USER) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === ITEM && actions === Actions.CHANGE_FILE_NUMBER && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_PAYMENTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER) || userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_PAYMENTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === REPORTS && actions === READ) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === USER && actions === Actions.UNLOCK) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === RECEIVABLES_ACCOUNTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === RECEIVABLES_ACCOUNTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.RECEIVABLE_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_PAYMENTS && actions === Actions.VOID && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === PAYMENT_REQUEST && actions === Actions.SEND_REMINDER) {
        return true;
    }

    if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.REJECT_OWN_DRAFT && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.REJECT_ANY_DRAFT && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }
    if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.REVIEW && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER) || userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (resources === DISBURSEMENT_PAYMENTS && actions === Actions.UPDATE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.READ && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER) || (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_CREATOR)))) {
        return true;
    }
    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.CREATE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }

    if (userPriv === BROKER_PLUS && resources === DISBURSEMENT_ACCOUNTS && actions === Actions.DELETE && (userHasIndividualPriv(IndividualPrivileges.DISBURSEMENT_MANAGER))) {
        return true;
    }

    if (userPriv === RECEIVABLES_REQUESTER && resources === PAYMENT_REQUEST && actions === Actions.CREATE) {
        return true;
    }

    if (userPriv === RECEIVABLES_REQUESTER && resources === PAYMENT_REQUEST && actions === Actions.READ) {
        return true;
    }

    if (userPriv === RECEIVABLES_REQUESTER && resources === PAYMENT_REQUEST && actions === Actions.SEND_REMINDER) {
        return true;
    }

    if (userPriv && resources === PROFILE && actions === Actions.READ) {
        return true;
    }

    if (userPriv && resources === HELP && actions === Actions.READ) {
        return true;
    }

    if (resources === Resources.ITEM && actions === Actions.RETURN_ITEM && (userHasAnyPriv([IndividualPrivileges.RECEIVABLES_APPROVER, IndividualPrivileges.RECEIVABLE_MANAGER]))) {
        return true;
    }

    return false;
}

export function defineUserPriv() {
    const token = localStorage.getItem("token");
    if (token === null) {
        return 0;
    }
    const decodedToken: IDecodedToken = jwt_Decode(token);
    return decodedToken.userpriv;
}

export function defineIfUserIsCardholder() {
    const userIsCardholder = localStorage.getItem("userIsCardholder");
    if (userIsCardholder === null || userIsCardholder === "false") {
        return false;
    }
    return true;
}

export const userIsCardholder = async () => {
    if (defineUserPriv() != PrivilegeLevels.RECEIVABLES_REQUESTER) {
        getBillingAccountsForCardholder(0, 1)
            .then((response) => {
                localStorage.setItem("userIsCardholder", (!!response?.data?.totalCount).toString());
            })
    }
}

export function defineUserIndividualPriv(): number[] {
    const token = localStorage.getItem("token");
    if (token === null) {
        return [0];
    }
    const decodedToken: IDecodedToken = jwt_Decode(token);
    return decodedToken.privileges ? decodedToken.privileges : [];
}

export function userHasIndividualPriv(privilegeID: number): boolean {
    if (privilegeID === null) {
        return false;
    }
    return defineUserIndividualPriv().includes(privilegeID);
}

export function userHasAnyPriv(privilegeIDs: number[]): boolean {
    for (const privilegeID of privilegeIDs) {
        if (defineUserIndividualPriv().includes(privilegeID)) {
            return true
        }
    }
    return false;
}
