import request, { requestWithoutErrorHandling } from "tools/request";
import requestPassport from "tools/passportRequest";
import {
  IAdminLogResponse,
  IBankAccountType,
  IBillingAccountCustomerResponse,
  IBillingAccountInvoice,
  IBillingAccountReportResponse,
  IBillingAccountResponse,
  IBPAccount,
  IChangePasswordSelfRequest,
  IChangePasswordWithResetIdRequest,
  IChangeTwoFactorAuthRequest,
  IChangeTwoFactorAuthResponse,
  ICompanyAccountRequest,
  ICompanyAccountResponse,
  ICompanyIdList,
  ICompanyIdName,
  ICompanyRequest,
  ICompanyResponse,
  ICompanyWebBasedReceivablesDetails,
  IConfirm2faResetRequest,
  IDepositItemRequest,
  IDisbursementAccountAllResponse,
  IDisbursementAccountResponse,
  IDisbursementDepositOption,
  IDisbursementPaymentItemResponse,
  IDisbursementPaymentRequest,
  IForgotPasswordRequest,
  IGenericHttpResponse,
  IImageEndorsementRequest,
  IItemHistory,
  IMultipleDisbursementRequest,
  IMultipleDisbursementResponse,
  INewBillingAccountRequest,
  INewItemNoteResponse,
  INewReceivablePaymentRequest,
  INewReceivableRequest,
  INewReceivableResponse,
  INewUserRequest,
  INewUserResponse,
  IPageResponse,
  IPayment,
  IPaymentImageResponse,
  IPaymentMethod,
  IPaymentSummary,
  IPaymentType,
  IPriceProductListResponse,
  IPrivResponse,
  IReset2faResponse,
  IResetUserPasswordResponse,
  ISetup2faResponse,
  ISignature,
  IStatus,
  IStripeCustomerBillingPortalSession,
  IStripeSubscription,
  ISubmitItemResult,
  IUpdateBillingAccountRequest,
  IUpdateItemActionResponse,
  IUpdateItemAmountResponse,
  IUpdateItemResponse,
  IUpdateUserRequest,
  IUserResponse,
  IUsStatesResponse,
  IValidateResetRequest,
  IWhitelabelConfigurationResponse,
  LoginRequest,
  LoginResponse,
} from "interfaces";
import { IUserDetails, IUserSummaryResponse } from "interfaces/IUserModel";
import {
  ICreateDisbursementAccountRequest,
  IReceivableAccountRequest,
  IReceivableAccountResponse,
  IUpdateDisbursementAccountRequest,
  IUpdateReceivableAccountRequest,
} from "interfaces/IDisbursementAccountModel";
import { GridSortDirection } from "@mui/x-data-grid/models/gridSortModel";
import { ISignatureRequest } from "../interfaces/ISignature";
import {
  IDisbursementPaymentAttachmentResponse,
  IRecurrentPayee,
} from "../interfaces/IDisbursementPaymentModel";
import { IMultiplePaymentRequests } from "interfaces/IMultipleRFP";
import { IPaymentAttachmentResponse } from "interfaces/IPayment";
import {
  EndorsementPreviewRequest,
  EndorsementResponse,
} from "interfaces/IEndorsement";
import { CropRequest } from "interfaces/ICrop";
import { ICreditCardPassportTokenRequest } from "interfaces/IPassportToken";
import {
  IWithdrawItemRequest,
  IWithdrawItemsSameAccountRequest,
} from "interfaces/IMultiplePassportDeposit";
import { ISubmitCheckRequest } from "interfaces/IMultipleCheckDeposit";

export const getAllCompanies = async (
  pageNumber = 0,
  pageSize = 10,
  statusFilter: string = "",
  filter = "",
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<ICompanyResponse>>(
    `private/companies/all/`,
    {
      params: { pageNumber, pageSize, statusFilter, filter, sortBy, order },
    }
  );
};

export const getAllCompanyAccountsForCompany = async (
  companyId: string | undefined,
  pageNumber = 0,
  pageSize = 10,
  filter = "",
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<ICompanyAccountResponse>>(
    `private/company-account/${companyId}/accounts/`,
    {
      params: { pageNumber, pageSize, filter, sortBy, order },
    }
  );
};

export const createNewCompany = async (companyRequest: ICompanyRequest) => {
  return request.post<ICompanyResponse>("private/companies", companyRequest);
};

export const deleteCompany = async (id: number) => {
  return request.delete<ICompanyResponse>(`private/companies/${id}`);
};

export const updateCompany = async (
  companyRequest: ICompanyRequest,
  companyId: number
) => {
  return request.put<ICompanyResponse>(
    `private/companies/${companyId}`,
    companyRequest
  );
};

export const getCompanyDetail = async (
  companyId: string,
  WithErrorHandling: boolean = true
) => {
  return WithErrorHandling
    ? request.get<ICompanyResponse>(`private/companies/${companyId}`)
    : requestWithoutErrorHandling.get<ICompanyResponse>(
        `private/companies/${companyId}`
      );
};

export const getCompaniesWithNoBillingAccounts = async () => {
  return request.get<ICompanyIdName[]>(`private/companies/no-billing-account/`);
};

export const getAdminsForCompanies = async (companyIds: ICompanyIdList) => {
  const idsFormatted = companyIds.adminCompanies.join(",");
  return request.get<IUserResponse[]>(
    `private/users/adminsForCompanies/?companiesIds=${idsFormatted}`
  );
};

export const getBillingReport = async (
  billingAccountId: number | string,
  month: number,
  year: number
) => {
  return request.get<IBillingAccountReportResponse>(
    `private/billingAccount/${billingAccountId}/billingReport`,
    {
      params: { month, year },
    }
  );
};

export const getPaymentMethods = async () => {
  return request.get<IPaymentMethod[]>(`private/payment-method`);
};

export const getCompanyAccountDetails = async (
  companyId: number | undefined,
  companyAccountId: number | undefined
) => {
  return request.get<ICompanyAccountResponse>(
    `private/company-account/${companyId}/accounts/${companyAccountId}`
  );
};

export const createNewCompanyAccount = async (
  companyAccountRequest: ICompanyAccountRequest,
  companyId: string | undefined
) => {
  return request.post<ICompanyAccountResponse>(
    `private/company-account/${companyId}/accounts`,
    companyAccountRequest
  );
};

export const updateCompanyAccount = async (
  companyAccountRequest: ICompanyAccountRequest,
  companyId: string | undefined,
  companyAccountId: string | undefined
) => {
  return request.put<ICompanyAccountResponse>(
    `private/company-account/${companyId}/accounts/${companyAccountId}`,
    companyAccountRequest
  );
};

export const getPaymentTypesForCompany = async (companyId: string) => {
  return request.get<IPaymentType[]>(
    `private/companies/payment-types/${companyId}`
  );
};

export const getBankAccounts = async () => {
  return request.get<IBPAccount[]>("private/companies/banks");
};
export const getBankAccountTypes = async () => {
  return request.get<IBankAccountType[]>(`private/companies/bankaccounttypes`);
};

export const login = async (loginRequest: LoginRequest) => {
  return request.post<LoginResponse>("public/loginPortal", loginRequest);
};

export const logout = async () => {
  return request.post("private/users/logout");
};

export const setup2fa = async () => {
  return request.get<ISetup2faResponse>("private/2fa/setup");
};

export const login2fa = async (code: number | string) => {
  return request.post<LoginResponse>("private/2fa/login", { code: code });
};

export const resetUserPassword = async (userId: string | number) => {
  return request.put<IResetUserPasswordResponse>(
    `private/users/${userId}/resetPassword`
  );
};

export const createNewUser = async (createNewUserRequest: INewUserRequest) => {
  return request.post<INewUserResponse>(`private/users`, createNewUserRequest);
};

export const getAllPrivileges = async () => {
  return request.get<IPrivResponse>(
    `private/privilege?OnlyThePrivilegesYouCanGive=true`
  );
};

export const reset2fa = async (userId: number) => {
  return request.put<IReset2faResponse>(`private/users/${userId}/reset2fa`);
};

export const updateUser = async (
  updateUserRequest: IUpdateUserRequest,
  userId: string | number
) => {
  return request.put(`private/users/${userId}`, updateUserRequest);
};

export const getCompaniesForUser = async () => {
  return request.get<ICompanyIdName[]>(`private/companies/all/noPag`);
};

export const getCompaniesWithBillingAccounts = async () => {
  return request.get<ICompanyIdName[]>(`private/companies/all/noPag`);
};

export const getUserDetails = async (userId: string | number) => {
  return request.get<IUserResponse>(`private/users/details/${userId}`);
};

export const getCurrentUserDetails = async () => {
  return request.get<IUserResponse>(`private/users/details/`);
};

export const getUsers = async (
  pageNumber = 0,
  pageSize = 10,
  statusFilter: string = "",
  filter = "",
  companiesFilterId: number[] = [],
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<IUserResponse>>(`private/users/`, {
    params: {
      pageNumber,
      pageSize,
      statusFilter: statusFilter,
      filter,
      companiesFilterId: companiesFilterId.toString(),
      sortBy,
      order,
    },
  });
};

export const getUserSummary = async () => {
  return request.get<IUserSummaryResponse>(`private/users/summary`);
};

export const updateBillingAccount = async (
  billingAccountRequest: IUpdateBillingAccountRequest,
  billingAccountId: string
) => {
  return request.put(
    `private/billingAccount/${billingAccountId}`,
    billingAccountRequest
  );
};

export const createNewBillingAccount = async (
  billingAccountRequest: INewBillingAccountRequest
) => {
  return request.post<IBillingAccountResponse>(
    `private/billingAccount`,
    billingAccountRequest
  );
};

export const getBillingAccounts = async (
  pageNumber = 0,
  pageSize = 10,
  filter = "",
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<IBillingAccountResponse>>(
    `private/billingAccount/`,
    {
      params: { pageNumber, pageSize, filter, sortBy, order },
    }
  );
};

export const getBillingAccountDetails = async (billingAccountId: string) => {
  return request.get<IBillingAccountResponse>(
    `private/billingAccount/${billingAccountId}`
  );
};

export const getPriceProducts = async () => {
  return request.get<IPriceProductListResponse>(
    `private/billingAccount/priceProducts`
  );
};

export const getPaymentImage = async (itemId: number) => {
  return request.get<IPaymentImageResponse>(`private/images?itemId=${itemId}`);
};

export const getPaymentPage = async (
  paymentTypeId: number | null,
  pageNumber: number = 0,
  pageSize: number = 10,
  statusFilter: string = "",
  filter = "",
  sortBy: string = "",
  order: GridSortDirection = undefined,
  companiesFilterId: number[] = []
) => {
  return request.get<IPageResponse<IPayment>>(`private/items/payments`, {
    params: {
      paymentTypeId,
      pageNumber,
      pageSize,
      statusFilter,
      filter,
      sortBy,
      order,
      companiesFilterId: companiesFilterId.toString(),
    },
  });
};

export const getPaymentSummary = async () => {
  return request.get<IPaymentSummary[]>(`private/items/count`);
};

export const getReportsForCompany = async (
  companyId: string | undefined,
  url: string,
  startDate: string | null,
  endDate: string | null
) => {
  return request.get<string>(`private/report/${url}/company/${companyId}`, {
    params: { startDate, endDate },
  });
};

export const forgotPassword = async (
  forgotPasswordRequest: IForgotPasswordRequest
) => {
  return request.put(`public/forgot-password`, forgotPasswordRequest);
};

export const validateReset = async (
  validateResetRequest: IValidateResetRequest
) => {
  return request.put<LoginResponse>(
    `public/validate-reset`,
    validateResetRequest
  );
};

export const changePasswordWithResetId = async (
  changePasswordWithResetIdRequest: IChangePasswordWithResetIdRequest
) => {
  return request.put(
    `private/reset-password`,
    changePasswordWithResetIdRequest
  );
};

export const depositItem = async (
  depositItemRequest: IDepositItemRequest,
  itemId: number
) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/deposit`,
    depositItemRequest
  );
};

export const holdItem = async (itemId: number, note: string) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/hold`,
    { note }
  );
};

export const rejectItem = async (itemId: number, note: string) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/reject`,
    { note }
  );
};

export const releaseHeldItem = async (itemId: number, note: string) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/release-held`,
    { note }
  );
};

export const addNoteToItem = async (itemId: number, note: string) => {
  return request.put<INewItemNoteResponse>(`private/items/${itemId}/add-note`, {
    note,
  });
};

export const changeItemAmount = async (
  itemId: number,
  amountInCents: number
) => {
  return request.put<IUpdateItemAmountResponse>(
    `private/items/${itemId}/change-amount`,
    { amountInCents }
  );
};

export const updateItemNotes = async (itemId: number, notes: string) => {
  return request.put<INewItemNoteResponse>(
    `private/items/${itemId}/update-notes`,
    { notes }
  );
};

export const getStatuses = async () => {
  return request.get<IStatus[]>("private/items/status");
};

export const overrideItemStatus = async (
  itemId: number,
  newStatusId: number,
  comment: string
) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/status`,
    { newStatusId, comment }
  );
};

export const getCompanyAccountsForCompany = async (companyId: number) => {
  return request.get<ICompanyAccountResponse[]>(
    `private/company-account/${companyId}/accounts/nopag`
  );
};
export const getReceivableAccountsForCompany = async (companyId: number) => {
  return request.get<ICompanyAccountResponse[]>(
    `private/receivable-account/${companyId}/receivableaccounts/nopag`
  );
};

export const getItemHistory = async (
  itemId: number,
  pageNumber: number = 0,
  pageSize: number = 5
) => {
  return request.get<IPageResponse<IItemHistory>>(
    `private/items/${itemId}/history?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

const MAINTENANCE_ROOT = `/private/maintenance`;
export const getCompanyMaintenanceReport = async (
  companyId: string,
  pageNumber = 0,
  pageSize = 10,
  filter = ""
) => {
  return request.get<IPageResponse<IAdminLogResponse>>(
    MAINTENANCE_ROOT + `/company/${companyId}`,
    {
      params: { pageNumber, pageSize, filter },
    }
  );
};

export const getUserMaintenanceReport = async (
  userId: string,
  pageNumber = 0,
  pageSize = 10,
  filter = ""
) => {
  return request.get<IPageResponse<IAdminLogResponse>>(
    MAINTENANCE_ROOT + `/user/${userId}`,
    {
      params: { pageNumber, pageSize, filter },
    }
  );
};

export const getCompanyAccountMaintenanceReport = async (
  companyAccountId: string,
  pageNumber = 0,
  pageSize = 10,
  filter = ""
) => {
  return request.get<IPageResponse<IAdminLogResponse>>(
    MAINTENANCE_ROOT + `/companyAccount/${companyAccountId}`,
    {
      params: { pageNumber, pageSize, filter },
    }
  );
};

export const submitItems = async (submitCheckrequest: ISubmitCheckRequest) => {
  return request.post<ISubmitItemResult[]>(
    `private/items/submit-checks`,
    submitCheckrequest
  );
};

export const submitACHItems = async (itemIdList: number[]) => {
  return request.post<ISubmitItemResult[]>(`private/checkbook/direct`, {
    itemIdList,
  });
};

export const changePasswordSelf = async (
  changePasswordSelfRequest: IChangePasswordSelfRequest
) => {
  return request.put(`private/reset-password/self`, changePasswordSelfRequest);
};

export const getBillingAccountsForCardholder = async (
  pageNumber = 0,
  pageSize = 10,
  filter = "",
  sortBy: string = "",
  order: GridSortDirection = "asc"
) => {
  return request.get<IPageResponse<IBillingAccountResponse>>(
    "private/billingAccount/cardholder",
    {
      params: { pageNumber, pageSize, filter, sortBy, order },
    }
  );
};

export const getBillingAccountInvoices = async (
  billingAccountId: number,
  pageNumber = 0,
  pageSize = 10
) => {
  return request.get<IBillingAccountInvoice[]>(
    `private/billingAccount/${billingAccountId}/invoices`,
    {
      params: { pageNumber, pageSize },
    }
  );
};

export const createStripeCustomerPortalSessionUrl = async (
  billingAccountId: number
) => {
  return request.get<IStripeCustomerBillingPortalSession>(
    `private/billingAccount/${billingAccountId}/stripe-session`
  );
};

export const returnItem = async (itemId: number, note: string) => {
  return request.put<IUpdateItemActionResponse>(
    `private/items/${itemId}/return`,
    { note }
  );
};

export const selfReset2faEmail = async () => {
  return request.get<IGenericHttpResponse>(`private/2fa/self-reset`);
};

export const validateSelfReset2fa = async (
  validateResetRequest: IValidateResetRequest
) => {
  return request.put<IGenericHttpResponse>(
    `private/2fa/self-reset/validate`,
    validateResetRequest
  );
};

export const confirmSelfReset2fa = async (
  confirm2faResetRequest: IConfirm2faResetRequest
) => {
  return request.put<LoginResponse>(
    `private/2fa/self-reset`,
    confirm2faResetRequest
  );
};
export const getUsStates = async () => {
  return request.get<IUsStatesResponse[]>(`public/states`);
};

export const getPaymentTypeById = async (paymentTypeId: number) => {
  return request.get<IPaymentType>(
    `private/items/payment-type/${paymentTypeId}`
  );
};

export const updateItemFileNumber = async (
  itemId: number,
  fileNumber: string
) => {
  return request.put<IUpdateItemResponse>(`private/items/${itemId}`, {
    fileNumber,
  });
};

interface ILinkTokenResponse {
  token: string;
}

export const getPlaidLinkToken = async () => {
  console.log("Requesting plaid link token");
  return request.get<ILinkTokenResponse>(
    `private/plaid/link-token?oAuthRedirectEnum=WEB`
  );
};

export const getVerificationToken = async (disbursementAccountId: number) => {
  console.debug(
    `Requesting plaid link token for verifying disbursement account id: ${disbursementAccountId}`
  );
  return request.get<ILinkTokenResponse>(
    `private/plaid/link-token?linkTokenVerification=true&disbursementAccountId=${disbursementAccountId}`
  );
};

export const verifyDisbursementAccount = async (
  disbursementAccountId: number
) => {
  console.debug(`Verifying disbursement account: ${disbursementAccountId}`);
  return request.put<ILinkTokenResponse>(
    `private/disbursementAccounts/verify`,
    {
      disbursementAccountId,
    }
  );
};
export const createDisbursementPayment = async (
  disbursementPaymentRequest: IDisbursementPaymentRequest
) => {
  return request.post<IDisbursementPaymentItemResponse>(
    "private/disbursement",
    disbursementPaymentRequest
  );
};

export const getDisbursementAccountsByCompanyId = async (companyId: number) => {
  return request.get<IDisbursementAccountAllResponse[]>(
    `private/companies/${companyId}/disbursementAccounts/by-company`
  );
};

export const getCompaniesAdminByUser = async () => {
  return request.get<ICompanyIdName[]>(`private/companies/by-user`);
};

export const getAllDisbursementAccountsForCompany = async (
  pageNumber = 0,
  pageSize = 10,
  filter = "",
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<IDisbursementAccountResponse>>(
    `private/disbursementAccounts`,
    {
      params: { pageNumber, pageSize, filter, sortBy, order },
    }
  );
};

export const createDisbursementAccount = async (
  companyId: number,
  createDisbursementAccountRequest: ICreateDisbursementAccountRequest
) => {
  return request.post<IDisbursementAccountResponse[]>(
    `/private/companies/${companyId}/disbursementAccounts`,
    createDisbursementAccountRequest
  );
};
export const getDisbursementPaymentsPage = async (
  pageNumber: number = 0,
  pageSize: number = 10,
  statusFilter: string,
  filter: string,
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<IDisbursementPaymentItemResponse>>(
    `/private/disbursement`,
    {
      params: {
        pageNumber,
        pageSize,
        statusFilter,
        filter,
        sortBy,
        order,
      },
    }
  );
};

export const updateDisbursementAccount = async (
  companyId: number | undefined,
  disbursementAccountId: number | undefined,
  updateDisbursementAccountRequest: IUpdateDisbursementAccountRequest
) => {
  return request.put<IDisbursementAccountResponse>(
    `/private/companies/${companyId}/disbursementAccounts/${disbursementAccountId}`,
    updateDisbursementAccountRequest
  );
};

export const getDisbursementAccountSignature = async (
  disbursementAccountId: number
) => {
  return request.get<ISignature>(
    `private/disbursementAccounts/${disbursementAccountId}/signature`
  );
};

export const getBillingAccountStripeSubscription = async (
  billingAccountId: string | number
) => {
  return request.get<IStripeSubscription[]>(
    `private/billingAccount/${billingAccountId}/stripeSubscription`
  );
};

export const getDisbursementAccountMaintenanceReport = async (
  disbursementAccountId: string,
  pageNumber = 0,
  pageSize = 10,
  filter = ""
) => {
  return request.get<IPageResponse<IAdminLogResponse>>(
    MAINTENANCE_ROOT + `/disbursementAccount/${disbursementAccountId}`,
    {
      params: { pageNumber, pageSize, filter },
    }
  );
};

export const deactivateDisbursementAccount = async (
  companyId: number | undefined,
  disbursementAccountId: number | undefined
) => {
  return request.delete<IDisbursementAccountResponse>(
    `/private/companies/${companyId}/disbursementAccounts/${disbursementAccountId}`
  );
};

export const getDisbursementPaymentById = async (
  disbursementId: string | undefined
) => {
  const { data } = await request.get(`/private/disbursement/${disbursementId}`);
  return data as IDisbursementPaymentItemResponse;
};

export const getDisbursementPaymentAttachmentById = async (
  disbursementId: string | undefined
) => {
  const { data } = await request.get(
    `/private/disbursement/${disbursementId}/attachment`
  );
  return data as IDisbursementPaymentAttachmentResponse;
};

export const rejectDisbursementById = async (
  disbursementId: string | undefined
) => {
  return request.put<IGenericHttpResponse>(
    `/private/disbursement/${disbursementId}/reject`
  );
};

export const updateDraftDisbursementById = async (
  disbursementId: string | undefined,
  updateDisbursementRequest: IDisbursementPaymentRequest
) => {
  return request.put<IDisbursementPaymentItemResponse>(
    `/private/disbursement/${disbursementId}`,
    updateDisbursementRequest
  );
};

export const getUserSignature = async (userId: number) => {
  return request.get<ISignature>(`private/users/${userId}/signature`);
};

export const resendInvite = async (userId: number) => {
  return request.put<IUserResponse>(`private/users/${userId}/resendInvite`);
};

export const addUserSignature = async (
  userId: number,
  signatureRequest: ISignatureRequest
) => {
  return request.post<ISignature>(
    `/private/users/${userId}/signature`,
    signatureRequest
  );
};

export const reviewApproveDisbursement = async (
  disbursementId: string | undefined
) => {
  const { data } = await request.put(
    `/private/disbursement/${disbursementId}/review-approve`
  );
  return data as IGenericHttpResponse;
};

export const reviewRejectDisbursement = async (
  disbursementId: string | undefined
) => {
  const { data } = await request.put(
    `/private/disbursement/${disbursementId}/review-reject`
  );
  return data as IGenericHttpResponse;
};

export const unlockUser = async (userId: number) => {
  return request.put<IUserResponse>(`private/users/${userId}/unlock`);
};

export const getCompanyPublicDetailsByCompanyCode = async (
  companyCode: string | undefined
) => {
  const { data } = await request.get(`/public/company/${companyCode}`);
  return data as ICompanyWebBasedReceivablesDetails;
};

export const sendMultipleDisbursement = async (
  multipleDisbursementRequest: IMultipleDisbursementRequest
) => {
  return request.post<IMultipleDisbursementResponse>(
    `private/disbursement/multiple`,
    multipleDisbursementRequest
  );
};

export const getAvailableDisbursementAccountsCSV = async () => {
  return request.get<string>(`private/disbursementAccounts/csv`);
};

export const getMultipleDisbursementsCSVTemplate = async () => {
  return request.get<string>(`private/disbursement/multiple/csv`);
};

export const createNewWebBasedReceivable = async (
  newReceivableRequest: INewReceivableRequest
) => {
  return request.post<INewReceivableResponse>(
    `/public/web-based-receivable`,
    newReceivableRequest
  );
};

export const deleteBillingAccount = async (billingAccountId: number) => {
  return request.delete(`private/billingAccount/${billingAccountId}`);
};

export const getBillingAccountCustomerInfo = async (
  customerId: string | number
) => {
  return request.get<IBillingAccountCustomerResponse>(
    `private/billingAccount/customer`,
    { params: { customerId } }
  );
};

export const updateCustomerIdForBillingAccount = async (
  customerId: string | number,
  billingAccountId: number | string
) => {
  return request.put<IBillingAccountResponse>(
    `private/billingAccount/${billingAccountId}/update-customer/`,
    {},
    { params: { customerId } }
  );
};

export const setupEmail2fa = async () => {
  return request.get<IGenericHttpResponse>("private/2fa/setupOTP");
};

export const changeTwoFactorAuthSelf = async (
  changeTwoFactorAuthRequest: IChangeTwoFactorAuthRequest
) => {
  return request.put<IChangeTwoFactorAuthResponse>(
    `private/2fa/update-2fa-method`,
    changeTwoFactorAuthRequest
  );
};

export const validateTwoFactorAuthMethodChange = async (code: string) => {
  return request.put<IGenericHttpResponse>(`private/2fa/validate-2fa-method`, {
    code: code,
  });
};

export const getWhitelabelConfigBySubDomain = async () => {
  const subDomain = window.location.host.split(".")[0];
  return request.get<IWhitelabelConfigurationResponse>(
    `public/whitelabel-configuration/${subDomain}`
  );
};

export const newReceivablePaymentRequest = async (
  paymentRequest: INewReceivablePaymentRequest
) => {
  return request.post<INewReceivableResponse>(
    `private/items/payment-request`,
    paymentRequest
  );
};

export const getAgentsForCompany = async (companyId: number) => {
  return request.get<IUserDetails[]>(
    `private/users/agentsForCompany/?companyId=${companyId}`
  );
};

export const getPaymentDetailsByPaymentRequestId = async (
  paymentRequestId: number
) => {
  return request.get<IPayment>(
    `public/payment-requests/${paymentRequestId}`,
    {}
  );
};

export const getPaymentMethodsForPaymentRequestForGivenCompany = async (
  companyId: number
) => {
  return request.get<IPaymentMethod[]>(
    `private/payment-method/payment-request/${companyId}`,
    {}
  );
};

export const getAllReceivablesAccounts = async (
  pageNumber = 0,
  pageSize = 10,
  filter = "",
  sortBy: string,
  order: GridSortDirection
) => {
  return request.get<IPageResponse<IReceivableAccountResponse>>(
    `private/receivable-account/`,
    {
      params: { pageNumber, pageSize, filter, sortBy, order },
    }
  );
};

export const createNewReceivableAccount = async (
  receivableAccountRequest: IReceivableAccountRequest
) => {
  return request.post<IReceivableAccountResponse>(
    `private/receivable-account/`,
    receivableAccountRequest
  );
};

export const updateReceivableAccount = async (
  receivableAccountRequest: IUpdateReceivableAccountRequest,
  receivableAccountId: number
) => {
  return request.put<IReceivableAccountResponse>(
    `private/receivable-account/${receivableAccountId}`,
    receivableAccountRequest
  );
};

export const withdrawItemToExternalAccount = async (
  itemId: number,
  destinationReceivableAccountId: number,
  paymentMethodId: number
) => {
  return request.post<ISubmitItemResult>(`private/items/withdraw`, {
    itemId,
    destinationReceivableAccountId,
    paymentMethodId,
  });
};

export const getPaymentMethodsForExternalDeposit = async () => {
  return request.get<IPaymentMethod[]>(
    `private/payment-method/for-external-deposit`
  );
};

export const getExternalDepositMethodsForCompany = async (
  companyId: string
) => {
  return request.get<IPaymentMethod[]>(
    `private/companies/external-deposit-methods/${companyId}`
  );
};

export const getReceivableAccountById = async (receivableAccountId: number) => {
  return request.get<IReceivableAccountResponse>(
    `private/receivable-account/${receivableAccountId}`
  );
};

export const getReceivableAccountMaintenanceReport = async (
  receivableAccountId: number,
  pageNumber = 0,
  pageSize = 10
) => {
  return request.get<IPageResponse<IAdminLogResponse>>(
    MAINTENANCE_ROOT + `/receivableAccount/${receivableAccountId}`,
    {
      params: { pageNumber, pageSize },
    }
  );
};

export const sendMultiplePaymentRequests = async (
  multiplePaymentRequests: IMultiplePaymentRequests
) => {
  return request.post<any>(
    `private/items/payment-request/multiple`,
    multiplePaymentRequests
  );
};

export const getAvailableCompaniesCSV = async () => {
  return request.get<string>(`private/companies/availableCompanies/csv`);
};

export const reactivateCompany = async (id: number) => {
  return request.put<ICompanyResponse>(`private/companies/${id}/reactivate`);
};

export const undeleteUser = async (userId: string | number) => {
  return request.put(`private/users/${userId}/undelete`);
};

export const voidDisbursement = async (disbursementId: string | undefined) => {
  const { data } = await request.delete(
    `private/disbursement/${disbursementId}/void`
  );
  return data as IGenericHttpResponse;
};

export const getDisbursementAccountsDepositOptions = async () => {
  return request.get<IDisbursementDepositOption[]>(
    `private/disbursementAccounts/deposit-options`
  );
};

export const getRecurrentPayeesForDisbursement = async () => {
  return request.get<IRecurrentPayee[]>(
    `private/disbursement/recurrent-payees`
  );
};

export const getItemAttachment = async (itemId: number) => {
  return request.get<IPaymentAttachmentResponse>(
    `private/items/${itemId}/item-attachment`
  );
};

export const getEndorsementPreview = async (
  endorsementRequest: EndorsementPreviewRequest
) => {
  return request.post<EndorsementResponse>(
    `private/items/endorsement-preview`,
    endorsementRequest
  );
};

export const getPaymentById = async (itemId: number) => {
  return request.get<IPayment>(`private/items/${itemId}/details`);
};

export const resentReceivablePaymentRequest = async (
  itemId: string | undefined
) => {
  const { data } = await request.get(
    `/private/items/${itemId}/payment-request`
  );
  return data as IGenericHttpResponse;
};

export const cropPreview = async (cropRequest: CropRequest) => {
  return request.post<EndorsementResponse>(
    `private/images/crop-preview`,
    cropRequest
  );
};

export const cropImage = async (cropRequest: CropRequest) => {
  return request.post<EndorsementResponse>(`private/images/crop`, cropRequest);
};

export const getPaymentMethodsForReceivableAccountsByCompanyId = async (
  companyId: number
) => {
  return request.get<IPaymentMethod[]>(
    `private/payment-method/receivable-account/${companyId}`,
    {}
  );
};

export const creditCardPassportToken = async (
  request: ICreditCardPassportTokenRequest
) => {
  return requestPassport.post<any>(`/v1/card/tokenize`, request);
};

export const submitPassportChecks = async (itemsIds: number[]) => {
  return request.post<any>(`private/items/passport/submit-checks`, {
    items: itemsIds,
  });
};

export const itemsDetailsForDeposit = async (itemsIds: number[]) => {
  return request.post<any>(`private/items/items-for-deposit`, {
    items: itemsIds,
  });
};

export const withdrawMultipleItemsToExternalAccount = async (
  items: IWithdrawItemRequest[]
) => {
  return request.post<ISubmitItemResult[]>(
    `private/items/withdraw-items-multiple-account`,
    items
  );
};

export const withdrawMultipleItemsToSameAccount = async (
  itemsToSameAccountRequest: IWithdrawItemsSameAccountRequest
) => {
  return request.post<ISubmitItemResult[]>(
    `private/items/withdraw-items-same-account`,
    itemsToSameAccountRequest
  );
};

export const deleteRecurringPaymentRequest = async (
  recurringPaymentId: number
) => {
  return request.delete<any>(
    `private/items/delete-recurring-payment/${recurringPaymentId}`
  );
};

export const pauseRecurringRequestForPayment = async (
  recurringPaymentId: number | undefined
) => {
  const { data } = await request.put(
    `/private/items/pause-payment-request/${recurringPaymentId}`
  );
  return data as IGenericHttpResponse;
};

export const unpauseRecurringRequestForPayment = async (
  recurringPaymentId: number | undefined
) => {
  const { data } = await request.put(
    `/private/items/unpause-payment-request/${recurringPaymentId}`
  );
  return data as IGenericHttpResponse;
};

export const checkItemsDetailsForDeposit = async (itemsIds: number[]) => {
  return request.post<any>(`private/items/check-items-for-deposit`, {
    items: itemsIds,
  });
};

export const loginWithGoogle = async (accessToken: string) => {
  return request.post<LoginResponse>("public/loginPortalWithGoogle", {
    accessToken,
  });
};

export const loginWithMicrosoft = async (accessToken: string) => {
  return request.post<LoginResponse>("public/loginPortalWithMicrosoft", {
    accessToken,
  });
};
