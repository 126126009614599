import { DisbursementForm, DisbursementList } from "pages/Disbursements";
import { Route, RouteProps, Routes } from "react-router-dom";
import { Actions, Resources } from "tools/privilegeChecker";
import { PrivDependantRoute } from "./PrivDependantRoute";
import React from "react";
import MultipleDisbursement from "pages/Disbursements/multipleDisbursement/multipleDisbursement";

const DisbursementRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.DISBURSEMENT_PAYMENTS,
            }}
          >
            <DisbursementList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:disbursementId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.DISBURSEMENT_PAYMENTS,
            }}
          >
            <DisbursementList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/create"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.CREATE,
              resource: Resources.DISBURSEMENT_PAYMENTS,
            }}
          >
            <DisbursementForm />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/update/:disbursementId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.CREATE,
              resource: Resources.DISBURSEMENT_PAYMENTS,
            }}
          >
            <DisbursementForm />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/multiple"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.CREATE,
              resource: Resources.DISBURSEMENT_PAYMENTS,
            }}
          >
            <MultipleDisbursement />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default DisbursementRoutes;
