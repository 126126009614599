import React, { useCallback, useState } from "react";
import { Container, Icon, TypoGraph } from "components";
import Button from "components/button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import strings from "strings";
import { InputFormField } from "components/hookForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  IImageEndorsementRequest,
  IPayment,
  ISubmitItemResult,
} from "interfaces";
import { submitACHItems, submitItems } from "api";

interface IMultipleSubmitDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedPayments: IPayment[];
  onSuccessCallback: (response: ISubmitItemResult[]) => void;
}

const MultipleSubmitDialog: React.FC<IMultipleSubmitDialogProps> = (props) => {
  const { open, handleClose, selectedPayments, onSuccessCallback } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<any>();
  const { handleSubmit, reset } = methods;

  const onSubmit = async (formData: any) => {
    if (!selectedPayments || selectedPayments?.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const achItems = selectedPayments
        .filter((payment) => payment.paymentMethodId === 1)
        .map((payment) => payment.id);
      const nonAchItems: number[] = selectedPayments
        .filter((payment) => payment.paymentMethodId !== 1)
        .map((payment) => payment.id);
      let note = formData.note || "";
      let responseList: ISubmitItemResult[] = [];
      if (nonAchItems.length > 0) {
        await submitItems({
          items: nonAchItems,
          chequeImageEndorsementListRequest: undefined,
          note,
        }).then((response) => {
          response?.data?.forEach((item) => responseList.push(item));
        });
      }
      if (achItems.length > 0) {
        await submitACHItems(achItems).then((response) => {
          response?.data?.forEach((item) => responseList.push(item));
        });
      }
      reset({
        newStatusId: formData.newStatusId,
        amountInDollars: formData.amountInDollars,
      });
      onSuccessCallback(responseList);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {strings.CONFIRM_DEPOSIT_ACTION}
        </DialogTitle>
        <DialogContent>
          <TypoGraph
            content={`Confirm Deposit Selected (${selectedPayments?.length} items)`}
            variant="caption"
            align="left"
          />
          <Icon name={"icon_deposit"} height={150} width={700} />

          <InputFormField
            name={strings.NOTE.toLowerCase()}
            label={strings.OPTIONAL_NOTE}
            defaultValue={undefined}
            required={false}
          />
          <TypoGraph
            variant="body2"
            content={`The items with these ids will be deposited: ${selectedPayments
              .map((payment) => payment.id)
              ?.join(", ")}`}
          />
        </DialogContent>
        <DialogActions>
          <Container
            sx={{
              gridTemplateColumns: "auto auto",
              justifyContent: "end",
              gridGap: "1rem",
              padding: "12px 20px",
            }}
          >
            <Button
              id={"Cancel"}
              label="Cancel"
              variant="text"
              onClick={() => {
                handleClose();
                reset();
              }}
            />

            <Button
              id={strings.DEPOSIT}
              onClick={handleSubmit(onSubmit)}
              color={"primary"}
              label={strings.DEPOSIT}
              loading={loading}
              disabled={loading}
            />
          </Container>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default MultipleSubmitDialog;
