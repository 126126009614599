import { createTheme, Theme } from "@mui/material/styles";
import { themeSettings } from "theme/themeOverride";
import colors from "theme/colors";

const receivableTheme: Theme = createTheme({
  ...themeSettings,
  typography: {
    ...themeSettings.typography,
    fontFamily: "Inter, sans-serif",
    h5: {
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: "0.5px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontSize: "24px",
      letterSpacing: "0.3px",
    },
    subtitle2: {
      fontSize: "18px",
      fontWeight: 400,
      letterSpacing: "0.5px",
      lineHeight: "20px",
    },
  },
});

const fontStyle: any = {
  h1: {
    fontFamily: "Thicccboi, sans-serif",
    fontSize: "70px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: "0.75px",
    lineHeight: "inherit",
  },
  h2: {
    fontFamily: "Thicccboi, sans-serif",
    fontSize: "48px",
    fontWeight: "normal",
    color: colors.primaryFont,
    lineHeight: "inherit",
  },
  h3: {
    fontFamily: "Thicccboi, sans-serif",
    fontSize: "40px",
    fontWeight: 400,
    color: colors.primaryFont,
    lineHeight: "inherit",
  },
  body1: {
    fontFamily: "Thicccboi, sans-serif",
    fontSize: "24px",
    lineHeight: "inherit",
  },
  subtitle1: {
    fontFamily: "Thicccboi, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "19px",
  },
  digitalCheck: {
    fontFamily: "La Belle Aurore",
    fontSize: "24px",
    lineHeight: "19px",
  },
};

const cardStyles = {
  backgroundColor: "#F8F8F8",
  width: "1023px",
  boxShadow: "0px 16px 24px 4px rgba(18, 28, 45, 0.2)",
  borderRadius: "16px",
};

const snippetCardStyles = {
  backgroundColor: "#F8F8F8",
  width: "1023px",
  boxShadow: "0px 16px 24px 4px rgba(18, 28, 45, 0.2)",
  borderRadius: "16px",
};

const paymentTypesIcons = [
  {
    id: 0,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Earnest Money",
    enabled: true,
  },
  {
    id: 1,
    icon: "icon_securityDeposit",
    buttonLabel: "Send Security Deposit",
    enabled: true,
  },
  {
    id: 2,
    icon: "icon_commision",
    buttonLabel: "Send Commission Check",
    enabled: true,
  },
  {
    id: 3,
    icon: "icon_rent",
    buttonLabel: "Send Rent Payment",
    enabled: true,
  },
  {
    id: 4,
    icon: "icon_hud",
    buttonLabel: "Send HUD Earnest Money",
  },
  {
    id: 6,
    icon: "icon_remittance",
    buttonLabel: "Send Remittance",
  },
  {
    id: 7,
    icon: "icon_optionFee",
    buttonLabel: "Send Option Fee",
    enabled: true,
  },
  {
    id: 8,
    icon: "icon_closingFunds",
    buttonLabel: "Send Closing Funds",
    enabled: true,
  },
  {
    id: 9,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Earnest Money plus Option",
    enabled: true,
  },
  {
    id: 10,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Survey Fees",
    enabled: true,
  },
  {
    id: 11,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Invoice Collection",
    enabled: true,
  },
  {
    id: 12,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Client Payment",
    enabled: true,
  },
  {
    id: 13,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send Agent Fees",
    enabled: true,
  },
  {
    id: 14,
    icon: "icon_paymentsCategory",
    buttonLabel: "Send HOA",
    enabled: true,
  },
  {
    id: 5,
    icon: "icon_other",
    buttonLabel: "Send Other",
    enabled: true,
  },
];

export {
  fontStyle,
  receivableTheme,
  cardStyles,
  paymentTypesIcons,
  snippetCardStyles,
};
