import { ReactNode } from "react";
import { Card, Chip, Container, Divider, Icon, TypoGraph } from "components";
import { IChipProps } from "components/chip";
import styles from "./blade.module.scss";

interface IBladeProps {
  content?: ReactNode;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  show: boolean;
  handleClose?: () => void;
  title?: string;
  headerChipProp?: IChipProps;
  headerLine?: boolean;
}

interface IBladeChildProps {
  children?: ReactNode;
}

const Blade = (props: IBladeProps) => {
  const {
    headerContent,
    content,
    footerContent,
    show,
    handleClose,
    title,
    headerChipProp,
    headerLine = false,
  } = props;

  const BladeHeader = (props: IBladeChildProps) => {
    const { children } = props;

    if (children) return <>{children}</>;

    return (
      <Container>
        <Container className={styles.bladeHeaderContainer}>
          <Container className={styles.titleContainer}>
            <TypoGraph variant="h2" content={title} />
            {headerChipProp && <Chip {...headerChipProp} />}
          </Container>
          <Icon
            imageId="closeBlade"
            name="icon_cancel"
            size={16}
            iconButton
            onClick={handleClose}
          />
        </Container>
        {headerLine && <Divider sx={{ marginTop: 0 }} />}
      </Container>
    );
  };

  const BladeContent = (props: IBladeChildProps) => {
    const { children } = props;
    return (
      <Container className={styles.bladeContentContainer}>
        <Container className={styles.content}>{children}</Container>
      </Container>
    );
  };

  const BladeFooter = (props: IBladeChildProps) => {
    const { children } = props;
    return (
      <Container className={styles.bladeFooterContainer}>{children}</Container>
    );
  };

  return (
    <Container
      className={styles.bladeWrapper}
      sx={{ display: show ? "grid" : "none" }}
    >
      <Card sxContent={{ height: "100%", padding: 0 }}>
        <Container className={styles.bladeContainer}>
          <BladeHeader children={headerContent} />
          <BladeContent children={content} />
          <BladeFooter children={footerContent} />
        </Container>
      </Card>
    </Container>
  );
};

export default Blade;
