import { Container, CustomDivider, UserInfoForm } from "components";
import { InputFormField, DatePickerFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { DropdownOptions } from "interfaces";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";
import { useState } from "react";
import { todayDate, MONT_YEAR_FORMAT } from "constants/datePicker";
import { formatDateToMMDDYYYY } from "tools/format";
import { formatDate } from "tools/validators";
import { useFormContext } from "react-hook-form";
import { isDate } from "moment";

interface AgentFeesComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const AgentFeesComponent: React.FC<AgentFeesComponentProps> = (props) => {
  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date>(
    new Date()
  );
  const { getValues, setValue } = useFormContext();
  return (
    <Container className={styles.transactionDetailContainer}>
      <Container>
        <CustomDivider title="Your Info" />
        <UserInfoForm />
        <CustomDivider title="Payment Details" />
        <Container className={styles.buyersContainer}>
          <InputFormField
            name="realEstateLicense"
            label="Real State License #"
            maxLength={12}
            required
          />
          <InputFormField name="nickname" label="Nickname" maxLength={80} />
          <DatePickerFormField
            name={"coveringDate"}
            onChange={(e: React.ChangeEvent<any> | null) => {
              isDate(e) && setSelectedPeriodDate(e);
              isDate(e) && setValue("coveringDate", formatDateToMMDDYYYY(e));
            }}
            minDate={todayDate}
            inputFormat={MONT_YEAR_FORMAT}
            views={["month"]}
            value={
              getValues("coveringDate")
                ? new Date(formatDate(getValues("coveringDate")))
                : selectedPeriodDate
            }
            label={"Which period is this covering?"}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default AgentFeesComponent;
