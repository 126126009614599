const profileTabs = [
  {
    label: "Profile",
    value: 0,
  },
  {
    label: "Signature",
    value: 1,
  },
  {
    label: "Security",
    value: 2,
  },
];

export { profileTabs };
