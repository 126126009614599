import React, { FormEvent, useEffect, useState } from "react";
import { Button, Container, Toast as toast, TypoGraph } from "components";
import { TextField } from "components/controllers";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import { useNavigate, useParams } from "react-router-dom";
import { confirmSelfReset2fa, validateSelfReset2fa } from "api";

interface IResetProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const Confirm2faRest = (props: IResetProps) => {
  const { styles, handleStep } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    password: "",
  });
  const { resetId } = useParams();

  const { password } = formValues;

  const handleFormChange = (e: React.ChangeEvent<any>, name: string) => {
    setFormValues({
      ...formValues,
      [name]: e.target.value,
    });
  };

  useEffect(() => {
    if (resetId) {
      validateSelfReset2fa({ resetId }).catch(() => {
        navigate("login");
        handleStep(0);
      });
    }
  }, [resetId, handleStep, navigate]);

  const onConfirmPressed = (e: FormEvent) => {
    e.preventDefault();
    if (resetId) {
      setLoading(true);
      confirmSelfReset2fa({ password: password, resetId: resetId })
        .then((response) => {
          toast({ title: strings.SUCCESSFULLY_RESET_2FA });
          localStorage.setItem("token", response.data.token);
          handleStep(6);
        })
        .catch(() => {
          navigate("login");
          handleStep(0);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <form onSubmit={onConfirmPressed}>
      <Container className={styles.formContainer}>
        <TypoGraph
          content={strings.YOU_HAVE_TO_ENTER_YOUR_PASSWORD}
          align={"center"}
        />
        <TextField
          id={strings.PASSWORD}
          label={strings.PASSWORD}
          type="password"
          onChange={(e) => handleFormChange(e, "password")}
        ></TextField>
        <Container className={styles.buttonContainer}>
          <Button
            id={strings.CONFIRM_2FA_RESET}
            type="submit"
            loading={loading}
            disabled={password === ""}
            variant="contained"
            label={strings.CONFIRM_2FA_RESET}
          />
        </Container>
        <Container className={styles.buttonContainer}>
          <Button
            id={strings.CANCEL}
            variant="text"
            onClick={() => handleStep(0)}
            label={strings.CANCEL}
          />
        </Container>
      </Container>
    </form>
  );
};

export default Confirm2faRest;
