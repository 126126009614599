import { Tabs as MuiTabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { Container } from "components";
import styles from "./tab.module.scss";

export interface ITabData {
  label: string;
  badgeColor?: string;
  badgeContent?: string;
  filterValue?: string;
  count?: number;
}

interface ITabsProps {
  tabData: ITabData[];
  value: number;
  iconPosition?: "top" | "start" | "end" | "bottom";
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface IMuiTabProps {
  label: string;
  value: number | string;
  key: string;
  iconPosition?: "top" | "start" | "end" | "bottom";
  icon?: any;
}

const MuiTab = styled((props: IMuiTabProps) => (
  <Tab id={"tab-" + (props.value as string)} disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  fontWeight: 500,
  width: "auto",
  minHeight: "45px",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
}));

const Tabs = (props: ITabsProps) => {
  const { tabData, value, handleChange, iconPosition = "end" } = props;
  return (
    <Container sx={{ position: "relative" }}>
      <MuiTabs value={value} onChange={(e, val) => handleChange(e, val)}>
        {tabData?.map((item, index) => (
          <MuiTab
            key={`key-${index}`}
            icon={
              item.badgeColor && (
                <Container
                  className={styles.badge}
                  sx={{ background: item.badgeColor }}
                >
                  12
                </Container>
              )
            }
            value={index}
            label={item.label + (!!item.count ? ` (${item.count})` : ``)}
            iconPosition={iconPosition}
          />
        ))}
      </MuiTabs>
    </Container>
  );
};

interface ITabpanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  className?: string;
}

const TabPanel = (props: ITabpanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export { Tabs, TabPanel };
