const roleNameLookup = (lookup: string | number): string => {
  const accountAdmin = "Account Admin";
  const portalUser = "Portal user";
  const mobileUser = "Mobile user";
  const owner = "Owner";
  switch (typeof lookup) {
    case "string":
      switch (lookup) {
        case "Broker +":
          return accountAdmin;
        case "Broker":
          return portalUser;
        case "Agent":
          return mobileUser;
        default:
          return lookup;
      }
    case "number":
      switch (lookup) {
        case 0:
          return "None";
        case 10:
          return "ServiceAccount";
        case 20:
          return mobileUser;
        case 30:
          return "Receivables Requester";
        case 40:
          return portalUser;
        case 45:
          return accountAdmin;
        case 50:
          return "Reports";
        case 60:
          return "Inquiry";
        case 70:
          return "Maintenance";
        case 100:
          return "SuperAdmin";
        case 120:
          return owner;
        default:
          return "Unknown user role";
      }
    default:
      return "Unknown user role";
  }
};

export { roleNameLookup };
