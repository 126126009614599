import NumberFormat from "react-number-format";
import moment from "moment";
import numberConverter from "number-to-words";
import { Container, Icon, TypoGraph } from "components";
import { fontStyle } from "pages/receivables/util";
import styles from "./digitalCheck.module.scss";

interface IDigitalCheckProps {}

const DigitalCheckPayment: React.FC<IDigitalCheckProps> = (props) => {
  const recipientFirstName = "Sadam";
  const recipientLastName = "Shoukath";
  const amount = 5800;
  const centAmount = 1;
  const memo = "sample digital check for testing";
  return (
    <Container className={styles.mainContainer}>
      <Container className={styles.checkContainer}>
        <Container className={styles.checkHeadContainer}>
          <Container className={styles.logoContainer}>
            <Icon name="smLogo" type="images" height={41} width={57} />
          </Container>
          <Container>
            <TypoGraph variant="h2" content={"Your Company Name"} />
            <TypoGraph content="123 Ameris Way," />
            <TypoGraph content="Atlanta, GA 30303" />
          </Container>
          <Container className={styles.dateContainer}>
            <TypoGraph content="Date" />
            <TypoGraph
              className={styles.bordered}
              sx={fontStyle.digitalCheck}
              content={moment().format("DD-MM-yyyy")}
            />
          </Container>
        </Container>
        <Container className={styles.payNameContainer}>
          <TypoGraph content="Pay To The Order Of" />
          <Container className={styles.dateContainer}>
            <TypoGraph
              sx={fontStyle.digitalCheck}
              className={`${styles.bordered} ${styles.payeeName}`}
              content={`${recipientFirstName || ""} ${recipientLastName || ""}`}
            />
          </Container>
          <Container className={styles.amountContainer}>
            <NumberFormat
              value={amount || 0}
              renderText={(value) => <TypoGraph>{value}</TypoGraph>}
              displayType="text"
              thousandSeparator={true}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale={true}
              isNumericString={true}
            />
          </Container>
        </Container>
        <Container className={styles.dollarContainer}>
          <Container>
            <TypoGraph sx={fontStyle.digitalCheck} className={styles.bordered}>
              {`${
                amount
                  ? `${numberConverter.toWords(amount)} and ${
                      centAmount || 0
                    }/100`
                  : ""
              }`}
            </TypoGraph>
          </Container>
          <TypoGraph content="Dollars" />
        </Container>
        <Container className={styles.memoContainer}>
          <TypoGraph content="Memo" />
          <TypoGraph
            sx={fontStyle.digitalCheck}
            content={memo}
            className={styles.bordered}
          />
          <TypoGraph className={styles.bordered} />
        </Container>
      </Container>
    </Container>
  );
};

export default DigitalCheckPayment;
