import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import { IPayment } from "interfaces/IPayment";
import { TextField } from "components/controllers";
import { addNoteToItem, getItemHistory } from "api";
import strings from "strings";
import { IItemHistory, IPageResponse } from "interfaces";
import styles from "./paymentHistory.module.scss";
import { actionText } from "constants/actions";

interface IPaymentDetailProps {
  payment?: IPayment;
  handleSuccessCallback: () => void;
}

const PaymentHistoryComponent: React.FC<IPaymentDetailProps> = (props) => {
  const { payment, handleSuccessCallback } = props;
  const [note, setNote] = useState<string>();
  const [itemHistory, setItemHistory] = useState<IPageResponse<IItemHistory>>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>();
  const [loading, setLoading] = useState(true);

  const loadHistory = useCallback(
    (pageNumber: number = 1, pageSize: number = 5) => {
      if (!payment) {
        return;
      }
      setLoading(true);
      getItemHistory(payment.id, pageNumber - 1, pageSize)
        .then((response) => {
          setItemHistory(response?.data);
          setLastPage(Math.ceil(response?.data?.totalCount / pageSize));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [payment]
  );

  useEffect(() => {
    loadHistory(pageNumber);
  }, [pageNumber, loadHistory]);

  const onAddNoteClicked = () => {
    if (payment && note) {
      addNoteToItem(payment?.id, note).then((response) => {
        toast({
          title: strings.ADDED_NOTE_SUCCESSFULLY,
          subTitle: `${strings.ITEM_ID}: ${response?.data?.itemId}`,
        });
        handleSuccessCallback();
      });
    }
  };

  return (
    <Container sx={{ paddingTop: "10px" }}>
      <Divider title={strings.ITEM_HISTORY.toUpperCase()} />
      <Loader loading={loading} />
      {itemHistory?.items && itemHistory?.items.length > 0 && (
        <Container className={styles.paginationContainer}>
          <Button
            id={strings.PREVIOUS}
            disabled={pageNumber <= 1}
            variant="text"
            label={strings.PREVIOUS}
            onClick={() => {
              setPageNumber((pageNumber) => pageNumber - 1);
            }}
          />
          <TypoGraph content={`${strings.PAGE} ${pageNumber}/${lastPage}`} />
          <Button
            id={strings.NEXT}
            disabled={pageNumber === lastPage}
            variant="text"
            label={strings.NEXT}
            onClick={() => {
              setPageNumber((pageNumber) => pageNumber + 1);
            }}
          />
        </Container>
      )}
      {itemHistory?.items.map((itemTransaction) => (
        <Container key={`Transaction ${itemTransaction.id}`}>
          <TypoGraph
            variant="body1"
            content={
              actionText[itemTransaction.action] || itemTransaction.action
            }
          />
          <TypoGraph variant="body2" content={itemTransaction.note} />
          {(itemTransaction.firstName || itemTransaction.lastName) && (
            <TypoGraph
              variant="body2"
              content={`By ${itemTransaction.firstName} ${itemTransaction.lastName}`}
            />
          )}
          <TypoGraph variant="caption" content={`${itemTransaction.created}`} />
          <Container
            sx={{
              gridTemplateColumns: "auto auto",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
          ></Container>
        </Container>
      ))}

      <TextField
        id={strings.NOTE}
        label={strings.NOTE}
        multiline={true}
        rows={4}
        value={note}
        onChange={(e: React.ChangeEvent<any>) => setNote(e.target.value)}
      />
      <Container sx={{ justifyContent: "end" }}>
        <Button
          id={strings.ADD_NOTE}
          label={strings.ADD_NOTE}
          variant="outlined"
          onClick={() => onAddNoteClicked()}
        />
      </Container>
      <Container
        sx={{
          gridTemplateColumns: "auto auto",
          justifyContent: "space-between",
          padding: "5px 10px",
        }}
      ></Container>
    </Container>
  );
};

export default PaymentHistoryComponent;
