import { ObjectAnyKeys } from "interfaces/UtilitiesModule";

export const Types = {
  SET_PAYMENTS_LIST: "SET_PAYMENTS_LIST",
};

/**
 * Dispatch an action to reducer and changes the state values
 */
export const dispatchPaymentList = (data: ObjectAnyKeys[]) => ({
  type: Types.SET_PAYMENTS_LIST,
  data,
});
