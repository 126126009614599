import React, { useEffect, useState } from "react";
import styles from "./desktopNavBar.module.scss";
import { Icon, TypoGraph } from "components";
import { INavBarActionItem } from "interfaces/IWhitelabelConfigurationResponse";
import NavBarItemWithPopOverList from "./navBarWithPopoverList";

interface DesktopNavBarProps {
  items?: INavBarActionItem[];
  logoBase64?: string;
  logoSize?: number;
}

const DesktopNavBar: React.FC<DesktopNavBarProps> = ({
  items,
  logoBase64,
  logoSize,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const configHasItems = items && items.length > 0;

  const controlNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div
      className={`${
        configHasItems ? styles.navbar : styles.navbarWithoutItems
      } ${isScrolled ? styles.fadeEffect : ""}`}
    >
      <Icon
        type="images"
        url={`data:image/png;base64,${logoBase64}`}
        height={logoSize || 45}
      />
      <div className={styles.navItems}>
        {items?.map((item, index) =>
          item.subItems && item.subItems.length > 0 ? (
            <NavBarItemWithPopOverList item={item} index={index} />
          ) : (
            <TypoGraph
              key={index}
              content={item.content}
              className={styles.navItem}
              onClick={() => {
                window.open(item.url);
              }}
              link
            />
          )
        )}
      </div>
    </div>
  );
};

export default DesktopNavBar;
