const canvasConfig = {
  vertices: [
    { x: 200, y: 150 },
    { x: 500, y: 150 },
    { x: 500, y: 350 },
    { x: 200, y: 350 },
  ],
  rect: {
    startX: 100,
    startY: 100,
    w: 300,
    h: 200,
  },
  rectMaxWidth: 50,
  rectMaxHeight: 50,
  closeEnough: 10,
  width: 700,
  height: 500,
};

export { canvasConfig };
