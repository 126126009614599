import React, { useEffect } from "react";
import {
  Container,
  CustomDivider,
  PropertyForm,
  UserInfoForm,
} from "components";
import { InputFormField, DropdownFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import regex from "regex";
import strings from "strings";
import { DropdownOptions } from "interfaces";
import { availableEarnestMoneyRoles } from "constants/dropdownOptions";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";
import { useFormContext, useWatch } from "react-hook-form";

interface HOAComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const HOAComponent: React.FC<HOAComponentProps> = (props) => {
  const { getValues, setValue, control } = useFormContext();
  const { statesOptions, customLabels } = props;

  const selectedRoleWatch = useWatch({
    control,
    name: "trrole",
    defaultValue: getValues("trrole") || "",
  });

  useEffect(() => {
    if (getValues("trrole") === "") {
      setValue("buyerFirstName", undefined);
      setValue("buyerLastName", undefined);
      setValue("buyerEmail", undefined);
      setValue("buyerPhone", undefined);
    }
  }, [selectedRoleWatch]);

  return (
    <Container className={styles.transactionDetailContainer}>
      <Container>
        <DropdownFormField
          name={"trrole"}
          label={"Your role in the transaction"}
          options={availableEarnestMoneyRoles}
          required
        />
        {getValues("trrole") !== undefined && (
          <Container>
            <CustomDivider
              title={
                getValues("trrole") === availableEarnestMoneyRoles[0].value
                  ? "Buyer's Info"
                  : "Agent's Info"
              }
            />
            <UserInfoForm selectedRole={getValues("trrole")} />
            <CustomDivider title="Additional Contact Information" />
            <Container className={styles.buyersContainer}>
              <InputFormField
                name="buyerFirstName"
                label={
                  getValues("trrole") !== availableEarnestMoneyRoles[0].value
                    ? "Buyer's First Name"
                    : "Agent's First Name"
                }
                required
              />
              <InputFormField
                name="buyerLastName"
                label={
                  getValues("trrole") !== availableEarnestMoneyRoles[0].value
                    ? "Buyer's Last Name"
                    : "Agent's Last Name"
                }
                required
              />
              <InputFormField
                name="buyerEmail"
                label={
                  getValues("trrole") !== availableEarnestMoneyRoles[0].value
                    ? "Buyer's Email"
                    : "Agent's Email"
                }
                pattern={{
                  value: regex.EMAIL_REGEX_PATTERN,
                  message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                }}
                required
              />
              <InputFormField
                name="buyerPhone"
                label={
                  getValues("trrole") !== availableEarnestMoneyRoles[0].value
                    ? "Buyer's Phone Number"
                    : "Agent's Phone Number"
                }
                type="number"
                required
              />
              <InputFormField name="name3" label="Seller's name" />
              <InputFormField
                name="titleCoContact"
                label={"Title Co. Contact"}
                maxLength={35}
              />
            </Container>
          </Container>
        )}
        <CustomDivider title="Property Details" />
        <PropertyForm
          statesOptions={statesOptions}
          customLabels={customLabels?.address}
        />
      </Container>
    </Container>
  );
};

export default HOAComponent;
