import { IDecodedToken } from "interfaces/IdecodedToken";
import jwt_Decode from "jwt-decode";

export default function startIntercom(token: string) {
  const decodedToken: IDecodedToken = jwt_Decode(token);
  // @ts-ignore
  window.Intercom("boot", {
    // @ts-ignore
    app_id: "ld73ztx4",
    email: decodedToken.pemail1,
    name: decodedToken.fullName,
    user_id: decodedToken.puid,
    created_at: decodedToken.createdAt,
  });
  // @ts-ignore
}
