import { Container, Icon, TypoGraph } from "components";
import Button, { IButtonProps } from "components/button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export type ISubmitDialogType = "success" | "warning" | "delete";
export interface ISubmitDialogProps {
  open: boolean;
  primaryBtnProps?: IButtonProps;
  secondaryBtnProps?: IButtonProps;
  type?: ISubmitDialogType;
  title?: string;
  body1?: string;
  body2?: string;
  showCancel?: boolean;
  handleClose: () => void;
}

const SubmitDialog: React.FC<ISubmitDialogProps> = (props) => {
  const {
    open,
    handleClose,
    primaryBtnProps,
    secondaryBtnProps,
    type = "success",
    title = "Confirmation",
    showCancel = true,
    body1,
    body2,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Icon name={`icon_dialog_${type}`} height={150} width={700} />
        <TypoGraph
          mt={2}
          mb={2}
          content={body1}
          variant="body1"
          align="center"
        />
        <TypoGraph content={body2} variant="body2" align="center" />
      </DialogContent>
      <DialogActions>
        <Container
          sx={{
            gridTemplateColumns: "auto auto",
            justifyContent: "end",
            gridGap: "1rem",
            padding: "12px 20px",
          }}
        >
          {showCancel && (
            <Button
              label="Cancel"
              variant="text"
              onClick={handleClose}
              {...secondaryBtnProps}
            />
          )}
          {primaryBtnProps && (
            <Button
              color={type === "delete" ? "error" : "primary"}
              {...primaryBtnProps}
            />
          )}
        </Container>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitDialog;
