import {
  Container,
  CustomDivider,
  PropertyForm,
  UserInfoForm,
} from "components";
import { InputFormField, DatePickerFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { DropdownOptions } from "interfaces";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";
import { useState } from "react";
import { todayDate, DATE_FORMAT_YYYY_MM_DD } from "constants/datePicker";
import { formatDateToMMDDYYYY } from "tools/format";
import { formatDate } from "tools/validators";
import { useFormContext } from "react-hook-form";
import { isDate } from "moment";

interface ClientPaymentComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const ClientPaymentComponent: React.FC<ClientPaymentComponentProps> = (
  props
) => {
  const { statesOptions, customLabels } = props;
  const [closeDate, setCloseDate] = useState<Date>(new Date());
  const { getValues, setValue } = useFormContext();
  return (
    <Container className={styles.transactionDetailContainer}>
      <Container>
        <CustomDivider title="Your Info" />
        <UserInfoForm />
        <CustomDivider title="Payment Details" />
        <Container className={styles.buyersContainer}>
          <InputFormField
            name="crrAgentName"
            label="CRR Agent's Name"
            required
          />
          <InputFormField name="mlsNumber" label="MLS Number" maxLength={8} />
          <DatePickerFormField
            name={"closedate"}
            onChange={(e: React.ChangeEvent<any> | null) => {
              isDate(e) && setCloseDate(e);
              isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
            }}
            minDate={todayDate}
            inputFormat={DATE_FORMAT_YYYY_MM_DD}
            value={
              getValues("closedate")
                ? new Date(formatDate(getValues("closedate")))
                : closeDate
            }
            label={"Closing Date"}
          />
        </Container>
        <CustomDivider title="Property Details" />
        <PropertyForm
          statesOptions={statesOptions}
          customLabels={customLabels?.address}
        />
      </Container>
    </Container>
  );
};

export default ClientPaymentComponent;
