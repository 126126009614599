import { toast as event, ToastContainer as TContainer } from "react-toastify";
import { Container, TypoGraph } from "components";

interface IToastContainerProps {
  position?: "bottom-right";
  autoClose?: number;
}

export const ToastContainer = (props: IToastContainerProps) => {
  const {
    position = "bottom-right",
    autoClose = 5000, // default autoclose is 5 sec
  } = props;
  return (
    <TContainer
      position={position}
      autoClose={autoClose}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

interface IToastContent {
  title: string;
  subTitle?: string;
}

const ToastContent = (props: IToastContent) => {
  const { title, subTitle } = props;
  return (
    <Container>
      <TypoGraph id={"toastTitle"} content={title} />
      {subTitle && (
        <TypoGraph
          id={"toastSubtitle"}
          variant="subtitle2"
          content={subTitle}
        />
      )}
    </Container>
  );
};

interface IToastProps {
  type?: "info" | "success" | "warning" | "error";
  title: string;
  subTitle?: string;
  position?: "bottom-right";
  autoClose?: number;
  theme?: "light" | "dark" | "colored";
  showIcon?: boolean;
}

const Toast = (props: IToastProps) => {
  const {
    type = "success",
    title = "",
    subTitle = "",
    position = "bottom-right",
    autoClose = 5000,
    theme = "light",
    showIcon = true,
  } = props;
  return event[type](<ToastContent title={title} subTitle={subTitle} />, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
    icon: showIcon,
  });
};

export default Toast;
