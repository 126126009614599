export const paymentStatus = {
  NEW: 0,
  READ: 1,
  ON_HOLD: 2,
  REJECTED: 3,
  DEPOSITED: 4,
  BP_SUBMITTED: 5,
  BP_REJECTED: 6,
  BP_REVIEW: 7,
  BP_SUCCESS: 8,
  PROCESSED: 9,
  LIMIT_HOLD: 10,
  ACH_SUBMIT_QUEUED: 11,
  ACH_SUBMIT_FAIL: 12,
  ACH_SUBMIT_SUCCESS: 13,
  ACH_PAID: 14,
  ACH_FAIL: 15,
  ACH_IN_PROGRESS: 16,
  ACH_VOID: 17,
  DC_PRINT_PENDING: 18,
  DC_PRINTED: 19,
  DC_PRINT_FAILED: 20,
  REQUESTED: 21,
  PASSPORT_ACH_SUBMITTED: 22,
  PASSPORT_ACH_COMPLETED: 23,
  PASSPORT_ACH_FAILED: 24,
  PASSPORT_WITHDRAWN_USING_WIRE: 25,
  PASSPORT_WITHDRAWN_USING_ACH: 26,
  RECURRING: 27,
  PASSPORT_RC_SUBMITTED: 28,
  PASSPORT_RC_COMPLETED: 29,
  PASSPORT_RC_FAILED: 30,
};

export const passportIds = [
  paymentStatus.PASSPORT_ACH_SUBMITTED,
  paymentStatus.PASSPORT_ACH_COMPLETED,
  paymentStatus.PASSPORT_RC_SUBMITTED,
  paymentStatus.PASSPORT_RC_COMPLETED,
  paymentStatus.PASSPORT_WITHDRAWN_USING_WIRE,
  paymentStatus.PASSPORT_WITHDRAWN_USING_ACH,
  paymentStatus.PASSPORT_RC_FAILED,
  paymentStatus.PASSPORT_ACH_FAILED,
];
