import React, { useEffect, useState } from "react";
import {
  Container,
  Icon,
  Loader,
  StepperComponent,
  TypoGraph,
} from "components";
import strings from "strings";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import toast from "components/toast";
import { FormProvider, useForm } from "react-hook-form";
import { DropdownOptions, IPayment, ISubmitItemResult } from "interfaces";
import {
  itemsDetailsForDeposit,
  withdrawMultipleItemsToExternalAccount,
  withdrawMultipleItemsToSameAccount,
} from "api";
import DepositFormRow from "./partial/depositFormRow";
import styles from "./multiplePassportDepositDialog.module.scss";
import {
  IPaymentForDeposit,
  ISelectedOptions,
  IWithdrawItemRequest,
  IWithdrawItemsSameAccountRequest,
} from "interfaces/IMultiplePassportDeposit";
import { CheckboxFormField, DropdownFormField } from "components/hookForm";
import { fireworksConfetti } from "tools/confetti";

interface IMultiplePassportDepositDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedPayments: IPayment[];
  onSuccessCallback: (response: ISubmitItemResult[]) => void;
}

const MultiplePassportDepositDialog: React.FC<
  IMultiplePassportDepositDialogProps
> = (props) => {
  const { open, handleClose, selectedPayments, onSuccessCallback } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<any>();
  const { handleSubmit, reset, getValues, watch, setValue } = methods;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentForDeposit, setPaymentDeposit] = useState<IPaymentForDeposit[]>(
    []
  );
  const [paymentsForDepositList, setPaymentForDepositList] = useState<
    ISelectedOptions[]
  >([]);
  const [formValid, setFormValid] = useState(false);
  const [depositError, setDepositError] = useState<boolean>(false);
  const steps = ["Select Bank Account", "Confirm Details", "Done"];
  const [isSameAccountDeposit, setIsSameAccountDeposit] =
    useState<boolean>(false);

  const [sameAccountDepositAvailable, setSameAccountDepositAvailable] =
    useState<boolean>(false);
  const [availableJointAccounts, setAvailableJointAccounts] = useState<
    DropdownOptions[]
  >([]);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    DropdownOptions[]
  >([]);
  const [selectedAccountId, setSelectedAccountId] = useState<any>();
  const [selectedAccountLabel, setSelectedAccountLabel] = useState<
    string | undefined | number
  >();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<any>();
  const [successDepositCounter, setSuccessDepositCounter] = useState<number>(0);
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>();

  const onSubmit = async (formData: any) => {
    if (activeStep == 3) {
      handleClose();
      reset();
      return;
    }
    if (activeStep == 1) {
      setDepositLoading(true);
      let responseList: ISubmitItemResult[] = [];
      let itemDepositCounter: number = 0;

      if (!isSameAccountDeposit) {
        const request: IWithdrawItemRequest[] = paymentsForDepositList.map(
          ({ itemId, destinationReceivableAccountId, paymentMethodId }) => ({
            itemId,
            destinationReceivableAccountId,
            paymentMethodId,
          })
        );
        await withdrawMultipleItemsToExternalAccount(request)
          .then((response) => {
            response?.data?.forEach((item) => {
              if (item.success) {
                itemDepositCounter++;
              }
              responseList.push(item);
            });
            setSuccessDepositCounter(itemDepositCounter);
            onSuccessCallback(responseList);
            if (itemDepositCounter > 0) {
              fireworksConfetti();
            }
            setActiveStep(3);
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.message || "Unknown Error";

            setResponseData(errorMessage);
            setDepositError(true);
          })
          .finally(() => {
            setDepositLoading(false);
          });
      } else {
        const request: IWithdrawItemsSameAccountRequest = {
          itemsIdList: paymentsForDepositList.map((item) => item.itemId),
          destinationReceivableAccountId: selectedAccountId,
          paymentMethodId: selectedPaymentMethodId,
        };

        withdrawMultipleItemsToSameAccount(request)
          .then((response) => {
            response?.data?.forEach((item) => {
              if (item.success) {
                itemDepositCounter++;
              }
              responseList.push(item);
            });
            setSuccessDepositCounter(itemDepositCounter);
            onSuccessCallback(responseList);
            if (itemDepositCounter > 0) {
              fireworksConfetti();
            }
            setActiveStep(3);
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.message || "Unknown Error";

            setResponseData(errorMessage);
            setDepositError(true);
          })
          .finally(() => {
            setDepositLoading(false);
          });
      }
    } else {
      if (formValid) {
        setActiveStep(activeStep + 1);
      } else {
        toast({
          type: "warning",
          title: "Invalid Form",
          subTitle: `Select all the options`,
        });
      }
    }
  };

  function addSelectedOptions(selectedOptions: ISelectedOptions) {
    const index = paymentsForDepositList.findIndex(
      (payment) => payment.itemId === selectedOptions.itemId
    );

    let newPaymentsForDepositRequest;

    if (index !== -1) {
      newPaymentsForDepositRequest = [
        ...paymentsForDepositList.slice(0, index),
        selectedOptions,
        ...paymentsForDepositList.slice(index + 1),
      ];
    } else {
      newPaymentsForDepositRequest = [
        ...paymentsForDepositList,
        selectedOptions,
      ];
    }
    setPaymentForDepositList(newPaymentsForDepositRequest);
    setFormValid(
      newPaymentsForDepositRequest.length == selectedPayments.length
    );
  }

  // Load item information and initialize constants.
  useEffect(() => {
    if (open) {
      setFormValid(false);
      setPaymentForDepositList([]);
      setActiveStep(0);
      setSuccessDepositCounter(0);
      setIsSameAccountDeposit(false);
      setAvailableJointAccounts([]);
      setAvailablePaymentMethods([]);
      setSameAccountDepositAvailable(false);
      setLoading(true);
      const items = selectedPayments.map((payment) => payment.id);
      itemsDetailsForDeposit(items)
        .then((response: any) => {
          setPaymentDeposit(response.data);

          const commonReceivableAccounts = response.data
            .map((payment: IPaymentForDeposit) =>
              payment.receivableAccounts.map((account: any) => account.id)
            )
            .reduce((commonIds: any, accountIds: any) =>
              commonIds.filter((id: any) => accountIds.includes(id))
            );
          const commonAccounts = response.data[0].receivableAccounts.filter(
            (account: any) => commonReceivableAccounts.includes(account.id)
          );

          if (commonAccounts.length > 0) {
            setSameAccountDepositAvailable(true);
            const accountOptions = commonAccounts.map((account: any) => {
              return {
                value: account.id,
                label: account.accountName,
                externalDepositPaymentMethods:
                  account.externalDepositPaymentMethods,
              };
            });

            setAvailableJointAccounts(accountOptions);
          }

          setLoading(false);
        })
        .catch(() => {
          handleClose();
          reset();
        });
    }
  }, [open]);

  // Handles the change of form (same account <-> multiple account).
  useEffect(() => {
    if (!getValues("sameBankAccount") && !sameAccountDepositAvailable) {
      return;
    }
    if (sameAccountDepositAvailable) {
      setIsSameAccountDeposit(getValues("sameBankAccount"));
      setPaymentForDepositList([]);
      setValue("accountId", undefined);
      setValue("paymentMethodId", undefined);
      setAvailablePaymentMethods([]);
      setFormValid(false);
    } else {
      toast({
        type: "warning",
        title: "Not available option",
        subTitle: `No shared accounts for selected payments`,
      });
      setValue("sameBankAccount", false);
    }
  }, [watch("sameBankAccount")]);

  // Handles account selection to load the payment methods.
  useEffect(() => {
    const accountId = getValues("accountId");
    if (selectedAccountId) {
      setFormValid(false);
    }
    setSelectedAccountId(accountId);
    setSelectedAccountLabel(
      availableJointAccounts?.find((option) => option.value === accountId)
        ?.label
    );

    setValue("paymentMethodId", undefined);
    if (availableJointAccounts) {
      const options: any = availableJointAccounts;
      const selectedOption = options.find(
        (option: any) => option.value === accountId
      );

      if (selectedOption) {
        const paymentMethodsOptions =
          selectedOption.externalDepositPaymentMethods.map((method: any) => {
            return {
              value: method.id,
              label: method.name,
            };
          });
        setAvailablePaymentMethods(paymentMethodsOptions);
      }
    }
  }, [watch("accountId")]);

  // Handle payment method selection for create the request (Same account form).
  useEffect(() => {
    const paymentMethodId = getValues("paymentMethodId");
    const paymentMethodLabel = availablePaymentMethods?.find(
      (option) => option.value === paymentMethodId
    )?.label;
    if (paymentMethodId != undefined) {
      setSelectedPaymentMethodId(paymentMethodId);
      setPaymentForDepositList([]);
      selectedPayments.forEach((selectedPayment) => {
        const selectedOptions: ISelectedOptions = {
          itemId: selectedPayment.id,
          accountLabel: selectedAccountLabel,
          destinationReceivableAccountId: selectedAccountId,
          methodLabel: paymentMethodLabel,
          paymentMethodId: paymentMethodId,
        };

        setPaymentForDepositList((prevPayments) => [
          ...prevPayments,
          selectedOptions,
        ]);
      });

      setFormValid(true);
    }
  }, [watch("paymentMethodId")]);

  const nextBtnLabel = () => {
    if (activeStep == 1) {
      if (depositError) {
        return "Try again";
      }
      return "Confirm Deposit";
    }
    return "Next";
  };

  const backBtnLabel = () => {
    if (activeStep == 1) {
      return "Back";
    }
    return "";
  };

  const handleStepBack = () => {
    if (activeStep == 1) {
      reset();
      setFormValid(false);
      setPaymentForDepositList([]);
      setDepositError(false);
      setIsSameAccountDeposit(false);
      setActiveStep(activeStep - 1);
    }
  };

  const getSelectedAccountLabel = (paymentId: number) => {
    const selectedOption = paymentsForDepositList.find(
      (payment) => payment.itemId === paymentId
    );
    return selectedOption ? selectedOption.accountLabel?.toString() : "-";
  };

  const getSelectedMethodLabel = (paymentId: number) => {
    const selectedOption = paymentsForDepositList.find(
      (payment) => payment.itemId === paymentId
    );
    return selectedOption ? selectedOption.methodLabel?.toString() : "-";
  };

  const getTotalAmount = () => {
    if (paymentForDeposit && paymentForDeposit.length > 0) {
      const totalAmountInCents = paymentForDeposit?.reduce((total, payment) => {
        return total + payment.amount;
      }, 0);

      const totalAmountString = totalAmountInCents.toString();
      const formattedTotalAmount = `$${totalAmountString.slice(
        0,
        -2
      )}.${totalAmountString.slice(-2)}`;

      return formattedTotalAmount;
    }
  };

  const DetailsHeader = () => {
    return (
      <Container
        className={
          activeStep == 0 && !isSameAccountDeposit
            ? styles.depositRow
            : styles.confirmDetailsRow
        }
      >
        <TypoGraph
          sx={{ fontWeight: "bolder" }}
          content={"Id Number"}
        ></TypoGraph>
        <TypoGraph
          sx={{ fontWeight: "bolder" }}
          content={"Property"}
        ></TypoGraph>
        <TypoGraph
          sx={{ fontWeight: "bolder" }}
          content={"Received As"}
        ></TypoGraph>
        <TypoGraph sx={{ fontWeight: "bolder" }} content={"Amount"}></TypoGraph>
        {(!isSameAccountDeposit || activeStep == 1) && (
          <>
            <TypoGraph
              sx={{ fontWeight: "bolder" }}
              className={styles.accountDropdown}
              content={"Bank Account"}
            ></TypoGraph>
            <TypoGraph
              sx={{ fontWeight: "bolder" }}
              className={styles.accountDropdown}
              content={"Deposit Method"}
            ></TypoGraph>
          </>
        )}
      </Container>
    );
  };

  const ConfirmDetails = () => {
    if (depositError) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_warning"} height={150} width={552} />
            <TypoGraph
              variant="body1"
              align="center"
              content={"Oh no! Something went wrong."}
            />
            <TypoGraph variant="body2" align="center" content={responseData} />
          </Container>
        </Container>
      );
    }
    return (
      <Container>
        <DetailsHeader />
        {paymentForDeposit?.map((payment) => (
          <Container className={styles.confirmDetailsRow}>
            <TypoGraph content={payment.paymentId.toString()}></TypoGraph>
            <TypoGraph
              content={payment.property ? payment.property : "-"}
            ></TypoGraph>
            <TypoGraph content={payment.paymentMethod.name}></TypoGraph>
            <TypoGraph content={payment.amountFormatted}></TypoGraph>

            <TypoGraph
              content={getSelectedAccountLabel(payment.paymentId)}
            ></TypoGraph>
            <TypoGraph
              content={getSelectedMethodLabel(payment.paymentId)}
            ></TypoGraph>
          </Container>
        ))}
      </Container>
    );
  };

  const SuccessStep = () => {
    return (
      <Container>
        <Container className={styles.responseContainer}>
          <Icon
            name={
              successDepositCounter > 0
                ? "icon_dialog_success"
                : "icon_dialog_warning"
            }
            height={150}
            width={552}
          />
          <Container>
            <TypoGraph align="center" variant="body1">
              {successDepositCounter}{" "}
              {successDepositCounter == 1 ? "Receivable" : "Receivables"} has
              been deposited successfully.
            </TypoGraph>
          </Container>
        </Container>
      </Container>
    );
  };

  const Footer = () => {
    return (
      <Container>
        <Container sx={{ display: "flex", marginTop: "16px" }}>
          <TypoGraph>
            Deposit {paymentForDeposit.length} Payments for total amount of{" "}
            <span className={styles.boldText}> {getTotalAmount()}.</span>
          </TypoGraph>
        </Container>
        {selectedPayments.length > 1 && (
          <Container sx={{ marginTop: "16px" }}>
            <CheckboxFormField
              id={"sameBankAccount"}
              name={"sameBankAccount"}
              label={"Deposit into same Bank Acccount"}
            />
          </Container>
        )}
      </Container>
    );
  };

  const SameAccountForm = () => {
    return (
      <Container>
        {paymentForDeposit?.map((payment) => (
          <Container className={styles.sameAccountRow}>
            <TypoGraph content={payment.paymentId.toString()}></TypoGraph>
            <TypoGraph
              content={payment.property ? payment.property : "-"}
            ></TypoGraph>
            <TypoGraph content={payment.paymentMethod.name}></TypoGraph>
            <TypoGraph content={payment.amountFormatted}></TypoGraph>
          </Container>
        ))}
        <Container className={styles.selectOptionsContainer}>
          <Container>
            <TypoGraph
              sx={{ fontWeight: "bolder" }}
              content={strings.DESTINATION_ACCOUNT}
            ></TypoGraph>

            <DropdownFormField
              {...{
                options: availableJointAccounts,
                label: strings.DESTINATION_ACCOUNT,
                defaultValue: undefined,
                name: "accountId",
                required: true,
              }}
            />
          </Container>
          <Container>
            <TypoGraph
              sx={{ fontWeight: "bolder" }}
              content={strings.PAYMENT_METHOD}
            ></TypoGraph>

            <DropdownFormField
              {...{
                disabled: availablePaymentMethods.length == 0,
                options: availablePaymentMethods,
                label: strings.PAYMENT_METHOD,
                defaultValue: undefined,
                name: "paymentMethodId",
                required: true,
              }}
            />
          </Container>
        </Container>
      </Container>
    );
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <Container>
            <DetailsHeader />
            {isSameAccountDeposit ? (
              <SameAccountForm />
            ) : (
              <Container>
                {paymentForDeposit?.map((payment, index) => (
                  <DepositFormRow
                    paymentId={payment.paymentId}
                    payment={payment}
                    accounts={payment.receivableAccounts}
                    onSelectCallback={(optionsSelected) => {
                      addSelectedOptions(optionsSelected);
                    }}
                    onValidFormCallback={(valid) => {
                      setFormValid(valid);
                    }}
                  ></DepositFormRow>
                ))}
              </Container>
            )}

            <Footer />
          </Container>
        );

      case 1:
        return <ConfirmDetails />;

      case 3:
        return <SuccessStep />;
      default:
        return <></>;
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        maxWidth={"xl"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deposit"}</DialogTitle>
        {loading ? (
          <Loader loading={loading} type={"default"} height="35vh" />
        ) : (
          <DialogContent>
            <Container>
              <StepperComponent
                btnLabels={{
                  backBtn: backBtnLabel(),
                  nextBtn: nextBtnLabel(),
                  submitBtn: "Done",
                }}
                disableNextBtn={!formValid}
                activeStep={activeStep}
                loading={depositLoading}
                steps={steps}
                stepperClassName={styles.updateItemStepper}
                btnsAling={"end !important"}
                alternativeLabel
                handleNext={handleSubmit(onSubmit)}
                handleBack={handleStepBack}
              >
                <Container>{renderSteps()}</Container>
              </StepperComponent>
            </Container>
          </DialogContent>
        )}
      </Dialog>
    </FormProvider>
  );
};

export default MultiplePassportDepositDialog;
