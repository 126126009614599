import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import {
  Card,
  Container,
  Divider,
  HeaderBar,
  Icon,
  Loader,
  SubmitDialog,
  Toast as toast,
  TypoGraph,
} from "components";
import styles from "./multipleDisbursement.module.scss";
import strings from "strings";
import {
  getAvailableDisbursementAccountsCSV,
  sendMultipleDisbursement,
} from "api";
import {
  IMultipleDisbursementRequest,
  IMultipleDisbursementResponse,
} from "interfaces";
import { useNavigate } from "react-router-dom";
import BaseDialog from "components/dialog/baseDialog";
import { convertFileToBase64, downloadCSVFile } from "tools/base64";
import Typograph from "components/typograph";
import colors from "theme/colors";
import FileDownload from "js-file-download";
import { DataGrid } from "@mui/x-data-grid";
import { Box, List } from "@mui/material";
import { multipleDisbursementsExampleColumns } from "constants/tableData";
import Papa from "papaparse";
import {
  ISubmitDialogProps,
  ISubmitDialogType,
} from "components/dialog/submitDialog";
import { isNullOrEmpty, isSameDay } from "tools/validators";
import currencyFormatter from "tools/currencyFormatter";
import Decimal from "decimal.js";

interface IDisbursementFormProps {}

const MultipleDisbursement: React.FC<IDisbursementFormProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [tableRows, setTableRows] = useState<[]>();
  const [showResultDialog, setShowResultDialog] = useState<boolean>(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
  const [multipleDisbursementResult, setMultipleDisbursementResult] =
    useState<IMultipleDisbursementResponse>();
  const [validDollarFormat, setValidDollarFormat] = useState<boolean>(false);
  const MULTIPLE_DISBURSEMENT_TEMPLATE_LINK =
    "https://bankshot-static-public-resources.s3.amazonaws.com/public/template/DisbursementTemplateV2.xlsx";

  const [action, setAction] = useState<number>(0);
  const RESET_ACTION = 0;
  const SUBMIT_ACTION = 1;
  const DELETE_ACTION = 2;

  const t = strings.DISBURSEMENT_STRING;
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!attachedFile) {
      return;
    }

    const base64 = await convertFileToBase64(attachedFile);

    if (!base64) {
      return;
    }

    let formData: IMultipleDisbursementRequest = {
      file: "",
    };
    setLoading(true);
    formData.file = base64?.toString()?.replace("data:text/csv;base64,", "");

    formData.file = formData.file
      ?.toString()
      ?.replace("data:application/octet-stream;base64,", "");

    sendMultipleDisbursement(formData)
      .then((response) => {
        setMultipleDisbursementResult(response?.data);
      })
      .finally(() => {
        setLoading(false);
        setShowSubmitDialog(false);
        setShowResultDialog(true);
      });
  };

  const downloadDisbursementAccountsCSV = () => {
    getAvailableDisbursementAccountsCSV().then((r) => {
      FileDownload(r.data, "AvailableAccounts.csv");
      toast({
        title: strings.AVAILABLE_ACCOUNTS_SUCCESSFULLY_DOWNLOADED,
      });
    });
  };

  const downloadCSV = async (attachedFileData: File) => {
    const fileURL = (await convertFileToBase64(attachedFileData)) as string;
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = attachedFileData.name;
    alink.click();
  };

  const downloadSummaryAsCSV = async () => {
    if (
      !multipleDisbursementResult ||
      !multipleDisbursementResult.paymentSummaryCSV
    ) {
      return;
    }
    downloadCSVFile(
      multipleDisbursementResult.paymentSummaryCSV,
      "Payment Summary.csv"
    );
  };

  const hasValidFields = (CSVFields: any[]): boolean => {
    if (!CSVFields || CSVFields.length < 1) {
      return false;
    }
    for (const field of CSVFields) {
      if (isNullOrEmpty(field[t.DISBURSEMENT_ACCOUNT_ID])) {
        return false;
      }
      if (isNullOrEmpty(field[t.AMOUNT]) || /,/.test(field[t.AMOUNT])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYEES_EMAIL])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYEES_FIRST_NAME])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYEES_LAST_NAME])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYMENT_DATE])) {
        return false;
      }
      if (isNullOrEmpty(field[t.DIRECT_DEPOSIT_AS_DO])) {
        return false;
      }
      if (isNullOrEmpty(field[t.MAIL_MY_CHECK_AS_DO])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PRINT_AND_DEPOSIT_AS_DO])) {
        return false;
      }
    }
    setValidDollarFormat(true);
    return true;
  };

  useEffect(() => {
    if (attachedFile) {
      Papa.parse(attachedFile, {
        header: true,
        complete: (result: any) => {
          const jsonData = result?.data.filter(
            (record: any) => record["Disbursement Account Id"] != ""
          );
          if (!hasValidFields(jsonData)) {
            setAttachedFile(undefined);
            toast({
              type: "error",
              title: strings.FILE_UPLOAD_ERROR,
              subTitle: strings.FILE_UPLOAD_ERROR_DETAILS,
            });
            return;
          }
          const tableRowsWithStatus = jsonData.map((record: any) => {
            const date = new Date(record[t.PAYMENT_DATE] as string);
            const now = new Date();
            if (isSameDay(date, now)) {
              record[t.STATUS] = "Inmediate";
            } else if (date < now) {
              record[t.STATUS] = "Error";
            } else {
              record[t.STATUS] = "Future";
            }
            return record;
          });
          const tableRowsWithIndex = tableRowsWithStatus.map(
            (row: object, index: number) => ({
              ...row,
              id: index + 1,
            })
          );
          setTableRows(tableRowsWithIndex);
        },
        error: (error) => {
          toast({
            type: "error",
            title: strings.FILE_UPLOAD_ERROR,
            subTitle: strings.FILE_UPLOAD_ERROR_DETAILS,
          });
        },
      });
    }
  }, [attachedFile]);

  const resultDialogContent = (
    <Container>
      <TypoGraph
        variant="h3"
        content={`You just sent ${multipleDisbursementResult?.multipleDisbursementPaymentList.length} Payments, here is the summary of each one of them:`}
      ></TypoGraph>

      <List>
        {multipleDisbursementResult?.multipleDisbursementPaymentList.map(
          (disbursement, index) => {
            return disbursement.success ? (
              <Container>
                <TypoGraph content={`Row ${index + 2}`}></TypoGraph>
                <TypoGraph content={"Sent"} color={colors.success}></TypoGraph>
                <TypoGraph content={disbursement.message}></TypoGraph>
                <TypoGraph
                  content={`Disbursement ID: ${disbursement.id.toString()}`}
                ></TypoGraph>
                <TypoGraph
                  content={`Disbursement status: ${disbursement.disbursementStatus}`}
                ></TypoGraph>
                <Divider></Divider>
              </Container>
            ) : (
              <Container>
                <TypoGraph content={`Row ${index + 2}`}></TypoGraph>
                <TypoGraph
                  content={"Failed to send"}
                  color={colors.error}
                ></TypoGraph>
                <TypoGraph content={disbursement.message}></TypoGraph>
                <Divider></Divider>
              </Container>
            );
          }
        )}
      </List>
    </Container>
  );

  type ISubmissionDetails = {
    body1: string;
    body2: string;
    type: ISubmitDialogType;
  };

  function getSubmissionDetails(): ISubmissionDetails {
    let submissionDetails: ISubmissionDetails = {
      body1: "",
      body2: "",
      type: "warning",
    };

    if (!tableRows) {
      return submissionDetails;
    }
    let rowsWithErrors = tableRows?.filter(
      (row: any) => row.status === "Error"
    ).length;

    let totalAmount = "";
    if (validDollarFormat) {
      totalAmount = currencyFormatter(
        tableRows
          .reduce((total, item) => {
            const amount = new Decimal(item["Amount"] || 0);
            return total.plus(amount);
          }, new Decimal(0))
          .toFixed(2)
      );
    }

    if (rowsWithErrors === 0) {
      submissionDetails.body1 = `You are about to submit ${tableRows.length} disbursements for a total of ${totalAmount}`;
      submissionDetails.body2 = `Are you sure you'd like to submit these ${tableRows.length} disbursements?`;
      submissionDetails.type = "success";
      return submissionDetails;
    } else {
      submissionDetails.body1 = `${rowsWithErrors} Disbursements contain errors and will fail to be sent. \n You are about to submit ${tableRows?.length} disbursements for a total of ${totalAmount}`;
      submissionDetails.body2 = `Are you sure you'd like to submit these ${tableRows.length} disbursements?`;
      submissionDetails.type = "warning";
      return submissionDetails;
    }
  }

  const submitDialogProps: ISubmitDialogProps[] = [
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: strings.RESET_DATA,
      type: "delete",
      body1: "Reseting the data will also remove the uploaded files",
      body2: "Are you sure you want to reset the data?",
      primaryBtnProps: {
        label: strings.RESET_DATA,
        onClick: () => {
          setAttachedFile(undefined);
          setShowSubmitDialog(false);
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: "Disbursement Summary",
      type: getSubmissionDetails().type,
      body1: getSubmissionDetails().body1,
      body2: getSubmissionDetails().body2,
      primaryBtnProps: {
        label: "Submit Disbursements",
        onClick: () => {
          onSubmit();
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: "Delete File",
      type: "delete",
      body1: "Are you sure you want to delete this file",
      primaryBtnProps: {
        label: "Delete File",
        onClick: () => {
          setAttachedFile(undefined);
          setShowSubmitDialog(false);
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
  ];

  return (
    <Container>
      <HeaderBar
        title={t.DISBURSEMENTS}
        primaryBtnProps={{
          label: strings.SEND_DISBURSEMENTS,
          disabled: !attachedFile,
          onClick: () => {
            setAction(SUBMIT_ACTION);
            setShowSubmitDialog(true);
          },
        }}
        secondaryBtnProps={{
          label: strings.RESET_DATA,
          onClick: () => {
            setAction(RESET_ACTION);
            setShowSubmitDialog(true);
          },
          disabled: !attachedFile,
        }}
      />
      <BaseDialog
        open={loading}
        handleClose={() => {}}
        showCancel={false}
        content={
          <Container>
            <TypoGraph content={strings.SENDING_DISBURSEMENT_PAYMENTS} />
            <Loader loading={loading} type="default" height="30vh" />
          </Container>
        }
      />
      <Card>
        <TypoGraph
          variant="h2"
          sx={{ paddingTop: 1, paddingBottom: 2 }}
          content={strings.CREATE_MULTIPLE_DISBURSEMENTS}
        />
        <Container className={styles.instructionsContainer}>
          <Container className={styles.iconInfoContainer}>
            <Icon
              name="icon_info_filled"
              iconClassName={styles.iconInfo}
              size={25}
              color={colors.primary}
            />
          </Container>
          <Container className={styles.instructionsText}>
            <TypoGraph content="Download the template, then fill in the required information, export as CSV,  attach the file and click “Send Disbursements”" />
            <TypoGraph
              variant="body1"
              content={"Download template"}
              onClick={() => {
                window.open(MULTIPLE_DISBURSEMENT_TEMPLATE_LINK, "_blank");
              }}
              sx={{ width: "30%" }}
              link
              color={colors.primary}
            />
          </Container>
        </Container>
        <Container className={styles.formContainer}>
          <Container className={styles.fileUploadContainer}>
            {attachedFile && (
              <Container
                key={attachedFile?.name}
                className={styles.filePreview}
              >
                <Container className={styles.fileContainer}>
                  <Container className={styles.fileNameContainer}>
                    <Icon
                      boxClassName={styles.fileUploadIcon}
                      name="icon_uploadSuccess"
                    />
                    <TypoGraph
                      variant="caption"
                      content={attachedFile?.name}
                      className={styles.text}
                    />
                    <Container className={styles.cancelIcon}>
                      <Icon
                        name="icon_filecancel"
                        iconButton
                        iconClassName={styles.fileCancelIcon}
                        onClick={() => {
                          setAction(DELETE_ACTION);
                          setShowSubmitDialog(true);
                        }}
                      />
                    </Container>
                  </Container>
                </Container>
              </Container>
            )}
            {!attachedFile && (
              <Container>
                <Dropzone
                  accept={{ "text/csv": [] }}
                  multiple={false}
                  onDrop={(file) => setAttachedFile(file[0])}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className={styles.dropzoneContainer}>
                      <div
                        {...getRootProps({
                          className: "dropzone",
                          onDrop: (event) => event.stopPropagation(),
                        })}
                      >
                        <Container className={styles.fileDropzone}>
                          <Icon name="icon_fileupload_v2" size={50} />
                          <TypoGraph
                            content={"Attach CSV File"}
                            color="#666666"
                          />
                          <TypoGraph
                            content={
                              "Drag And Drop Files from your computer, or click to Upload"
                            }
                            color="#666666"
                          />
                        </Container>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Container>
            )}
          </Container>
        </Container>
        {!attachedFile ? (
          <Container sx={{ height: 400 }}>
            <ul>
              <li>
                <TypoGraph content="The only non-required field is Memo, so please fill all the other fields, otherwise, the file will not be processed"></TypoGraph>
              </li>
              <li>
                <TypoGraph content="Please do not remove the title row (row #1) from your CSV"></TypoGraph>
              </li>
              <li>
                <TypoGraph content="Amount must be on dollars format, with or without cents (no commas needed) "></TypoGraph>
              </li>
              <li>
                <TypoGraph content="Date must be on format YYYY-MM-DD, example: 2023-06-31 "></TypoGraph>
              </li>
            </ul>
          </Container>
        ) : (
          <Container>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={tableRows ? tableRows : []}
                columns={multipleDisbursementsExampleColumns}
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
              />
            </Box>
          </Container>
        )}
        <Container sx={{ "margin-top": "20px", display: "flex" }}>
          <Typograph
            sx={{ marginRight: "5px" }}
            content={`Note: If you do not know a disbursement account ID, you can find it in this file: `}
          />
          <TypoGraph
            variant="body1"
            content={"Available Disbursement Accounts"}
            onClick={() => {
              downloadDisbursementAccountsCSV();
            }}
            sx={{ width: "30%" }}
            link
            color={colors.primary}
          />
        </Container>
      </Card>
      <BaseDialog
        title="Payment Result Summary"
        open={showResultDialog}
        content={resultDialogContent}
        handleClose={() => {}}
        primaryBtnProps={{
          label: "Accept",
          onClick: () => {
            const successfulDisbursements =
              multipleDisbursementResult?.multipleDisbursementPaymentList.filter(
                (disbursement) => disbursement.success
              ).length ?? 0;
            navigate("/disbursements");
            if (successfulDisbursements > 0) {
              toast({
                title:
                  successfulDisbursements == 1
                    ? `${successfulDisbursements} Disbursement submitted successfully`
                    : `${successfulDisbursements} Disbursements submitted successfully`,
              });
            } else {
              toast({
                type: "error",
                title: `${successfulDisbursements} Disbursements were submitted`,
              });
            }
          },
        }}
        secondaryBtnProps={{
          label: "Download Summary as CSV",
          onClick: () => downloadSummaryAsCSV(),
        }}
      />
      <SubmitDialog {...submitDialogProps[action]} />
    </Container>
  );
};

export default MultipleDisbursement;
