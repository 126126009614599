import React, { useEffect, useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import jwt_Decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router";
import ApplicationRoutes from "routes";
import renderBreadcrumbs from "constants/breadCrumb";
import { BreadCrumbs, Container, Toast, ToastContainer } from "components";
import MenuBar from "./menuBar";
import Login from "pages/login";
import Receivables from "pages/receivables";
import { IDecodedToken } from "interfaces/IdecodedToken";
import { Helmet } from "react-helmet";
import strings from "strings";

const defineIsAuth = () => {
  const token = localStorage.getItem("token");
  if (token === null) {
    return false;
  }
  const decodedToken: IDecodedToken = jwt_Decode(token);
  if (decodedToken.userpriv === 0) {
    return false;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  const remainingTime = decodedToken.exp - currentTime;
  if (remainingTime < 0) {
    return false;
  }
  return true;
};

const defineUserPriv = () => {
  const token = localStorage.getItem("token");
  if (token === null) {
    return 0;
  }
  const decodedToken: IDecodedToken = jwt_Decode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  const remainingTime = decodedToken.exp - currentTime;
  if (remainingTime < 0) {
    return 0;
  }
  return decodedToken.userpriv;
};

const receivablesRoutesAsRegExp: RegExp[] = [
  /^\/receivables$/,
  /^\/receivables\/\d+$/,
  /^\/receivables\/\d+\/request\/\d+$/,
];

const isReceivablesPath = (path: string) => {
  return receivablesRoutesAsRegExp.some((expresion) => expresion.test(path));
};

const AppContainer: React.FunctionComponent = (props) => {
  const isAuth = defineIsAuth();
  const userPriv = defineUserPriv();
  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token");
      if (token != null) {
        const decodedToken: IDecodedToken = jwt_Decode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = decodedToken.exp - currentTime;
        if (remainingTime < 0) {
          localStorage.removeItem("token");
          navigate("/*");
          Toast({
            title: strings.SESSION_HAS_TIMED_OUT,
            subTitle: strings.PLEASE_RE_ENTER_YOUR_CREDENTIALS,
          });
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isAuth, navigate]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const routes = renderBreadcrumbs(path);

  return (
    <Container>
      {process.env.REACT_APP_HOTJAR_ENABLED === "true" && (
        <Helmet>
          <script>
            {`
                (function (h, o, t, j, a, r) {
                  h.hj =
                    h.hj ||
                    function () {
                      (h.hj.q = h.hj.q || []).push(arguments);
                    };
                  h._hjSettings = { hjid: 3182527, hjsv: 6 };
                  a = o.getElementsByTagName("head")[0];
                  r = o.createElement("script");
                  r.async = 1;
                  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

            `}
          </script>
        </Helmet>
      )}

      <ToastContainer />
      {isAuth ? (
        <>
          {isReceivablesPath(path) && (
            <Routes>
              <Route path="/receivables" element={<Receivables />} />
              <Route
                path="/receivables/:companyCodeId"
                element={<Receivables />}
              />
              <Route
                path="/receivables/:companyCodeId/request/:paymentRequestId"
                element={<Receivables />}
              />
            </Routes>
          )}
          {!isReceivablesPath(path) && (
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <MenuBar path={path} navigate={navigate} userPriv={userPriv} />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 2,
                  maxWidth: "1920px",
                  margin: "auto",
                  position: "relative",
                }}
              >
                <BreadCrumbs routes={routes} />
                <ApplicationRoutes isAuth={isAuth} userPriv={userPriv} />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login currentStep={0} />}></Route>
          <Route path="/login/sso/:token" element={<Login currentStep={0} />}></Route>
          <Route path="/receivables" element={<Receivables />} />
          <Route path="/receivables/:companyCodeId" element={<Receivables />} />
          <Route
            path="/receivables/:companyCodeId/request/:paymentRequestId"
            element={<Receivables />}
          />
          <Route
            path="/snippet/receivables"
            element={<Receivables isSnippet={true} />}
          />
          <Route
            path="/snippet/receivables/:companyCodeId"
            element={<Receivables isSnippet={true} />}
          />
          <Route
            path="/snippet/receivables/:companyCodeId/request/:paymentRequestId"
            element={<Receivables isSnippet={true} />}
          />
          <Route
            path="/reset-password/:resetId"
            element={<Login currentStep={2} resetPassword />}
          ></Route>
          <Route
            path="/create-password/:resetId"
            element={<Login currentStep={2} resetPassword createPassword />}
          ></Route>
          <Route
            path="/reset-2fa/:resetId"
            element={<Login currentStep={5} />}
          ></Route>
          <Route path="*" element={<Navigate to={"/login"} />} />
        </Routes>
      )}
    </Container>
  );
};

export default AppContainer;
