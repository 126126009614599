import React, { useEffect, useState } from "react";
import {
  Container,
  Icon,
  Loader,
  StepperComponent,
  TypoGraph,
} from "components";
import strings from "strings";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import toast from "components/toast";
import { FormProvider, useForm } from "react-hook-form";
import { DropdownOptions, IPayment, ISubmitItemResult } from "interfaces";
import { checkItemsDetailsForDeposit, submitItems } from "api";
import styles from "./multipleCheckDepositDialog.module.scss";

import { DropdownFormField, InputFormField } from "components/hookForm";
import { fireworksConfetti } from "tools/confetti";
import {
  ICheckPayment,
  ICompanyAccount,
  ISubmitCheckRequest,
} from "interfaces/IMultipleCheckDeposit";
import Endorsement from "pages/completed/partial/endorsement";

interface IMultiplePassportDepositDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedPayments: IPayment[];
  onSuccessCallback: (response: ISubmitItemResult[]) => void;
}

const MultipleCheckDepositDialog: React.FC<
  IMultiplePassportDepositDialogProps
> = (props) => {
  const { open, handleClose, selectedPayments, onSuccessCallback } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<any>();
  const { handleSubmit, reset, getValues, watch } = methods;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentForDeposit, setPaymentDeposit] = useState<ICheckPayment[]>([]);

  const [depositError, setDepositError] = useState<boolean>(false);
  const steps = ["Select Bank Account", "Endorse", "Confirm Details", "Done"];

  const [availableAccounts, setAvailableAccounts] = useState<DropdownOptions[]>(
    []
  );

  const [selectedAccountId, setSelectedAccountId] = useState<any>();
  const [selectedAccountLabel, setSelectedAccountLabel] = useState<
    string | undefined | number
  >();

  const paymentCount = selectedPayments.length;
  const companyId = selectedPayments[0]?.companyId;
  const [loadingBackImg, setLoadingBackImg] = useState<boolean>(false);

  const [endorsementRequired, setEndorsementRequired] =
    useState<boolean>(false);
  const [currentItemEndorsed, setCurrentItemEndorsed] =
    useState<boolean>(false);
  const [currentItemRotated, setCurrentItemRotated] = useState<boolean>(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [currentItemEndorseCoordinates, setCurrentItemEndorseCoordinates] =
    useState<any>();

  const [paymentsRequest, setPaymentsRequest] = useState<any[]>();
  const [successDepositCounter, setSuccessDepositCounter] = useState<number>(0);
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>();

  const onSubmit = async (formData: any) => {
    if (activeStep == 0) {
      setInitalRequest();
    }
    if (activeStep == 1 && currentItemIndex + 1 <= paymentCount) {
      if (currentItemEndorsed) {
        updateCoordinatesRequest(
          currentItemEndorseCoordinates,
          currentItemIndex
        );
      }
      if (
        endorsementRequired &&
        !currentItemEndorsed &&
        !paymentForDeposit[currentItemIndex].backImage.endorsed
      ) {
        toast({
          type: "warning",
          title: "Endorse Required",
          subTitle: "Selected account requires endorse",
        });
        return;
      }
      if (currentItemIndex + 1 == paymentCount) {
        setActiveStep(activeStep + 1);
        return;
      }
      setLoadingBackImg(true);
      setTimeout(() => {
        setCurrentItemIndex(currentItemIndex + 1);
        setLoadingBackImg(false);
      }, 500);
      return;
    }
    if (activeStep == 4) {
      handleClose();
      reset();
      return;
    }
    if (activeStep == 2) {
      setDepositLoading(true);
      let responseList: ISubmitItemResult[] = [];
      let itemDepositCounter: number = 0;
      const request = {
        items: paymentsRequest!.flatMap((item) => item.items),
        chequeImageEndorsementListRequest: paymentsRequest!.map(
          (item) => item.chequeImageEndorsementRequest
        ),
        note: formData.note ? formData.note : "",
      };
      submitItems(request)
        .then((response) => {
          response?.data?.forEach((item) => {
            if (item.success) {
              itemDepositCounter++;
            }
            responseList.push(item);
          });
          setSuccessDepositCounter(itemDepositCounter);
          onSuccessCallback(responseList);
          if (itemDepositCounter > 0) {
            fireworksConfetti();
          }
          setActiveStep(4);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Unknown Error";

          setResponseData(errorMessage);
          setDepositError(true);
        })
        .finally(() => {
          setDepositLoading(false);
        });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  // Load item information and initialize constants.
  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setSuccessDepositCounter(0);
      setCurrentItemEndorsed(false);
      setCurrentItemIndex(0);
      setAvailableAccounts([]);
      setLoading(true);
      const items = selectedPayments.map((payment) => payment.id);
      checkItemsDetailsForDeposit(items)
        .then((response: any) => {
          setPaymentDeposit(response.data.items);

          const companyAccounts = response.data.accounts;

          if (companyAccounts.length > 0) {
            const accountOptions = companyAccounts.map(
              (account: ICompanyAccount) => {
                return {
                  value: account.id,
                  label: account.name,
                };
              }
            );

            setAvailableAccounts(accountOptions);
          }

          setLoading(false);
        })
        .catch(() => {
          handleClose();
          reset();
        });
    }
  }, [open]);

  // Handles account selection.
  useEffect(() => {
    const accountId = getValues("accountId");

    setSelectedAccountId(accountId);
    setSelectedAccountLabel(
      availableAccounts?.find((option) => option.value === accountId)?.label
    );
  }, [watch("accountId")]);

  const nextBtnLabel = () => {
    if (activeStep == 2) {
      if (depositError) {
        return "Try again";
      }
      return "Confirm Deposit";
    }
    if (activeStep == 1) {
      if (currentItemEndorsed) {
        return `Endorse ${currentItemIndex + 1}/${paymentCount}`;
      }
      return `Continue ${currentItemIndex + 1}/${paymentCount}`;
    }
    return "Next";
  };

  const backBtnLabel = () => {
    if (activeStep == 1 || activeStep == 2) {
      return "Back";
    }
    return "";
  };

  const handleStepBack = () => {
    if (activeStep == 2) {
      setActiveStep(activeStep - 1);
      setInitalRequest();
      setLoadingBackImg(true);
      setTimeout(() => {
        setCurrentItemIndex(0);
        setLoadingBackImg(false);
      }, 500);
      refreshEndorseConstant();
      return;
    }
    if (activeStep == 1) {
      reset();
      setCurrentItemIndex(0);
      setDepositError(false);
      setActiveStep(activeStep - 1);
    }
  };

  function refreshEndorseConstant() {
    setCurrentItemEndorseCoordinates(undefined);
    setCurrentItemEndorsed(false);
    setCurrentItemRotated(false);
  }

  function setInitalRequest() {
    const initialRequest = paymentForDeposit.map((payment) => ({
      items: [payment.paymentId],
      chequeImageEndorsementRequest: {
        imageId: payment.backImage.id,
        accountId: selectedAccountId,
        rotate: false,
      },
      note: "",
    }));
    console.log(initialRequest);
    setPaymentsRequest(initialRequest);
  }

  // Add endorsement coordinates into payment request
  const updateCoordinatesRequest = (coordinatesRequest: any, index: number) => {
    setPaymentsRequest((prev) => {
      if (!prev) return prev;

      const updatedRequests: any[] = [...prev];

      if (index < 0 || index >= updatedRequests.length) return prev;

      updatedRequests[index] = {
        ...updatedRequests[index],
        chequeImageEndorsementRequest: {
          ...updatedRequests[index].chequeImageEndorsementRequest,
          coordinatesRequest: coordinatesRequest,
          rotate: currentItemRotated,
        },
      };
      console.log("update", updatedRequests);
      refreshEndorseConstant();
      return updatedRequests;
    });
  };

  const getTotalAmount = () => {
    if (paymentForDeposit && paymentForDeposit.length > 0) {
      const totalAmountInCents = paymentForDeposit?.reduce((total, payment) => {
        return total + payment.amount;
      }, 0);

      const totalAmountString = totalAmountInCents.toString();
      const formattedTotalAmount = `$${totalAmountString.slice(
        0,
        -2
      )}.${totalAmountString.slice(-2)}`;

      return formattedTotalAmount;
    }
  };

  const getEndorsementText = () => {
    const endorsed =
      paymentForDeposit[currentItemIndex].backImage.endorsed ||
      currentItemEndorsed;

    if (endorsed || currentItemEndorsed) {
      return strings.CHECK_IS_ALREADY_ENDORSED;
    }
    if (endorsementRequired && !endorsed) {
      return strings.PLEASE_ADD_REQUIRED_ENDORSEMENT;
    }
    if (!endorsementRequired && !endorsed) {
      return strings.YOU_CAN_ADD_OPTIONAL_ENDORSEMENT;
    }
  };

  const ConfirmDetails = () => {
    if (depositError) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_warning"} height={150} width={552} />
            <TypoGraph
              variant="body1"
              align="center"
              content={"Oh no! Something went wrong."}
            />
            <TypoGraph variant="body2" align="center" content={responseData} />
          </Container>
        </Container>
      );
    }
    return (
      <Container>
        {activeStep == 2 && (
          <Container>
            <Container className={styles.sameAccountRow}>
              <TypoGraph
                sx={{ fontWeight: "bolder" }}
                content={"Account"}
              ></TypoGraph>
              <TypoGraph
                sx={{ fontWeight: "bolder" }}
                align="right"
                content={selectedAccountLabel?.toString()}
              ></TypoGraph>
            </Container>{" "}
          </Container>
        )}
        {paymentForDeposit?.map((payment) => (
          <Container className={styles.sameAccountRow}>
            <TypoGraph content={payment.paymentId.toString()}></TypoGraph>

            <TypoGraph
              align="right"
              content={payment.amountFormatted}
            ></TypoGraph>
          </Container>
        ))}
        <Container className={styles.sameAccountRow}>
          <TypoGraph
            sx={{ fontWeight: "bolder" }}
            content={"Total"}
          ></TypoGraph>
          <TypoGraph
            sx={{ fontWeight: "bolder" }}
            align="right"
            content={getTotalAmount()}
          ></TypoGraph>
        </Container>
        {getValues("note") && (
          <Container className={styles.sameAccountRow}>
            <TypoGraph content={"Note"}></TypoGraph>
            <TypoGraph align="right" content={getValues("note")}></TypoGraph>
          </Container>
        )}
      </Container>
    );
  };

  const SuccessStep = () => {
    return (
      <Container>
        <Container className={styles.responseContainer}>
          <Icon
            name={
              successDepositCounter > 0
                ? "icon_dialog_success"
                : "icon_dialog_warning"
            }
            height={150}
            width={552}
          />
          <Container>
            <TypoGraph align="center" variant="body1">
              {successDepositCounter}{" "}
              {successDepositCounter == 1 ? "Receivable" : "Receivables"} has
              been deposited successfully.
            </TypoGraph>
          </Container>
        </Container>
      </Container>
    );
  };

  const SameAccountForm = () => {
    return (
      <Container>
        <TypoGraph
          sx={{ fontWeight: "bolder", marginBottom: "16px" }}
          content={"Select Bank Account"}
        ></TypoGraph>
        <ConfirmDetails />
        <Container>
          <Container>
            <DropdownFormField
              {...{
                options: availableAccounts,
                label: strings.DESTINATION_ACCOUNT,
                defaultValue: undefined,
                name: "accountId",
                required: true,
              }}
            />
          </Container>
          <InputFormField
            name={strings.NOTE.toLowerCase()}
            label={strings.OPTIONAL_NOTE}
            defaultValue={undefined}
            required={false}
          />
        </Container>
      </Container>
    );
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <Container>
            <SameAccountForm />
          </Container>
        );

      case 1:
        return (
          <Container>
            {loadingBackImg ? (
              <Loader loading={loadingBackImg} type={"default"} height="35vh" />
            ) : (
              <>
                <Endorsement
                  payment={undefined}
                  backImage={paymentForDeposit[currentItemIndex].backImage}
                  frontImage={undefined}
                  accountId={selectedAccountId}
                  companyId={companyId}
                  onSuccessCallback={(val: boolean) => {
                    console.log(val);
                    setCurrentItemEndorseCoordinates(val);
                  }}
                  setEndorsement={(val: boolean) => {
                    setCurrentItemEndorsed(val);
                  }}
                  isEndorseRequired={(value) => {
                    setEndorsementRequired(value);
                  }}
                  isRotated={(value) => {
                    setCurrentItemRotated(value);
                  }}
                />
                <TypoGraph
                  sx={{ marginLeft: "24px" }}
                  align="left"
                  variant="body1"
                  content={getEndorsementText()}
                ></TypoGraph>
              </>
            )}
          </Container>
        );
      case 2:
        return <ConfirmDetails />;

      case 4:
        return <SuccessStep />;
      default:
        return <></>;
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        maxWidth={"xl"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deposit"}</DialogTitle>
        {loading ? (
          <Loader loading={loading} type={"default"} height="35vh" />
        ) : (
          <DialogContent>
            <Container>
              <StepperComponent
                btnLabels={{
                  backBtn: backBtnLabel(),
                  nextBtn: nextBtnLabel(),
                  submitBtn: "Done",
                }}
                activeStep={activeStep}
                loading={depositLoading}
                steps={steps}
                stepperClassName={styles.updateItemStepper}
                btnsAling={"end !important"}
                alternativeLabel
                handleNext={handleSubmit(onSubmit)}
                handleBack={handleStepBack}
              >
                <Container>{renderSteps()}</Container>
              </StepperComponent>
            </Container>
          </DialogContent>
        )}
      </Dialog>
    </FormProvider>
  );
};

export default MultipleCheckDepositDialog;
