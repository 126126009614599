import MuiPopover from "@mui/material/Popover";

type IDialogProps = {
  content?: React.ReactNode;
  children?: React.ReactNode;
  anchorEl: Element | null;
  handleClose: () => void;
  position?: "bottom" | undefined;
};

const Popover: React.FC<IDialogProps> = (props) => {
  const { children, handleClose, anchorEl, content, position } = props;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={
        position == "bottom"
          ? {
              vertical: "bottom",
              horizontal: "left",
            }
          : {
              vertical: "center",
              horizontal: "right",
            }
      }
      transformOrigin={
        position == "bottom"
          ? undefined
          : {
              vertical: "center",
              horizontal: "left",
            }
      }
    >
      {children || content}
    </MuiPopover>
  );
};

export default Popover;
