import { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Toast as toast,
  TypoGraph,
} from "components";
import { IPayment } from "interfaces/IPayment";
import { TextField } from "components/controllers";
import { updateItemNotes } from "api";
import strings from "strings";

interface IPaymentDetailProps {
  payment?: IPayment;
  handleSuccessCallback: () => void;
}

const PaymentNotesComponent: React.FC<IPaymentDetailProps> = (props) => {
  const { payment, handleSuccessCallback } = props;
  const [note, setNote] = useState<string>();

  const onUpdateNotesClicked = () => {
    if (payment && note) {
      updateItemNotes(payment?.id, note).then((response) => {
        toast({
          title: strings.UPDATED_NOTES_SUCCESSFULLY,
          subTitle: `${strings.ITEM_ID}: ${response?.data?.itemId}`,
        });
        handleSuccessCallback();
      });
    }
  };

  return (
    <Container sx={{ paddingTop: "10px" }}>
      <TextField
        id={strings.NOTE}
        label={strings.NOTE}
        multiline={true}
        rows={4}
        value={note}
        onChange={(e: React.ChangeEvent<any>) => setNote(e.target.value)}
      />
      <Container sx={{ justifyContent: "end" }}>
        <Button
          id={strings.UPDATE_NOTES}
          label={strings.UPDATE_NOTES}
          variant="outlined"
          onClick={() => onUpdateNotesClicked()}
        />
      </Container>
      <Container
        sx={{
          gridTemplateColumns: "auto auto",
          justifyContent: "space-between",
          padding: "5px 10px",
        }}
      ></Container>
      <Divider title={strings.NOTES.toUpperCase()} />
      {payment && (
        <Container>
          <TypoGraph variant="body2" content={payment.notes} />
          <Container
            sx={{
              gridTemplateColumns: "auto auto",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
          ></Container>
        </Container>
      )}
    </Container>
  );
};

export default PaymentNotesComponent;
