import React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import CompaniesList from "pages/companies/index";
import CompanyPage from "pages/companies/form";
import CompanyAccountsList from "pages/accounts";
import CompanyAccountPage from "pages/accounts/companyAccountPage";
import MaintenanceReport from "pages/maintenanceReport";
import Reports from "pages/reports";
import { PrivDependantRoute } from "./PrivDependantRoute";
import { Actions, Resources } from "tools/privilegeChecker";

interface IApplicationRoute extends RouteProps {
  userPriv: number;
}

const CompaniesRoutes: React.FunctionComponent<IApplicationRoute> = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivDependantRoute
            requirements={{ action: Actions.READ, resource: Resources.COMPANY }}
          >
            <CompaniesList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId"
        element={
          <PrivDependantRoute
            requirements={{ action: Actions.READ, resource: Resources.COMPANY }}
          >
            <CompaniesList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/create"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.CREATE,
              resource: Resources.COMPANY,
            }}
          >
            <CompanyPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/edit"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.UPDATE,
              resource: Resources.COMPANY,
            }}
          >
            <CompanyPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/accounts"
        element={
          <PrivDependantRoute
            requirements={{ action: Actions.READ, resource: Resources.ACCOUNT }}
          >
            <CompanyAccountsList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/accounts/:companyAccountId"
        element={
          <PrivDependantRoute
            requirements={{ action: Actions.READ, resource: Resources.ACCOUNT }}
          >
            <CompanyAccountsList />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/accounts/new"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.CREATE,
              resource: Resources.ACCOUNT,
            }}
          >
            <CompanyAccountPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/accounts/:companyAccountId/edit"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.UPDATE,
              resource: Resources.ACCOUNT,
            }}
          >
            <CompanyAccountPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/maintenance"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.GENERATE_MAINTENANCE_REPORT,
              resource: Resources.COMPANY,
            }}
          >
            <MaintenanceReport />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/accounts/:companyAccountId/maintenance"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.GENERATE_MAINTENANCE_REPORT,
              resource: Resources.ACCOUNT,
            }}
          >
            <MaintenanceReport />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:companyId/reports"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.GENERATE_TRANSACTION_REPORT,
              resource: Resources.COMPANY,
            }}
          >
            <Reports />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default CompaniesRoutes;
