import ErrorBoundary from "./ErrorBoundary";
import { RouteProps } from "react-router-dom";
import AppContainer from "appContainer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App: React.FunctionComponent<RouteProps> = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppContainer />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
