import { ReactNode } from "react";
import { Container } from "components";
import Button, { IButtonProps } from "components/button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface ISubmitDialogProps {
  open: boolean;
  primaryBtnProps?: IButtonProps;
  secondaryBtnProps?: IButtonProps;
  title?: string;
  content?: ReactNode;
  handleClose: () => void;
  size?: "lg" | "sm" | "md" | "xl" | "xs";
  showCancel?: boolean;
}

const BaseDialog: React.FC<ISubmitDialogProps> = (props) => {
  const {
    open,
    handleClose,
    primaryBtnProps,
    secondaryBtnProps,
    title = "Confirmation",
    content,
    size = "md",
    showCancel = true,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={size}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Container
          sx={{
            gridTemplateColumns: "auto auto",
            justifyContent: "end",
            gridGap: "1rem",
            padding: "12px 20px",
          }}
        >
          {showCancel && (
            <Button
              label="Cancel"
              variant="text"
              onClick={handleClose}
              {...secondaryBtnProps}
            />
          )}
          {primaryBtnProps && <Button color={"primary"} {...primaryBtnProps} />}
        </Container>
      </DialogActions>
    </Dialog>
  );
};

export default BaseDialog;
