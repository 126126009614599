import { Container, CustomDivider, UserInfoForm } from "components";
import { InputFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { DropdownOptions } from "interfaces";

interface OtherPaymentComponentProps {
  statesOptions?: DropdownOptions[];
}

const OtherPaymentComponent: React.FC<OtherPaymentComponentProps> = (props) => {
  const { statesOptions } = props;

  return (
    <Container className={styles.transactionDetailContainer}>
      <CustomDivider title="Your Info" />
      <UserInfoForm />
      <CustomDivider title="Transaction Info" />
      <Container className={styles.paymentDescriptionContainer}>
        <InputFormField
          name="otherptype"
          label="Payment Description"
          required
        />
      </Container>
    </Container>
  );
};

export default OtherPaymentComponent;
