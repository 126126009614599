export const frequencyOptions = [
  { label: "Does not repeat", value: "NOTREPEAT" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Biweekly", value: "BIWEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Custom", value: "CUSTOM" },
];

export const weekdayOptions = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];

export const customPatternTypeOptions = [
  { label: "Day", value: "DAY" },
  { label: "Week", value: "WEEK" },
  { label: "Month", value: "MONTH" },
];

export const endingOptions = [
  { value: "NEVER", label: "Never" },
  { value: "DATE", label: "On" },
  { value: "OCCURRENCE", label: "After" },
];

export const endDateOptions = [
  { value: "NOTEND", label: "Does Not End" },
  { value: "ENDSONDATE", label: "Ends On Date" },
];

export const weekdaysAbbreviated = [
  {
    key: 7,
    label: "S",
  },
  {
    key: 1,
    label: "M",
  },
  {
    key: 2,
    label: "T",
  },
  {
    key: 3,
    label: "W",
  },
  {
    key: 4,
    label: "T",
  },
  {
    key: 5,
    label: "F",
  },
  {
    key: 6,
    label: "S",
  },
];
