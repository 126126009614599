import { Actions, Resources } from "tools/privilegeChecker";

export interface ISecurityRequirements {
  action: Actions;
  resource: Resources;
}

export interface IMenuBarListItem {
  name: string;
  icon: string;
  id: string;
  securityRequirements: ISecurityRequirements;
}

export interface IMenuBarList {
  list1: IMenuBarListItem[];
  list2: IMenuBarListItem[];
}

const menuBarList: IMenuBarList = {
  list1: [
    {
      name: "Receivables",
      icon: "icon_payments",
      id: "payments",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.ITEM,
      },
    },
    {
      name: "Disbursements",
      icon: "icon_payments",
      id: "disbursements",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.DISBURSEMENT_PAYMENTS,
      },
    },
    {
      name: "Reports",
      icon: "icon_reports",
      id: "reports",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.REPORTS,
      },
    },
    {
      name: "Requests",
      icon: "icon_payments",
      id: "paymentrequest",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.PAYMENT_REQUEST,
      },
    },
  ],
  list2: [
    {
      name: "Settings",
      icon: "icon_settings",
      id: "settings",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.SETTINGS,
      },
    },
    {
      name: "Help",
      icon: "icon_help",
      id: "help",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.HELP,
      },
    },
    {
      name: "Profile",
      icon: "icon_profile",
      id: "profile",
      securityRequirements: {
        action: Actions.READ,
        resource: Resources.PROFILE,
      },
    },
  ],
};

export default menuBarList;
