import React, { useCallback, useEffect, useState } from "react";
import { DatePickerFormField } from "components/hookForm";
import { FormProvider, useForm } from "react-hook-form";
import { isDate } from "moment";
import {
  Button,
  Container,
  HeaderBar,
  Icon,
  TableComponent,
  Toast as toast,
} from "components";
import styles from "./reports.module.scss";
import strings from "strings";
import { DropdownOptions, IReports } from "interfaces";
import { getCompaniesForUser, getReportsForCompany } from "api";
import { DATE_FORMAT_YYYY_MM_DD } from "constants/datePicker";
import {
  defineUserIndividualPriv,
  defineUserPriv,
} from "tools/privilegeChecker";
import DropdownFormField from "../../components/hookForm/dropdownFormField";
import { GridColDef } from "@mui/x-data-grid";
import {
  dailyDepositReportColumns,
  disbursementReportColumns,
  dailyItemStatusReportColumns,
  dailyAgentRollupColumns,
} from "../../constants/tableData";
import Papa from "papaparse";

const reportsStrings = strings.REPORTS_STRINGS;
const reportsWithDisbursements = [
  { label: "Daily Agent Rollup", value: "daily-agent-rollup" },
  { label: "Daily Deposits", value: "daily-deposits" },
  { label: "Daily Item Status", value: "daily-item-status" },
  { label: "All Disbursements", value: "disbursements" },
  {
    label: "Successful Disbursements",
    value: "successful-disbursements",
  },
];
const reportsWithoutDisbursements = [
  { label: "Daily Agent Rollup", value: "daily-agent-rollup" },
  { label: "Daily Deposits", value: "daily-deposits" },
  { label: "Daily Item Status", value: "daily-item-status" },
];

const Reports = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState("");
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [reportOptions, setReportOptions] = useState(
    reportsWithoutDisbursements
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [formattedStartDate, setFormattedStartDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [companyID, setCompanyID] = useState("");
  const [reportType, setReportType] = useState("");
  const [tableRows, setTableRows] = useState<[]>([]);
  const [titleReport, setTitleReport] = useState<string>("");
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const methods = useForm<IReports>({
    reValidateMode: "onChange",
  });
  const userPriv = defineUserPriv();
  const individualUserPriv = defineUserIndividualPriv();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompany = e.target.value;
    setCompanyID(selectedCompany);
  };

  const handleReportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedReport = e.target.value;
    setReportType(selectedReport);
  };

  const handleStartDateChange = (e: React.ChangeEvent<any> | null) => {
    isDate(e) && setStartDate(e);
  };
  const handleEndDateChange = (e: React.ChangeEvent<any> | null) => {
    isDate(e) && setEndDate(e);
  };

  const getReportsUser = () => {
    if (individualUserPriv.includes(1) || individualUserPriv.includes(2)) {
      setReportOptions(reportsWithDisbursements);
    }
  };

  const getCompanies = useCallback(async () => {
    try {
      const response = await getCompaniesForUser();
      const companyOptions = response?.data?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setCompaniesOptions(companyOptions);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, []);

  useEffect(() => {
    getCompanies();
    getReportsUser();
  }, [userPriv]);

  useEffect(() => {
    if (companyID && reportType && startDate && endDate) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [companyID, reportType, startDate, endDate]);

  dailyItemStatusReportColumns[0].handleClick = (data: any) => {
    const itemId = data["Item ID"];
    handleIdSelected(itemId);
  };

  dailyDepositReportColumns[0].handleClick = (data: any) => {
    const itemId = data["Item ID"];
    handleIdSelected(itemId);
  };

  dailyAgentRollupColumns[0].handleClick = (data: any) => {
    const userId = data["User ID"];
    handleIdSelected(userId);
  };

  disbursementReportColumns[0].handleClick = (data: any) => {
    const disbursementId = data["Disbursement ID"];
    handleIdSelected(disbursementId);
  };

  const handleIdSelected = (id: number) => {
    const baseURL = window.location.origin;
    if (
      reportType == "successful-disbursements" ||
      reportType == "disbursements"
    ) {
      window.open(`${baseURL}/disbursements/${id}`, "_blank");
      return;
    }

    if (reportType == "daily-agent-rollup") {
      window.open(`${baseURL}/settings/users/${id}`, "_blank");
    }

    window.open(`${baseURL}/payments/99/detail/${id}`, "_blank");
  };

  const showReportTable = ({
    companyID,
    reportType,
    startDate,
    endDate,
  }: {
    companyID: string | undefined;
    reportType: string;
    startDate: Date;
    endDate: Date;
  }) => {
    setLoading(true);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 1);
    setReportType(reportType);
    setFormattedStartDate(formatToCustomString(startDate));
    setFormattedEndDate(formatToCustomString(endDate));
    getReportsForCompany(
      companyID,
      reportType,
      startDate.toISOString().split("T")[0],
      newEndDate.toISOString().split("T")[0]
    )
      .then((response) => {
        Papa.parse(response?.data, {
          header: true,
          complete: (result: any) => {
            const jsonData = result?.data;
            if (!jsonData || jsonData.length == 0) {
              toast({
                title: "Error",
                subTitle: "Failed to load Data",
                type: "error",
              });
              return;
            }

            const filteredData = jsonData.filter((row: any) => {
              return Object.values(row).some((value) => value !== "");
            });
            if (filteredData.length == 0) {
              setTableRows([]);
              toast({
                title: "No Reports found",
                subTitle: "Try broadening your filters",
                type: "info",
              });
              return;
            }
            const tableRowsWithIndex = filteredData.map(
              (row: any, index: number) => {
                if (row["Payment Type"] === "Commission Earned") {
                  return {
                    ...row,
                    id: index + 1,
                    "Payment Type": "Commission Check",
                  };
                } else {
                  return {
                    ...row,
                    id: index + 1,
                  };
                }
              }
            );

            setTableRows(tableRowsWithIndex);
            setTableData(reportType);
            switch (reportType) {
              case "daily-agent-rollup":
                setTitleReport("Daily Agent Rollup");
                setColumns(dailyAgentRollupColumns);
                break;
              case "daily-item-status":
                setTitleReport("Daily Item Status");
                setColumns(dailyItemStatusReportColumns);
                break;
              case "daily-deposits":
                setTitleReport("Daily Deposits");
                setColumns(dailyDepositReportColumns);
                break;
              case "disbursements":
                setTitleReport("All Disbursements");
                setColumns(disbursementReportColumns);
                break;
              case "successful-disbursements":
                setTitleReport("Successful Disbursements");
                setColumns(disbursementReportColumns);
                break;
              default:
                setTitleReport("Default");
                setColumns(disbursementReportColumns);
                break;
            }
          },
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          subTitle: "Please complete the empty fields",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formatToCustomString = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Container>
      <Container className={styles.reportsContainer}>
        <HeaderBar title={reportsStrings.COMPANY_REPORTS} />
        <FormProvider {...methods}>
          <Container className={styles.datePickerContainer}>
            <DropdownFormField
              name="companies"
              label={reportsStrings.COMPANY}
              options={companiesOptions}
              value={companyID}
              onChange={handleCompanyChange}
              required={true}
            />
            <DropdownFormField
              name="reports"
              label={reportsStrings.REPORTS}
              options={reportOptions}
              value={reportType}
              onChange={handleReportChange}
              required={true}
            />
            <DatePickerFormField
              name={"startDate"}
              onChange={handleStartDateChange}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={startDate}
              label={reportsStrings.START_DATE}
              maxDate={endDate}
              required={true}
            ></DatePickerFormField>
            <DatePickerFormField
              name={"endDate"}
              onChange={handleEndDateChange}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={endDate}
              label={reportsStrings.END_DATE}
              minDate={startDate}
              required={true}
            ></DatePickerFormField>
            <Container className={styles.btnContainer}>
              <Button
                id={"search"}
                variant="contained"
                label="Search"
                loading={loading}
                onClick={() =>
                  showReportTable({
                    companyID: companyID?.toString(),
                    reportType: reportType?.toString() || "",
                    startDate: startDate,
                    endDate: endDate,
                  })
                }
                disabled={!isButtonEnabled}
              />
            </Container>
          </Container>
        </FormProvider>
      </Container>

      <Container>
        {tableRows?.length > 0 ? (
          <TableComponent
            key={tableData}
            title={
              titleReport +
              " - " +
              formattedStartDate +
              " - " +
              formattedEndDate
            }
            data={tableRows || []}
            totalCount={tableRows?.length}
            columns={columns}
            loading={loading}
            serverSortingMode={false}
            serverPaginationMode={false}
            isSearchable={false}
          />
        ) : (
          <Container className={styles.emptyResultContainer}>
            <Icon size={135} name={"icon_noRecords"} />
            <h4>No records in the table</h4>
            <h5>Select company, report type and date to preview your report</h5>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default Reports;
