import { disbursementTableList } from "constants/tableData";
import { Document, Page, Text } from "@react-pdf/renderer";
import { PDFDatagrid, pdfStyles } from "../../components/dataGrid/pdfDataGrid";
import { IDisbursementPaymentItemResponse } from "../../interfaces";
import strings from "../../strings";

interface IPDFComponentProps {
  disbursementDetails: IDisbursementPaymentItemResponse;
}

const DisbursementPdf = (props: IPDFComponentProps) => {
  const { disbursementDetails } = props;

  const disbursement = { ...disbursementDetails };
  if (disbursement.disbursementStatusId === 0) {
    disbursement.disbursementStatusName = "Sent";
  }

  if (!disbursement.memo) {
    disbursement.memo = strings.NO_MEMO;
  }

  if (!disbursement.checkNumber) {
    disbursement.checkNumber = strings.THIS_PAYMENT_HAS_NOT_CHECK_NUMBER;
  }

  return (
    <Document>
      <Page size="A4">
        <Text
          style={pdfStyles.title}
        >{`Disbursement payment ${disbursement.id}`}</Text>
        <Text style={pdfStyles.sectionTitle}>DETAILS</Text>
        <PDFDatagrid
          columns={disbursementTableList.details}
          data={disbursement}
          rowStyle={{
            flexDirection: "row",
            margin: 5,
            justifyContent: "space-between",
          }}
        />

        {!!disbursement.reviewerUserName ? (
          <>
            <Text style={pdfStyles.sectionTitle}>Reviewed By</Text>
            <PDFDatagrid
              columns={disbursementTableList.reviewedBy}
              data={disbursement}
            />
          </>
        ) : null}
      </Page>
    </Document>
  );
};

export default DisbursementPdf;
