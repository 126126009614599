import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { useEffect } from "react";

interface IPlaidContainerProps {
  options: PlaidLinkOptions;
}

const PlaidContainer = (props: IPlaidContainerProps) => {
  const { open, exit, ready } = usePlaidLink(props.options);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);
  return <></>;
};

export default PlaidContainer;
