import React, { useEffect, useState } from "react";
import { Container, Loader, TypoGraph } from "components";
import styles from "./receivableAccountDetailBlade.module.scss";
import {
  IAdminLogResponse,
  IPageResponse,
  IReceivableAccountResponse,
} from "interfaces";
import { getReceivableAccountMaintenanceReport } from "api";
import { camelCaseToSeparatedWords } from "tools/format";

interface IReceivableAccountData {
  receivableAccountData: IReceivableAccountResponse;
}

const ReceivableAccountAudit: React.FC<IReceivableAccountData> = (props) => {
  const { receivableAccountData } = props;
  useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getReceivableAccountMaintenanceReport(receivableAccountData.id, 0, 20)
      .then((response) => {
        setReceivableAccountDetailsPage(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const [submitBtnLoader] = useState<boolean>(false);

  const [receivableAccountDetailsPage, setReceivableAccountDetailsPage] =
    useState<IPageResponse<IAdminLogResponse>>();

  function getAction(item: IAdminLogResponse): string {
    if (item.action) {
      return item.action;
    }
    const fieldName = item.fieldName;

    return `Updated ${camelCaseToSeparatedWords(fieldName)} from ${
      item.previousValue
    } to ${item.newValue}`;
  }

  return (
    <>
      <Loader loading={loading} />
      <Container className={styles.auditList}>
        <Container className={styles.header}>
          <TypoGraph className={styles.column} content="User/Account" />
          <TypoGraph className={styles.column} content="Changes/Action" />
          <TypoGraph className={styles.column} content="Date" />
        </Container>

        {receivableAccountDetailsPage?.items.map((item, index) => (
          <React.Fragment key={index}>
            <Container className={styles.body}>
              <TypoGraph
                className={styles.content}
                content={item.adminUser || ""}
              />
              <TypoGraph className={styles.content} content={getAction(item)} />
              <TypoGraph
                className={styles.content}
                content={item.created ? item.created.substring(0, 10) : ""}
              />
            </Container>
          </React.Fragment>
        ))}
      </Container>
    </>
  );
};

export default ReceivableAccountAudit;
