import axios from "axios";
import { ErrorResponse } from "interfaces";
import { Toast as toast } from "components";

const STAGING_API_URL = process.env.REACT_APP_SERVER;

const request = axios.create({
  baseURL: STAGING_API_URL,
  timeout: 20000,
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = { Authorization: `Bearer ${token}` };
    return config;
  },
  (error) => {
    toast({
      type: "error",
      title: "Error",
      subTitle: error?.response?.data?.message || "Something Went Wrong",
    });
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: ErrorResponse) => {
    if (error.response.data.status === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      toast({
        type: "error",
        title: "Error",
        subTitle: error?.response?.data?.message || "Something Went Wrong",
      });
      return Promise.reject(error);
    }
  }
);

const requestWithoutErrorHandling = axios.create({
  baseURL: STAGING_API_URL,
  timeout: 20000,
});

requestWithoutErrorHandling.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = { Authorization: `Bearer ${token}` };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;

export { requestWithoutErrorHandling };
