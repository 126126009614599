import * as React from "react";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const MuiTextFieldThemed = styled(MuiTextField)({
  "&.MuiTextField-root": {
    width: "100%",
  },
});

export interface IDatePickerProps {
  id?: string;
  label?: string;
  inputFormat?: string;
  type?: "text";
  variant?: "outlined" | "filled" | "standard";
  defaultValue?: string | number;
  value?: Date | null;
  minDate?: any;
  maxDate?: any;
  InputLabelProps?: object;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  inputRef?: any;
  select?: boolean;
  children?: React.ReactNode;
  views?: Array<"day" | "month" | "year">;
  onAccept?: (e: React.ChangeEvent<Element> | null) => void;
  onChange?: (e: React.ChangeEvent | null, keyboardInputValue?: string) => void;
  onBlur?: (e: React.ChangeEvent) => void;
}

const DatePicker: React.FunctionComponent<IDatePickerProps> = (props) => {
  const {
    id,
    label,
    inputFormat,
    variant = "outlined",
    value,
    minDate,
    maxDate,
    type = "text",
    defaultValue,
    views = ["day"],
    onChange = () => {},
    InputLabelProps = {},
    readOnly = false,
    required = false,
    disabled = false,
    error = false,
    multiline = false,
    helperText,
    rows = 4,
    inputRef,
    select = false,
    onAccept,
    children,
    onBlur,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat={inputFormat}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        views={views}
        onChange={onChange}
        onAccept={onAccept}
        disabled={disabled}
        renderInput={(props) => (
          <MuiTextFieldThemed
            onKeyDown={(e) => e.preventDefault()}
            id={id}
            type={type}
            select={select}
            inputRef={inputRef}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            variant={variant}
            required={required}
            helperText={helperText}
            disabled={disabled}
            error={error}
            multiline={multiline}
            rows={rows}
            InputLabelProps={InputLabelProps}
            margin="normal"
            InputProps={{
              readOnly: readOnly,
            }}
            {...props}
          >
            {children}
          </MuiTextFieldThemed>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
