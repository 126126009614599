import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import numberConverter from "number-to-words";
import moment, { isDate } from "moment";
import Dropzone from "react-dropzone";
import {
  Card,
  Container,
  Divider,
  HeaderBar,
  Icon,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  CheckboxFormField,
  DropdownFormField,
  InputFormField,
  RadioFormField,
} from "components/hookForm";
import styles from "./disbursementForm.module.scss";
import strings from "strings";
import {
  createDisbursementPayment,
  getCompaniesAdminByUser,
  getDisbursementAccountsByCompanyId,
  getDisbursementPaymentAttachmentById,
  getDisbursementPaymentById,
  getRecurrentPayeesForDisbursement,
  updateDraftDisbursementById,
} from "api";
import {
  DropdownOptions,
  IDisbursementDepositOption,
  IDisbursementPaymentItemResponse,
  IDisbursementPaymentRequest,
} from "interfaces";
import { DropdownField } from "components/controllers";
import { amountIsValid } from "tools/validators";
import { amountPattern } from "constants/regex";
import { useNavigate } from "react-router-dom";
import BaseDialog from "components/dialog/baseDialog";
import { base64ToFile, convertFileToBase64 } from "tools/base64";
import colors from "theme/colors";
import regex from "regex";
import DatePickerFormField from "components/hookForm/datePickerFormField";
import {
  DATE_FORMAT_YYYY_MM_DD,
  todayDate,
  todayDatePlus365Days,
} from "constants/datePicker";
import { useParams } from "react-router";
import { useMutation, useQueries } from "@tanstack/react-query";
import { IDisbursementPaymentAttachmentResponse } from "interfaces/IDisbursementPaymentModel";
import { canUpdateDisbursement } from "constants/disbursementUpdateActions";
import { LightTooltip } from "components/toolTip/lightTooltip";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
} from "@mui/material";
import { paymentMethods } from "constants/paymentMethods";

interface IDisbursementFormProps {}

interface IDisbursementForm {
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  amount: string;
  memo: string;
  paymentMethodId: number;
  disbursementAccountId: undefined | number | string;
  companyId: undefined | number | string;
  depositOption: IDisbursementDepositOption[];
}

const DisbursementForm: React.FC<IDisbursementFormProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState<Date>(new Date());
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [disbursementAccountOptions, setDisbursementAccountOptions] =
    useState<DropdownOptions[]>();
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [requireApproval, setRequireApproval] = useState<boolean>(false);
  const [nextCheckNumber, setNextCheckNumber] = useState<number>();
  const [disbursementCheckNumber, setDisbursementCheckNumber] = useState<
    string | number
  >();
  const [payeeOptions, setPayeeOptions] = useState<DropdownOptions[]>([]);
  const [selectedPayee, setSelectedPayee] = useState<DropdownOptions>();

  const methods = useForm<IDisbursementPaymentRequest>({
    reValidateMode: "onChange",
  });
  const { handleSubmit, getValues, resetField, watch, setValue } = methods;
  const t = strings.DISBURSEMENT_STRING;
  const navigate = useNavigate();
  const { disbursementId } = useParams();
  const [depositOptionsRequired, setDepositOptionsRequired] =
    useState<boolean>(false);
  const [depositOptions, setDepositOptions] = useState<
    IDisbursementDepositOption[]
  >([]);
  const updateDraftDisbursement = useMutation(
    ({
      id,
      updateDisbursementRequest,
    }: {
      id: string | undefined;
      updateDisbursementRequest: IDisbursementPaymentRequest;
    }) => {
      return updateDraftDisbursementById(id, updateDisbursementRequest);
    },
    {
      onError: (error) => {
        setLoading(false);
      },
      onSuccess: (response) => {
        getDetails.refetch();
        getAttachment.refetch();

        isDraft
          ? toast({
              title: t.UPDATED_DISBURSEMENT_SUCCESSFULLY,
              subTitle: `Id: ${response.data.id}`,
            })
          : toast({
              title: t.CREATED_SUCCESSFULLY,
              subTitle: `Id: ${response?.data?.id}`,
            });

        navigate("/disbursements");
      },
    }
  );

  const getTimezoneOffset = (value: any) => value.getTimezoneOffset() * 60000;

  const makeLocalAppearUTC = (value: any) => {
    const dateTime = new Date(value);
    return new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  };

  const [getAttachment, getDetails] = useQueries({
    queries: [
      {
        queryKey: ["disbursementAttachment", disbursementId],
        queryFn: () => getDisbursementPaymentAttachmentById(disbursementId),
        enabled: !!disbursementId,
        onError: () => navigate("/disbursements/create"),
        onSuccess: (attachment: IDisbursementPaymentAttachmentResponse) => {
          if (!!attachment.base64Attachment) {
            setAttachedFile(
              base64ToFile(attachment.base64Attachment, attachment.fileName)
            );
          }
        },
        staleTime: Infinity,
      },
      {
        queryKey: ["disbursementDetails", disbursementId],
        queryFn: () => getDisbursementPaymentById(disbursementId),
        enabled: !!disbursementId,
        onError: () => navigate("/disbursements/create"),
        onSuccess: (details: IDisbursementPaymentItemResponse) => {
          setScheduledDate(
            makeLocalAppearUTC(
              details.scheduledDateNoFormat
                ? new Date(details.scheduledDateNoFormat)
                : todayDate
            )
          );
          setDisbursementCheckNumber(details?.checkNumber);
          setDepositOptions(details?.depositOptions);
          setSelectedCompany(details.companyId);
        },
        refetchOnMount: true,
        refetchOnReconnect: "always",
        staleTime: Infinity,
      },
    ],
  });

  let initialValues: IDisbursementForm = {
    recipientFirstName: "",
    recipientLastName: "",
    recipientEmail: "",
    amount: "0",
    memo: "",
    paymentMethodId: 2,
    disbursementAccountId: undefined,
    companyId: undefined,
    depositOption: [],
  };
  if (getDetails.data) {
    if (
      !canUpdateDisbursement(
        getDetails.data.disbursementStatusId,
        getDetails.data.payingUserId
      )
    ) {
      navigate("/disbursements/create");
    } else {
      initialValues = {
        recipientFirstName: getDetails.data?.recipientFirstName,
        recipientLastName: getDetails.data?.recipientLastName,
        recipientEmail: getDetails.data?.recipientEmail,
        amount: getDetails.data ? getDetails.data.amount : "0",
        memo: getDetails.data?.memo,
        paymentMethodId: getDetails.data?.paymentMethodId
          ? getDetails.data?.paymentMethodId
          : 2,
        disbursementAccountId: getDetails.data?.disbursementAccountId,
        companyId: getDetails.data?.companyId,
        depositOption: getDetails.data?.depositOptions,
      };
    }
  }

  const recipientFirstName = watch("recipientFirstName");
  const recipientLastName = watch("recipientLastName");
  const amount = watch("amount");
  const memo = watch("memo");
  const disbursementAccountId = watch("disbursementAccountId");

  const centAmount = (amount + "").split(".")[1];

  const onSubmit = async (formData: IDisbursementPaymentRequest) => {
    if (attachedFile) {
      const base64 = await convertFileToBase64(attachedFile);
      formData.attachment = base64
        ?.toString()
        ?.replace("data:application/pdf;base64,", "");

      formData.attachment = formData.attachment
        ?.toString()
        ?.replace("data:application/octet-stream;base64,", "");
    }
    setLoading(true);
    const match = formData.amount.toString().match(amountPattern);
    if (!match) {
      return;
    }
    const amountAsFloat = parseFloat(match[1]);
    formData.amount = Math.trunc(amountAsFloat * 100);
    if (formData.scheduledDate) {
      formData.scheduledDate = new Date(scheduledDate);
    }
    const scheduledDateString = isDate(scheduledDate)
      ? scheduledDate?.toLocaleDateString()
      : null;
    formData.scheduledDate = isDate(scheduledDate)
      ? scheduledDate.toISOString().split("T")[0]
      : null;
    if (scheduledDateString === todayDate.toLocaleDateString()) {
      formData.scheduledDate = null;
    }
    formData.paymentMethodId = paymentMethods.DIGITAL_CHECK;

    const filteredDepositOptions = depositOptions.filter(
      (depositOption) => formData?.depositOptions[depositOption.id]?.active
    );
    const anyDepositMethodSelected = filteredDepositOptions.length > 0;
    setDepositOptionsRequired(!anyDepositMethodSelected);
    formData.depositOptions = filteredDepositOptions;
    if (!anyDepositMethodSelected) {
      setLoading(false);
      return;
    }
    if (successGettingDetailsOfDisbursementToUpdate) {
      updateDraftDisbursement.mutate({
        id: disbursementId,
        updateDisbursementRequest: formData,
      });
    } else {
      createDisbursementPayment(formData)
        .then((response) => {
          toast({
            title: t.CREATED_SUCCESSFULLY,
            subTitle: `Id: ${response?.data?.id}`,
          });
          navigate("/disbursements");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getCompanies();
    if (disbursementId) {
      getAttachment.refetch();
      getDetails.refetch();
    }
    getRecurrentPayeesForDisbursement().then((response) => {
      let options = response.data.map((option, index) => {
        return {
          value: index,
          label: `${option.recipientFirstName} ${option.recipientLastName} (${option.recipientEmail})`,
          recurrentPayee: option,
        };
      });
      setPayeeOptions(options);
    });
  }, []);

  useEffect(() => {
    resetField("disbursementAccountId");
    if (selectedCompany) {
      getDisbursementAccountsByCompanyId(selectedCompany).then((response) => {
        const disbursementAccounts = response?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
            boolean: !!item?.approvalRequired,
            checkNumber: item?.nextCheckNumber,
            depositOptions: item?.depositOptions,
          };
        });
        setDisbursementAccountOptions(disbursementAccounts);
        if (
          !!getDetails?.data?.disbursementAccountId &&
          disbursementAccounts.find(
            (item) => item.value === getDetails?.data?.disbursementAccountId
          )
        ) {
          setValue(
            "disbursementAccountId",
            getDetails.data.disbursementAccountId
          );
        }
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    let selectedDisbursementAccount = disbursementAccountOptions?.find(
      (account) => account.value == disbursementAccountId
    );
    setRequireApproval(!!selectedDisbursementAccount?.boolean);
    setNextCheckNumber(selectedDisbursementAccount?.checkNumber);
    setDepositOptions(selectedDisbursementAccount?.depositOptions || []);
  }, [disbursementAccountId]);

  useEffect(() => {
    if (payeeOptions.length > 0 && getDetails.data) {
      let defaultOption = payeeOptions.filter(
        (option) =>
          option.recurrentPayee?.recipientFirstName ==
            getDetails.data.recipientFirstName &&
          option.recurrentPayee?.recipientLastName ==
            getDetails.data.recipientLastName &&
          option.recurrentPayee?.recipientEmail ==
            getDetails.data.recipientEmail
      )[0];
      setSelectedPayee(defaultOption);
    }
  }, [payeeOptions, getDetails]);

  const getCompanies = useCallback(() => {
    getCompaniesAdminByUser().then((response) => {
      const companyOptions = response?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
          address: item?.address,
          location: item?.location,
        };
      });
      setCompaniesOptions(companyOptions);
    });
  }, []);

  const downloadPDF = async (attachedFileData: File) => {
    const fileURL = (await convertFileToBase64(attachedFileData)) as string;
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = attachedFileData.name;
    alink.click();
  };

  const successGettingDetailsOfDisbursementToUpdate =
    getDetails.isSuccess && getAttachment.isSuccess;

  const isLoading = getDetails.isFetching || getAttachment.isFetching;

  const emailFieldWithAutocomplete = (
    hasDefaultValue: boolean
  ): JSX.Element => {
    return (
      <Autocomplete
        freeSolo
        options={payeeOptions}
        disablePortal
        defaultValue={hasDefaultValue ? selectedPayee : undefined}
        getOptionLabel={(option) => {
          let value = option as DropdownOptions;
          if (value.value == selectedPayee?.value) {
            return value.recurrentPayee?.recipientEmail || "";
          }
          return value.label?.toString();
        }}
        onChange={(event, value) => {
          if (!value) {
            return;
          }
          let selectedOption = value as DropdownOptions;

          let payee = selectedOption.recurrentPayee;

          if (!payee) {
            return;
          }
          setValue("recipientFirstName", payee.recipientFirstName);
          setValue("recipientLastName", payee.recipientLastName);
          setValue("recipientEmail", payee.recipientEmail);
          setSelectedPayee(selectedOption);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <InputFormField
            {...params}
            id={t.PAYEES_EMAIL}
            name="recipientEmail"
            defaultValue={initialValues.recipientEmail}
            label={t.PAYEES_EMAIL}
            maxLength={128}
            required
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: strings.PLEASE_ENTER_A_VALID_EMAIL,
            }}
          />
        )}
      />
    );
  };

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <Container>
      <HeaderBar
        title={t.DISBURSEMENTS}
        primaryBtnProps={{
          label: requireApproval ? t.SUBMIT_FOR_APPROVAL : t.SEND_DISBURSEMENT,
          onClick: handleSubmit((data) => {
            setIsDraft(false);
            data.isDraft = false;
            onSubmit(data);
          }),
        }}
        secondaryBtnProps={{
          label: t.SAVE_AS_DRAFT,
          onClick: handleSubmit((data) => {
            setIsDraft(true);
            data.isDraft = true;
            onSubmit(data);
          }),
        }}
      />
      <FormProvider {...methods}>
        <BaseDialog
          open={loading}
          handleClose={() => {}}
          showCancel={false}
          content={
            <Container>
              {isDraft ? (
                <TypoGraph content={t.SAVING_DRAFT_DISBURSEMENT} />
              ) : (
                <TypoGraph
                  content={`${t.SENDING_DISBURSEMENT_PAYMENT} ${getValues(
                    "recipientEmail"
                  )} `}
                />
              )}
              <Loader loading={loading} type="default" height="30vh" />
            </Container>
          }
        />
        <Card>
          <TypoGraph
            variant="h2"
            sx={{ paddingTop: 1, paddingBottom: 2 }}
            content={t.DISBURSEMENT_DETAILS}
          />
          <Container className={styles.formContainer}>
            <Divider title={t.PAYMENT_DETAILS} />
            <Divider title={t.ACCOUNTING_DETAILS} />
          </Container>
          <Container className={styles.formContainer}>
            <Container>
              {payeeOptions.length > 0 &&
                selectedPayee &&
                emailFieldWithAutocomplete(true)}
              {payeeOptions.length > 0 &&
                !selectedPayee &&
                emailFieldWithAutocomplete(false)}
              {!selectedPayee && payeeOptions.length == 0 && (
                <InputFormField
                  id={t.PAYEES_EMAIL}
                  name="recipientEmail"
                  defaultValue={initialValues.recipientEmail}
                  label={t.PAYEES_EMAIL}
                  maxLength={128}
                  required
                  pattern={{
                    value: regex.EMAIL_REGEX_PATTERN,
                    message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                  }}
                />
              )}
              <InputFormField
                id="confirmRecipientEmail"
                name="confirmRecipientEmail"
                label={strings.CONFIRM_EMAIL}
                maxLength={128}
                required
                pattern={{
                  value: regex.EMAIL_REGEX_PATTERN,
                  message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                }}
                validate={(val) =>
                  val === getValues("recipientEmail") ||
                  strings.EMAIL_ADDRESSES_DO_NOT_MATCH
                }
              />
              <Container className={styles.formTwoContainer}>
                <InputFormField
                  id={t.PAYEES_FIRST_NAME}
                  name="recipientFirstName"
                  defaultValue={initialValues.recipientFirstName}
                  label={t.PAYEES_FIRST_NAME}
                  maxLength={128}
                  required
                />
                <InputFormField
                  id={t.PAYEES_LAST_NAME}
                  name="recipientLastName"
                  defaultValue={initialValues.recipientLastName}
                  label={t.PAYEES_LAST_NAME}
                  maxLength={128}
                  required
                />
              </Container>
              <Container className={styles.formTwoContainer}>
                <InputFormField
                  id={t.PAYMENT_AMOUNT}
                  name="amount"
                  label={t.PAYMENT_AMOUNT}
                  defaultValue={initialValues.amount}
                  maxLength={13}
                  required
                  currency={true}
                  validate={(textFieldValue: string) =>
                    amountIsValid(textFieldValue)
                  }
                />
                <DatePickerFormField
                  id={"scheduledDate"}
                  name="scheduledDate"
                  label={"Payment date"}
                  value={scheduledDate}
                  inputFormat={DATE_FORMAT_YYYY_MM_DD}
                  minDate={todayDate}
                  onChange={(e: React.ChangeEvent<any> | null) => {
                    isDate(e) && setScheduledDate(e);
                  }}
                  maxDate={todayDatePlus365Days}
                  validate={(dateValue: string | number) => {
                    if (
                      Date.parse(new Date(dateValue).toDateString()) <
                        Date.parse(todayDate.toDateString()) ||
                      Date.parse(new Date(dateValue).toDateString()) >
                        Date.parse(todayDatePlus365Days.toDateString())
                    ) {
                      return "Invalid Paying date";
                    }
                  }}
                />
              </Container>
              <InputFormField
                id={t.MEMO}
                name="memo"
                label={t.MEMO}
                defaultValue={initialValues.memo}
                maxLength={128}
              />
            </Container>
            <Container className={styles.secondColumnForm}>
              <DropdownField
                label={t.COMPANY}
                options={companiesOptions}
                defaultValue={initialValues.companyId}
                onChange={(e) => setSelectedCompany(e.target.value)}
                disabled={!companiesOptions || companiesOptions.length === 0}
              />
              <DropdownFormField
                name="disbursementAccountId"
                label={t.FUNDING_ACCOUNT}
                options={disbursementAccountOptions}
                defaultValue={initialValues.disbursementAccountId}
                disabled={
                  !disbursementAccountOptions ||
                  disbursementAccountOptions.length === 0
                }
                required
              />
              {selectedCompany &&
                (!disbursementAccountOptions ||
                  disbursementAccountOptions.length === 0) && (
                  <TypoGraph
                    color={colors.error}
                    content={
                      "Selected company has no available Funding Accounts"
                    }
                  />
                )}
              <Container className={styles.fileUploadContainer}>
                {attachedFile && (
                  <Container
                    key={attachedFile?.name}
                    className={styles.filePreview}
                  >
                    <Container className={styles.fileNameContainer}>
                      <Icon name="icon_uploadSuccess" />
                      <TypoGraph
                        variant="caption"
                        content={attachedFile?.name}
                        className={styles.text}
                      />
                    </Container>
                    <Container className={styles.cancelIcon}>
                      <Icon
                        name="icon_filedownload"
                        iconButton
                        onClick={() => downloadPDF(attachedFile)}
                      />
                      <Icon
                        name="icon_filecancel"
                        iconButton
                        onClick={() => setAttachedFile(undefined)}
                      />
                    </Container>
                  </Container>
                )}
                {!attachedFile && (
                  <Container>
                    <Dropzone
                      accept={{ "application/pdf": [] }}
                      multiple={false}
                      onDrop={(file) => setAttachedFile(file[0])}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className={styles.dropzoneContainer}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              onDrop: (event) => event.stopPropagation(),
                            })}
                          >
                            <input {...getInputProps()} />
                            <Container className={styles.fileUploadText}>
                              <Icon name="icon_fileupload" />
                              <TypoGraph
                                content={
                                  "Upload an attachment to this disbursement"
                                }
                                color="#666666"
                              />
                            </Container>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Container>
                )}
              </Container>
              <Divider title="Deposit Options" />
              <Container>
                {depositOptions.map((field, index) => (
                  <Container key={`key-${field.id}`}>
                    <Container>
                      <CheckboxFormField
                        id={field.name}
                        name={`depositOptions.${field.id}.active`}
                        label={field.name}
                      />
                    </Container>
                  </Container>
                ))}
                {depositOptionsRequired && (
                  <TypoGraph
                    variant="body2"
                    color={colors.error}
                    content="It is required to select at least one *"
                  />
                )}
              </Container>
            </Container>
          </Container>
          <Container>
            <Divider title={t.PREVIEW} textAlign="center" />
            <Container className={styles.checkContainer}>
              <Container className={styles.checkHeadContainer}>
                <Container>
                  <TypoGraph
                    variant="h2"
                    content={
                      selectedCompany
                        ? (companiesOptions?.filter(
                            (company) => company?.value === selectedCompany
                          )[0].label as string)
                        : "Your Company Name"
                    }
                  />
                  <TypoGraph
                    content={
                      selectedCompany
                        ? (companiesOptions?.filter(
                            (company) => company?.value === selectedCompany
                          )[0].address as string)
                        : "Your company address"
                    }
                  />
                  <TypoGraph
                    content={
                      selectedCompany
                        ? (companiesOptions?.filter(
                            (company) => company?.value === selectedCompany
                          )[0].location as string)
                        : ""
                    }
                  />
                </Container>
                <Container className={styles.dateContainer}>
                  <TypoGraph content="Date" />
                  <TypoGraph
                    className={styles.bordered}
                    content={moment(scheduledDate).format("MM-DD-yyyy")}
                  />
                </Container>
                {disbursementCheckNumber ||
                (nextCheckNumber && nextCheckNumber > 0) ? (
                  <Container className={styles.checkNumberContainer}>
                    <TypoGraph
                      variant={"h2"}
                      content={
                        disbursementCheckNumber?.toString() ||
                        nextCheckNumber?.toString()
                      }
                    />
                    <LightTooltip
                      title={
                        disbursementCheckNumber
                          ? strings.CHECK_NUMBER_FOR_THIS_DISBURSEMENT
                          : strings.DIGITAL_CHECK_NUMBER_HELP
                      }
                    >
                      <Box
                        sx={{
                          alignSelf: "center",
                          height: 20,
                        }}
                      >
                        <Icon name="icon_help" size={30}></Icon>
                      </Box>
                    </LightTooltip>
                  </Container>
                ) : (
                  <Container className={styles.checkNumberContainer}>
                    <TypoGraph variant={"h2"} content={"00000000"} />
                  </Container>
                )}
              </Container>
              <Container className={styles.payNameContainer}>
                <TypoGraph content="Pay To The Order Of" />
                <Container className={styles.dateContainer}>
                  <TypoGraph
                    className={`${styles.bordered} ${styles.payeeName}`}
                    content={`${recipientFirstName || ""} ${
                      recipientLastName || ""
                    }`}
                  />
                </Container>
                <Container className={styles.amountContainer}>
                  <NumberFormat
                    value={amount || 0}
                    renderText={(value) => <TypoGraph>{value}</TypoGraph>}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </Container>
              </Container>
              <Container className={styles.dollarContainer}>
                <Container>
                  <TypoGraph className={styles.bordered}>
                    {amount
                      ? `${numberConverter.toWords(
                          parseInt(
                            (amount.toString().startsWith(".")
                              ? `0${amount}`
                              : amount.toString()
                            ).split(".")[0],
                            10
                          )
                        )} and ${
                          (amount.toString().startsWith(".")
                            ? `0${amount}`
                            : amount.toString()
                          ).split(".")[1] || "00"
                        }/100`
                      : ""}
                  </TypoGraph>
                </Container>
                <TypoGraph content="Dollars" />
              </Container>
              <Container className={styles.memoContainer}>
                <TypoGraph content="Memo" />
                <TypoGraph content={memo} className={styles.bordered} />
                <TypoGraph className={styles.bordered} />
              </Container>
            </Container>
          </Container>
        </Card>
      </FormProvider>
    </Container>
  );
};

export default DisbursementForm;
