import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { companyFormFields } from "constants/formFields";
import {
  createNewCompany,
  deleteCompany,
  getCompanyDetail,
  getPaymentMethods,
  getPaymentMethodsForExternalDeposit,
  getUsStates,
  reactivateCompany,
  updateCompany,
} from "api";
import {
  DropdownOptions,
  ICheckCaptureProcessor,
  ICompanyRequest,
  ICompanyResponse,
  IPaymentMethod,
} from "interfaces";
import {
  Button,
  Card,
  Container,
  Divider,
  HeaderBar,
  Loader,
  SubmitDialog,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  CheckboxFormField,
  InputFormField,
  MultiSelectFormField,
} from "components/hookForm";
import styles from "./companyPage.module.scss";
import strings from "strings";
import { AxiosResponse } from "axios";
import Decimal from "decimal.js";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import { checkCaptureProcessors } from "constants/checkCaptureProcessors";

const CompanyPage: React.FunctionComponent = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] =
    useState<IPaymentMethod[]>();
  const [availableExternalDepositMethods, setAvailableExternalDepositMethods] =
    useState<IPaymentMethod[]>();
  const [checkedStates, setCheckedStates] = useState(
    new Array(companyFormFields.paymentTypesCheckbox.length).fill(false)
  );
  const [statesOptions, setStatesOptions] = useState<DropdownOptions[]>();
  const [companyData, setCompanyData] = useState<ICompanyResponse>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [reactivateModal, setReactivateModal] = useState<boolean>(false);
  const [confirmModalBtnLoader, setConfirmModalBtnLoader] =
    useState<boolean>(false);
  const methods = useForm<ICompanyRequest>({
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset, control, getValues, watch, setValue } = methods;

  const { fields: paymentMethods } = useFieldArray({
    control,
    name: "paymentMethods",
  });

  const { fields: externalDepositMethods } = useFieldArray({
    control,
    name: "externalDepositPaymentMethods",
  });
  const { fields: paymentTypeFeeList } = useFieldArray({
    control,
    name: "paymentTypeFeeList",
  });
  const { fields: checkCaptureProcessorList } = useFieldArray({
    control,
    name: "checkCaptureProcessorList",
  });
  const navigate = useNavigate();
  const { companyId } = useParams();
  const isEdit = Boolean(companyData);

  useEffect(() => {
    getMethods();
    getExternalDepositMethods();
    getStates();
    if (!!companyId) {
      getCompany(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    getStateCheckbox();
  }, [companyData]);

  const getStateCheckbox = () => {
    const checkedStatesResponse: any[] = [];

    companyFormFields.paymentTypesCheckbox.forEach((item, index) => {
      if (companyData !== undefined) {
        const value = companyData[item.name];
        checkedStatesResponse[index] = value;
      }
    });
    setCheckedStates(checkedStatesResponse);
  };

  const getCompany = (companyId: string) => {
    setLoading(true);
    getCompanyDetail(companyId)
      .then((response) => setCompanyData(response.data))
      .finally(() => setLoading(false));
  };
  const getMethods = () => {
    setLoading(true);
    getPaymentMethods()
      .then((response) => setAvailablePaymentMethods(response.data))
      .finally(() => setLoading(false));
  };
  const getExternalDepositMethods = () => {
    setLoading(true);
    getPaymentMethodsForExternalDeposit()
      .then((response) => setAvailableExternalDepositMethods(response.data))
      .finally(() => setLoading(false));
  };

  const getStates = () => {
    setLoading(true);
    getUsStates()
      .then((response) => {
        const statesOptions = response?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setStatesOptions(statesOptions);
      })
      .finally(() => setLoading(false));
  };

  const buildPaymentMethodFormData = useCallback(
    (
      data: ICompanyResponse,
      paymentMethods: IPaymentMethod[],
      externalDepositMethods: IPaymentMethod[]
    ) => {
      data.paymentMethods = paymentMethods.map((paymentMethod) => {
        paymentMethod.active = !!data.paymentMethods.find(
          (paymentMethodFromCompany) =>
            paymentMethodFromCompany.id === paymentMethod.id
        );
        return paymentMethod;
      });
      data.externalDepositPaymentMethods = externalDepositMethods.map(
        (externalDepositMethod) => {
          externalDepositMethod.active =
            !!data.externalDepositPaymentMethods.find(
              (externalDepositMethodFromCompany) =>
                externalDepositMethodFromCompany.id === externalDepositMethod.id
            );
          return externalDepositMethod;
        }
      );
      data.checkCaptureProcessorList = checkCaptureProcessors.map(
        (paymentProcessor) => {
          paymentProcessor.active = !!data.checkCaptureProcessorList.find(
            (checkCapturePaymentProcessor) =>
              checkCapturePaymentProcessor.id === paymentProcessor.id
          );
          return paymentProcessor;
        }
      );
      data.paymentTypeFeeList = data.paymentTypeFeeList.map((paymentFee) => {
        paymentFee.feeInCents = paymentFee.feeInCents / 100;
        return paymentFee;
      });
      data.pCode = data.pcode;
      return data;
    },
    []
  );

  useEffect(() => {
    if (
      companyData &&
      availablePaymentMethods &&
      availableExternalDepositMethods
    ) {
      const formData = buildPaymentMethodFormData(
        companyData,
        availablePaymentMethods,
        availableExternalDepositMethods
      );
      reset(formData);
    } else if (availablePaymentMethods && availableExternalDepositMethods) {
      const formData = getValues();
      const paymentMethodBase = availablePaymentMethods.map((paymentMethod) => {
        paymentMethod.active = false;
        return paymentMethod;
      });
      formData.paymentMethods = paymentMethodBase;

      formData.externalDepositPaymentMethods =
        availableExternalDepositMethods.map((externalDepositMethod) => {
          externalDepositMethod.active = false;
          return externalDepositMethod;
        });

      formData.checkCaptureProcessorList = checkCaptureProcessors.map(
        (paymentProcessor) => {
          paymentProcessor.active = false;
          return paymentProcessor;
        }
      );

      reset(formData);
    }
  }, [
    companyData,
    availablePaymentMethods,
    availableExternalDepositMethods,
    buildPaymentMethodFormData,
    reset,
    getValues,
  ]);

  const buildRequestPayload = (data: ICompanyRequest) => {
    let paymentMethodsRequest: IPaymentMethod[] = data.paymentMethods.filter(
      (paymentMethod) => paymentMethod.active
    );
    paymentMethodsRequest.map((paymentMethod) => delete paymentMethod.active);
    data.paymentMethods = paymentMethodsRequest;

    const paymentTypeFeeList = data.paymentTypeFeeList.map((item, index) => {
      if (checkedStates[index] === false || undefined) {
        delete data[`paymentTypeFeeList[${index}].feeInCents`];
        return {
          paymentTypeId: index,
          feeInCents: 0,
        };
      } else {
        const feeAmount = data.paymentTypeFeeList[index].feeInCents;
        delete data[`paymentTypeFeeList[${index}].feeInCents`];
        return {
          paymentTypeId: index,
          feeInCents:
            isNaN(feeAmount) || feeAmount.toString() === ""
              ? 0
              : new Decimal(feeAmount).times(100).toNumber(),
        };
      }
    });
    data.paymentTypeFeeList = paymentTypeFeeList.filter(
      (item) => item != undefined || item != null
    );

    companyFormFields.paymentTypesCheckbox.forEach((item, index) => {
      data[item.name] = checkedStates[index] ? true : undefined;
    });

    let externalDepositPaymentMethodsRequest: IPaymentMethod[] =
      data.externalDepositPaymentMethods.filter(
        (paymentMethod) => paymentMethod.active
      );
    externalDepositPaymentMethodsRequest.map(
      (paymentMethod) => delete paymentMethod.active
    );
    data.externalDepositPaymentMethods = externalDepositPaymentMethodsRequest;

    let checkCaptureProcessorListRequest: ICheckCaptureProcessor[] =
      data.checkCaptureProcessorList.filter(
        (paymentProcessor) => paymentProcessor.active
      );
    checkCaptureProcessorListRequest.map(
      (paymentProcessor) => delete paymentProcessor.active
    );
    data.checkCaptureProcessorList = checkCaptureProcessorListRequest;

    if (getValues("enabledSSO")) {
      let singleSignOnRequest = {
        entityId: getValues("entityId"),
        ssoService: getValues("ssoService"),
        idpCertificate: getValues("idpCertificate"),
      };
      data.singleSignOn = singleSignOnRequest;
    } else {
      data.singleSignOn = undefined;
    }

    delete data.id;
    delete data.deleted;
    delete data.pcode;
    delete data.entityId;
    delete data.ssoService;
    delete data.idpCertificate;

    return data;
  };

  const onSubmit = (companyForm: ICompanyRequest, type: string) => {
    setBtnLoader(true);
    let action;
    let successMessage = "Company created Successfully";
    if (!companyForm.address2) {
      companyForm.address2 = "";
    }
    companyForm = buildRequestPayload(companyForm);
    switch (type) {
      case strings.CREATE:
        action = createNewCompany(companyForm);
        successMessage = "Company created Successfully";
        break;
      case strings.DELETE:
        setConfirmModalBtnLoader(true);
        successMessage = "Company deleted Successfully";
        action = companyData
          ? deleteCompany(companyData.id)
          : createNewCompany(companyForm);
        break;
      case strings.EDIT:
        successMessage = "Company updated Successfully";
        action = companyData
          ? updateCompany(companyForm, companyData.id)
          : createNewCompany(companyForm);
        break;
      case strings.REACTIVATE:
        setConfirmModalBtnLoader(true);
        successMessage = "Company reactivated Successfully";
        action = companyData
          ? reactivateCompany(companyData.id)
          : createNewCompany(companyForm);
        break;
      default:
        action = createNewCompany(companyForm);
        break;
    }
    action
      .then((resp: AxiosResponse<ICompanyResponse>) => {
        toast({
          title: successMessage,
          subTitle: `Name: ${resp?.data?.name}\nId: ${resp?.data?.id}`,
        });
        navigate("/settings/companies");
      })
      .catch(() => setBtnLoader(false))
      .finally(() => {
        setConfirmModalBtnLoader(false);
        setDeleteModal(false);
      });
  };

  const handleCheckboxChange = (index: number) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);
  };

  return (
    <Container>
      <HeaderBar
        title={`${isEdit ? strings.EDIT : strings.CREATE} ${strings.COMPANY}`}
        primaryBtnProps={{
          id: isEdit ? strings.UPDATE_COMPANY : strings.CREATE_COMPANY,
          label: isEdit ? strings.UPDATE_COMPANY : strings.CREATE_COMPANY,
          onClick: handleSubmit((data) =>
            onSubmit(data, isEdit ? strings.EDIT : strings.CREATE)
          ),
        }}
        secondaryBtnProps={
          companyData?.deleted === false
            ? {
                id: strings.DELETE_COMPANY,
                label: isEdit ? strings.DELETE_COMPANY : undefined,
                color: "error",
                onClick: () => setDeleteModal(true),
              }
            : userCan(Actions.REACTIVATE, Resources.COMPANY)
            ? {
                id: strings.REACTIVATE_COMPANY,
                label: isEdit ? strings.REACTIVATE_COMPANY : undefined,
                variant: "outlined",
                onClick: () => setReactivateModal(true),
              }
            : {}
        }
      />
      <Loader loading={loading} />
      <Card>
        <TypoGraph
          variant="h2"
          sx={{ paddingTop: 1, paddingBottom: 2 }}
          content={`${
            isEdit
              ? `${strings.EDIT} Company Details`
              : `${strings.CREATE} a Company`
          }`}
        />
        <FormProvider {...methods}>
          <Container className={styles.textFieldContainer}>
            <Container>
              <InputFormField
                id={strings.COMPANY_NAME}
                name="name"
                label={strings.COMPANY_NAME}
                maxLength={128}
                required
              />
              <InputFormField
                id={strings.ADDRESS_1}
                name="address1"
                label={strings.ADDRESS_1}
                maxLength={128}
                required
              />
              <InputFormField
                id={strings.ADDRESS_2}
                name="address2"
                label={strings.ADDRESS_2}
                maxLength={128}
                required={false}
              />
              <InputFormField
                id={strings.CITY}
                name="city"
                maxLength={128}
                label={strings.CITY}
                required
              />
              <MultiSelectFormField
                id={strings.STATE}
                name="state"
                label={strings.STATE}
                options={statesOptions}
                isMulti={false}
                required
              />
              <InputFormField
                id={strings.POSTAL_CODE}
                name="pCode"
                label={strings.POSTAL_CODE}
                maxLength={32}
                required
              />
              <InputFormField
                id={strings.LOCATOR}
                name="locator"
                label={strings.LOCATOR}
                maxLength={16}
                required
              />
              <InputFormField
                id={strings.BROKER_CODE}
                name="brokerCode"
                label={strings.BROKER_CODE}
                maxLength={16}
                required
              />
              <InputFormField
                id={strings.ACCOUNT_TYPE}
                name="accountType"
                label={strings.ACCOUNT_TYPE}
                maxLength={40}
                required
              />
              <InputFormField
                id={strings.CHECK_RETENTION}
                name="checkRetention"
                type="number"
                maxLength={10}
                max={{ value: 2147483647, message: "Max value is 2147483647" }}
                min={{
                  value: 7,
                  message: strings.CHECK_RETENTION_ERROR_MSG,
                }}
                label={strings.CHECK_RETENTION}
              />
            </Container>
          </Container>
          <Divider title="Payment Types" />
          {companyFormFields.paymentTypesCheckbox.map((item, index) => (
            <Container className={styles.paymentFeeContainer}>
              <CheckboxFormField
                key={index}
                id={item.label}
                name={item.name}
                label={item.label}
                checkeds={checkedStates[index]}
                value={checkedStates[index]}
                onChanges={() => handleCheckboxChange(index)}
              />
              <Container className={styles.paymentFeeContainer}>
                <InputFormField
                  disabled={!checkedStates[index]}
                  id={`${index}`}
                  label={`Payment Fee`}
                  name={`paymentTypeFeeList.${index}.feeInCents`}
                  maxLength={13}
                  currency
                />
              </Container>
            </Container>
          ))}
          <Divider title="Payment Methods" />
          {paymentMethods.map((field, index) => {
            return (
              <Container key={`key-${field.id}`}>
                <CheckboxFormField
                  id={field.name}
                  name={`paymentMethods.${index}.active`}
                  label={field.name}
                />
              </Container>
            );
          })}
          <Divider title="Check Capture Payment Processors" />
          {checkCaptureProcessorList.map((field, index) => {
            return (
              <Container key={`key-${field.id}`}>
                <CheckboxFormField
                  id={field.name}
                  name={`checkCaptureProcessorList.${index}.active`}
                  label={field.name}
                />
              </Container>
            );
          })}
          <Divider title="Deposit to External Account" />
          {externalDepositMethods.map((field, index) => {
            return (
              <Container key={`key-${field.id}`}>
                <CheckboxFormField
                  id={field.name}
                  name={`externalDepositPaymentMethods.${index}.active`}
                  label={field.name}
                />
              </Container>
            );
          })}

          <Divider title="Security" />
          {companyFormFields.securityCheckbox.map((item, index) => (
            <CheckboxFormField
              id={item.label}
              key={`key-${index}`}
              name={item.name}
              label={item.label}
            />
          ))}

          <Divider title="Single Sign On" />

          <Container className={styles.textFieldContainer}>
            <Container>
              <CheckboxFormField
                id={"enabledSSO"}
                name={"enabledSSO"}
                label={"Enable Single Sign On"}
                onChanges={(event) => {
                  const isChecked = event.target.checked;
                  setValue("enabledSSO", isChecked);
                  setTimeout(() => {
                    const scrollElement =
                      document.getElementById("scrollElement");
                    if (scrollElement) {
                      scrollElement.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }
                  }, 10);
                }}
              />
              {watch("enabledSSO") && (
                <>
                  <InputFormField
                    id={strings.ENTITY_ID}
                    name={"entityId"}
                    label={strings.ENTITY_ID}
                    maxLength={512}
                    required
                  />
                  <InputFormField
                    id={strings.SSO_SERVICE}
                    name={"ssoService"}
                    label={strings.SSO_SERVICE}
                    maxLength={512}
                    required
                  />
                  <InputFormField
                    id={strings.IDP_CERTIFICATE}
                    name={"idpCertificate"}
                    label={strings.IDP_CERTIFICATE}
                    maxLength={4096}
                    required
                  />
                </>
              )}
            </Container>
          </Container>
          <span id="scrollElement"></span>

          <Divider />
          <Container className={styles.actionContainer}>
            {isEdit ? (
              <>
                {companyData?.deleted === false && (
                  <Button
                    id={strings.DELETE_COMPANY + " bottom"}
                    variant="outlined"
                    color="error"
                    label={strings.DELETE_COMPANY}
                    onClick={() => setDeleteModal(true)}
                  />
                )}
                {companyData?.deleted === true &&
                  userCan(Actions.REACTIVATE, Resources.COMPANY) && (
                    <Button
                      id={strings.REACTIVATE_COMPANY + " bottom"}
                      variant="outlined"
                      label={strings.REACTIVATE_COMPANY}
                      onClick={() => setReactivateModal(true)}
                    />
                  )}
                <Button
                  id={strings.UPDATE_COMPANY + " bottom"}
                  loading={btnLoader}
                  label={strings.UPDATE_COMPANY}
                  onClick={handleSubmit((data) => onSubmit(data, strings.EDIT))}
                />
                <Button
                  id={strings.CANCEL + " bottom"}
                  variant="outlined"
                  label={strings.CANCEL}
                  onClick={() => navigate(`/settings/companies/${companyId}`)}
                />
              </>
            ) : (
              <>
                <Button
                  id={strings.CREATE_COMPANY + " bottom"}
                  loading={btnLoader}
                  label={strings.CREATE_COMPANY}
                  onClick={handleSubmit((data) =>
                    onSubmit(data, strings.CREATE)
                  )}
                />
                <Button
                  id={strings.CANCEL + "bottomEnd"}
                  variant="outlined"
                  label={strings.CANCEL}
                  onClick={() => navigate("/settings/companies")}
                />
              </>
            )}
          </Container>
        </FormProvider>
      </Card>
      <SubmitDialog
        type="delete"
        title={strings.CONFIRM_DELETE}
        open={deleteModal}
        body1={`Are you sure you want to delete the company  ${
          getValues().name
        }?`}
        body2={strings.CONFIRM_DELETE_CAPTION}
        handleClose={() => setDeleteModal(false)}
        primaryBtnProps={{
          id: strings.DELETE + "Dialog",
          label: strings.DELETE,
          loading: confirmModalBtnLoader,
          onClick: handleSubmit((data) => onSubmit(data, strings.DELETE)),
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.REACTIVATE}
        open={reactivateModal}
        body1={`Are you sure you want to reactivate the company  ${
          getValues().name
        }?`}
        handleClose={() => setReactivateModal(false)}
        primaryBtnProps={{
          id: strings.REACTIVATE,
          label: strings.REACTIVATE,
          loading: confirmModalBtnLoader,
          onClick: handleSubmit((data) => onSubmit(data, strings.REACTIVATE)),
        }}
      />
    </Container>
  );
};

export default CompanyPage;
