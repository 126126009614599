import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Route from "route-parser";
import {
  getBillingAccountDetails,
  getCompanyAccountDetails,
  getCompanyDetail,
  getPaymentTypeById,
  getUserDetails,
} from "api";
import { useBreadcrumbOptions } from "./breadcrumbOptions";
import { ObjectAnyKeys } from "interfaces/UtilitiesModule";
import { Container, TypoGraph } from "components";
import styles from "./breadcrumb.module.scss";
import colors from "theme/colors";

const paramsName = {
  company: {
    id: "",
    name: "",
  },
  account: {
    id: "",
    name: "",
  },
  user: {
    id: "",
    name: "",
  },
  paymentType: {
    id: "",
    name: "",
  },
  myBilling: {
    id: "",
    name: "",
  },
  billingAccount: {
    id: "",
    name: "",
  },
};

interface IBreadcrumbProps {
  routes: ObjectAnyKeys | null;
}

/** **** Get Path Tokens *****
 *
 * Returns path segments (tokens)
 * @param {String} pathname Full pathname to split
 */
const getPathTokens = (pathname: string) => {
  const paths = ["/"];

  if (pathname === "/") {
    return paths;
  }

  pathname.split("/").reduce((prev: string, current: string) => {
    const currentPath = `${prev}/${current}`;
    paths.push(currentPath);

    return currentPath;
  });

  return paths;
};

/** **** Get Route Match *****
 *
 * Returns matched route
 * @param {Object} routes Object with valid routes
 * @param {String} path Path to match
 */
const getRouteMatch = (routes: ObjectAnyKeys | null, path: string) => {
  return (
    routes &&
    Object.keys(routes)
      .map((key) => {
        const params = new Route(key).match(path);

        return {
          didMatch: params !== false,
          params,
          key,
        };
      })
      .filter((item) => item.didMatch)[0]
  );
};

/** **** Breadcrumbs element *****
 *
 * Renders Breadcrumbs
 * @param {Object} routes Object with valid routes
 * @param {Undefined} match Current match (not used)
 * @param {String} location Current path
 */
const BreadcrumbsModule = (props: IBreadcrumbProps) => {
  const { routes } = props;
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { lastSectionName } = useBreadcrumbOptions();

  /** **** Get Breadcrumbs *****
   *
   * Returns array of breadcrumbs
   * @param {Object} routes Object with valid routes
   * @param {Undefined} match Current match (not used)
   * @param {String} location Current path
   */
  const getBreadcrumbs = (routes: ObjectAnyKeys | null, location: any) => {
    const pathTokens = getPathTokens(location.pathname);

    return Promise.all(
      pathTokens.map(async (path) => {
        const routeMatch: any = getRouteMatch(routes, path);

        if (!routeMatch) {
          return { name: "", path: "/" };
        }
        let name = "";

        const routeValue = routes && routes[routeMatch.key];
        const params = routeMatch.params;
        switch (routeValue) {
          case "getCompanyName":
            if (params.companyId !== paramsName.company.id) {
              const res = await getCompanyDetail(params.companyId, false);
              paramsName.company.name = res.data.name;
              paramsName.company.id = params.companyId;
            }
            name = paramsName.company.name;
            break;
          case "getAccountName":
            if (params.companyAccountId !== paramsName.account.id) {
              const res = await getCompanyAccountDetails(
                params.companyId,
                params.companyAccountId
              );
              paramsName.account.name = res.data.name;
              paramsName.account.id = params.companyAccountId;
            }
            name = paramsName.account.name;
            break;
          case "getUserName":
            if (params.userId !== paramsName.user.id) {
              const res = await getUserDetails(params.userId);
              paramsName.user.name = `${res.data.firstname} ${res.data.lastname}`;
              paramsName.user.id = params.userId;
            }
            name = paramsName.user.name;
            break;
          case "getPaymentTypeName":
            if (params.typeId !== paramsName.paymentType.id) {
              if (isNaN(Number(params.typeId))) {
                return;
              }
              const res = await getPaymentTypeById(params.typeId);
              paramsName.paymentType.name = res.data.name;
              paramsName.paymentType.id = params.typeId;
            }
            name =
              paramsName.paymentType.id === "2"
                ? "Commission Check"
                : paramsName.paymentType.name;
            break;
          case "getBillingAccountName":
            if (params.billingAccountId) {
              const res = await getBillingAccountDetails(
                params.billingAccountId
              );
              paramsName.myBilling.name = res?.data?.name;
            }
            name = paramsName.myBilling.name;
            break;
          case "getMyBillingAccountName":
            if (params.billingAccountId !== paramsName.billingAccount.id) {
              const res = await getBillingAccountDetails(
                params.billingAccountId
              );
              paramsName.billingAccount.name = res.data.name;
              paramsName.billingAccount.id = params.billingAccountId;
            }
            name = paramsName.billingAccount.name;
            break;
          default:
            name =
              typeof routeValue === "function"
                ? routeValue(routeMatch.params)
                : routeValue;
            break;
        }
        return { name, path };
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    getBreadcrumbs(routes, location)
      .then()
      .then((res: any) => {
        const data = res.filter((breadcrumb: any) => breadcrumb.name);
        setBreadcrumbs(data);
        setLoading(false);
      });
  }, [routes, location]);

  if (breadcrumbs?.length <= 1) return <></>;

  return (
    <Container className={styles.breadcrumbs}>
      {loading ? (
        <></>
      ) : (
        breadcrumbs.map((breadcrumb, i) => {
          const isBeforeLast = i + 1 < breadcrumbs.length;

          return (
            <React.Fragment key={`key-${i}`}>
              {isBeforeLast ? (
                <>
                  <TypoGraph
                    onClick={() => navigate(breadcrumb.path)}
                    color={colors.grey}
                    variant="subtitle2"
                    content={breadcrumb.name}
                    link
                  />
                  <TypoGraph color={colors.grey} content=">" />
                </>
              ) : (
                <TypoGraph
                  color={colors.primary}
                  variant="subtitle2"
                  content={lastSectionName || breadcrumb.name}
                ></TypoGraph>
              )}
            </React.Fragment>
          );
        })
      )}
    </Container>
  );
};

export default BreadcrumbsModule;
