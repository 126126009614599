import { Container, CustomDivider, UserInfoForm } from "components";
import {
  InputFormField,
  DropdownFormField,
  DatePickerFormField,
} from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { dateRangeValidator, formatDate } from "tools/validators";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { DropdownOptions } from "interfaces";
import { DATE_FORMAT_YYYY_MM_DD, todayDate } from "constants/datePicker";
import { isDate } from "moment";
import { formatDateToMMDDYYYY } from "tools/format";
import { boolOptions, securityDepositRoles } from "constants/dropdownOptions";
import { PropertyForm } from "components";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";

interface SecurityDepositComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const SecurityDepositComponent: React.FC<SecurityDepositComponentProps> = (
  props
) => {
  const { getValues, setValue } = useFormContext();
  const { statesOptions, customLabels } = props;
  const [closeDate, setCloseDate] = useState<Date>(new Date());
  const [bindDate, setBindDate] = useState<Date>(new Date());

  useEffect(() => {
    if (!getValues("closedate")) {
      setValue("closedate", formatDateToMMDDYYYY(new Date()));
    }
    if (!getValues("binddate")) {
      setValue("binddate", formatDateToMMDDYYYY(new Date()));
    }
  }, []);

  return (
    <Container className={styles.transactionDetailContainer}>
      <CustomDivider title="Your Info" />
      <UserInfoForm />
      <CustomDivider title="Transaction Info" />
      <Container className={styles.transactionInfoContainer4Columns}>
        <DropdownFormField
          name={"trrole"}
          label={"I am the"}
          options={securityDepositRoles}
          required
        ></DropdownFormField>
        <DatePickerFormField
          name={"closedate"}
          onChange={(e: React.ChangeEvent<any> | null) => {
            isDate(e) && setCloseDate(e);
            isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
          }}
          minDate={todayDate}
          inputFormat={DATE_FORMAT_YYYY_MM_DD}
          value={
            getValues("closedate")
              ? new Date(formatDate(getValues("closedate")))
              : closeDate
          }
          label={"Lease Start Date"}
          validate={() =>
            dateRangeValidator(getValues("closedate"), getValues("binddate"))
          }
        />
        <DatePickerFormField
          name={"binddate"}
          onChange={(e: React.ChangeEvent<any> | null) => {
            isDate(e) && setBindDate(e);
            isDate(e) && setValue("binddate", formatDateToMMDDYYYY(e));
          }}
          minDate={todayDate}
          inputFormat={DATE_FORMAT_YYYY_MM_DD}
          value={
            getValues("binddate")
              ? new Date(formatDate(getValues("binddate")))
              : bindDate
          }
          label={"Lease End Date"}
        />
        <DropdownFormField
          name={"petdep"}
          label={"Pet deposit included?"}
          options={boolOptions}
          required
        ></DropdownFormField>
        <InputFormField name="laname" label="Listing agent name" required />
        <InputFormField name="saname" label="Leasing name" />
        <InputFormField name="name1" label="Tenant 1" required />
        <InputFormField name="name2" label="Tenant 2" />
        <InputFormField name="name3" label="Landlord/owner 1" />
        <InputFormField name="name4" label="Landlord/owner 2" />
      </Container>
      <CustomDivider title="Property Details" />
      <PropertyForm
        statesOptions={statesOptions}
        customLabels={customLabels?.address}
      />
    </Container>
  );
};

export default SecurityDepositComponent;
