import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronLeftRight from "@mui/icons-material/ChevronRight";
import { logout } from "api";
import menuBarList, { ISecurityRequirements } from "constants/menuBar";
import { DrawerHeader, MuiDrawer } from "theme/themeOverride";
import { Divider, Icon, Popover, TypoGraph } from "components";
import colors from "theme/colors";
import decodeToken from "tools/token";
import { userCan } from "tools/privilegeChecker";
import styles from "./menuBar.module.scss";

interface ImenuBarProps {
  path: string;
  navigate: Function;
  userPriv: number;
}

interface ImenuBarList {
  name: string;
  icon: string;
  id: string;
  securityRequirements: ISecurityRequirements;
  show?: boolean;
}

interface ImenuListItem {
  data: ImenuBarList[];
  open: boolean;
  selectedItem: string;
  handleMenuOnclick: Function;
  className?: string;
  userDetails?: any;
}

export const MenuListItem = (props: ImenuListItem) => {
  const {
    data,
    open,
    selectedItem,
    handleMenuOnclick,
    className,
    userDetails,
  } = props;
  return (
    <List className={className ? className : styles.menuList}>
      {data.map((item: ImenuBarList, index) =>
        item.show ? (
          <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              id={item.name}
              sx={{
                height: 50,
                justifyContent: open ? "initial" : "center",
                borderRadius: 2,
                marginTop: 1,
                marginBottom: 1,
                paddingLeft: 1.4,
                paddingRight: 1.4,
              }}
              selected={selectedItem === item.id}
              onClick={(e) => {
                handleMenuOnclick(e, item.id);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name={item.icon}
                  size={34}
                  boxClassName={selectedItem === item.id ? "active" : ""}
                />
              </ListItemIcon>
              <TypoGraph
                variant="body1"
                mb={0}
                color={selectedItem === item.id ? colors.primary : ""}
                sx={{ fontSize: "15px" }}
              >
                {item.name === "Profile"
                  ? `${userDetails?.fullName}`
                  : item.name}
              </TypoGraph>
            </ListItemButton>
          </ListItem>
        ) : null
      )}
    </List>
  );
};

const MenuBar: React.FunctionComponent<ImenuBarProps> = (props) => {
  const { path, navigate } = props;
  const [selectedItem, setSelectedItem] = useState("companies");
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(true);
  const userDetails = decodeToken();
  let { list1, list2 } = menuBarList;

  list1 = list1.map((item: ImenuBarList) => {
    item.show = userCan(
      item.securityRequirements.action,
      item.securityRequirements.resource
    );
    return item;
  });

  list2 = list2.map((item: ImenuBarList) => {
    item.show = userCan(
      item.securityRequirements.action,
      item.securityRequirements.resource
    );
    return item;
  });

  /*
   * This useffect will trigger whenever the current url path changes
   * It will check the current path and menubar list id matches once it matched,
   * It will set the active menuBarItem to hightlight.
   */
  useEffect(() => {
    const findItemInList1 = menuBarList.list1.find((ele: ImenuBarList) => {
      return path.includes(ele.id) ? ele : null;
    });
    const findItemInList2 = menuBarList.list2.find((ele: ImenuBarList) => {
      return path.includes(ele.id) ? ele : null;
    });
    const findItemId = findItemInList1 || findItemInList2;
    if (findItemId) {
      setSelectedItem(findItemId.id);
    }
  }, [path]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleMenuOnclick = (e: any, path: String) => {
    if (path === "profile") {
      setAnchorEl(e.currentTarget);
    } else {
      navigate(path);
    }
  };

  const openCloseIconEvent = {
    onMouseOver: () => setShowCloseIcon(true),
    onMouseLeave: () => {
      setTimeout(() => {
        if (!showCloseIcon) {
          setShowCloseIcon(false);
        }
      }, 800);
    },
    onMouseOut: () => setShowCloseIcon(false),
  };

  const onLogoutPressed = () => {
    logout().then(() => {
      localStorage.removeItem("token");
      navigate("/login");
      setAnchorEl(null);
    });
  };

  return (
    <div {...openCloseIconEvent}>
      {showCloseIcon && (
        <Icon
          name="icon_open"
          boxClassName={`${styles.leftIconButton} ${
            !open && styles.rightIconButton
          }`}
          onClick={handleDrawerOpen}
        >
          {open ? <ChevronLeftIcon /> : <ChevronLeftRight />}
        </Icon>
      )}
      <MuiDrawer variant="permanent" open={open}>
        <DrawerHeader>
          <Icon
            name={open ? "icon_logoFullname" : "icon_logo"}
            width={200}
            height={45}
          />
        </DrawerHeader>
        <MenuListItem
          open
          data={list1}
          selectedItem={selectedItem}
          handleMenuOnclick={handleMenuOnclick}
        />
        <MenuListItem
          open
          data={list2}
          selectedItem={selectedItem}
          handleMenuOnclick={handleMenuOnclick}
          className={styles.stickyMenuList}
          userDetails={userDetails}
        />
      </MuiDrawer>
      <Popover anchorEl={anchorEl} handleClose={() => setAnchorEl(null)}>
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              id={"profile"}
              sx={{ padding: "1rem" }}
              onClick={() => {
                setAnchorEl(null);
                navigate("/profile");
              }}
            >
              <ListItemIcon>
                <Icon name="icon_profile" size={27} />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ margin: 0 }} />
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              id={"logout"}
              sx={{ padding: "1rem" }}
              onClick={onLogoutPressed}
            >
              <ListItemIcon>
                <Icon name="icon_logout" size={27} />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default React.memo(MenuBar);
