import React, { FormEvent, useEffect, useState } from "react";
import { Button, Container, StepperComponent, TypoGraph } from "components";
import { TextField } from "components/controllers";
import { fontStyle } from "pages/receivables/util";
import styles from "./companyCode.module.scss";
import strings from "strings";
import { useMutation } from "@tanstack/react-query";
import {
  getCompanyPublicDetailsByCompanyCode,
  getPaymentDetailsByPaymentRequestId,
} from "api";
import {
  DropdownOptions,
  ICompanyAccountRequest,
  ICompanyWebBasedReceivablesDetails,
  IPayment,
  IPaymentMethod,
  IPaymentType,
} from "interfaces";
import { useNavigate, useParams } from "react-router";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";
import { useForm } from "react-hook-form";
import currencyFormatter from "tools/currencyFormatter";
import Decimal from "decimal.js";
import { IPaymentTypeWithPaymentMethods } from "interfaces/ICompanyModel";

const st = strings.WEB_RECEIVABLES;

const steps = [
  "Payment Type",
  "Transaction Details",
  "Authorize",
  "Send Payment",
];

interface ICompanyCodeComponent {
  handlePaymentTypes: (params: {
    companyId: number;
    companyCode: string;
    paymentType: string;
    companyName: string;
    availablePaymentMethods: IPaymentMethod[];
    paymentTypeId: number;
    paymentRequested?: IPayment;
    achFee?: string;
  }) => void;
  handleValidCompanyCode: () => void;
  windowWidth: number;
  isSnippet?: boolean;
  whitelabelConfiguration?: IWhitelabelConfiguration;
  handleCompanyData: (params: {
    id: number;
    name: string;
    paymentTypes: IPaymentTypeWithPaymentMethods[];
  }) => void;
}

const CompanyCodeComponent: React.FC<ICompanyCodeComponent> = (props) => {
  const {
    handlePaymentTypes,
    handleValidCompanyCode,
    windowWidth,
    isSnippet,
    whitelabelConfiguration,
    handleCompanyData,
  } = props;
  const [paymentTypes, setPaymentTypes] = useState<DropdownOptions[]>();
  const methods = useForm<ICompanyAccountRequest>({
    reValidateMode: "onChange",
  });
  const [companyCode, setCompanyCode] = useState("");
  const [showPaymentTypes, setShowPaymentTypes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const { companyCodeId, paymentRequestId } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] =
    useState<ICompanyWebBasedReceivablesDetails>({
      id: 0,
      name: "",
      paymentTypes: [],
    });

  //Handler for company code in URL. If company code is in URL, we will fetch the company details.
  useEffect(() => {
    (async () => {
      if (!companyCodeId) {
        setShowPaymentTypes(false);
        return;
      }
      setLoading(true);
      getCompanyPublicDetails.mutate(companyCodeId);
    })();
  }, [companyCodeId]);

  useEffect(() => {
    // Get the payment details by payment request ID to skip the payment type selection step, and navigate to the transaction form step,
    // it runs any time the company ID or payment ID changes
    (async () => {
      if (paymentRequestId && companyData.paymentTypes.length > 0) {
        setLoading(true);
        getPaymentDetailsByPaymentRequestId(+paymentRequestId)
          .then((response) => {
            const paymentType = companyData.paymentTypes.find(
              (type) => type.id === response.data.typeId
            );

            if (!paymentType) {
              return;
            }
            handlePaymentTypes({
              paymentRequested: response?.data,
              companyId: companyData.id,
              companyCode,
              paymentType: paymentType.name,
              paymentTypeId: paymentType.id,
              companyName: companyData.name,
              availablePaymentMethods: response.data.allowedPaymentMethods
                ? response.data.allowedPaymentMethods
                    .filter((paymentMethod) =>
                      paymentType.paymentMethods.some(
                        (method) => method.id === paymentMethod.id
                      )
                    )
                    .map((paymentMethod) => ({
                      id: paymentMethod.id,
                      name: paymentMethod.name,
                      active: true,
                    }))
                : [],
              achFee: paymentType.achFeeInCents
                ? currencyFormatter(
                    new Decimal(paymentType.achFeeInCents)
                      .dividedBy(100)
                      .toNumber() + ""
                  )
                : "",
            });
            handleCompanyData({
              id: companyData.id,
              name: companyData.name,
              paymentTypes: companyData.paymentTypes.filter(
                (paymentType) =>
                  paymentType.paymentMethods &&
                  paymentType.paymentMethods.length > 0
              ),
            });
            setShowPaymentTypes(false);
          })
          .catch((error) => {
            navigate(
              isSnippet
                ? `/snippet/receivables/${companyCode}`
                : `/receivables/${companyCode}`
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (companyData.paymentTypes.length > 0) {
        handlePaymentTypeChange();
      }
    })();
  }, [companyData, paymentRequestId]);

  const onSearchPressed = (e: FormEvent) => {
    e.preventDefault();
    navigate(
      isSnippet
        ? `/snippet/receivables/${companyCode}`
        : `/receivables/${companyCode}`
    );
  };

  const getCompanyPublicDetails = useMutation(
    (id: string | undefined) => {
      setLoading(true);
      return getCompanyPublicDetailsByCompanyCode(id);
    },
    {
      onError: (error) => {
        setLoading(false);
        setShowPaymentTypes(false);
        //we can maybe put a timer here if error code 429 (too many requests)?
      },
      onMutate: (variables) => {
        if (variables) {
          setCompanyCode(variables);
        }
      },
      onSuccess: (response) => {
        setCompanyData(response);
        setShowPaymentTypes(true);
        setLoading(false);
        handleValidCompanyCode();
      },
    }
  );

  const handlePaymentTypeChange = () => {
    handlePaymentTypes({
      companyId: companyData.id,
      companyCode,
      paymentType: "",
      paymentTypeId: -1,
      companyName: companyData.name,
      availablePaymentMethods: [],
    });
    handleCompanyData({
      id: companyData.id,
      name: companyData.name,
      paymentTypes: companyData.paymentTypes.filter(
        (paymentType) =>
          paymentType.paymentMethods && paymentType.paymentMethods.length > 0
      ),
    });
  };

  return (
    <Container className={styles.mainContainer}>
      <Container className={styles.stepperContainer}>
        <StepperComponent
          steps={steps}
          color={whitelabelConfiguration?.backgroundColor}
          activeStep={0}
          showNextBtn={false}
          stepperClassName={styles.responsiveStepper}
          disableNextBtn={!companyCode || activeStep === 1}
          btnLabels={{
            backBtn: "Previous Step",
            nextBtn: "Continue to next step",
            submitBtn: "Great!",
          }}
          alternativeLabel
        >
          <Container className={styles.mainContainer}>
            <form onSubmit={(e) => onSearchPressed(e)}>
              <Container className={styles.sectionMargin}>
                <TypoGraph
                  variant="h3"
                  sx={{ ...fontStyle.h3, margin: "auto" }}
                  content={st.ENTER_COMPANY_CODE}
                  className={styles.enterCodeText}
                />
                <TypoGraph
                  variant="body1"
                  sx={{ margin: "auto" }}
                  content={st.ENTER_COMPANY_CODE_PROVIDED}
                  className={styles.enterCodeTextSubtittle}
                />
                <Container className={styles.companyCodeSection}>
                  <TextField
                    label={st.COMPANY_CODE}
                    value={companyCode}
                    type={"numericOnly"}
                    onChange={(e) => setCompanyCode(e.target.value)}
                  />
                  <Container className={styles.stepBtnContainer}>
                    <Button
                      customBackgroundColor={
                        whitelabelConfiguration?.backgroundColor
                      }
                      label={st.CONTINUE_STEP}
                      disabled={!companyCode}
                      loading={loading}
                      type="submit"
                    />
                  </Container>
                </Container>
              </Container>
            </form>
          </Container>
        </StepperComponent>
      </Container>
    </Container>
  );
};

export default CompanyCodeComponent;
