import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import MuiTextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import { styled } from "@mui/material/styles";

const MuiTextFieldThemed = styled(MuiTextField)({
  "&.MuiTextField-root": {
    width: "100%",
  },
});

export interface ITextFieldProps {
  id?: string;
  label: string;
  className?: string;
  name?: string;
  type?: "password" | "number" | "text" | "numericOnly";
  variant?: "outlined" | "filled" | "standard";
  defaultValue?: string | number;
  value?: string | number;
  InputLabelProps?: object;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  inputRef?: any;
  select?: boolean;
  currency?: boolean;
  children?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent) => void;
  onFocus?: (e: React.ChangeEvent) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

export interface ICurrencyProps extends ITextFieldProps {}

export interface INumberProps extends ITextFieldProps {}

const CurrencyFormatField: React.FC<ICurrencyProps | any> = (props) => {
  const { inputRef, onChange, value, type, maxLength, ...other } = props;

  return (
    <NumberFormat
      value={value}
      getInputRef={inputRef}
      thousandsGroupStyle="thousand"
      onValueChange={(values) => {
        onChange(values);
      }}
      decimalSeparator="."
      displayType="input"
      type="text"
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      inputMode="numeric"
      prefix={"$"}
      fixedDecimalScale
      isNumericString
      maxLength={maxLength}
      {...other}
    />
  );
};

const NumberFormatCustom: React.FC<INumberProps | any> = (props) => {
  const { inputRef, onChange, value, type, maxLength, ...other } = props;
  return (
    <NumberFormat
      value={value}
      getInputRef={inputRef}
      thousandsGroupStyle="thousand"
      onValueChange={(values) => {
        onChange(values);
      }}
      displayType="input"
      type="text"
      allowNegative={false}
      decimalScale={0}
      inputMode="numeric"
      maxLength={maxLength}
      {...other}
    />
  );
};

const TextField: React.FunctionComponent<ITextFieldProps> = (props) => {
  const {
    variant = "outlined",
    value,
    type = "text",
    name,
    id,
    defaultValue,
    onChange = () => {},
    InputLabelProps = {},
    readOnly = false,
    required = false,
    disabled = false,
    error = false,
    multiline = false,
    helperText,
    rows = 4,
    inputRef,
    select = false,
    children,
    currency = false,
    startAdornment = "",
    endAdornment = "",
    placeholder,
    className,
    autoFocus = false,
    maxLength,
    onBlur,
    onFocus,
    onPaste,
  } = props;

  let inputProps = {};
  let inputLabelProps = {};

  if (currency) {
    inputProps = {
      inputComponent: CurrencyFormatField,
    };
    inputLabelProps = {
      shrink: value,
    };
  }
  if (type === "number") {
    inputProps = {
      inputComponent: NumberFormatCustom,
    };
    inputLabelProps = {
      shrink: value,
    };
  }
  if (type === "numericOnly") {
    inputProps = {
      inputMode: "numeric",
    };
    inputLabelProps = {};
  }

  return (
    <MuiTextFieldThemed
      name={name}
      id={id}
      type={type}
      select={select}
      inputRef={inputRef}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      variant={variant}
      required={required}
      helperText={helperText}
      disabled={disabled}
      error={error}
      multiline={multiline}
      rows={rows}
      InputLabelProps={{ ...InputLabelProps, ...inputLabelProps }}
      margin="normal"
      inputProps={{ maxLength: maxLength, onPaste: onPaste, ...inputProps }}
      InputProps={{
        readOnly: readOnly,
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        ...inputProps,
      }}
      autoFocus={autoFocus}
      {...props}
    >
      {children}
    </MuiTextFieldThemed>
  );
};

export default TextField;
