import React, { useState } from "react";
import {
  Container,
  Dialog,
  Loader,
  PlaidContainer,
  Toast as toast,
  TypoGraph,
} from "components";
import { useLocation, useSearchParams } from "react-router-dom";
import { PlaidLinkOnSuccessMetadata, PlaidLinkOptions } from "react-plaid-link";
import { useNavigate } from "react-router";
import localStorageKeys from "constants/localStorageKeys";
import { createDisbursementAccount } from "api";
import strings from "strings";
import {
  ICreateDisbursementAccountRequest,
  IDisbursementAccountResponse,
} from "interfaces/IDisbursementAccountModel";
import { TextField } from "components/controllers";
import SignatureDialog from "./SignatureDialog/signatureDialog";
import BaseDialog from "components/dialog/baseDialog";

const OAUTH_STATE_ID_QUERY_PARAM = "oauth_state_id";
const OauthDisbursementAccount = () => {
  const { state } = useLocation();

  const companyId = state as number;

  const [publicToken] = useState<string>("");
  const [tokenPublic, setTokenPublic] = useState<string>("");
  const [showPopUpInstitutionName, setShowPopUpInstitutionName] =
    useState<boolean>(false);
  const [plaidMetaData, setPlaidMetaData] =
    useState<PlaidLinkOnSuccessMetadata>();
  const [institutionName, setInstitutionName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [createdDisbAccountDat, setCreatedDisbAccountData] =
    useState<IDisbursementAccountResponse>();
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [showSignatureBlade, setShowSignatureBlade] = useState(false);

  const config: undefined | PlaidLinkOptions = !publicToken
    ? {
        onSuccess: (public_token, metadata) => {
          if (metadata.institution?.name) {
            const payload: ICreateDisbursementAccountRequest = {
              publicToken: public_token,
              institution: {
                name: metadata.institution?.name,
                id: metadata.institution?.institution_id,
              },
              accounts: metadata.accounts,
            };
            setCreatingAccount(true);
            createDisbursementAccount(companyId, payload).then((response) => {
              toast({
                title: strings.CREATED_SUCCESSFULLY,
                subTitle:
                  strings.DISBURSEMENT_ACCOUNT_STRING
                    .DISBURSEMENT_ACCOUNT_WITH_ID +
                  response.data
                    .map((data) => {
                      return data.id.toString();
                    })
                    .toString(),
              });
              setCreatedDisbAccountData(response.data[0]);
              setCreatingAccount(false);
              setShowSignatureBlade(true);
            });
          } else {
            setShowPopUpInstitutionName(true);
            setTokenPublic(public_token);
            setPlaidMetaData(metadata);
          }
        },
        onExit: (err, metadata) => {
          navigate(`/settings/disbursementAccounts`, {
            state: {
              err,
              metadata,
            },
          });
        },
        token: localStorage.getItem(localStorageKeys.plaidLinkToken),
      }
    : undefined;
  const oauthStateId = searchParams.get(OAUTH_STATE_ID_QUERY_PARAM);
  if (oauthStateId && config) {
    config.receivedRedirectUri = window.location.href;
  }

  return (
    <Container>
      <Container>
        {config ? <PlaidContainer options={config} /> : <></>}
        <Dialog
          open={showPopUpInstitutionName}
          handleClose={() => {}}
          title={""}
          content={
            <Container>
              <TypoGraph
                mt={2}
                mb={2}
                content={strings.EXTRA_STEP_EXPLANATION}
                variant="body1"
              />
              <Container>
                <TextField
                  id={"customInstitutionNameTextField"}
                  label={"Institution Name"}
                  type="text"
                  value={institutionName}
                  disabled={loading}
                  onChange={(e) => setInstitutionName(e.target.value)}
                ></TextField>
              </Container>
            </Container>
          }
          showCancel={false}
          primaryBtnProps={{
            id: "addCustomInstitutionName",
            label: "Continue",
            loading: loading,
            disabled: loading,
            onClick: () => {
              setLoading(true);
              const payload: ICreateDisbursementAccountRequest = {
                publicToken: tokenPublic,
                institution: {
                  name: institutionName,
                  id: plaidMetaData?.institution?.institution_id,
                },
                accounts: !!plaidMetaData ? plaidMetaData.accounts : [],
              };
              createDisbursementAccount(companyId, payload).then((response) => {
                setLoading(false);
                toast({
                  title: strings.CREATED_SUCCESSFULLY,
                  subTitle:
                    strings.DISBURSEMENT_ACCOUNT_STRING
                      .DISBURSEMENT_ACCOUNT_WITH_ID +
                    response.data
                      .map((data) => {
                        return data.id.toString();
                      })
                      .toString(),
                });
                navigate(`/settings/disbursementAccounts`);
              });
            },
          }}
        ></Dialog>

        <BaseDialog
          open={creatingAccount}
          handleClose={() => {}}
          title={"Creating Disbursement Account"}
          showCancel={false}
          content={
            <Container sx={{ width: "300px !important" }}>
              <Loader loading={true} type={"default"} height="35vh"></Loader>
            </Container>
          }
        ></BaseDialog>

        {showSignatureBlade && (
          <SignatureDialog
            disbursementAccount={createdDisbAccountDat}
          ></SignatureDialog>
        )}
      </Container>
    </Container>
  );
};

export default OauthDisbursementAccount;
