import { amountPattern } from "constants/regex";
import strings from "strings";

export function statusIsDifferentFromCurrent(
  textFieldValue: string,
  currentStatus: string
) {
  if (textFieldValue === currentStatus) {
    return strings.NEW_STATUS_MUST_BE_IN_A_DIFFERENT_STATUS;
  }
}

export function amountIsValid(textFieldValue: string) {
  if (+textFieldValue === 0) {
    return strings.NEW_AMOUNT_MUST_BE_GREATER_THAN_0;
  }
  if (!textFieldValue.match(amountPattern)) {
    return strings.ENTERED_AMOUNT_IS_NOT_IN_DOLLARS;
  }
}

export function passwordStrengthValidator(password: string) {
  let regularExpression = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.-_])(?=.{8,})"
  );
  if (!regularExpression.test(password)) {
    return strings.PASSWORD_ERROR_FORMAT;
  }
}

export function isNullOrEmpty(value: string | null | undefined): boolean {
  return (
    value === null || value === undefined || value === "" || value.trim() === ""
  );
}

export function isSameDay(date1: Date, date2: Date) {
  return date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10);
}

export const formatDate = (date: string) => {
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = date.slice(4, 8);
  return `${month}/${day}/${year}`;
};

export const dateRangeValidator = (startDate: string, endDate: string) => {
  const start = formatDate(startDate);
  const end = formatDate(endDate);
  if (Date.parse(end) <= Date.parse(start)) {
    return "Lease start Date should be smaller than lease end date!";
  }
};
