import { Container, Icon } from "components/index";
import { InputFormField } from "components/hookForm";
import strings from "strings";
import styles from "./userInfoForm.module.scss";
import { Box, Tooltip } from "@mui/material";
import regex from "regex";
import { availableEarnestMoneyRoles } from "constants/dropdownOptions";

interface UserInfoFormComponentProps {
  selectedRole?: string;
}

const UserInfoForm: React.FC<UserInfoFormComponentProps> = (props) => {
  const { selectedRole } = props;
  return (
    <Container className={styles.userInfoContainer}>
      <Container className={styles.enterNameContainer}>
        <InputFormField
          name="agentFirstName"
          label={
            selectedRole == undefined
              ? "First Name"
              : selectedRole === availableEarnestMoneyRoles[0].value
              ? "Buyer's First Name"
              : "Agent's First Name"
          }
          required
        />
        <InputFormField
          name="agentLastName"
          label={
            selectedRole == undefined
              ? "Last Name"
              : selectedRole === availableEarnestMoneyRoles[0].value
              ? "Buyer's Last Name"
              : "Agent's Last Name"
          }
          required
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputFormField
            name="agentEmail"
            label={
              selectedRole == undefined
                ? "Email"
                : selectedRole === availableEarnestMoneyRoles[0].value
                ? "Buyer's Email"
                : "Agent's Email"
            }
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: strings.PLEASE_ENTER_A_VALID_EMAIL,
            }}
            required
          />
          <Tooltip title={strings.ENTER_YOUR_EMAIL_INFO} arrow>
            <Box ml={2}>
              <Icon name="icon_help" size={30}></Icon>
            </Box>
          </Tooltip>
        </div>
      </Container>
    </Container>
  );
};

export default UserInfoForm;
