import { Container, TypoGraph } from "../index";

import { SwitchFormField } from "../hookForm";
import React from "react";

interface ISwitchCustomCompProps {
  title: string;
  caption: string;
  name: string;
  style: string;
}

const SwitchCustomComp = (props: ISwitchCustomCompProps) => {
  const { title, caption, name, style } = props;
  return (
    <Container className={style}>
      <Container>
        <TypoGraph variant="body1" content={title} />
        <TypoGraph variant="caption" content={caption} />
      </Container>
      <SwitchFormField name={name} />
    </Container>
  );
};

export default SwitchCustomComp;
