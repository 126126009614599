import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ReactNode } from "react";

interface ICardProps {
  children?: ReactNode;
  raised?: boolean;
  classes?: object;
  sxContainer?: object;
  sxContent?: object;
  bordered?: boolean;
  className?: string;
}

const Card = (props: ICardProps) => {
  const {
    children,
    raised = false,
    classes,
    sxContainer,
    className,
    sxContent,
    bordered = true,
  } = props;
  return (
    <MuiCard
      sx={{
        boxShadow: "0px 4px 16px -8px rgba(0, 0, 0, 0.08)",
        border: bordered ? "1px solid #E0E0E0" : "",
        borderRadius: "8px",
        ...sxContainer,
      }}
      classes={classes}
      raised={raised}
      className={className}
    >
      <CardContent sx={sxContent}>{children}</CardContent>
    </MuiCard>
  );
};

export default Card;
