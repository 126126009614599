import React from "react";
import { Container, DataGrid, Divider } from "components";
import { paymentRequestDetailsColumn } from "constants/tableData";
import { IPayment } from "interfaces";
import { maskPropertyAddress } from "tools/format";

interface IPaymentDetailProps {
  payment?: IPayment;
}

const RequestDetailComponent: React.FC<IPaymentDetailProps> = (props) => {
  const { payment } = props;

  return (
    <Container sx={{ paddingTop: "10px" }}>
      <Divider title="REQUEST DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.requestDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="COMPANY" />
      <DataGrid
        columns={paymentRequestDetailsColumn.companyDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="PAYER DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.payerDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="PROPERTY" />
      <DataGrid
        columns={paymentRequestDetailsColumn.propertyDetails[0]}
        data={maskPropertyAddress(payment) || {}}
        hideIfEmpty={false}
      />
      <Divider title="TRANSACTION DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.transactionDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
    </Container>
  );
};

export default RequestDetailComponent;
