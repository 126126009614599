import { Container, Button } from "components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import styles from "./stepper.module.scss";
import colors from "theme/colors";

type IStepperProps = {
  loading?: boolean;
  steps: string[];
  handleNext?: () => void;
  handleBack?: () => void;
  activeStep: number;
  children: React.ReactNode;
  isEdit?: string;
  alternativeLabel?: boolean;
  showPrevBtn?: boolean;
  showNextBtn?: boolean;
  stepperClassName?: string;
  btnLabels: {
    backBtn: string;
    submitBtn: string;
    nextBtn: string;
  };
  color?: string;
  disableNextBtn?: boolean;
  btnsAling?: string;
};

const StepperComponent: React.FC<IStepperProps> = (props) => {
  const {
    loading,
    steps,
    handleNext,
    handleBack,
    activeStep,
    children,
    alternativeLabel,
    btnLabels,
    showPrevBtn = true,
    showNextBtn = true,
    stepperClassName = "",
    color,
    disableNextBtn,
    btnsAling = "center",
  } = props;

  const handleNextBtn = () => {
    if (activeStep !== steps.length) {
      handleNext && handleNext();
    } else {
      handleNext && handleNext();
    }
  };

  const handleBackBtn = () => {
    handleBack && handleBack();
  };

  const customIcons = (isStepCompleted: boolean, isStepActive: boolean) => {
    const iconFontSize = "28px";
    const bgColor = color ? color : colors.primary;

    if (isStepCompleted) {
      return (
        <CheckCircleIcon
          sx={{
            fontSize: iconFontSize,
            color: bgColor,
            zIndex: 1,
          }}
        />
      );
    } else if (isStepActive) {
      return (
        <RadioButtonCheckedIcon
          sx={{
            fontSize: iconFontSize,
            color: bgColor,
            zIndex: 1,
          }}
        />
      );
    } else {
      return (
        <RadioButtonUncheckedIcon
          sx={{
            fontSize: iconFontSize,
            color: colors.lightGrey,
            zIndex: 1,
          }}
        />
      );
    }
  };

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: color ? color : colors.primary,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: color ? color : colors.primary,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderTopWidth: 3,
      borderRadius: 1,
      marginRight: -9,
      marginLeft: -9,
      borderColor: colors.lightGrey,
    },
  }));

  return (
    <Container className={styles.stepperContainer}>
      <Container>
        <Stepper
          activeStep={activeStep}
          className={`${styles.containerPadding} ${stepperClassName}`}
          alternativeLabel={alternativeLabel}
          connector={<CustomStepConnector />}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};

            const isStepCompleted = activeStep > index;
            const isStepActive = activeStep === index;
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  icon={customIcons(isStepCompleted, isStepActive)}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Container
          className={`${styles.containerPadding} ${styles.fixedWidth}`}
        >
          {children}
        </Container>
        <Container
          className={`${styles.stepBtnContainer} ${styles.fixedWidth}`}
          sx={{ "justify-content": btnsAling }}
        >
          <Container>
            {activeStep > 0 && showPrevBtn && (
              <Button
                customColor={color}
                label={btnLabels.backBtn}
                variant="outlined"
                onClick={handleBackBtn}
                fullWidth={false}
                disabled={loading}
              />
            )}
          </Container>
          {showNextBtn && (
            <Button
              customBackgroundColor={color}
              type="submit"
              label={
                activeStep >= steps.length - 1
                  ? btnLabels.submitBtn
                  : btnLabels.nextBtn
              }
              onClick={handleNextBtn}
              loading={loading}
              disabled={disableNextBtn}
            />
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default StepperComponent;
