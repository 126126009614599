import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Loader,
  TableComponent,
} from "components";
import { getAllCompanyAccountsForCompany, getCompanyAccountDetails } from "api";
import { ICompanyAccountResponse, IPageResponse } from "interfaces";
import {
  allCompanyAccountsTableColumns,
  companyAccountsDetailsLabel,
} from "constants/tableData";
import strings from "strings";
import styles from "./companies.module.scss";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";

const CompanyAccounts: React.FunctionComponent = () => {
  const [companyAccounts, setCompanyAccounts] =
    useState<IPageResponse<ICompanyAccountResponse>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showBlade, setShowBlade] = useState<boolean>(false);
  const [companyAccountDetail, setCompanyAccountDetail] =
    useState<ICompanyAccountResponse>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { companyId, companyAccountId } = useParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  useEffect(() => {
    if (companyAccountId && companyId) {
      setShowBlade(true);
      setBladeLoader(true);
      getCompanyAccountDetails(parseInt(companyId), parseInt(companyAccountId))
        .then((res) => {
          setCompanyAccountDetail(res?.data);
        })
        .finally(() => {
          setBladeLoader(false);
        });
    } else {
      setShowBlade(false);
    }
  }, [companyAccountId, companyId]);

  allCompanyAccountsTableColumns[0].handleClick = (id, rowData) => {
    navigate(`/settings/companies/${companyId}/accounts/${id}`);
  };

  const loadCompanyAccounts = useCallback(
    (
      companyId: string | undefined,
      pageNumber: number = 0,
      pageSize: number = 10,
      filter = ""
    ) => {
      setLoading(true);
      getAllCompanyAccountsForCompany(
        companyId,
        pageNumber,
        pageSize,
        filter,
        sortModel[0]?.field,
        sortModel[0]?.sort
      )
        .then((companyAccounts) => setCompanyAccounts(companyAccounts.data))
        .finally(() => setLoading(false));
    },
    [sortModel]
  );

  useEffect(() => {
    loadCompanyAccounts(companyId);
  }, [loadCompanyAccounts, companyId]);

  const BladeContent = () => {
    const paymentMethodsArr = companyAccountDetail?.paymentMethods?.map(
      (item) => {
        return item?.name;
      }
    );
    const paymentMethodStr = paymentMethodsArr?.length
      ? paymentMethodsArr?.join(", ")
      : "-";
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid
          columns={companyAccountsDetailsLabel?.details}
          data={companyAccountDetail}
        />
        <Divider title="PAYMENT METHODS" />
        <DataGrid
          columns={companyAccountsDetailsLabel?.paymentMethods}
          data={{ paymentMethodsData: paymentMethodStr }}
        />
        <Divider title="DEPOSIT LIMIT" />
        <DataGrid
          columns={companyAccountsDetailsLabel?.depositLimit}
          data={companyAccountDetail}
        />
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={strings.MAINTENANCE_REPORT}
          variant="outlined"
          label={strings.MAINTENANCE_REPORT}
          onClick={() => {
            navigate(`maintenance`);
          }}
        />
        <Button
          id={strings.UPDATE}
          label={strings.UPDATE}
          onClick={() => {
            navigate(`edit`);
          }}
        />
      </Container>
    );
  };

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadCompanyAccounts(companyId, 0, 10, value);
  };

  return (
    <Container>
      <HeaderBar
        title={`${strings.DELUXE_ACCOUNTS}`}
        primaryBtnProps={{
          id: "newCompanyAccount",
          label: "Create a new Deluxe account",
          onClick: () =>
            navigate(`/settings/companies/${companyId}/accounts/new`),
        }}
      />
      <TableComponent
        title={strings.DELUXE_ACCOUNTS}
        data={companyAccounts?.items || []}
        totalCount={companyAccounts?.totalCount}
        columns={allCompanyAccountsTableColumns}
        loading={loading}
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(pageNumber, pageSize) =>
          loadCompanyAccounts(companyId, pageNumber, pageSize, searchValue)
        }
        handleSortModelChange={handleSortChange}
      />
      <Blade
        show={showBlade}
        title={
          !bladeLoader ? `${companyAccountDetail?.name} ${strings.DETAILS}` : ""
        }
        handleClose={() =>
          navigate(`/settings/companies/${companyId}/accounts`)
        }
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
    </Container>
  );
};

export default CompanyAccounts;
