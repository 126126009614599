const transmittingData = [
  {
    name: "DOLLARS\nPROCESSED",
    icon: "icon_dollars",
    count: " 561M",
  },
  {
    name: "PAYMENTS TRANSMITTED",
    icon: "icon_payments_trans",
    count: "100K",
  },
  {
    name: "FDIC BANKS",
    icon: "icon_banks",
    count: "67",
  },
];

const changelogHtmlStringOld = `<div style="text-align: center; font-family: Poppins; color: #666666; font-size: 20px; font-weight: 600;">  
  November 9, 2023 Changelog  
</div>  
<br />  
<div>  
  <span style="font-weight: 700; font-family: Poppins">🎉 What’s New in BankShot 🎊</span>  
</div>  
<ul>  
  <li>  
    <span style="font-family: Poppins">  
      <p><strong>We’re making payments even easier with a new transaction type:</strong></p>  
      <p><strong><i>Survey Fees</i></strong> - You can now accept survey fee payments through Bank Shot, moving you one step closer to fully digitized closings.</p>  
    </span>  
  </li>   
  <li>  
    <span style="font-family: Poppins">  
      <p><strong>Reporting just got an upgrade to be more accessible and customizable:</strong></p>  
      <ul>  
        <li style="margin-bottom: 0px; font-family: Poppins">The Reports menu is now conveniently located in the left sidebar navigation for quicker access.</li>  
        <li style="margin-bottom: 0px; font-family: Poppins">You can now preview all available reports for your company right in the sidebar.</li>  
        <li style="margin-bottom: 0px; font-family: Poppins">Choose which columns to include before downloading each report. Customize for the insights that are most useful for your team.</li>  
      </ul>  
    </span>  
  </li>   
</ul>`;

const changelogHtmlString = `<div style="text-align: center; font-family: Poppins; color: #666666; font-size: 20px; font-weight: 600;">  
  June 11, 2024 Changelog  
</div>  
<br />  
<div>  
  <span style="font-weight: 700; font-family: Poppins">🎉 Bank Shot's Check Deposit and Request for Payments Just Got Even Better! 🚀</span>  
</div>  
<ul style="list-style-type: none;">
  <li style="margin-bottom: 0px; font-family: Poppins"><strong>We've been listening to your feedback and working hard to enhance your experience with Bank Shot. We're excited to introduce some awesome new features that will make your check deposit and payment request workflows a breeze. Let's explore what's new!</strong></li>  
</ul>
<div>  
  <span style="font-weight: 700; font-family: Poppins">🌟 Check Deposit Workflow:</span>  
</div>
<ul style="list-style-type: none;">
  <li>
      <span style="font-family: Poppins">  
        <p><strong>📷 Crystal-Clear Check Images</strong></p>  
        <ul>
          <li style="margin-bottom: 0px; font-family: Poppins">Say goodbye to blurry check images! We've improved our image resolution to ensure your check deposits are always picture-perfect.</li> 
          <li style="margin-bottom: 0px; font-family: Poppins">Crop your check images with ease for a more precise and professional look.</li>
        </ul>  
      </span>
  </li>
  <li>
  <span style="font-family: Poppins">  
    <p><strong>🔄 Effortless Check Rotation</strong></p>  
    <ul>
      <li style="margin-bottom: 0px; font-family: Poppins">No more upside-down endorsements! You can now rotate your check images to ensure your endorsements are applied correctly every time.</li> 
    </ul>  
  </span> 
</ul>
<div>  
  <span style="font-weight: 700; font-family: Poppins">📥 Request for Payments:</span>  
</div>
<ul style="list-style-type: none;">
  <li>
      <span style="font-family: Poppins">  
        <p><strong>🔁 Set It and Forget It</strong></p>  
        <ul>
          <li style="margin-bottom: 0px; font-family: Poppins">Streamline your payment requests with our new recurring request feature. Set up your payment requests once, and let Bank Shot handle the rest.</li> 
          <li style="margin-bottom: 0px; font-family: Poppins">Choose your desired frequency and let Bank Shot automatically send out payment requests on your behalf, saving you time and effort.</li>
        </ul>  
      </span>
  </li> 
</ul>
<span style="font-family: Poppins">
<p>We're thrilled to bring you these enhancements designed to simplify your financial workflows and help your business thrive! As always, we're committed to delivering the best possible experience for our users.</p>
</span>
<span style="font-family: Poppins">
<p>Don't forget to check out our <a href="https://portal.productboard.com/ndeizjwmwxuxzzjqakpjma5x">Customer Facing Roadmap</a>, where you can vote on new feature requests or submit your own ideas. Your feedback is invaluable in shaping the future of Bank Shot!</p>
</span>
<span style="font-family: Poppins">
<p>If you have any questions, comments, or just want to share your excitement, our team is always here to listen. Reach out anytime – we can't wait to hear from you!</p>
</span>
<span style="font-family: Poppins">
<p>Happy depositing and requesting! 😄</p>
</span>
<span style="font-family: Poppins">
<p>The Bank Shot Team</p>
</span>
`;

export { transmittingData, changelogHtmlString };
