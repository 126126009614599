import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { addUserSignature, getUserSignature } from "api";
import {
  Button,
  Card,
  Container,
  Icon,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import decodeToken from "tools/token";
import { IUpdateUserRequest, ObjectStrings } from "interfaces";
import SignatureCanvas from "react-signature-canvas";
import { ISignatureRequest } from "interfaces/ISignature";
import { useMutation, useQuery } from "@tanstack/react-query";
import strings from "strings";
import HotjarSuppressor from "components/hotjarSuppressor";

interface IProfileTabProps {
  styles: ObjectStrings;
  profileStrings: ObjectStrings;
  userStrings: ObjectStrings;
}

const SignatureTab = (props: IProfileTabProps) => {
  const { styles, profileStrings } = props;
  const [submitBtnLoader, setSubmitBtnLoader] = useState<boolean>(false);
  const methods = useForm<IUpdateUserRequest>();
  const { handleSubmit } = methods;
  const [signature, setSignature] = useState<string | undefined>();
  const [editable, setEditable] = useState<boolean>(true);
  const userDetails = decodeToken();

  let sigCanvas: SignatureCanvas | null;
  const canvasHeight = 200;
  const canvasWidth = 600;

  const { isFetching, refetch: RefetchSignature } = useQuery(
    ["userId", userDetails?.puid],
    () => getUserSignature(userDetails?.puid as number),
    {
      enabled: !!userDetails,
      onSuccess: (response) => {
        setSignature(response.data.imageBase64);
        if (response.data.imageBase64 !== null) {
          setEditable(false);
        }
      },
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    RefetchSignature();
  }, []);

  const updateSignature = useMutation(
    ({
      id,
      signatureRequest,
    }: {
      id: number;
      signatureRequest: ISignatureRequest;
    }) => {
      return addUserSignature(id, signatureRequest);
    },
    {
      onSuccess: () => {
        toast({
          title: strings.PROFILE_STRING.SIGNATURE_SAVED,
          subTitle: strings.PROFILE_STRING.SIGNATURE_STORED,
        });
        setSubmitBtnLoader(false);
        setSignature(sigCanvas?.toDataURL());
      },
      onError: () => {
        setSubmitBtnLoader(false);
      },
    }
  );

  const onSubmit = () => {
    if (userDetails) {
      setSubmitBtnLoader(true);
      const payload: ISignatureRequest = {
        signatureImageBase64: sigCanvas?.toDataURL(),
      };
      updateSignature.mutate({
        id: userDetails.puid,
        signatureRequest: payload,
      });
    }
  };

  if (isFetching) {
    return <Loader height={"auto"} loading={isFetching} />;
  }

  return (
    <Container>
      <TypoGraph content={profileStrings.SIGNATURE} variant="h2" />
      <Container className={styles.formContainer}>
        <Container>
          <FormProvider {...methods}>
            <Card sxContainer={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <HotjarSuppressor>
                {signature ? (
                  <Icon
                    name="signature"
                    type="images"
                    url={`data:image/png;base64,${signature}`}
                    height={canvasHeight}
                    width={canvasWidth}
                  />
                ) : (
                  <SignatureCanvas
                    penColor="black"
                    ref={(ref) => {
                      sigCanvas = ref;
                    }}
                    canvasProps={{
                      width: canvasWidth,
                      height: canvasHeight,
                      className: "sigCanvas",
                    }}
                  />
                )}
              </HotjarSuppressor>
            </Card>
            <Button
              variant="outlined"
              label="Clear"
              loading={submitBtnLoader}
              onClick={() => {
                sigCanvas?.clear();
                setEditable(true);
                setSignature(undefined);
              }}
            />
            <Container className={styles.btnContainer}>
              {editable ? (
                <Button
                  label={strings.PROFILE_STRING.SAVE_SIGNATURE}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!editable}
                  loading={submitBtnLoader}
                />
              ) : (
                <></>
              )}
            </Container>
          </FormProvider>
        </Container>
      </Container>
    </Container>
  );
};

export default SignatureTab;
