import React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import Reports from "pages/reports";

const ReportsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Reports />} />
    </Routes>
  );
};

export default ReportsRoutes;
