import { IPayment } from "interfaces";
import colors from "theme/colors";

const earnestMoneyStatus = [
  {
    label: "All",
  },
  {
    label: "New",
    badgeColor: colors.new,
  },
  {
    label: "Hold",
    badgeColor: colors.warning,
  },
  {
    label: "Approved",
    badgeColor: colors.success,
  },
  {
    label: "Completed",
  },
  {
    label: "Rejected",
  },
];

const paymentsDetailTabs = [
  {
    label: "Details",
  },
  {
    label: "Notes",
  },
  {
    label: "History",
  },
];

const dcFailStatusById = [18, 20];
const completedStatusById = [8, 9, 13, 25, 26];

const maskPaymentStatus = (payment: IPayment | undefined) => {
  switch (payment?.statusId) {
    case 19:
    case 1:
    case 0:
      payment.statusMask = "New";
      break;
    case 2:
      payment.statusMask = "On Hold";
      break;
    case 3:
    case 6:
      payment.statusMask = "Rejected";
      break;
    case 4:
      payment.statusMask =
        payment.status !== "deposited" ? payment.status : "Approved";
      break;
    case 9:
    case 13:
    case 25:
    case 26:
    case 8:
    case 31:
      payment.statusMask = "Completed";
      break;
    case 10:
      payment.statusMask = "Limit Hold";
      break;
    case 5:
      payment.statusMask = "Submitted";
      break;
    case 21:
      payment.statusMask = "Requested";
      break;
    case 22:
      payment.statusMask = "Validating Funds";
      break;
    case 23:
      payment.heldByPassport === true
        ? (payment.statusMask = payment.receivableStatus)
        : (payment.statusMask = "Ready For Deposit");
      break;
    case 24:
    case 30:
    case 34:
      payment.statusMask = "Failed";
      break;
    case 27:
      payment.statusMask = "Recurring";
      break;
    case 28:
    case 32:
      payment.statusMask = "Validating Funds";
      break;
    case 29:
    case 33:
      payment.statusMask = "Ready For Deposit";
      break;
    default:
      if (!!payment) {
        payment.statusMask = payment?.status;
      }
      break;
  }
  return payment;
};

export {
  earnestMoneyStatus,
  paymentsDetailTabs,
  dcFailStatusById,
  maskPaymentStatus,
  completedStatusById,
};
