import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getCurrentUserDetails, updateUser } from "api";
import { Button, Container, Toast as toast, TypoGraph } from "components";
import { InputFormField, SwitchFormField } from "components/hookForm";
import decodeToken from "tools/token";
import { IUpdateUserRequest, ObjectStrings } from "interfaces";
import strings from "strings";

interface IProfileTabProps {
  styles: ObjectStrings;
  profileStrings: ObjectStrings;
  userStrings: ObjectStrings;
  setTwoFactorAuthMethod: (params: number) => void;
}

const ProfileTab = (props: IProfileTabProps) => {
  const { styles, profileStrings, userStrings, setTwoFactorAuthMethod } = props;
  const [submitBtnLoader, setSubmitBtnLoader] = useState<boolean>(false);
  const methods = useForm<IUpdateUserRequest>();
  const { handleSubmit, reset, formState } = methods;

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  useEffect(() => {
    const userDetails = decodeToken();
    if (userDetails) {
      getCurrentUserDetails().then((res) => {
        const resData = res?.data;
        reset({
          email: resData.email,
          firstName: resData.firstname,
          lastName: resData.lastname,
          priv: resData.privLevel,
          mobile2fa: resData.hasMobile2FA,
          active: true,
          emailNotifications: resData.newItemNotify,
          userCompanies: resData.userCompanies.map(
            (company: any): number => company.id
          ),
          adminCompanies: resData.adminCompanies.map(
            (company: any): number => company.id
          ),
          timezone: resData.timezone,
        });
        setTwoFactorAuthMethod(resData.twoFactorAuthMethod);
      });
    }
  }, [reset]);

  const onSubmit = (formData: IUpdateUserRequest) => {
    const userDetails = decodeToken();
    if (userDetails) {
      setSubmitBtnLoader(true);
      formData.privileges = userDetails.privileges;
      updateUser(formData, userDetails.puid)
        .then((res) => {
          const resData: any = res?.data;
          toast({
            title: strings.UPDATED_SUCCESSFULLY,
            subTitle: "Profile details updated successfully",
          });
          reset({
            email: resData.email,
            firstName: resData.firstname,
            lastName: resData.lastname,
            priv: resData.privLevel,
            mobile2fa: resData.hasMobile2FA,
            active: true,
            privileges: resData.privileges,
            emailNotifications: resData.newItemNotify,
            userCompanies: resData.userCompanies.map(
              (company: any): number => company.id
            ),
            adminCompanies: resData.adminCompanies.map(
              (company: any): number => company.id
            ),
            timezone: resData.timezone,
          });
        })
        .finally(() => {
          setSubmitBtnLoader(false);
        });
    }
  };

  return (
    <Container>
      <TypoGraph content={profileStrings.PROFILE_DETAILS} variant="h2" />
      <Container className={styles.formContainer}>
        <Container>
          <FormProvider {...methods}>
            <InputFormField
              name="firstName"
              label={profileStrings.FIRST_NAME}
              required
            />
            <InputFormField
              name="lastName"
              label={profileStrings.LAST_NAME}
              required
            />
            <InputFormField
              name="email"
              label={strings.EMAIL_ADDRESS}
              disabled
            />
            <InputFormField name="timezone" label="Timezone" disabled />
            <Container className={styles.switchContainer}>
              <Container>
                <TypoGraph
                  variant="body1"
                  content={userStrings.EMAIL_NOTIFICATION}
                />
                <TypoGraph
                  variant="caption"
                  content={userStrings.RECIEVE_EMAIL}
                />
              </Container>
              <SwitchFormField name="emailNotifications" />
            </Container>
            <Container className={styles.btnContainer}>
              <Button
                label="Update"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
                loading={submitBtnLoader}
              />
            </Container>
          </FormProvider>
        </Container>
      </Container>
    </Container>
  );
};

export default ProfileTab;
