import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container, TypoGraph } from "components";

export interface ICheckBoxProps {
  id?: string;
  checked?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelVariant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "overline";
  defaultChecked?: boolean;
  value?: boolean;
  inputRef?: any;
  labelPlacement?: "bottom" | "top" | "end" | "start";
  checkboxSize?: number;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  disabled?: boolean;
  checkBoxStyle?: string;
}

const CheckBoxField: React.FunctionComponent<ICheckBoxProps> = (props) => {
  const {
    id,
    checked = false,
    onChange,
    label,
    labelVariant = "body1",
    defaultChecked,
    value,
    inputRef,
    checkboxSize = 28,
    labelPlacement = "end",
    color = "primary",
    disabled,
    checkBoxStyle,
  } = props;
  return (
    <Container
      sx={{
        gridTemplateColumns: "auto",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        className={checkBoxStyle}
        control={
          <Checkbox
            id={id}
            defaultChecked={defaultChecked}
            sx={{
              "& .MuiSvgIcon-root": checkboxSize
                ? { fontSize: checkboxSize }
                : "",
            }}
            inputProps={{ "aria-label": "controlled" }}
            value={value}
            checked={checked || value}
            onChange={onChange}
            inputRef={inputRef}
            color={color}
          />
        }
        label={<TypoGraph variant={labelVariant} content={label} />}
        labelPlacement={labelPlacement}
        disabled={disabled}
      />
    </Container>
  );
};

export default CheckBoxField;
