import React from "react";
import { IPayment } from "interfaces";
import { TypoGraph } from "components";
import strings from "strings";
import colors from "theme/colors";

const DcFailedComponent: React.FC<{ payment: IPayment | undefined }> = (
  props
) => {
  const { payment } = props;
  return (
    <TypoGraph
      content={
        payment?.statusId === 18
          ? strings.DC_PRINT_PENDING_MESSAGE
          : strings.DC_PRINT_FAILED_MESSAGE
      }
      color={colors.error}
      align="center"
      sx={{ padding: "3rem" }}
    />
  );
};

export { DcFailedComponent };
