import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Divider,
  HeaderBar,
  TableComponent,
  Tabs,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  allPaymentFilters,
  paymentColumns,
  paymentFilters,
} from "constants/tableData";
import styles from "./paymentsList.module.scss";
import strings from "strings";
import { getPaymentPage, getPaymentTypeById } from "api";
import { IPageResponse, ISubmitItemResult } from "interfaces";
import { IPayment } from "interfaces/IPayment";
import { PaymentBladeComponent } from "./partial";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import {
  CompanyFilterDialog,
  MultipleCheckDepositDialog,
  MultiplePassportDepositDialog,
  MultipleSubmitDialog,
} from "components/dialog";
import BaseDialog from "components/dialog/baseDialog";
import { List } from "@mui/material";
import colors from "theme/colors";
import { RequestPaymentBladeComponent } from "./partial/requestPaymentBlade";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import { paymentStatus } from "constants/paymentStatus";

const PaymentsList: React.FunctionComponent = () => {
  const OTHER_PAYMENT_TYPE = "5";
  const ALL_PAYMENT_TYPES = "99";
  const { typeId, paymentId } = useParams();
  const [paymentFilterTab, setPaymentFilterTab] = useState(0);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [paymentPage, setPaymentPage] = useState<IPageResponse<IPayment>>();
  const [tabsFilter] = useState(
    typeId == ALL_PAYMENT_TYPES ? allPaymentFilters : paymentFilters
  );
  const [paymentFilterName, setPaymentFilterName] = useState<string>(
    tabsFilter[0].label
  );
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [paymentTypeName, setPaymentTypeName] = useState<string>();
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc",
    },
  ]);
  const [selectedPayments, setSelectedPayments] = useState<IPayment[]>([]);
  const [showUpdateItemDialog, setShowUpdateItemDialog] =
    useState<boolean>(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [multipleSubmitResult, setMultipleSubmitResult] =
    useState<ISubmitItemResult[]>();
  const [showRequestPaymentBlade, setShowRequestPaymentBlade] =
    useState<boolean>(false);

  const [openCompanyModal, setOpenCompanyModal] = React.useState(false);
  const [selectedCompaniesId, setSelectedCompaniesId] = useState<number[]>([]);
  const [companiesFilter, setCompaniesFilter] = useState<number[]>([]);

  const [showMultipleDepositDialog, setShowMultipleDepositDialog] =
    useState<boolean>(false);

  const [showMultipleCheckDepositDialog, setShowMultipleCheckDepositDialog] =
    useState<boolean>(false);

  const toggleOption = useCallback(
    (option: number) => {
      if (selectedCompaniesId.includes(option)) {
        setSelectedCompaniesId(
          selectedCompaniesId.filter((o: any) => o !== option)
        );
      } else {
        setSelectedCompaniesId([...selectedCompaniesId, option]);
      }
    },
    [selectedCompaniesId]
  );

  const handleApplyCompanyFilter = () => {
    setCompaniesFilter(selectedCompaniesId);
    setOpenCompanyModal(false);
  };

  const onCleanFilter = () => {
    setSelectedCompaniesId([]);
    setCompaniesFilter([]);
  };

  const paymentTypeCanBeRequested = [
    "0",
    "1",
    "2",
    "3",
    "5",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14"
  ];
  const userCanSubmitMultipleItems =
    (tabsFilter[paymentFilterTab].filterValue === "DEPOSIT_QUEUE" ||
      tabsFilter[paymentFilterTab].filterValue === "READY_FOR_DEPOSIT" ||
      tabsFilter[paymentFilterTab].filterValue === "NEW") &&
    userCan(Actions.SUBMIT, Resources.ITEM);

  const onSuccess = (response: ISubmitItemResult[]) => {
    setMultipleSubmitResult(response);
    setShowUpdateItemDialog(false);
    setShowResultModal(true);
    loadPayments();
  };

  const isSelectableItem = (endorsementRequired: boolean, statusId: number) => {
    if (tabsFilter[paymentFilterTab].filterValue === "READY_FOR_DEPOSIT") {
      return true;
    }

    if (tabsFilter[paymentFilterTab].filterValue === "DEPOSIT_QUEUE") {
      if (!endorsementRequired && statusId === paymentStatus.DEPOSITED) {
        return true;
      }
    }

    if (tabsFilter[paymentFilterTab].filterValue === "NEW") {
      if (!endorsementRequired && statusId === paymentStatus.NEW) {
        return true;
      }
    }

    return false;
  };

  const resultDialogContent = (
    <Container>
      <TypoGraph
        variant="h3"
        content={`You just sent ${multipleSubmitResult?.length} Payments, here is the summary of each one of them:`}
      />

      <List>
        {multipleSubmitResult?.map((payment) => {
          return payment.success ? (
            <Container>
              <TypoGraph content={`Payment ID: ${payment.id.toString()}`} />
              <TypoGraph content={"Submitted"} color={colors.success} />
              <TypoGraph content={payment.message} />
              <Divider></Divider>
            </Container>
          ) : (
            <Container>
              <TypoGraph content={`Payment ID: ${payment.id.toString()}`} />
              <TypoGraph content={"Failed to submit"} color={colors.error} />
              <TypoGraph content={`Reason: ${payment.error}`} />
              <TypoGraph content={payment.reason} />
              <Divider></Divider>
            </Container>
          );
        })}
      </List>
    </Container>
  );
  useEffect(() => {
    (async () => {
      if (!(paymentId && typeId)) {
        setShowPaymentDetails(false);
        return;
      }
      setShowPaymentDetails(true);
    })();
  }, [paymentId, typeId]);

  const loadPayments = useCallback(async () => {
    if (isNaN(Number(typeId))) {
      toast({
        type: "error",
        title: "Route does not exist",
        subTitle: "Try an existing payment type Id",
        autoClose: 2500,
      });
      navigate("/payments");
      return;
    }
    setLoading(true);
    if (paymentFilterName == "All") {
      paymentColumns[5].hide = Boolean(false);
      setShowAll(true);
    } else {
      paymentColumns[5].hide = Boolean(true);
      setShowAll(false);
    }
    if (!typeId) {
      return;
    }
    try {
      setPaymentTypeName((await getPaymentTypeById(+typeId))?.data?.name);
      const payments = (
        await getPaymentPage(
          typeId ? +typeId : 0,
          currentPageNumber,
          currentPageSize,
          tabsFilter[paymentFilterTab].filterValue,
          searchValue,
          sortModel[0]?.field,
          sortModel[0]?.sort,
          companiesFilter
        )
      )?.data;
      setPaymentPage(payments);
    } catch {
      navigate("/payments");
    } finally {
      setLoading(false);
    }
  }, [
    typeId,
    paymentFilterTab,
    sortModel,
    searchValue,
    currentPageSize,
    currentPageNumber,
    companiesFilter,
  ]);

  useEffect(() => {
    loadPayments();
  }, [
    typeId,
    paymentFilterTab,
    sortModel,
    searchValue,
    currentPageSize,
    currentPageNumber,
    loadPayments,
  ]);

  paymentColumns[6].hide = Boolean(typeId === OTHER_PAYMENT_TYPE);
  paymentColumns[5].hide = Boolean(
    paymentFilterName != "All" && typeId != ALL_PAYMENT_TYPES
  );
  paymentColumns[4].hide = Boolean(typeId != ALL_PAYMENT_TYPES);

  return (
    <Container className={styles.paymentsContainer}>
      <HeaderBar
        title={typeId === "2" ? "Commission Check" : paymentTypeName}
        secondaryBtnProps={
          userCanSubmitMultipleItems
            ? {
                label: "Submit selected receivables",
                disabled: selectedPayments.length === 0,
                onClick: () => {
                  if (
                    tabsFilter[paymentFilterTab].filterValue === "DEPOSIT_QUEUE"
                  ) {
                    setShowUpdateItemDialog(true);
                  }
                  if (
                    tabsFilter[paymentFilterTab].filterValue ===
                    "READY_FOR_DEPOSIT"
                  ) {
                    setShowMultipleDepositDialog(true);
                  }
                  if (tabsFilter[paymentFilterTab].filterValue === "NEW") {
                    setShowMultipleCheckDepositDialog(true);
                  }
                },
              }
            : {
                label: "Send Multiple Payment Requests",
                onClick: () => navigate("/payments/multiple-payment-request"),
              }
        }
        primaryBtnProps={
          typeof typeId !== "undefined" &&
          paymentTypeCanBeRequested.includes(typeId)
            ? {
                label: "Request Payment",
                onClick: () => {
                  setShowRequestPaymentBlade(true);
                },
                disabled: loading,
              }
            : {}
        }
      />

      <Tabs
        tabData={tabsFilter || []}
        value={paymentFilterTab}
        handleChange={(_e, val) => {
          setCurrentPageNumber(0);
          setPaymentFilterTab(val);
          setPaymentFilterName(tabsFilter[val].label);
          navigate(`/payments/${typeId}`);
        }}
      />

      {!showAll && (
        <TableComponent
          title={
            paymentTypeName &&
            `${typeId === ALL_PAYMENT_TYPES ? "" : paymentFilterName} ${
              typeId === "2" ? "Commission Check" : paymentTypeName
            }
           ${strings.RECEIVABLES}`
          }
          data={paymentPage?.items || []}
          totalCount={paymentPage?.totalCount}
          columns={paymentColumns}
          loading={loading}
          searchValue={searchValue}
          currentPageSize={currentPageSize}
          handleQuickSearch={(searchValue: string) => {
            setSearchValue(searchValue);
          }}
          handlePagination={(pageNumber, pageSize) => {
            setCurrentPageSize(pageSize);
            setCurrentPageNumber(pageNumber);
          }}
          handleSortModelChange={(model: GridSortModel) => {
            if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
              setSortModel(model);
            }
          }}
          checkboxSelection={userCanSubmitMultipleItems}
          handleCheckboxSelection={(selectedRows) => {
            let selectedPayments = paymentPage?.items.filter((payment) =>
              selectedRows.includes(payment.id)
            );
            setSelectedPayments(selectedPayments || []);
          }}
          isRowSelectable={(params) =>
            isSelectableItem(
              params.row.endorsementRequired,
              params.row.statusId
            )
          }
          handleCustomFilterButton={() => setOpenCompanyModal(true)}
          onRowClick={(row) => {
            navigate(`/payments/${typeId}/detail/${row.id}`);
          }}
        />
      )}
      {showAll && (
        <TableComponent
          title={
            paymentTypeName &&
            `${typeId === ALL_PAYMENT_TYPES ? "" : paymentFilterName} ${
              typeId === "2" ? "Commission Check" : paymentTypeName
            }
         ${strings.RECEIVABLES}`
          }
          data={paymentPage?.items || []}
          totalCount={paymentPage?.totalCount}
          columns={paymentColumns}
          loading={loading}
          searchValue={searchValue}
          currentPageSize={currentPageSize}
          handleQuickSearch={(searchValue: string) => {
            setSearchValue(searchValue);
          }}
          handlePagination={(pageNumber, pageSize) => {
            setCurrentPageSize(pageSize);
            setCurrentPageNumber(pageNumber);
          }}
          handleSortModelChange={(model: GridSortModel) => {
            if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
              setSortModel(model);
            }
          }}
          checkboxSelection={userCanSubmitMultipleItems}
          handleCheckboxSelection={(selectedRows) => {
            let selectedPayments = paymentPage?.items.filter((payment) =>
              selectedRows.includes(payment.id)
            );
            setSelectedPayments(selectedPayments || []);
          }}
          isRowSelectable={(params) =>
            isSelectableItem(
              params.row.endorsementRequired,
              params.row.statusId
            )
          }
          handleCustomFilterButton={() => setOpenCompanyModal(true)}
          onRowClick={(row) => {
            navigate(`/payments/${typeId}/detail/${row.id}`);
          }}
        />
      )}
      {showPaymentDetails && (
        <PaymentBladeComponent
          typeId={typeId}
          paymentId={paymentId}
          handleBladeSuccessCallBack={() => {
            navigate(`/payments/${typeId}`);
          }}
          handleDataChange={loadPayments}
        />
      )}
      <MultipleSubmitDialog
        open={showUpdateItemDialog}
        handleClose={() => setShowUpdateItemDialog(false)}
        selectedPayments={selectedPayments}
        onSuccessCallback={onSuccess}
      />

      <BaseDialog
        title="Submit Result Summary"
        open={showResultModal}
        content={resultDialogContent}
        handleClose={() => setShowResultModal(false)}
        primaryBtnProps={{
          label: strings.OK,
          onClick: () => setShowResultModal(false),
        }}
        showCancel={false}
      />
      {showRequestPaymentBlade && (
        <RequestPaymentBladeComponent
          handleClose={() => setShowRequestPaymentBlade(false)}
          handleDataChange={loadPayments}
          typeId={typeId}
          paymentTypeName={paymentTypeName}
        />
      )}
      <CompanyFilterDialog
        open={openCompanyModal}
        onClose={() => setOpenCompanyModal(false)}
        selectedOptions={selectedCompaniesId}
        toggleOption={toggleOption}
        onCleanFilter={onCleanFilter}
        onApplyFilter={handleApplyCompanyFilter}
      />

      <MultiplePassportDepositDialog
        open={showMultipleDepositDialog}
        handleClose={() => setShowMultipleDepositDialog(false)}
        selectedPayments={selectedPayments}
        onSuccessCallback={onSuccess}
      />

      <MultipleCheckDepositDialog
        open={showMultipleCheckDepositDialog}
        handleClose={() => setShowMultipleCheckDepositDialog(false)}
        selectedPayments={selectedPayments}
        onSuccessCallback={onSuccess}
      />
    </Container>
  );
};

export default PaymentsList;
