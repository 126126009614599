import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Container, HotjarSuppressor, Icon, TypoGraph } from "components";
import styles from "./imagePreview.module.scss";
import { IPayment, IPaymentImage, IPaymentImageResponse } from "interfaces";

interface IImagePreviewDialogProps {
  open: boolean;
  handleClose: () => void;
  payment?: IPayment;
  paymentImage: IPaymentImageResponse | undefined;
  initialImagePreview?: "front" | "back";
  frontImage: IPaymentImage | undefined;
  backImage: IPaymentImage | undefined;
}

const ImagePreviewDialog: React.FC<IImagePreviewDialogProps> = (props) => {
  const {
    open,
    handleClose,
    payment,
    paymentImage,
    initialImagePreview,
    frontImage,
    backImage,
  } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [rotation, setRotation] = useState<number>(0);
  const isVertical = rotation === 90 || rotation === 270;
  useEffect(() => {
    if (initialImagePreview === "front") {
      setCurrentStep(0);
    } else {
      setCurrentStep(1);
    }
  }, [open, initialImagePreview]);

  const ImageModalNode = (checkImageNode: (IPaymentImage | undefined)[]) => {
    return checkImageNode?.map((item, index) => (
      <Container
        className={
          isVertical ? styles.contentContainerVertical : styles.contentContainer
        }
        key={`key-${index}`}
      >
        <Container
          sx={{
            rotate: `${rotation}deg`,
            heigth: "45rem",
            marginBottom: "1rem",
          }}
        >
          <HotjarSuppressor>
            <Icon
              name="check_image"
              type="images"
              url={`data:image/png;base64,${item?.base64Image}`}
              boxClassName={isVertical ? styles.imageVertical : ""}
            />
          </HotjarSuppressor>
        </Container>
        <Container className={styles.detailContainer}>
          <Container>
            <Icon name="icon_user" />
            <TypoGraph content={`${payment?.firstName} ${payment?.lastName}`} />
          </Container>
          <Container>
            <Icon name="icon_image" />
            <TypoGraph content="Image 1" />
          </Container>
          <Container>
            <Icon name="icon_calender" />
            <TypoGraph content={payment?.dueDate} />
          </Container>
          <Container>
            <Icon name="icon_phoneCamera" />
            <TypoGraph content={paymentImage?.deviceName || "No data"} />
          </Container>
          <Container>
            <Icon name="icon_hash" />
            <TypoGraph
              content={paymentImage?.applicationVersion || "No data"}
            />
          </Container>
          <Container>
            <Icon name="icon_captureMode" />
            <TypoGraph content={paymentImage?.captureMode || "No data"} />
          </Container>
        </Container>
      </Container>
    ));
  };

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Dialog open={open} maxWidth="xl">
      <Container
        className={
          isVertical ? styles.dialogContainerVertical : styles.dialogContainer
        }
      >
        <Icon
          name="icon_cancel"
          color="white"
          boxClassName={styles.closeIcon}
          onClick={handleClose}
          iconButton
        />

        {ImageModalNode([frontImage, backImage])[currentStep]}

        {frontImage && backImage && (
          <>
            <Icon
              name="icon_sliderLeftArrow"
              boxClassName={styles.iconLeftArrow}
              size={40}
              onClick={handlePrev}
              iconButton
            />
            <Icon
              name="icon_sliderRightArrow"
              boxClassName={styles.iconRightArrow}
              size={40}
              onClick={handleNext}
              iconButton
            />
            <Icon
              name="icon_rotate"
              color="white"
              size={40}
              boxClassName={styles.rotateIcon}
              onClick={() => {
                rotation === 270 ? setRotation(0) : setRotation(rotation + 90);
              }}
              iconButton
            />
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default ImagePreviewDialog;
