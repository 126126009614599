import React, { useState } from "react";
import styles from "./desktopNavBar.module.scss";
import { Icon, Popover, TypoGraph } from "components";
import { INavBarActionItem } from "interfaces/IWhitelabelConfigurationResponse";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

interface DesktopNavBarProps {
  item: INavBarActionItem;
  index: number;
}

const NavBarItemWithPopOverList: React.FC<DesktopNavBarProps> = ({
  item,
  index,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <ListItemButton
        sx={{ paddingRight: "0" }}
        onClick={(e: any) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <TypoGraph
          key={index}
          content={item.content}
          className={styles.navItemWithIcon}
          onClick={(e: any) => {
            setAnchorEl(e.currentTarget);
          }}
          link
        />
        <Icon name="icon_sliderDownArrow" size="12" />
      </ListItemButton>

      <Popover
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        position={"bottom"}
      >
        <List>
          {item.subItems.map((subItem) => (
            <ListItem sx={{ padding: "0" }}>
              <ListItemButton
                id={"profile"}
                sx={{ padding: "1rem" }}
                onClick={() => {
                  window.open(subItem.url);
                }}
              >
                <ListItemText primary={subItem.content} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default NavBarItemWithPopOverList;
