import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import React from "react";
import colors from "theme/colors";

export const LightTooltip = ({ className, ...props }: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: colors.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: 1,
          },
        },
      }}
    />
  );
};
