import { Container } from "components";
import styles from "./achPayment.module.scss";
import { DropdownFormField, InputFormField } from "components/hookForm";
import { useFormContext } from "react-hook-form";
import {
  passportAccountHolderTypes,
  passportAccountTypes,
} from "constants/dropdownOptions";

interface IAchPaymentProps {}

const AchPayment: React.FC<IAchPaymentProps> = (props) => {
  const { getValues } = useFormContext();

  return (
    <Container className={styles.achDetailsContainer}>
      <InputFormField
        name="accountHolder"
        label="Account Holder Name"
        required
      />
      <Container className={styles.paymentDetails}>
        <DropdownFormField
          name={"bankAccountType"}
          label={"Account Type"}
          options={passportAccountTypes}
          required
        ></DropdownFormField>
        <DropdownFormField
          name={"holderType"}
          label={"Holder Type"}
          options={passportAccountHolderTypes}
          required
        ></DropdownFormField>
      </Container>

      <Container className={styles.paymentDetails}>
        <InputFormField
          name="accountNumber"
          label="Account Number"
          hideableText={true}
          required
        />
        <InputFormField
          name="accountNumberConfirmation"
          label="Confirm Account Number"
          hideableText={true}
          required
          validate={(value: any) =>
            value === getValues("accountNumber") ||
            "Account Numbers must match."
          }
        />
      </Container>
      <Container className={styles.paymentDetails}>
        <InputFormField
          name="routingNumber"
          label="Electronic Routing Number"
          hideableText={true}
          required
        />
        <InputFormField
          name="routingNumberConfirmation"
          label="Confirm Electronic Routing Number"
          hideableText={true}
          required
          validate={(value: any) =>
            value === getValues("routingNumber") ||
            "Electronic Routing Number must match."
          }
        />
      </Container>
    </Container>
  );
};

export default AchPayment;
