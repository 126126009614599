import { Blade, Button, Container, Loader, SubmitDialog } from "components";
import strings from "strings";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../paymentsList.module.scss";
import RequestDetailComponent from "./requestDetails";
import { IPayment } from "interfaces";
import { getPaymentById, resentReceivablePaymentRequest } from "api";
import { canSendReminder } from "constants/itemUpdateActions";
import toast from "components/toast";
import { useMutation } from "@tanstack/react-query";

interface IPaymentBladeProps {
  typeId: string | undefined;
  handleBladeSuccessCallBack: () => void;
  paymentId: string | undefined;
}

const RequestBladeComponent = ({ typeId, paymentId }: IPaymentBladeProps) => {
  const navigate = useNavigate();
  const [selectedPaymentDetail, setSelectedPaymentDetail] =
    useState<IPayment>();
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [showSendReminderDialog, setShowSendReminderDialog] =
    useState<boolean>(false);
  useEffect(() => {
    (async () => {
      if (!(paymentId && typeId)) {
        return;
      }
      setBladeLoader(true);
      try {
        const payment = (await getPaymentById(parseInt(paymentId))).data;
        setSelectedPaymentDetail(payment);
      } catch {
        navigate(`/paymentrequest`);
      } finally {
        setBladeLoader(false);
      }
    })();
  }, [paymentId, typeId]);

  const BladeFooter = () => {
    return (
      <Container className={styles.bladeFooterContainer}>
        {canSendReminder(selectedPaymentDetail?.statusId) && (
          <Button
            id={"Reminder"}
            label="Send Reminder"
            color="primary"
            onClick={() => setShowSendReminderDialog(true)}
          />
        )}
      </Container>
    );
  };

  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.bladeBodyContainer}>
        <Container className={styles.bladeTabContainer}>
          <RequestDetailComponent payment={selectedPaymentDetail} />
        </Container>
      </Container>
    );
  };

  const onActionSendReminder = useMutation(
    (id: string | undefined) => {
      return resentReceivablePaymentRequest(id);
    },
    {
      onError: (error) => {
        setShowSendReminderDialog(false);
        navigate("/paymentrequest");
      },
      onSuccess: (response) => {
        setShowSendReminderDialog(false);
        toast({
          title: response.message,
        });
        navigate("/paymentrequest");
      },
    }
  );

  return (
    <>
      <Blade
        show={true}
        title={!bladeLoader ? strings.REQUEST_DETAILS : ""}
        handleClose={() => navigate(`/paymentrequest`)}
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
      <SubmitDialog
        type="warning"
        title={strings.CONFIRM_SEND_REMINDER}
        open={showSendReminderDialog}
        body1={`${strings.ITEM_WILL_BE_SENT_A_REMINDER} ${selectedPaymentDetail?.laname}`}
        handleClose={() => setShowSendReminderDialog(false)}
        primaryBtnProps={{
          id: "SendReminder",
          label: "Send Reminder",
          onClick: () => {
            onActionSendReminder.mutate(paymentId);
          },
          loading: onActionSendReminder.isLoading,
          color: "success",
        }}
      />
    </>
  );
};

export { RequestBladeComponent };
