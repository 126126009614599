import MuiChip from "@mui/material/Chip";
import { ObjectStrings } from "interfaces/UtilitiesModule";

export interface IChipProps {
  label?: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  variant?: "filled" | "outlined";
  clickable?: boolean;
  disabled?: boolean;
  size?: "medium" | "small";
  sx?: object;
  style?: ObjectStrings;
  className?: string;
}

const Chip: React.FC<IChipProps> = (props) => {
  const {
    label,
    color = "primary",
    variant = "outlined",
    clickable,
    disabled,
    size = "small",
    sx,
    style,
    className,
  } = props;
  if (!label) {
    return <></>;
  }
  return (
    <MuiChip
      sx={sx}
      style={style}
      className={className}
      label={label}
      clickable={clickable}
      color={color}
      variant={variant}
      disabled={disabled}
      size={size}
    />
  );
};

export default Chip;
