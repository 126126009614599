import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getCompanyAccountMaintenanceReport,
  getCompanyMaintenanceReport,
  getDisbursementAccountMaintenanceReport,
  getUserMaintenanceReport,
} from "api";
import { Container, HeaderBar, TableComponent } from "components";
import strings from "strings";
import { maintenanceReportColumns } from "constants/tableData";
import { IAdminLogResponse, IPageResponse } from "interfaces";

import styles from "./maintenanceReport.module.scss";

interface ITypeOfMaintenanceReport {
  id: string;
  type: string;
}

const MaintenanceReport: React.FunctionComponent = () => {
  const [maintenanceReports, setMaintenanceReports] =
    useState<IPageResponse<IAdminLogResponse>>();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [type, setType] = useState<ITypeOfMaintenanceReport>({
    id: "",
    type: "",
  });
  const { companyId, userId, companyAccountId, disbursementAccountId } =
    useParams();

  const loadMaintenanceReports = (
    typeOfMaintenanceReport: ITypeOfMaintenanceReport,
    pageNumber: number = 0,
    pageSize: number = 10,
    filter = ""
  ) => {
    setLoading(true);
    switch (typeOfMaintenanceReport.type) {
      case strings.USER_MAINTENANCE_REPORT_TYPE:
        getUserMaintenanceReport(
          typeOfMaintenanceReport.id,
          pageNumber,
          pageSize,
          filter
        )
          .then((logs) => setMaintenanceReports(logs.data))
          .finally(() => setLoading(false));
        break;
      case strings.COMPANY_MAINTENANCE_REPORT_TYPE:
        getCompanyMaintenanceReport(
          typeOfMaintenanceReport.id,
          pageNumber,
          pageSize,
          filter
        )
          .then((logs) => setMaintenanceReports(logs.data))
          .finally(() => setLoading(false));
        break;
      case strings.ACCOUNT_MAINTENANCE_REPORT_TYPE:
        getCompanyAccountMaintenanceReport(
          typeOfMaintenanceReport.id,
          pageNumber,
          pageSize,
          filter
        )
          .then((logs) => setMaintenanceReports(logs.data))
          .finally(() => setLoading(false));
        break;

      case strings.DISBURSEMENT_ACCOUNT_MAINTENANCE_REPORT_TYPE:
        getDisbursementAccountMaintenanceReport(
          typeOfMaintenanceReport.id,
          pageNumber,
          pageSize,
          filter
        )
          .then((logs) => setMaintenanceReports(logs.data))
          .finally(() => setLoading(false));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    //Here we set what type of maintenance report this page will show using the ids of the params.
    if (userId) {
      const typeOfMaintenanceReport: ITypeOfMaintenanceReport = {
        id: userId,
        type: strings.USER_MAINTENANCE_REPORT_TYPE,
      };
      setType(typeOfMaintenanceReport); //This state is for the table component, to keep up with the change of page.
      loadMaintenanceReports(typeOfMaintenanceReport); // I need to set up the data for the first load.
    }
    if (companyId) {
      if (companyAccountId) {
        const typeOfMaintenanceReport: ITypeOfMaintenanceReport = {
          id: companyAccountId,
          type: strings.ACCOUNT_MAINTENANCE_REPORT_TYPE,
        };
        setType(typeOfMaintenanceReport);
        loadMaintenanceReports(typeOfMaintenanceReport);
      } else {
        const typeOfMaintenanceReport: ITypeOfMaintenanceReport = {
          id: companyId,
          type: strings.COMPANY_MAINTENANCE_REPORT_TYPE,
        };
        setType(typeOfMaintenanceReport);
        loadMaintenanceReports(typeOfMaintenanceReport);
      }
    }

    if (disbursementAccountId) {
      const typeOfMaintenanceReport: ITypeOfMaintenanceReport = {
        id: disbursementAccountId,
        type: strings.DISBURSEMENT_ACCOUNT_MAINTENANCE_REPORT_TYPE,
      };
      setType(typeOfMaintenanceReport); //This state is for the table component, to keep up with the change of page.
      loadMaintenanceReports(typeOfMaintenanceReport); // I need to set up the data for the first load.
    }
  }, [companyId, userId, companyAccountId, disbursementAccountId]);

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadMaintenanceReports(type, 0, 10, value);
  };
  return (
    <Container>
      <HeaderBar title={strings.MAINTENANCE_REPORT} />
      {maintenanceReports && (
        <Container className={styles.alertBox}>
          {`Log generated at ${maintenanceReports?.generatedTime}`}
        </Container>
      )}
      <TableComponent
        title={strings.MAINTENANCE_REPORT}
        data={maintenanceReports?.items || []}
        totalCount={maintenanceReports?.totalCount}
        columns={maintenanceReportColumns}
        loading={loading}
        density="comfortable"
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(pageNumber, pageSize) =>
          loadMaintenanceReports(type, pageNumber, pageSize, searchValue)
        }
      />
    </Container>
  );
};

export default MaintenanceReport;
