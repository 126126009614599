import { Container, CustomDivider, UserInfoForm } from "components";
import { InputFormField, DropdownFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import { amountIsValid } from "tools/validators";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { DropdownOptions } from "interfaces";
import { boolOptions } from "constants/dropdownOptions";
import { PropertyForm } from "components";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";

interface RentPaymentComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const RentPaymentComponent: React.FC<RentPaymentComponentProps> = (props) => {
  const { getValues, setValue, control } = useFormContext();
  const { statesOptions, customLabels } = props;

  const xtraFundsWatch = useWatch({
    control,
    name: "xtrafunds",
    defaultValue: getValues("xtrafunds") || "0",
  });

  useEffect(() => {
    if (getValues("xtrafunds") === "0") {
      setValue("xfundamountInDollars", undefined);
      setValue("xfundnote", undefined);
    }
  }, [xtraFundsWatch]);

  return (
    <Container className={styles.transactionDetailContainer}>
      <CustomDivider title="Your Info" />
      <UserInfoForm />
      <CustomDivider title="Transaction Info" />
      <Container className={styles.transactionInfoContainer}>
        <DropdownFormField
          name={"curmonth"}
          label={"Funds for current month?"}
          options={boolOptions}
          required
        ></DropdownFormField>
        <DropdownFormField
          name={"rentlate"}
          label={"Paying rent late?"}
          options={boolOptions}
          required
        />
        <DropdownFormField
          name={"xtrafunds"}
          label={"Additional funds included?"}
          options={boolOptions}
          required
        />
        {getValues("xtrafunds") === "1" && (
          <>
            <InputFormField
              name="xfundamountInDollars"
              label="Additional amount"
              maxLength={13}
              defaultValue={
                getValues("xfundamountInDollars")
                  ? getValues("xfundamountInDollars")
                  : "0"
              }
              currency
              validate={(textFieldValue: string) =>
                amountIsValid(textFieldValue)
              }
              required
            />
            <InputFormField name="xfundnote" label="Explanation" required />
          </>
        )}
      </Container>
      <CustomDivider title="Property Details" />
      <PropertyForm
        statesOptions={statesOptions}
        customLabels={customLabels?.address}
      />
    </Container>
  );
};

export default RentPaymentComponent;
