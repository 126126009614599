import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  createNewCompanyAccount,
  getBankAccounts,
  getBankAccountTypes,
  getCompanyAccountDetails,
  getCompanyDetail,
  getPaymentMethods,
  getPaymentTypesForCompany,
  updateCompanyAccount,
} from "api";
import {
  DropdownOptions,
  ICompanyAccountRequest,
  ICompanyAccountResponse,
  IPaymentMethod,
} from "interfaces";
import {
  Button,
  Card,
  Container,
  Divider,
  HeaderBar,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import strings from "strings";
import styles from "./companyAccountPage.module.scss";
import {
  CheckboxFormField,
  DropdownFormField,
  InputFormField,
} from "components/hookForm";
import { AxiosResponse } from "axios";
import regex from "regex";

const CompanyAccountPage: React.FunctionComponent = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>();
  const [
    paymentMethodsAvailableForCompany,
    setPaymentMethodsAvailableForCompany,
  ] = useState<IPaymentMethod[]>();
  const [bpAccounts, setBPAccounts] = useState<DropdownOptions[]>();
  const [paymentTypes, setPaymentTypes] = useState<DropdownOptions[]>();
  const [bankAccountTypes, setBankAccountTypes] = useState<DropdownOptions[]>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { companyId, companyAccountId } = useParams();
  const navigate = useNavigate();

  const methods = useForm<ICompanyAccountRequest>({
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset, watch } = methods;
  const isAchSelected = watch("ACH Transfer", true);
  const isDigitalCheckSelected = watch("Digital Check", true);
  const watchAccountNumberEdit = watch("accountNumberEdit", true);

  const noSelectionDropdownOption: DropdownOptions[] = [
    {
      value: -1,
      label: "No Selection",
    },
  ];

  const buildPaymentMethodFormData = useCallback(
    (data: ICompanyAccountResponse) => {
      const checkedPaymentMethods = data.paymentMethods;
      if (checkedPaymentMethods) {
        for (let item of checkedPaymentMethods) {
          data[item.name] = true;
        }
      }
      return data;
    },
    []
  );

  const fetchData = useCallback(() => {
    setLoading(true);

    /* if companyAccountId and companyId present in the params 
      reset form values from the company account details response
    */
    if (companyAccountId && companyId) {
      setIsEdit(true);
      getCompanyAccountDetails(
        parseInt(companyId),
        parseInt(companyAccountId)
      ).then((res) => {
        const formData = buildPaymentMethodFormData(res?.data);
        reset(formData);
      });
    }

    if (!!companyId) {
      getCompanyDetail(companyId).then((response) =>
        setPaymentMethodsAvailableForCompany(response.data.paymentMethods)
      );

      getPaymentTypesForCompany(companyId).then((response) => {
        const paymentTypeOptions = response?.data?.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        setPaymentTypes(paymentTypeOptions);
      });
    }
    getPaymentMethods().then((response) => setPaymentMethods(response.data));
    getBankAccounts().then((response) => {
      const bpAccountsOptions = response?.data?.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setBPAccounts(bpAccountsOptions);
    });
    getBankAccountTypes()
      .then((response) => {
        const bankAccountTypeOptions = response?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setBankAccountTypes(bankAccountTypeOptions);
      })
      .finally(() => setLoading(false));
  }, [companyId, companyAccountId, buildPaymentMethodFormData, reset]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const buildRequestPayload = (data: ICompanyAccountRequest) => {
    const paymentMethodsRequest: IPaymentMethod[] = [];
    paymentMethods?.forEach((payment) => {
      if (data[payment.name]) {
        paymentMethodsRequest.push(payment);
      }
      delete data[payment.name];
    });
    if (!data.endorsement) {
      data.endorsement = "";
    }
    if (!data.endorsementRequired) {
      data.endorsementRequired = false;
    }
    if (!data.accountNumberEdit) {
      data.accountNumberEdit = false;
    }
    if (isEdit) {
      delete data.id;
    }
    if (data.bankAccountType === -1) {
      data.bankAccountType = null;
    }
    data.paymentMethods = paymentMethodsRequest;
    return data;
  };

  const navigateToCompanyAccounts = () => {
    setBtnLoader(false);
    navigate(`/settings/companies/${companyId}/accounts`);
  };

  const onSubmit = (
    companyAccountForm: ICompanyAccountRequest,
    type: string
  ) => {
    let action;
    setBtnLoader(true);
    if (!companyAccountForm.paymentMethods) {
      companyAccountForm.paymentMethods = [];
    }
    companyAccountForm = buildRequestPayload(companyAccountForm);
    switch (type) {
      case strings.CREATE:
        action = createNewCompanyAccount(companyAccountForm, companyId).then(
          (resp: AxiosResponse<ICompanyAccountResponse>) => {
            toast({
              title: "Company Account Created Successfully",
              subTitle: `Name: ${resp.data.name}\nId: ${resp.data.id}`,
            });
            navigateToCompanyAccounts();
          }
        );
        break;
      case strings.EDIT:
        if (companyAccountId) {
          action = updateCompanyAccount(
            companyAccountForm,
            companyId,
            companyAccountId
          ).then((resp: AxiosResponse<ICompanyAccountResponse>) => {
            toast({
              title: "Company Account Updated Successfully",
              subTitle: `Name: ${resp.data.name}\nId: ${resp.data.id}`,
            });
            navigateToCompanyAccounts();
          });
        }
        break;
      default:
        break;
    }
    action?.finally(() => {
      setBtnLoader(false);
    });
  };

  return (
    <Container>
      <HeaderBar
        title={`${isEdit ? strings.EDIT : strings.CREATE} ${
          strings.DELUXE_ACCOUNT
        }`}
        primaryBtnProps={{
          id: isEdit
            ? `${strings.UPDATE} ${strings.DELUXE_ACCOUNT}`
            : `${strings.CREATE} ${strings.DELUXE_ACCOUNT}`,
          label: isEdit
            ? `${strings.UPDATE} ${strings.DELUXE_ACCOUNT}`
            : `${strings.CREATE} ${strings.DELUXE_ACCOUNT}`,
          onClick: handleSubmit((data) =>
            onSubmit(data, isEdit ? strings.EDIT : strings.CREATE)
          ),
          loading: btnLoader,
        }}
      />
      <Loader loading={loading} />
      <Card>
        <TypoGraph
          variant="h2"
          sx={{ paddingTop: 1, paddingBottom: 2 }}
          content={`${
            isEdit
              ? `${strings.EDIT} Deluxe Account Details`
              : `${strings.CREATE} a Deluxe Account`
          }`}
        />
        <FormProvider {...methods}>
          <Container className={styles.textFieldContainer}>
            <Container>
              <InputFormField
                name="name"
                label={"Name"}
                maxLength={32}
                minLength={{
                  value: 1,
                  message: `Must have minimum length of 1`,
                }}
                required
              />
              <InputFormField
                name="accountNo"
                label={strings.ACCOUNT_NUMBER}
                required
                disabled={!watchAccountNumberEdit && isEdit}
                maxLength={64}
                minLength={{
                  value: 1,
                  message: `Must have minimum length of 1`,
                }}
              />
              {isEdit && (
                <CheckboxFormField
                  name="accountNumberEdit"
                  label={strings.UPDATE_ACCOUNT_NUMBER}
                />
              )}
              <InputFormField
                name="routingNumber"
                label={strings.ROUTING_NUMBER}
                pattern={{
                  value: regex.NUMERIC_ONLY_EXACT_LENGTH_9,
                  message: "Must be numeric only and size 9!",
                }}
                maxLength={9}
                required
              />
              <DropdownFormField
                name="paymentType"
                label={strings.PAYMENT_TYPE}
                options={paymentTypes}
                required
              />
              <DropdownFormField
                name="processor"
                label={strings.PROCESSOR_ACCOUNT}
                options={bpAccounts}
                required
              />
              <InputFormField
                name="depDaily"
                type="number"
                label={strings.DAILY_DEPOSITS_LIMIT}
                maxLength={10}
                min={{ value: 0, message: "Must be greater than zero" }}
                max={{
                  value: 2147483647,
                  message: "Must be less than or equal to 2147483647",
                }}
              />
              <InputFormField
                name="depItemLimit"
                type="number"
                label={strings.DEPOSITED_ITEM_LIMIT}
                maxLength={10}
                min={{ value: 0, message: "Must be greater than zero" }}
                max={{
                  value: 2147483647,
                  message: "Must be less than or equal to 2147483647",
                }}
              />
              <InputFormField
                name="depBrokerDaily"
                type="number"
                label={strings.DAILY_DEPOSITS_LIMIT_PER_BROKER}
                maxLength={10}
                min={{ value: 0, message: "Must be greater than zero" }}
                max={{
                  value: 2147483647,
                  message: "Must be less than or equal to 2147483647",
                }}
              />
              {bankAccountTypes && (
                <DropdownFormField
                  name="bankAccountType"
                  label={strings.BANK_ACCOUNT_TYPE}
                  options={
                    isAchSelected || isDigitalCheckSelected
                      ? bankAccountTypes
                      : noSelectionDropdownOption.concat(bankAccountTypes)
                  }
                  required={isAchSelected || isDigitalCheckSelected}
                />
              )}
            </Container>
          </Container>
          <Divider title="Active:" />
          <CheckboxFormField name="active" label={"Deluxe Account"} />
          <Divider title="Allowed Payment Methods" />
          {paymentMethodsAvailableForCompany &&
            paymentMethodsAvailableForCompany.map(
              (paymentMethod: IPaymentMethod) => {
                return (
                  <Container key={`key-${paymentMethod.id}`}>
                    <CheckboxFormField
                      name={paymentMethod.name}
                      label={paymentMethod.name}
                    />
                  </Container>
                );
              }
            )}

          <Divider title="Virtual Endorsement" />
          <Container className={styles.textFieldContainer}>
            <Container>
              <CheckboxFormField
                name={"endorsementRequired"}
                label={strings.REQUIRE_VIRTUAL_ENDORSEMENT}
              />
              <InputFormField
                name="endorsement"
                label={strings.ENDORSEMENT_TEXT}
                maxLength={100}
              />
            </Container>
          </Container>
          <Divider />
          <Container className={styles.actionContainer}>
            {isEdit ? (
              <>
                <Button
                  id={"updateAccountBottom"}
                  loading={btnLoader}
                  label={`${strings.UPDATE} ${strings.COMPANY_ACCOUNT}`}
                  onClick={handleSubmit((data) => onSubmit(data, strings.EDIT))}
                />
                <Button
                  id={strings.CANCEL}
                  variant="outlined"
                  label={strings.CANCEL}
                  onClick={() => navigateToCompanyAccounts()}
                />
              </>
            ) : (
              <>
                <Button
                  id={"createAccountBottom"}
                  loading={btnLoader}
                  label={`${strings.CREATE} ${strings.COMPANY_ACCOUNT}`}
                  onClick={handleSubmit((data) =>
                    onSubmit(data, strings.CREATE)
                  )}
                />
                <Button
                  id={strings.CANCEL}
                  variant="outlined"
                  label={strings.CANCEL}
                  onClick={() => navigateToCompanyAccounts()}
                />
              </>
            )}
          </Container>
        </FormProvider>
      </Card>
    </Container>
  );
};

export default CompanyAccountPage;
