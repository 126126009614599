import { Button, Container, TypoGraph } from "components";
import { IButtonProps } from "components/button";
import styles from "./header.module.scss";

interface IheaderProps {
  title?: string;
  primaryBtnProps?: IButtonProps;
  secondaryBtnProps?: IButtonProps;
}

const HeaderBar = (props: IheaderProps) => {
  const { title, primaryBtnProps, secondaryBtnProps } = props;

  return (
    <Container className={styles.headerContainer}>
      <Container>
        <TypoGraph variant="h1" mb={0}>
          {title ? title : "  "}
        </TypoGraph>
      </Container>
      <Container className={styles.headerAction}>
        {secondaryBtnProps && (
          <Button variant="outlined" {...secondaryBtnProps} />
        )}
        {primaryBtnProps && <Button variant="contained" {...primaryBtnProps} />}
      </Container>
    </Container>
  );
};

export default HeaderBar;
