import React, { ReactNode } from "react";
import { Container } from "@mui/material";

interface ReusableContainerProps {
  children: ReactNode;
}

const HotjarSuppressor: React.FC<ReusableContainerProps> = ({ children }) => {
  return <Container className={`data-hj-suppress`}>{children}</Container>;
};

export default HotjarSuppressor;
