import CircularProgress from "@mui/material/CircularProgress";
import { Card, Container } from "components";
import colors from "theme/colors";

interface ILoaderProps {
  type?: "container" | "default";
  loading?: boolean;
  containerClassName?: string;
  height?: string;
}

const Loader = (props: ILoaderProps) => {
  const {
    type = "container",
    loading,
    containerClassName,
    height = "90vh",
  } = props;
  const CircularLoader = () => {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: height,
        }}
        className={containerClassName}
      >
        <CircularProgress
          size={70}
          sx={{
            color: colors.primary,
          }}
        />
      </Container>
    );
  };

  if (!loading) {
    return <></>;
  }

  return type === "container" ? (
    <Card
      sxContainer={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 2,
      }}
      bordered={false}
    >
      <CircularLoader />
    </Card>
  ) : (
    <CircularLoader />
  );
};

export default Loader;
