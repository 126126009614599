import { connect } from "react-redux";
import { ObjectAnyKeys } from "interfaces/UtilitiesModule";
import PaymentsList from "pages/payments/paymentsList";
import { dispatchPaymentList } from "actions/payments";

function mapStateToProps(stateProps: ObjectAnyKeys) {
  const { paymentsList } = stateProps.payments || {};
  const paymentsListData = paymentsList.listData.data || [];
  return {
    paymentsListData: paymentsListData,
  };
}

function mergeProps(
  stateProps: ObjectAnyKeys,
  dispatchProps: ObjectAnyKeys,
  ownProps = {}
) {
  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
  };
}

export default connect(
  mapStateToProps,
  {
    dispatchPaymentList,
  },
  mergeProps
)(PaymentsList);
