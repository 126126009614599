import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import UsersPage from "pages/users/usersPage";
import MaintenanceReport from "pages/maintenanceReport";

const UserRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersPage />} />
      <Route path="/:userId" element={<UsersPage />} />
      <Route path="/:userId/maintenance" element={<MaintenanceReport />} />
    </Routes>
  );
};

export default UserRoutes;
