const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currencyFormatter = (value: string) => {
  return formatter.format(+value);
};

export default currencyFormatter;
