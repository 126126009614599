import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { DropdownFormField, InputFormField } from "components/hookForm";
import {
  Button,
  Container,
  Divider,
  Icon,
  SubmitDialog,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  changePasswordSelf,
  changeTwoFactorAuthSelf,
  validateTwoFactorAuthMethodChange,
} from "api";
import {
  IChangePasswordSelfRequest,
  IChangeTwoFactorAuthRequest,
  IChangeTwoFactorAuthResponse,
  ObjectStrings,
} from "interfaces";
import { passwordStrengthValidator } from "tools/validators";
import { useNavigate } from "react-router";
import BaseDialog from "components/dialog/baseDialog";
import QRCode from "react-qr-code";
import strings from "strings";
import colors from "theme/colors";
import OtpInput from "react18-input-otp";
import { twoFactorAuthAvailableMethods } from "constants/dropdownOptions";

interface IPasswordTabProps {
  styles: ObjectStrings;
  passwordStrings: ObjectStrings;
  twoFactorAuthMethod: number | undefined;
  onSuccess: () => void;
}

interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface IChange2FA {
  twoFactorAuthID: number;
}

const PasswordTab = (props: IPasswordTabProps) => {
  const { styles, passwordStrings, twoFactorAuthMethod, onSuccess } = props;
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loading2FA, setLoading2FA] = useState(false);
  const methods = useForm<IChangePassword>();
  const methods2FA = useForm<IChange2FA>({
    reValidateMode: "onChange",
  });
  const { handleSubmit: handleSubmitPassword, getValues: getValuesPassword } =
    methods;
  const { handleSubmit: handleSubmit2FA, setValue, watch } = methods2FA;
  const navigate = useNavigate();

  const [changeTwoFactorAuthResponse, setChangeTwoFactorAuthResponse] =
    useState<IChangeTwoFactorAuthResponse>();
  const [selectedTwoFactorAuthMethod, setSelectedTwoFactorAuthMethod] =
    useState<number>();
  const [uri, setUri] = useState("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openValidationDialog, setOpenValidationDialog] =
    useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [twoFactorCode, setTwoFactorCode] = useState<string>("");
  const issuer = "app.getbankshot.com";
  const multifactorStrings = strings.MULTIFACTOR_STRINGS;
  const profileStrings = strings.PROFILE_STRING;

  useEffect(() => {
    if (twoFactorAuthMethod) {
      setValue("twoFactorAuthID", twoFactorAuthMethod);
    }
  }, []);

  const onSubmitPassword = (formData: IChangePassword) => {
    setLoadingPassword(true);
    setOpenConfirmationDialog(false);
    const changePasswordRequest: IChangePasswordSelfRequest = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };
    changePasswordSelf(changePasswordRequest)
      .then(() => {
        toast({ title: "Password changed successfully!" });
        navigate("/profile");
      })
      .finally(() => setLoadingPassword(false));
  };

  const onSubmit2FA = (formData: IChange2FA) => {
    setLoading2FA(true);
    const change2FARequest: IChangeTwoFactorAuthRequest = {
      twoFactorAuthMethod: formData.twoFactorAuthID,
    };
    changeTwoFactorAuthSelf(change2FARequest)
      .then((response) => {
        const responseData = response?.data;
        setChangeTwoFactorAuthResponse(responseData);
        setSelectedTwoFactorAuthMethod(responseData.method);
        if (responseData.method === 1) {
          setOpenValidationDialog(true);
          setOpenDialog(false);
        } else {
          setUri(
            "otpauth://totp/" +
              encodeURIComponent(issuer) +
              ":" +
              encodeURIComponent(responseData.email) +
              "?secret=" +
              responseData.secret +
              "&issuer=" +
              encodeURIComponent(issuer)
          );

          setOpenDialog(true);
          setOpenValidationDialog(false);
        }
        toast({ title: "2FA changed successfully!" });
      })
      .finally(() => {
        setLoading2FA(false);
        setOpenConfirmationDialog(false);
      });
  };

  const qrCodeContainer = () => {
    return (
      <Container className={styles.multiFactorContainer}>
        <Container className={styles.multiFactorContent}>
          <TypoGraph
            variant="h6"
            content={multifactorStrings.MULTIFACTOR_AUTHENTICATION}
            align="center"
          />
          <TypoGraph
            variant="caption"
            content={multifactorStrings.OPEN_APP}
            align="center"
          />
        </Container>
        <Container className={styles.multiCredentialContainer}>
          <Container className={styles.qrCodeContainer}>
            <Container className={styles.optionTitle}>
              <Icon name="icon_qrscanner" width={30} height={35} />
              <TypoGraph
                content="Scan QR Code"
                sx={{ fontWeight: "400" }}
                align="center"
              />
            </Container>
            <Container className={styles.qrCodeCenter}>
              <QRCode
                className={styles.qrContainer}
                value={uri}
                size={200}
                id="QRCode"
              />
            </Container>
            <Container>
              <TypoGraph variant="caption" content={multifactorStrings.STEP1} />
              <TypoGraph variant="caption" content={multifactorStrings.STEP2} />
              <TypoGraph variant="caption" content={multifactorStrings.STEP3} />
            </Container>
          </Container>
          <Divider orientation="vertical" title="OR" textAlign="center" />
          <Container className={styles.accountContainer}>
            <Container
              className={`${styles.optionTitle} ${styles.accountTittle}`}
            >
              <Icon name="icon_accountkey" width={30} height={20} />
              <TypoGraph
                content="Enter Setup Key"
                sx={{ fontWeight: "400" }}
                align="center"
              />
            </Container>
            <Container className={styles.accountBordered}>
              <TypoGraph
                variant="caption"
                content={strings.ACCOUNT}
                align="center"
              />
              <TypoGraph
                content={changeTwoFactorAuthResponse?.email}
                align="center"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              />
              <TypoGraph
                variant="caption"
                content={strings.KEY}
                align="center"
              />
              <TypoGraph
                content={changeTwoFactorAuthResponse?.secret}
                align="center"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                id="SetUpKey"
              />
            </Container>
            <TypoGraph
              variant="caption"
              content={multifactorStrings.ENTER_CODE}
            />
          </Container>
        </Container>
      </Container>
    );
  };

  const twoFactorCodeValidation = () => {
    return (
      <Container className={styles.multiFactorContainer}>
        <Container className={styles.multiFactorContent}>
          <TypoGraph
            variant="h6"
            content={multifactorStrings.MULTIFACTOR_AUTHENTICATION}
            align="center"
          />
          <TypoGraph
            variant="body2"
            content={
              selectedTwoFactorAuthMethod === 1
                ? multifactorStrings.ENTER_CONFIRMATION_CODE_EMAIL
                : multifactorStrings.ENTER_CONFIRMATION_CODE
            }
            align="center"
          />
          <TypoGraph
            content={multifactorStrings.LEARN_2FA}
            color={colors.primary}
            link
            onClick={() =>
              window.open(
                "https://5066537.hs-sites.com/knowledge/what-is-dual-factor-authentication",
                "_blank"
              )
            }
          />
          <OtpInput
            value={twoFactorCode}
            onChange={(val: string) => setTwoFactorCode(val)}
            numInputs={6}
            inputStyle={styles.otpInput}
            isInputNum={selectedTwoFactorAuthMethod === 1 ? false : true}
          />
        </Container>
      </Container>
    );
  };

  return (
    <Container>
      <TypoGraph content={passwordStrings.CHANGE_PASSWORD} variant="h2" />
      <Container className={styles.chipContainer}>
        <TypoGraph
          content={passwordStrings.PASSWORD_FORMAT}
          variant="subtitle2"
        />
      </Container>
      <Container className={styles.formContainer}>
        <Container>
          <FormProvider {...methods}>
            <InputFormField
              name="oldPassword"
              type="password"
              label={passwordStrings.PASSWORD}
              required
            />
            <InputFormField
              name="newPassword"
              label={passwordStrings.NEW_PASSWORD}
              type="password"
              required
              validate={passwordStrengthValidator}
            />
            <InputFormField
              name="confirmPassword"
              label={passwordStrings.CONFIRM_PASSWORD}
              type="password"
              required
              validate={(value: any) =>
                value === getValuesPassword("newPassword") ||
                "Passwords must match."
              }
            />
            <Container className={styles.btnContainer}>
              <Button
                id={passwordStrings.CHANGE_PASSWORD}
                loading={loadingPassword}
                label={passwordStrings.CHANGE_PASSWORD}
                onClick={handleSubmitPassword(onSubmitPassword)}
              />
            </Container>
          </FormProvider>
        </Container>
      </Container>

      <TypoGraph content={passwordStrings.CHANGE_2FA} variant="h2" />
      <Container className={styles.chipContainer}>
        <TypoGraph
          content={passwordStrings.TWO_FACTOR_AUTH_FORMAT}
          variant="subtitle2"
        />
      </Container>
      <Container className={styles.formContainer}>
        <Container>
          <FormProvider {...methods2FA}>
            <DropdownFormField
              name="twoFactorAuthID"
              label="Two Factor Authentication Method"
              defaultValue={twoFactorAuthMethod}
              options={twoFactorAuthAvailableMethods}
              required
            />
            <Container className={styles.btnContainer}>
              <Button
                id={passwordStrings.CHANGE_2FA}
                loading={loading2FA}
                disabled={watch("twoFactorAuthID") == twoFactorAuthMethod}
                label={passwordStrings.CHANGE_2FA}
                onClick={() => setOpenConfirmationDialog(true)}
              />
            </Container>
          </FormProvider>
        </Container>
      </Container>
      <SubmitDialog
        type="warning"
        title={profileStrings.CONFIRMATION_TITLE_CHANGE_2FA}
        open={openConfirmationDialog}
        body1={profileStrings.CONFIRMATION_QUESTION_CHANGE_2FA}
        body2={profileStrings.CONFIRMATION_WARNING_CHANGE_2FA}
        handleClose={() => setOpenConfirmationDialog(false)}
        primaryBtnProps={{
          id: strings.CONFIRM,
          label: strings.CONFIRM,
          onClick: handleSubmit2FA(onSubmit2FA),
          loading: loading2FA,
        }}
      />

      <BaseDialog
        open={openDialog}
        handleClose={() => {}}
        showCancel={false}
        content={qrCodeContainer()}
        primaryBtnProps={{
          label: "Validate",
          onClick: () => {
            setOpenDialog(false);
            setOpenValidationDialog(true);
          },
        }}
      />
      <BaseDialog
        open={openValidationDialog}
        handleClose={() => {}}
        showCancel={false}
        content={twoFactorCodeValidation()}
        primaryBtnProps={{
          label: "Submit",
          onClick: () => {
            validateTwoFactorAuthMethodChange(twoFactorCode).then(
              (response) => {
                setOpenValidationDialog(false);
                toast({ title: response?.data?.message });
                setTwoFactorCode("");
                onSuccess();
              }
            );
          },
          disabled: twoFactorCode === "",
        }}
      />
    </Container>
  );
};

export default PasswordTab;
