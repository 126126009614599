import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ITypoGraphProps } from "components/typograph";
import { ObjectAnyKeys } from "interfaces";
import currencyFormatter from "tools/currencyFormatter";

interface IPDFDataGridColumns {
  name: string;
  label?: string;
  data?: string;
  labelTypoProps?: ITypoGraphProps;
  dataTypoProps?: ITypoGraphProps;
  messageIfEmpty?: string;
  hide?: (data: any) => boolean;
}

export interface IPDFDataGridProps {
  columns: IPDFDataGridColumns[];
  data?: ObjectAnyKeys;
  hideIfEmpty?: boolean;
  rowStyle?: ObjectAnyKeys;
}

const pdfStyles = StyleSheet.create({
  title: {
    margin: 20,
    fontSize: 15,
    textAlign: "center",
    textTransform: "uppercase",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    marginHorizontal: 20,
    marginVertical: 10,
    fontSize: 15,
  },
  sectionTitle: {
    marginLeft: 20,
    fontSize: 15,
    textAlign: "left",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
});
const PDFDatagrid = (props: IPDFDataGridProps) => {
  const { columns, data, hideIfEmpty, rowStyle } = props;
  for (const key in data) {
    columns.forEach((ele, i) => {
      if (ele.name === key) {
        columns[i].data = data[key];
      }
    });
  }
  return (
    <View style={pdfStyles.section}>
      {columns
        .filter((c) => !!c.data || !hideIfEmpty)
        .map((item, index) => {
          if (item.label === "Amount" && item.data) {
            item.data = currencyFormatter(item.data);
          }
          if (item.hide && item.hide(data)) {
            return;
          }
          return (
            <View
              key={`key-${index}`}
              style={rowStyle ? rowStyle : pdfStyles.rowContainer}
            >
              <View>{item.label && <Text>{item.label}</Text>}</View>
              {Array.isArray(item.data) ? (
                <View>
                  {item.data &&
                    item.data.map((item) => {
                      return <Text>{item}</Text>;
                    })}
                </View>
              ) : (
                <Text>
                  {item.data === "Commission Earned"
                    ? "Commission Check"
                    : item.data || item.messageIfEmpty}
                </Text>
              )}
            </View>
          );
        })}
    </View>
  );
};
export { PDFDatagrid, pdfStyles };
