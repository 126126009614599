import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
} from "react-hook-form";
import TextField, { ITextFieldProps } from "components/controllers/input";
import React, { useState } from "react";

export interface IInputFieldProps extends ITextFieldProps {
  name: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number | { value: number; message: string };
  max?: number | { value: number; message: string };
  min?: number | { value: number; message: string };
  pattern?: ValidationRule<RegExp> | undefined;
  defaultValue?: string | number | undefined;
  validate?: Validate<any> | Record<string, Validate<any>> | undefined;
  disabled?: boolean;
  type?: "password" | "number" | "text" | "numericOnly";
  hideableText?: boolean;
}
const InputFormField: React.FC<IInputFieldProps> = (props) => {
  const {
    name,
    required = false,
    maxLength,
    minLength,
    max,
    min,
    pattern,
    defaultValue,
    validate,
    currency,
    disabled,
    hideableText = false,
    type = undefined,
  } = props;
  const { control, setValue } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  const [hideText, setHideText] = useState<boolean>(hideableText);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required: isRequired,
        validate: validate,
        minLength: minLength,
        min: min,
        max: max,
        pattern: pattern,
      }}
      render={({
        field: { onChange, onBlur, value = "", ref },
        fieldState: { error },
      }) => {
        return (
          <TextField
            id={name}
            value={value ? value : ""}
            disabled={disabled}
            onChange={(e: any) => {
              currency || props.type === "number"
                ? setValue(name, e.value)
                : onChange(e);
            }}
            onBlur={() => {
              if (hideableText) {
                setHideText(true);
              }
              onBlur();
            }}
            onFocus={() => {
              setHideText(false);
            }}
            type={hideText ? "password" : type}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            {...props}
            maxLength={maxLength}
          />
        );
      }}
    />
  );
};

export default InputFormField;
