import {
  Button,
  Card,
  Container,
  Divider,
  Icon,
  Loader,
  SubmitDialog,
  TypoGraph,
  Toast as toast,
} from "components";
import strings from "strings";
import styles from "./multiplePaymentRequests.module.scss";
import colors from "theme/colors";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  ISubmitDialogProps,
  ISubmitDialogType,
} from "components/dialog/submitDialog";
import { Box, List } from "@mui/material";
import currencyFormatter from "tools/currencyFormatter";
import Decimal from "decimal.js";
import Papa from "papaparse";
import { multiplePaymentRequestsExampleColumns } from "constants/tableData";
import { getAvailableCompaniesCSV, sendMultiplePaymentRequests } from "api";
import { IMultiplePaymentRequests } from "interfaces/IMultipleRFP";
import { convertFileToBase64, downloadCSVFile } from "tools/base64";
import BaseDialog from "components/dialog/baseDialog";
import { IMultiplePaymentRequestsResponse } from "interfaces/IMultipleRFP";
import { isNullOrEmpty } from "tools/validators";
import fileDownload from "js-file-download";

interface IMultiplePaymentRequestFormProps {}

const MultiplePaymentRequests: React.FC<
  IMultiplePaymentRequestFormProps
> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [tableRows, setTableRows] = useState<[]>();
  const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
  const [showResultDialog, setShowResultDialog] = useState<boolean>(false);
  const [validDollarFormat, setValidDollarFormat] = useState<boolean>(false);
  const [action, setAction] = useState<number>(0);
  const [multiplePaymentRequestsResult, setMultiplePaymentRequestsResult] =
    useState<IMultiplePaymentRequestsResponse>();
  const RESET_ACTION = 0;
  const SUBMIT_ACTION = 1;
  const DELETE_ACTION = 2;
  const t = strings.MULTIPLE_RFP_STRING;
  const ALL_PAYMENT_TYPES = "99";
  const MULTIPLE_RFP_TEMPLATE_LINK =
    "https://bankshot-static-public-resources.s3.amazonaws.com/public/template/RFPTemplateV2.xlsx";

  const onSubmit = async () => {
    if (!attachedFile) {
      return;
    }

    const base64 = await convertFileToBase64(attachedFile);

    if (!base64) {
      return;
    }

    let formData: IMultiplePaymentRequests = {
      file: "",
    };
    setLoading(true);
    formData.file = base64?.toString()?.replace("data:text/csv;base64,", "");

    formData.file = formData.file
      ?.toString()
      ?.replace("data:application/octet-stream;base64,", "");

    sendMultiplePaymentRequests(formData)
      .catch(() => {})
      .then((response) => {
        setMultiplePaymentRequestsResult(response?.data);
        setLoading(false);
        setShowSubmitDialog(false);
        if (response?.data) {
          setShowResultDialog(true);
        } else {
          setAttachedFile(undefined);
          setTableRows([]);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (attachedFile) {
      Papa.parse(attachedFile, {
        header: true,
        complete: (result: any) => {
          const jsonData = result?.data.filter(
            (record: any) => record["Company ID"] != ""
          );
          if (!hasValidFields(jsonData)) {
            setAttachedFile(undefined);
            toast({
              type: "error",
              title: strings.FILE_UPLOAD_ERROR,
              subTitle: strings.FILE_UPLOAD_ERROR_DETAILS,
            });
            return;
          }
          const tableRowsWithIndex = jsonData.map(
            (row: object, index: number) => ({
              ...row,
              id: index + 1,
            })
          );
          setTableRows(tableRowsWithIndex);
        },
        error: (error) => {
          toast({
            type: "error",
            title: strings.FILE_UPLOAD_ERROR,
            subTitle: strings.FILE_UPLOAD_ERROR_DETAILS,
          });
        },
      });
    }
  }, [attachedFile]);

  const hasValidFields = (CSVFields: any[]): boolean => {
    if (!CSVFields || CSVFields.length < 1) {
      return false;
    }
    for (const field of CSVFields) {
      if (isNullOrEmpty(field[t.COMPANY_ID])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYMENT_TYPE])) {
        return false;
      }
      if (isNullOrEmpty(field[t.AMOUNT]) || /,/.test(field[t.AMOUNT])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYER_FIRST_NAME])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYER_LAST_NAME])) {
        return false;
      }
      if (isNullOrEmpty(field[t.PAYER_EMAIL_ADDRESS])) {
        return false;
      }
      if (isNullOrEmpty(field[t.CHECK_CAPTURE_AS_PM])) {
        return false;
      }
      if (isNullOrEmpty(field[t.SAME_DAY_ACH_AS_PM])) {
        return false;
      }
    }
    setValidDollarFormat(true);
    return true;
  };

  type ISubmissionDetails = {
    body1: string;
    body2: string;
    type: ISubmitDialogType;
  };

  function getSubmissionDetails(): ISubmissionDetails {
    let submissionDetails: ISubmissionDetails = {
      body1: "",
      body2: "",
      type: "success",
    };

    if (!tableRows) {
      return submissionDetails;
    }

    if (validDollarFormat) {
      let totalAmount = currencyFormatter(
        tableRows
          .reduce((total, item) => {
            const amount = new Decimal(item["Amount"] || 0);
            return total.plus(amount);
          }, new Decimal(0))
          .toFixed(2)
      );

      submissionDetails.body1 = `You are about to submit ${tableRows.length} Payment Requests for a total of ${totalAmount}`;
      submissionDetails.body2 = `Are you sure you'd like to submit these ${tableRows.length} Payment Requests?`;
    }

    return submissionDetails;
  }

  const downloadSummaryAsCSV = async () => {
    if (
      !multiplePaymentRequestsResult ||
      !multiplePaymentRequestsResult.paymentSummaryCSV
    ) {
      return;
    }
    downloadCSVFile(
      multiplePaymentRequestsResult.paymentSummaryCSV,
      "Payment Requests Summary.csv"
    );
  };

  const downloadCompaniesCSV = () => {
    getAvailableCompaniesCSV().then((r) => {
      fileDownload(r.data, "AvailableCompanies.csv");
      toast({
        title: "Available Companies successfully downloaded",
        autoClose: 1500,
      });
    });
  };

  const resultDialogContent = (
    <Container>
      <TypoGraph
        variant="h3"
        content={`You just sent ${multiplePaymentRequestsResult?.multipleRequestForPayments.length} Payment Requests, here is the summary of each one of them:`}
      ></TypoGraph>

      <List>
        {multiplePaymentRequestsResult?.multipleRequestForPayments.map(
          (paymentRequest, index) => {
            return paymentRequest.success ? (
              <Container>
                <TypoGraph content={`Row ${index + 2}`}></TypoGraph>
                <TypoGraph content={"Sent"} color={colors.success}></TypoGraph>
                <TypoGraph content={paymentRequest.message}></TypoGraph>
                <TypoGraph
                  content={`Payment Request ID: ${paymentRequest.id.toString()}`}
                ></TypoGraph>

                <Divider></Divider>
              </Container>
            ) : (
              <Container>
                <TypoGraph content={`Row ${index + 2}`}></TypoGraph>
                <TypoGraph
                  content={"Failed to send"}
                  color={colors.error}
                ></TypoGraph>
                <TypoGraph content={paymentRequest.message}></TypoGraph>
                <Divider></Divider>
              </Container>
            );
          }
        )}
      </List>
    </Container>
  );

  const submitDialogProps: ISubmitDialogProps[] = [
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: strings.RESET_DATA,
      type: "delete",
      body1: "Reseting the data will also remove the uploaded files",
      body2: "Are you sure you want to reset the data?",
      primaryBtnProps: {
        label: strings.RESET_DATA,
        onClick: () => {
          setAttachedFile(undefined);
          setShowSubmitDialog(false);
          toast({
            title: "Data was Reseted Succesfully",
            autoClose: 2500,
          });
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: "Payment Requests Summary",
      type: getSubmissionDetails().type,
      body1: getSubmissionDetails().body1,
      body2: getSubmissionDetails().body2,
      primaryBtnProps: {
        label: "Submit Payment Requests",
        onClick: () => {
          onSubmit();
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
    {
      open: showSubmitDialog,
      handleClose: () => setShowSubmitDialog(false),
      title: "Delete File",
      type: "delete",
      body1: "Are you sure you want to delete this file",
      primaryBtnProps: {
        label: "Delete File",
        onClick: () => {
          setAttachedFile(undefined);
          setShowSubmitDialog(false);
        },
      },
      secondaryBtnProps: {
        onClick: () => {
          setShowSubmitDialog(false);
        },
      },
    },
  ];

  const TemplateInstructions = () => {
    return (
      <Container className={styles.instructionsContainer}>
        <Container className={styles.iconInfoContainer}>
          <Icon
            name="icon_info_filled"
            iconClassName={styles.iconInfo}
            size={25}
            color={colors.primary}
          />
        </Container>
        <Container className={styles.instructionsText}>
          <TypoGraph
            content={strings.DOWNLOAD_TEMPLATE_FOR_MULTIPLE_PAYMENT_REQUESTS}
          />
          <TypoGraph
            variant="body1"
            content={"Download Template"}
            onClick={() => {
              window.open(MULTIPLE_RFP_TEMPLATE_LINK, "_blank");
            }}
            sx={{ width: "30%" }}
            link
            color={colors.primary}
          />
        </Container>
      </Container>
    );
  };

  const TableData = () => {
    return (
      <Container>
        <Container>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={tableRows ? tableRows : []}
              columns={multiplePaymentRequestsExampleColumns}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
            />
          </Box>
        </Container>
      </Container>
    );
  };

  const ConditionsList = () => {
    return (
      <Container className={styles.conditionsList}>
        <ul>
          <li>
            <TypoGraph
              variant="body2"
              color="#212121"
              content="Please do not remove the title row (row #1) from your CSV"
            ></TypoGraph>
          </li>
          <li>
            <TypoGraph
              variant="body2"
              color="#212121"
              content="Amount must be on dollars format, with or without cents (no commas needed) "
            ></TypoGraph>
          </li>
          <li>
            <TypoGraph
              variant="body2"
              color="#212121"
              content="Date must be on format YYYY-MM-DD or YYYY/MM/DD, example: 2024-06-31 or 2024/06/31 "
            ></TypoGraph>
          </li>

          <li>
            <Container sx={{ display: "flex" }}>
              <TypoGraph
                variant="body2"
                color="#212121"
                sx={{ "margin-right": "5px" }}
                content={`If you do not know a company ID, you can find it in this file: `}
              />
              <TypoGraph
                variant="body2"
                color={colors.primary}
                content={"Available Companies"}
                onClick={() => {
                  downloadCompaniesCSV();
                }}
                sx={{ width: "30%", "font-weight": "bold" }}
                link
              />
            </Container>
          </li>
        </ul>
      </Container>
    );
  };

  const Footer = () => {
    return (
      <Container className={styles.footerContainer}>
        <Button
          variant="outlined"
          label="Reset Data"
          fullWidth={false}
          disabled={!attachedFile}
          onClick={() => {
            setAction(RESET_ACTION);
            setShowSubmitDialog(true);
          }}
        />
        <Button
          label="Submit Requests for Payments"
          fullWidth={false}
          className={styles.submitBtn}
          disabled={!attachedFile}
          onClick={() => {
            setAction(SUBMIT_ACTION);
            setShowSubmitDialog(true);
          }}
        />
      </Container>
    );
  };

  const FileUpload = () => {
    return (
      <Container className={styles.formContainer}>
        <Container className={styles.fileUploadContainer}>
          {attachedFile && (
            <Container key={attachedFile?.name} className={styles.filePreview}>
              <Container className={styles.fileContainer}>
                <Container className={styles.fileNameContainer}>
                  <Icon
                    boxClassName={styles.fileUploadIcon}
                    name="icon_uploadSuccess"
                  />
                  <TypoGraph
                    variant="caption"
                    content={attachedFile?.name}
                    className={styles.text}
                  />
                  <Container className={styles.cancelIcon}>
                    <Icon
                      name="icon_filecancel"
                      iconButton
                      iconClassName={styles.fileCancelIcon}
                      onClick={() => {
                        setAction(DELETE_ACTION);
                        setShowSubmitDialog(true);
                      }}
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          )}
          {!attachedFile && (
            <Container>
              <Dropzone
                accept={{ "text/csv": [] }}
                multiple={false}
                onDrop={(file) => setAttachedFile(file[0])}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className={styles.dropzoneContainer}>
                    <div
                      {...getRootProps({
                        className: "dropzone",
                        onDrop: (event) => event.stopPropagation(),
                      })}
                    >
                      <Container className={styles.fileDropzone}>
                        <Icon name="icon_fileupload_v2" size={120} />
                        <TypoGraph
                          content={"Attach CSV File"}
                          color="#212121"
                        />
                        <TypoGraph
                          content={
                            "Drag And Drop Files from your computer, or click to Upload"
                          }
                          variant="body2"
                          color="#212121"
                        />
                      </Container>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Container>
          )}
        </Container>
      </Container>
    );
  };

  return (
    <Container>
      <BaseDialog
        open={loading}
        handleClose={() => {}}
        showCancel={false}
        content={
          <Container>
            <TypoGraph content={strings.SENDING_PAYMENT_REQUESTS} />
            <Loader loading={loading} type="default" height="30vh" />
          </Container>
        }
      />
      <Card>
        <Container className={styles.headerContainer}>
          <TypoGraph
            variant="h2"
            sx={{ paddingTop: 1, paddingBottom: 0 }}
            content={strings.SEND_MULTIPLE_PAYMENT_REQUEST}
          />

          <Icon
            imageId="closeBlade"
            name="icon_cancel"
            size={16}
            iconButton
            onClick={() => navigate("/payments/" + ALL_PAYMENT_TYPES)}
          />
        </Container>
        <Divider sx={{ paddingBottom: 1 }} />
        <TemplateInstructions />
        <FileUpload />
        {!attachedFile ? <ConditionsList /> : <TableData></TableData>}

        <Divider sx={{ paddingBottom: 1 }} />
        <Footer></Footer>
        <SubmitDialog {...submitDialogProps[action]} />
      </Card>

      <BaseDialog
        title="Payment Result Summary"
        open={showResultDialog}
        content={resultDialogContent}
        handleClose={() => {}}
        primaryBtnProps={{
          label: "Accept",
          onClick: () => {
            const successfulPaymentRequest =
              multiplePaymentRequestsResult?.multipleRequestForPayments.filter(
                (paymentRequest) => paymentRequest.success
              ).length ?? 0;
            navigate("/payments/" + ALL_PAYMENT_TYPES);
            if (successfulPaymentRequest > 0) {
              toast({
                title:
                  successfulPaymentRequest == 1
                    ? `${successfulPaymentRequest} Payment Requests submitted successfully`
                    : `${successfulPaymentRequest} Payment Requests submitted successfully`,
              });
            } else {
              toast({
                type: "error",
                title: `${successfulPaymentRequest} Payment Requests were submitted`,
              });
            }
          },
        }}
        secondaryBtnProps={{
          label: "Download Summary as CSV",
          onClick: () => downloadSummaryAsCSV(),
        }}
      />
    </Container>
  );
};

export default MultiplePaymentRequests;
