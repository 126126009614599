import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import { Actions, Resources } from "tools/privilegeChecker";
import ProtectedRoute from "./ProtectedRoute";
import { PrivDependantRoute } from "./PrivDependantRoute";
import { Settings } from "pages/settings";
import UserRoutes from "./userRoutes";
import CompaniesRoutes from "./companiesRoutes";
import DisbursementAccountRoutes from "./disbursementAccountRoutes";

interface ISettingsRouteProps extends RouteProps {
  isAuth: boolean;
  userPriv: number;
}

const SettingsRoutes: React.FunctionComponent<ISettingsRouteProps> = (
  props
) => {
  const { isAuth, userPriv } = props;
  return (
    <Routes>
      <Route path="/" element={<Settings />} />
      <Route
        path="/users/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{ action: Actions.WRITE, resource: Resources.USER }}
            >
              <UserRoutes />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/companies/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{
                action: Actions.READ,
                resource: Resources.COMPANY,
              }}
            >
              <CompaniesRoutes userPriv={userPriv} />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/disbursementAccounts/*"
        element={
          <PrivDependantRoute
            requirements={[
              {
                action: Actions.READ,
                resource: Resources.DISBURSEMENT_ACCOUNTS,
              },
              {
                action: Actions.READ,
                resource: Resources.RECEIVABLES_ACCOUNTS,
              },
            ]}
          >
            <DisbursementAccountRoutes />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default SettingsRoutes;
