import EndorseEditor from "components/endorse";
import { Container, Loader } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { IPaymentImage } from "interfaces/IPaymentImageResponse";
import { IPayment, EndorsementPositionRequest } from "interfaces";
import { getCompanyAccountDetails, getPaymentImage } from "api";

export interface IStateProps {
  payment: IPayment | undefined;
  backImage: IPaymentImage | undefined;
  frontImage: IPaymentImage | undefined;
  refresh?: boolean;
  imagesCropped?: boolean;
  accountId?: any;
  companyId?: any;
  onSuccessCallback?: (params: any) => void;
  setEndorsement?: (params: any) => void;
  isEndorseRequired?: (params: any) => void;
  isRotated?: (params: boolean) => void;
}

const Endorsement: React.FunctionComponent<IStateProps> = (props) => {
  const {
    payment,
    backImage,
    refresh,
    accountId,
    companyId,
    imagesCropped,
    onSuccessCallback,
    setEndorsement,
    isEndorseRequired,
    isRotated,
  } = props;

  const [endorsementText, setEndorsementText] = useState("");
  const [loading, setLoading] = useState(true);
  const [backImageToEdit, setBackImagetoEdit] = useState(backImage);
  const [base64Image, setBase64Image] = useState(
    `data:image/png;base64,${backImage?.base64Image}`
  );

  const loadCompanyAccountDetails = useCallback(() => {
    setLoading(true);
    if (!payment?.accountId) {
      if (!accountId) {
        setLoading(false);
        return;
      }
    }
    getCompanyAccountDetails(
      companyId || payment?.companyId,
      accountId || payment?.accountId
    )
      .then((companyAccount) => {
        setEndorsementText(
          `${companyAccount.data.endorsement}\n${payment?.companyName}\n${companyAccount.data.accountNo}`
        );
        isEndorseRequired?.(companyAccount.data.endorsementRequired);
      })
      .finally(() => {
        if (!imagesCropped) {
          setLoading(false);
        }
      });

    if (imagesCropped) {
      getPaymentImage(payment!.id)
        .then((image) => {
          if (image) {
            const back = image.data.images
              ?.filter((i) => i.imageIndex === "BACK")
              .pop();
            if (back) {
              setBackImagetoEdit(back);
              setBase64Image(`data:image/png;base64,${back.base64Image}`);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [payment]);

  useEffect(() => {
    loadCompanyAccountDetails();
  }, [loadCompanyAccountDetails]); // executes api call once component receives payment

  return (
    <Container>
      {loading ? (
        <Loader loading={loading} type={"default"} height="35vh"></Loader>
      ) : (
        <EndorseEditor
          refresh={refresh}
          endorsementText={endorsementText}
          base64Image={base64Image}
          backImage={backImageToEdit}
          imageId={backImage?.id || 0}
          accountId={accountId || payment?.accountId}
          onSuccessCallback={(params: EndorsementPositionRequest) =>
            onSuccessCallback?.(params)
          }
          isRotated={(params: boolean) => isRotated?.(params)}
          setEndorsement={(params: any) => setEndorsement?.(params)}
        />
      )}
    </Container>
  );
};

export default Endorsement;
