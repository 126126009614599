import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import PaymentsListContainer from "containers/paymentsContainer";
import PaymentsCategory from "pages/payments/paymentsCategory";
import MultiplePaymentRequests from "pages/payments/multiplePaymentRequest/multiplePaymentRequests";

const PaymentsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentsCategory />} />
      <Route path="/:typeId" element={<PaymentsListContainer />} />
      <Route
        path="/:typeId/detail/:paymentId"
        element={<PaymentsListContainer />}
      />

      <Route
        path="/multiple-payment-request"
        element={<MultiplePaymentRequests />}
      />
    </Routes>
  );
};

export default PaymentsRoutes;
