const BreadcrumbList = {
  payments: {
    "/payments": "Receivables",
    "/payments/:typeId": "getPaymentTypeName",
    "/payments/:typeId/detail/:paymentId": (params: any) => params.paymentId,
  },
  settings: {
    "/settings": "Settings",
    //USERS SETTINGS
    "/settings/users": "Users",
    "/settings/users/:userId": "getUserName",
    "/settings/users/:userId/maintenance": "Maintenance report",
    //COMPANIES SETTINGS
    "/settings/companies": "All Companies",
    "/settings/companies/create": "Create",
    "/settings/companies/:companyId": "getCompanyName",
    "/settings/companies/:companyId/edit": "Edit",
    "/settings/companies/:companyId/reports/*": `Reports`,
    "/settings/companies/:companyId/maintenance": "Maintenance reports",
    "/settings/companies/:companyId/accounts": "Deluxe accounts",
    "/settings/companies/:companyId/accounts/new": "Create",
    "/settings/companies/:companyId/accounts/:companyAccountId":
      "getAccountName",
    "/settings/companies/:companyId/accounts/:companyAccountId/maintenance":
      "Maintenance reports",
    "/settings/companies/:companyId/accounts/:companyAccountId/edit": "Edit",
    //DISBURSEMENT ACCOUNTS
    "/settings/disbursementAccounts": "Company Accounts",
    "/settings/disbursementAccounts/new": "Create",
    "/settings/disbursementAccounts/:disbursementAccountId/maintenance":
      "Maintenance reports",
    // MY BILLING ACCOUNTS
    "/settings/mybilling": "My billing",
    "/settings/mybilling/:billingAccountId": "getMyBillingAccountName",
    "/settings/mybilling/:billingAccountId/invoices": "Invoices",
    // BILLING ACCOUNTS
    "/settings/billingaccounts": "Billing Accounts",
    "/settings/billingaccounts/create": "Create",
    "/settings/billingaccounts/:billingAccountId": "getBillingAccountName",
    "/settings/billingaccounts/:billingAccountId/edit": "Edit",
  },
};

/** ***** Breadcrumb Logic *****
 * Switch statement to determine which breadcrumbs are to display on the pages
 */
const renderBreadcrumbs = (path: string) => {
  let breadcrumbs = null;
  switch (true) {
    case /\/payments/.test(path):
      breadcrumbs = BreadcrumbList.payments;
      break;
    case /\/settings/.test(path):
      breadcrumbs = BreadcrumbList.settings;
      break;
    default:
      break;
  }
  return breadcrumbs;
};

export default renderBreadcrumbs;
