import React, { useEffect, useState } from "react";
import styles from "./mobileNavBar.module.scss";
import { Icon } from "components";
import { INavBarActionItem } from "interfaces/IWhitelabelConfigurationResponse";

interface MobileNavProps {
  items?: INavBarActionItem[];
  logoBase64?: string;
  onClickMenu: () => void;
  isMenuOpen: boolean;
  logoSize?: number;
}

const MobileNavBar: React.FC<MobileNavProps> = ({
  items,
  logoBase64,
  onClickMenu,
  isMenuOpen,
  logoSize,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const configHasItems = items && items.length > 0;

  const controlNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  return (
    <div
      className={`${
        configHasItems ? styles.navbar : styles.navbarWithoutItems
      } ${isScrolled ? styles.fadeEffect : ""}`}
    >
      {configHasItems && (
        <div className={styles.leftAlignedMenu}>
          <Icon name="icon_menu" size={22} onClick={onClickMenu} />
        </div>
      )}
      {!isMenuOpen && (
        <div className={styles.centeredLogo}>
          <Icon
            type="images"
            url={`data:image/png;base64,${logoBase64}`}
            height={logoSize || 45}
            alt={"vector_images"}
          />
        </div>
      )}
    </div>
  );
};

export default MobileNavBar;
