import { Button, Card, Container, Icon, TypoGraph } from "components";
import { ObjectStrings } from "interfaces";
import { changelogHtmlString, transmittingData } from "constants/changelogData";
import colors from "theme/colors";
import strings from "strings";
import { marketingLink } from "constants/marketingLink";

interface IChangelogProps {
  styles: ObjectStrings;
}

interface IMarketingData {
  data: ObjectStrings[];
  styles: ObjectStrings;
}

const MarketingData = (props: IMarketingData) => {
  const { data, styles } = props;
  return (
    <>
      {data.map((item, index) => (
        <Container key={`key-${index}`} className={styles.transmittingContent}>
          <TypoGraph
            content={item.count}
            variant="h1"
            align="center"
            color={colors.white}
          />
          <Icon name={item.icon} size={25} />
          <TypoGraph
            content={item.name}
            variant="h3"
            align="center"
            color={colors.white}
          />
        </Container>
      ))}
    </>
  );
};

const MarketingChangelog = (props: IChangelogProps) => {
  const { styles } = props;

  return (
    <Container className={styles.marketingContainer}>
      <Container className={styles.transmittingContainer}>
        <MarketingData data={transmittingData} styles={styles} />
      </Container>
      <Container className={styles.bgIconContainer}>
        <Icon name="icon_bg_logo" width={500} height={330} color="#d4d2e64d" />
      </Container>
      <Container>
        <Card
          sxContainer={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.82) 15.22%, rgba(255, 255, 255, 0.5658) 100%)",
            backdropFilter: "blur(28.5px)",
            overflowY: "scroll",
          }}
        >
          <Icon
            name="icon_b_logo_primary"
            size={40}
            hover
            onClick={() => window.open(marketingLink, "_blank")}
          />
          <Container
            innerHtml={{
              __html: changelogHtmlString,
            }}
          />
        </Card>
      </Container>
      <Container className={styles.blogContainer}>
        <Button
          id={strings.READ_BLOG}
          variant="outlined"
          label={strings.READ_BLOG}
          className={styles.blogButton}
          onClick={() =>
            window.open("https://getbankshot.com/resources/", "_blank")
          }
          startIcon="icon_blog"
        />
        <TypoGraph
          variant="caption"
          content="version prod-2024-11-05"
          align="center"
          color={colors.white}
        />
      </Container>
    </Container>
  );
};

export default MarketingChangelog;
