import { Container, TypoGraph } from "../index";
import { IPaymentMethod } from "../../interfaces";
import { CheckboxFormField } from "../hookForm";
import React from "react";
import styles from "./paymentMethodSelection.module.scss";

interface PaymentMethodSelectionProps {
  availablePaymentMethods?: IPaymentMethod[];
}

const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = (
  props
) => {
  const { availablePaymentMethods } = props;

  return (
    <Container>
      <TypoGraph
        content={
          !availablePaymentMethods
            ? "Select a company to see the available payment methods."
            : availablePaymentMethods.length === 0
            ? "There are no available Payment Methods for this company."
            : "Choose accepted payment methods for this transaction."
        }
      />
      {availablePaymentMethods &&
        availablePaymentMethods.map((paymentMethod: IPaymentMethod) => {
          return (
            <Container key={`key-${paymentMethod.id}`}>
              <CheckboxFormField
                name={paymentMethod.name}
                label={paymentMethod.name}
                labelPlacement={"start"}
                checkboxStyle={styles.paymentMethodCheckBox}
              />
            </Container>
          );
        })}
    </Container>
  );
};

export default PaymentMethodSelection;
