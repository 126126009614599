import { DropdownOptions } from "interfaces";

export const months: DropdownOptions[] = [
  {
    value: 1,
    label: "01",
  },
  {
    value: 2,
    label: "02",
  },
  {
    value: 3,
    label: "03",
  },
  {
    value: 4,
    label: "04",
  },
  {
    value: 5,
    label: "05",
  },
  {
    value: 6,
    label: "06",
  },
  {
    value: 7,
    label: "07",
  },

  {
    value: 8,
    label: "08",
  },
  {
    value: 9,
    label: "09",
  },

  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
];

export function availableYears() {
  let availableYears: DropdownOptions[] = [];
  let year = new Date().getFullYear();

  while (year >= 2015) {
    availableYears.push({ value: year, label: year });
    year--;
  }

  return availableYears;
}

export function nextTenYears() {
  let availableYears: DropdownOptions[] = [];
  let year = new Date().getFullYear();
  const yearsPlusTen = year + 10;

  while (year <= yearsPlusTen) {
    availableYears.push({ value: year, label: year });
    year++;
  }

  return availableYears;
}
