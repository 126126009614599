import React, { useCallback, useEffect, useState } from "react";
import {
  Blade,
  Button,
  Container,
  CustomDivider,
  Icon,
  Toast as toast,
  TypoGraph,
} from "components";
import styles from "./receivableAccountBlade.module.scss";
import strings from "strings";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  createNewReceivableAccount,
  getCompaniesForUser,
  getExternalDepositMethodsForCompany,
  getPaymentMethodsForReceivableAccountsByCompanyId,
  getPaymentTypesForCompany,
  updateReceivableAccount,
} from "api";
import { DropdownOptions, IPaymentMethod, IPaymentType } from "interfaces";
import {
  CheckboxFormField,
  DropdownFormField,
  InputFormField,
} from "components/hookForm";
import {
  IReceivableAccountRequest,
  IReceivableAccountResponse,
  IReceivableAccountUpdateRequest,
} from "interfaces/IDisbursementAccountModel";
import colors from "theme/colors";
import { amountIsValid } from "tools/validators";
import { AxiosResponse } from "axios";
import { paymentMethods } from "constants/paymentMethods";

interface IReceivableAccountBladeForm {
  showBlade: boolean;
  handleClose: () => void;
  handleDataChange: () => void;
  receivableAccountData?: IReceivableAccountResponse;
}

const ReceivableAccountBladeForm: React.FC<IReceivableAccountBladeForm> = ({
  showBlade = false,
  handleClose,
  handleDataChange,
  receivableAccountData,
}) => {
  const methods = useForm<IReceivableAccountRequest>({
    reValidateMode: "onChange",
  });
  const { handleSubmit, watch, reset, control, getValues, setValue } = methods;
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>(
    []
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null
  );
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<IPaymentType[]>(
    []
  );
  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState<
    IPaymentMethod[]
  >([]);
  const [externalDepositMethodsOptions, setExternalDepositMethodsOptions] =
    useState<IPaymentMethod[]>([]);
  const [receivableAccountDetailTab, setReceivableAccountDetailTab] =
    useState<number>(0);
  const [externalDepositMethodRequired, setExternalDepositMethodRequired] =
    useState<boolean>(false);
  const [paymentMethodRequired, setPaymentMethodRequired] =
    useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const t = strings.DISBURSEMENT_ACCOUNT_STRING;

  const { fields: fieldsPaymentMethods } = useFieldArray({
    control,
    name: "paymentMethods",
  });

  const { fields: fieldsPaymentTypes } = useFieldArray({
    control,
    name: "paymentTypes",
  });

  const { fields: fieldsExternalDepositMethods } = useFieldArray({
    control,
    name: "externalDepositPaymentMethods",
  });

  const buildPaymentMethodFormData = useCallback(
    (data: IReceivableAccountResponse, paymentMethods: IPaymentMethod[]) => {
      data.paymentMethods = paymentMethods.map((paymentMethod) => {
        paymentMethod.active = !!data.paymentMethods.find(
          (paymentMethodFromCompany) =>
            paymentMethodFromCompany.id === paymentMethod.id
        );
        return paymentMethod;
      });

      return data;
    },
    []
  );

  const buildExternalDepositMethodFormData = useCallback(
    (
      data: IReceivableAccountResponse,
      externalDepositMethods: IPaymentMethod[]
    ) => {
      if (!data.externalDepositPaymentMethods) {
        data.externalDepositPaymentMethods = [];
      }
      data.externalDepositPaymentMethods = externalDepositMethods.map(
        (externalDepositMethod) => {
          externalDepositMethod.active =
            !!data.externalDepositPaymentMethods.find(
              (externalDepositMethodFromCompany) =>
                externalDepositMethodFromCompany.id === externalDepositMethod.id
            );
          return externalDepositMethod;
        }
      );

      return data;
    },
    []
  );

  const buildPaymentTypeFormData = useCallback(
    (data: IReceivableAccountResponse, paymentTypes: IPaymentType[]) => {
      data.paymentTypes = paymentTypes.map((paymentType) => {
        paymentType.active = !!data.paymentTypes.find(
          (paymentTypeFromCompany) =>
            paymentTypeFromCompany.id === paymentType.id
        );
        return paymentType;
      });
      return data;
    },
    []
  );

  const getCompanies = useCallback(() => {
    getCompaniesForUser().then((response) => {
      const companyOptions = response?.data?.map((item) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      });
      setCompaniesOptions(companyOptions);
    });
  }, []);

  const getPaymentMethodsData = useCallback(() => {
    if (!selectedCompanyId) {
      return;
    }
    getPaymentMethodsForReceivableAccountsByCompanyId(
      parseInt(selectedCompanyId)
    ).then((response) => {
      const paymentMethodsOptions = response?.data.map(
        (item: IPaymentMethod) => ({
          id: item.id,
          name: item.name,
        })
      );

      setPaymentMethodsOptions(paymentMethodsOptions);
    });
  }, [selectedCompanyId]);

  const getPaymentTypeData = useCallback(() => {
    if (!selectedCompanyId) {
      return;
    }
    getPaymentTypesForCompany(selectedCompanyId).then((response) => {
      const paymentTypeOptions =
        response?.data.map((item: IPaymentType) => ({
          id: item.id,
          name: item.name,
        })) || [];
      setPaymentTypeOptions(paymentTypeOptions);
    });
  }, [selectedCompanyId]);

  const getExternalDepositMethodsData = useCallback(() => {
    if (!selectedCompanyId) {
      return;
    }
    getExternalDepositMethodsForCompany(selectedCompanyId).then((response) => {
      const externalDepositMethodsOptions =
        response?.data.map((item: IPaymentMethod) => ({
          id: item.id,
          name: item.name,
        })) || [];
      setExternalDepositMethodsOptions(externalDepositMethodsOptions);
    });
  }, [selectedCompanyId]);

  const handleNextClick = () => {
    setReceivableAccountDetailTab(1);
  };

  const handleSubmitReceivableAccount = (
    formData: IReceivableAccountRequest
  ) => {
    if (isEdit) {
      editReceivableAccount(formData);
    } else {
      createReceivableAccount(formData);
    }
  };

  const editReceivableAccount = (formData: IReceivableAccountRequest) => {
    if (!receivableAccountData) {
      return;
    }
    setButtonLoader(true);
    let paymentMethodsRequest: IPaymentMethod[] =
      formData.paymentMethods.filter((paymentMethod) => paymentMethod.active);
    paymentMethodsRequest.forEach(
      (paymentMethod) => delete paymentMethod.active
    );
    formData.paymentMethods = paymentMethodsRequest;

    let externalDepositPaymentMethodsRequest: IPaymentMethod[] =
      formData.externalDepositPaymentMethods.filter(
        (paymentMethod) => paymentMethod.active
      );
    externalDepositPaymentMethodsRequest.map(
      (paymentMethod) => delete paymentMethod.active
    );
    formData.externalDepositPaymentMethods =
      externalDepositPaymentMethodsRequest;

    let paymentTypesRequest: IPaymentType[] = formData.paymentTypes.filter(
      (paymentType) => paymentType.active
    );
    paymentTypesRequest.forEach((paymentType) => delete paymentType.active);
    formData.paymentTypes = paymentTypesRequest;

    const methodsSelectedValid = validateSelectedMethods(formData);

    if (!methodsSelectedValid) {
      setButtonLoader(false);
      return;
    }

    let formDataUpdate: IReceivableAccountUpdateRequest = {
      companyId: formData.companyId,
      accountName: formData.accountName,
      paymentTypes: formData.paymentTypes,
      paymentMethods: formData.paymentMethods,
      externalDepositPaymentMethods: formData.externalDepositPaymentMethods,
      dailyDepositLimit: formData.dailyDepositLimit,
      depositedItemLimit: formData.depositedItemLimit,
      dailyDepositLimitPerBroker: formData.dailyDepositLimitPerBroker,
      active: receivableAccountData?.active,
    };

    updateReceivableAccount(formDataUpdate, receivableAccountData.id)
      .then((resp: AxiosResponse<IReceivableAccountResponse>) => {
        handleClose();
        handleDataChange();
        toast({
          title: "Receivable Account Updated Successfully",
          subTitle: `Name: ${resp.data.accountName}`,
        });
        reset({});
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  const createReceivableAccount = (formData: IReceivableAccountRequest) => {
    if (!selectedCompanyId) {
      return;
    }
    setButtonLoader(true);
    let paymentMethodsRequest: IPaymentMethod[] =
      formData.paymentMethods.filter((paymentMethod) => paymentMethod.active);
    paymentMethodsRequest.forEach(
      (paymentMethod) => delete paymentMethod.active
    );
    formData.paymentMethods = paymentMethodsRequest;

    let paymentTypesRequest: IPaymentType[] = formData.paymentTypes.filter(
      (paymentType) => paymentType.active
    );
    paymentTypesRequest.forEach((paymentType) => delete paymentType.active);
    formData.paymentTypes = paymentTypesRequest;

    formData.companyId = selectedCompanyId;
    formData.accountName = formData.accountName || "";
    formData.financialInstitution = formData.financialInstitution || "";
    formData.accountNumber = formData.accountNumber || "";
    formData.routingNumber = formData.routingNumber || 0;
    formData.dailyDepositLimit = formData.dailyDepositLimit || 0;
    formData.depositedItemLimit = formData.depositedItemLimit || 0;
    formData.dailyDepositLimitPerBroker =
      formData.dailyDepositLimitPerBroker || 0;

    let externalDepositPaymentMethodsRequest: IPaymentMethod[] =
      formData.externalDepositPaymentMethods.filter(
        (paymentMethod) => paymentMethod.active
      );
    externalDepositPaymentMethodsRequest.map(
      (paymentMethod) => delete paymentMethod.active
    );
    formData.externalDepositPaymentMethods =
      externalDepositPaymentMethodsRequest;

    const methodsSelectedValid = validateSelectedMethods(formData);

    if (!methodsSelectedValid) {
      setButtonLoader(false);
      return;
    }

    createNewReceivableAccount(formData)
      .then((resp: AxiosResponse<IReceivableAccountResponse>) => {
        handleClose();
        handleDataChange();
        toast({
          title: "Receivable Account Created Successfully",
          subTitle: `Name: ${resp.data.accountName}`,
        });
        reset({});
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  function validateSelectedMethods(formData: IReceivableAccountRequest) {
    const isExternalDepositMethodSelected =
      formData.externalDepositPaymentMethods.length === 0;
    const isPaymentMethodSelected = formData.paymentMethods.length === 0;

    setExternalDepositMethodRequired(isExternalDepositMethodSelected);
    setPaymentMethodRequired(isPaymentMethodSelected);

    return !isExternalDepositMethodSelected && !isPaymentMethodSelected;
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (receivableAccountData) {
      setIsEdit(true);
      receivableAccountData.abaRoutingNumber =
        receivableAccountData.routingNumber;
      receivableAccountData.accountNumber =
        receivableAccountData.maskedAccountNumber;
      setSelectedCompanyId(receivableAccountData.companyId);
    } else {
      setIsEdit(false);
      setPaymentMethodsOptions([]);
      setPaymentTypeOptions([]);
      setExternalDepositMethodsOptions([]);
      setPaymentMethodRequired(false);
      setExternalDepositMethodRequired(false);
      reset({});
      setSelectedCompanyId(null);
    }
  }, [receivableAccountData]);

  useEffect(() => {
    if (receivableAccountData && paymentMethodsOptions) {
      const formData = buildPaymentMethodFormData(
        receivableAccountData,
        paymentMethodsOptions
      );
      reset(formData);
    } else if (paymentMethodsOptions) {
      const formData = getValues();
      formData.paymentMethods = paymentMethodsOptions.map((paymentMethod) => {
        paymentMethod.active = false;
        return paymentMethod;
      });

      reset(formData);
    }
  }, [paymentMethodsOptions, buildPaymentMethodFormData, reset, getValues]);

  useEffect(() => {
    if (receivableAccountData && externalDepositMethodsOptions) {
      const formData = buildExternalDepositMethodFormData(
        receivableAccountData,
        externalDepositMethodsOptions
      );
      reset(formData);
    } else if (externalDepositMethodsOptions) {
      const formData = getValues();
      formData.externalDepositPaymentMethods =
        externalDepositMethodsOptions.map((externalDepositMethod) => {
          externalDepositMethod.active = false;
          return externalDepositMethod;
        });
      reset(formData);
    }
  }, [
    externalDepositMethodsOptions,
    buildExternalDepositMethodFormData,
    reset,
    getValues,
  ]);

  useEffect(() => {
    if (receivableAccountData && paymentTypeOptions) {
      const formData = buildPaymentTypeFormData(
        receivableAccountData,
        paymentTypeOptions
      );
      reset(formData);
    } else if (paymentTypeOptions) {
      const formData = getValues();
      formData.paymentTypes = paymentTypeOptions.map((paymentType) => {
        paymentType.active = false;
        return paymentType;
      });
      reset(formData);
    }
  }, [paymentTypeOptions, buildPaymentTypeFormData, reset, getValues]);

  useEffect(() => {
    getPaymentTypeData();
    getPaymentMethodsData();
    getExternalDepositMethodsData();
  }, [selectedCompanyId]);

  const handleSelectAllPaymentMethods = () => {
    fieldsPaymentMethods.forEach((_, index) => {
      setValue(`paymentMethods.${index}.active`, true);
    });
  };

  const handleSelectAllExternalDepositMethods = () => {
    fieldsExternalDepositMethods.forEach((_, index) => {
      setValue(`externalDepositPaymentMethods.${index}.active`, true);
    });
  };

  const handleSelectAllPaymentTypes = () => {
    fieldsPaymentTypes.forEach((_, index) => {
      setValue(`paymentTypes.${index}.active`, true);
    });
  };

  const BladeContent = () => {
    return (
      <Container className={styles.bladeContainer}>
        <FormProvider {...methods}>
          <DropdownFormField
            options={companiesOptions}
            name="companyId"
            label={strings.COMPANY}
            value={selectedCompanyId || ""}
            disabled={isEdit}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedCompanyId(event.target.value);
            }}
          />
          <CustomDivider title={"Bank Account Details"} titleColor={"dark"} />
          <InputFormField
            name="accountHolderName"
            label="Holder Name"
            disabled={isEdit}
            required
          />
          <Container className={styles.twoFieldsContainer}>
            <InputFormField name="accountName" label="Account Name" required />
            <InputFormField
              name="financialInstitution"
              label="Financial Institution"
              disabled={isEdit}
              required
            />
          </Container>
          <InputFormField
            name="accountNumber"
            label="Account Number"
            disabled={isEdit}
            required
          />
          <InputFormField
            name="abaRoutingNumber"
            label="ABA Routing Number"
            disabled={isEdit}
            required
          />
          <InputFormField
            name="routingNumber"
            label="Routing Number"
            disabled={isEdit}
            required
          />
          <div className={styles.dividerContainer}>
            <CustomDivider
              title={"Payment Methods For Customers"}
              titleColor={"dark"}
            />
            {selectedCompanyId !== null && paymentMethodsOptions.length != 0 && (
              <div>
                <label className={styles.textBlade}>
                  Choose accepted payment methods you want to allow your
                  customers to use to pay you
                </label>
                <button
                  className={`${styles.selectButton} ${styles.textButton}`}
                  onClick={() => handleSelectAllPaymentMethods()}
                >
                  Select All
                </button>
              </div>
            )}
            {selectedCompanyId === null && (
              <label className={styles.textBlade}>
                Select a Company to see the Payment Methods*
              </label>
            )}
            {selectedCompanyId !== null &&
              paymentMethodsOptions.length == 0 && (
                <label className={styles.textBlade}>
                  Selected company does not have Payment Methods Options*
                </label>
              )}
          </div>
          <Container className={styles.checkboxContainer}>
            {fieldsPaymentMethods.map((field, index) => {
              return (
                <Container>
                  <Container key={`key-${field.id}`}>
                    <CheckboxFormField
                      id={field.name}
                      name={`paymentMethods.${index}.active`}
                      label={field.name}
                    />
                  </Container>
                </Container>
              );
            })}
            {paymentMethodRequired && (
              <TypoGraph
                variant="body2"
                color={colors.error}
                content="It is required to select at least one  *"
              ></TypoGraph>
            )}
          </Container>

          <div className={styles.dividerContainer}>
            <CustomDivider title={"Deposit Options"} titleColor={"dark"} />
            {selectedCompanyId !== null &&
              externalDepositMethodsOptions.length != 0 && (
                <div>
                  <label className={styles.textBlade}>
                    Choose how you want to recieve customers payments into your
                    bank account
                  </label>
                  <button
                    className={`${styles.selectButton} ${styles.textButton}`}
                    onClick={() => handleSelectAllExternalDepositMethods()}
                  >
                    Select All
                  </button>
                </div>
              )}
            {selectedCompanyId === null && (
              <label className={styles.textBlade}>
                Select a Company to see the Deposit options*
              </label>
            )}
            {selectedCompanyId !== null &&
              externalDepositMethodsOptions.length == 0 && (
                <label className={styles.textBlade}>
                  Selected company does not have External Deposit Options*
                </label>
              )}
          </div>
          <Container className={styles.checkboxContainer}>
            {fieldsExternalDepositMethods.map((field, index) => {
              return (
                <Container>
                  <Container key={`key-${field.id}`}>
                    <CheckboxFormField
                      id={field.name}
                      name={`externalDepositPaymentMethods.${index}.active`}
                      label={field.name}
                    />
                  </Container>
                </Container>
              );
            })}
            {externalDepositMethodRequired && (
              <TypoGraph
                variant="body2"
                color={colors.error}
                content="It is required to select at least one *"
              ></TypoGraph>
            )}
          </Container>
          <CustomDivider title={"Payment Types"} titleColor={"dark"} />
          {selectedCompanyId !== null && (
            <label>
              <button
                onClick={() => handleSelectAllPaymentTypes()}
                className={styles.selectButton}
              >
                Select All
              </button>
            </label>
          )}
          {selectedCompanyId === null && (
            <label className={styles.textBlade}>
              Select a Company to see the Payment Types*
            </label>
          )}
          <Container className={styles.checkboxContainer}>
            <div className={styles.paymentTypesContainer}>
              {fieldsPaymentTypes.map((field, index) => {
                return (
                  <Container
                    key={`key-${field.id}`}
                    className={styles.paymentTypeCheck}
                  >
                    <CheckboxFormField
                      id={field.name}
                      name={`paymentTypes.${index}.active`}
                      label={
                        field.name === "Commission Earned"
                          ? "Commission Check"
                          : field.name
                      }
                    />
                  </Container>
                );
              })}
            </div>
          </Container>
          <CustomDivider title={"Account Limits"} titleColor={"dark"} />
          <label className={styles.textBlade}>
            Establishing a limit for the account is at your discretion; without
            doing so, the account will not have any imposed limits.
          </label>
          <Container className={styles.twoFieldsContainer}>
            <InputFormField
              name="dailyDepositLimit"
              label="Daily Deposit Limit"
              maxLength={13}
              defaultValue={
                getValues("dailyDepositLimit")
                  ? getValues("dailyDepositLimit")
                  : "0"
              }
              type="number"
            />
            <InputFormField
              name="depositedItemLimit"
              label="Deposited Item Limit"
              maxLength={13}
              defaultValue={
                getValues("depositedItemLimit")
                  ? getValues("depositedItemLimit")
                  : "0"
              }
              type="number"
            />
          </Container>
          <Container className={styles.twoFieldsContainer}>
            <InputFormField
              name="dailyDepositLimitPerBroker"
              label="Daily Deposit Limit Per Broker"
              maxLength={13}
              defaultValue={
                getValues("dailyDepositLimitPerBroker")
                  ? getValues("dailyDepositLimitPerBroker")
                  : "0"
              }
              type="number"
            />
          </Container>
        </FormProvider>
      </Container>
    );
  };

  const BladeFooter = ({}: {
    setReceivableAccountDetailTab: (value: number) => void;
  }) => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={strings.CANCEL}
          variant="outlined"
          label={strings.CANCEL}
          onClick={() => {
            handleDataChange();
            handleClose();
            methods.reset();
          }}
        />

        <Button
          id={t.CREATE_RECEIVABLE_ACCOUNT}
          variant="contained"
          loading={buttonLoader}
          label={
            isEdit ? t.UPDATE_RECEIVABLE_ACCOUNT : t.CREATE_RECEIVABLE_ACCOUNT
          }
          onClick={handleSubmit((data) => handleSubmitReceivableAccount(data))}
        />
      </Container>
    );
  };

  const BladeVerificationContent = () => {
    return (
      <Container className={styles.bladeContainer}>
        <TypoGraph content={"Verification"} className={styles.divider} />
        <Container className={styles.verificationCard}>
          <Icon name="info" size={24} iconClassName={styles.infoIcon} />
          <p>{strings.RECEIVABLE_ACCOUNT_VERIFICATION_NEEDED}</p>
        </Container>

        <FormProvider {...methods}>
          <Container className={styles.transForm}>
            <TypoGraph content={"Transaction value"} />
            <InputFormField
              className={styles.transInput}
              name="transactionValue1"
              label=""
              maxLength={13}
              defaultValue={
                getValues("transactionValue1")
                  ? getValues("transactionValue1")
                  : "0"
              }
              currency={true}
              required
              validate={(textFieldValue: string) =>
                amountIsValid(textFieldValue)
              }
            />
          </Container>
          <Container className={styles.transForm}>
            <TypoGraph content={"Transaction value"} />
            <InputFormField
              className={styles.transInput}
              name="transactionValue2"
              label=""
              maxLength={13}
              defaultValue={
                getValues("transactionValue2")
                  ? getValues("transactionValue2")
                  : "0"
              }
              currency={true}
              required
              validate={(textFieldValue: string) =>
                amountIsValid(textFieldValue)
              }
            />
          </Container>
        </FormProvider>
      </Container>
    );
  };

  const BladeVerificationFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={"doThisLater"}
          variant="outlined"
          label={"Do This Later"}
          onClick={() => {
            handleClose();
            setReceivableAccountDetailTab(0);
            methods.reset();
          }}
        />

        <Button
          id={"verifyAccount"}
          variant="outlined"
          label={"Verify Account"}
          onClick={() => {
            setReceivableAccountDetailTab(0);
            handleClose();
            methods.reset();
            toast({
              title: `Account Verified`,
            });
          }}
        />
      </Container>
    );
  };

  return (
    <Container>
      {receivableAccountDetailTab === 0 && (
        <Container className={styles.actionContainer}>
          <Blade
            show={showBlade}
            title={
              isEdit ? t.UPDATE_RECEIVABLE_ACCOUNT : t.CREATE_RECEIVABLE_ACCOUNT
            }
            handleClose={() => {
              handleDataChange();
              handleClose();
            }}
            content={<BladeContent />}
            footerContent={
              <BladeFooter
                setReceivableAccountDetailTab={setReceivableAccountDetailTab}
              />
            }
            headerLine
          />
        </Container>
      )}
      {receivableAccountDetailTab === 1 && (
        <Container className={styles.actionContainer}>
          <Blade
            show={showBlade}
            title={"Receivable Account"}
            handleClose={handleClose}
            content={<BladeVerificationContent />}
            footerContent={<BladeVerificationFooter />}
            headerLine
          />
        </Container>
      )}
    </Container>
  );
};

export default ReceivableAccountBladeForm;
