import { ICheckCaptureProcessor } from "interfaces";

export const checkCaptureProcessors: ICheckCaptureProcessor[] = [
  {
    id: 0,
    name: "Deluxe",
    active: true,
  },
  {
    id: 1,
    name: "Priority Commerce",
    active: true,
  },
];
