import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import RadioField, { IRadioProps } from "components/controllers/radio";

interface IRadioFieldProps extends IRadioProps {
  name: string;
  required?: boolean;
  defaultValue?: number | string;
}

const RadioFormField: React.FC<IRadioFieldProps> = (props) => {
  const { name, required = false, defaultValue } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <>
            <RadioField
              {...props}
              id={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
            />
            <ErrorMessage errors={errors} name={name} />
          </>
        );
      }}
    />
  );
};

export default RadioFormField;
