import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Container, HotjarSuppressor, Icon } from "components";
import { canvasConfig } from "constants/component.config";
import styles from "./endorse.module.scss";
import { IPaymentImage } from "interfaces";
import { getEndorsementPreview } from "api";
import {
  EndorsementPositionRequest,
  EndorsementPreviewRequest,
} from "interfaces";

/**
 * Helpful resource for canvas tutorial
 * https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_usage
 */

interface IProps {
  endorsementText: string;
  base64Image: string;
  imageId: number;
  refresh?: boolean;
  backImage: IPaymentImage | undefined;
  accountId: number;
  onSuccessCallback?: (params: EndorsementPositionRequest) => void;
  setEndorsement?: (params: any) => void;
  isRotated?: (params: boolean) => void;
}

let rect = { ...canvasConfig.rect },
  rectMaxWidth = canvasConfig.rectMaxWidth,
  rectMaxHeight = canvasConfig.rectMaxHeight,
  mouseX,
  mouseY,
  startX: number,
  startY: number,
  closeEnough = canvasConfig.closeEnough,
  dragTL = false,
  dragBL = false,
  dragTR = false,
  dragBR = false,
  isDragging = false,
  offsetX: number,
  offsetY: number;

const EndorseEditor = (props: IProps) => {
  const {
    base64Image,
    imageId,
    refresh = false,
    backImage,
    accountId,
    onSuccessCallback,
    setEndorsement,
    isRotated,
  } = props;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null | undefined>(
    null
  );
  const [showPreview, setPreview] = useState(false);
  const [endorsedImage, setEndorsedImage] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [rotation, setRotation] = useState<boolean>(false);

  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setImageDimensions({ width: image.width, height: image.height });
    };
    image.src = base64Image;
  }, [base64Image]);

  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const width = canvasSize.width ?? canvasConfig.width;
  const height = canvasSize.height ?? canvasConfig.height;

  useEffect(() => {
    if (imageDimensions) {
      const maxWidth = 700;
      const maxHeight = 500;
      const widthRatio = maxWidth / imageDimensions.width;
      const heightRatio = maxHeight / imageDimensions.height;
      const ratio = Math.min(widthRatio, heightRatio);

      setCanvasSize({
        width: imageDimensions.width * ratio,
        height: imageDimensions.height * ratio,
      });
    }
  }, [imageDimensions]);

  useEffect(() => {
    if (showPreview) {
      const image = new Image();
      image.onload = () => {
        setImageDimensions({ width: image.width, height: image.height });
      };
      image.src = endorsedImage;
    }
  }, [endorsedImage, showPreview]);

  // Resetting all the global variables and states on first render.
  useEffect(() => {
    setCtx(null);
    setPreview(false);
    rect = { ...canvasConfig.rect };
    rectMaxHeight = canvasConfig.rectMaxHeight;
    closeEnough = canvasConfig.closeEnough;
    dragTL = false;
    dragBL = false;
    dragTR = false;
    dragBR = false;
    mouseX = 0;
    mouseY = 0;
    startX = 0;
    startY = 0;
    isDragging = false;
    offsetX = 0;
    offsetY = 0;
  }, []);

  useEffect(() => {
    if (!showPreview) {
      setCtx(null);
      setPreview(false);
      rect = calculateInitialRect(canvasSize.width, canvasSize.height);

      rectMaxHeight = canvasConfig.rectMaxHeight;
      closeEnough = canvasConfig.closeEnough;
      dragTL = false;
      dragBL = false;
      dragTR = false;
      dragBR = false;
      mouseX = 0;
      mouseY = 0;
      startX = 0;
      startY = 0;
      isDragging = false;
      offsetX = 0;
      offsetY = 0;
    }
  }, [canvasSize]);

  function calculateInitialRect(canvasWidth: any, canvasHeight: any) {
    const maxRectWidth = canvasWidth * 0.5;
    const maxRectHeight = canvasHeight * 0.5;

    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    const startX = centerX - maxRectWidth / 2;
    const startY = centerY - maxRectHeight / 2;

    return {
      startX: startX,
      startY: startY,
      w: maxRectWidth,
      h: maxRectHeight,
    };
  }

  // used to calc canvas position relative to window
  const reOffset = () => {
    let BB = canvasRef?.current?.getBoundingClientRect();
    offsetX = BB?.left || 265;
    offsetY = BB?.top || 15;
  };

  // given mouse X & Y (mx & my) and shape object
  // return true/false whether mouse is inside the shape
  const isMouseInShape = (mx: number, my: number) => {
    // this is a rectangle
    var rLeft = rect.startX + 10;
    var rRight = rect.startX + rect.w + 10;
    var rTop = rect.startY + 10;
    var rBott = rect.startY + rect.h - 10;
    // math test to see if mouse is inside rectangle
    if (mx > rLeft && mx < rRight && my > rTop && my < rBott) {
      return true;
    } else {
      // the mouse isn't in any of the shapes
      return false;
    }
  };

  const drawCircle = useCallback(
    (x: number, y: number, radius: number, active: boolean) => {
      ctx?.beginPath();
      if (ctx?.fillStyle) {
        ctx.fillStyle = active || isDragging ? "blue" : "white";
      }
      ctx?.setLineDash([0]);
      ctx?.arc(x, y, radius, 0, 2 * Math.PI);
      ctx?.closePath();
      ctx?.fill();
      ctx?.stroke();
    },
    [ctx]
  );

  const drawHandles = useCallback(() => {
    //top left arc
    drawCircle(rect.startX, rect.startY, closeEnough, dragTL);
    //top right arc
    drawCircle(rect.startX + rect.w, rect.startY, closeEnough, dragTR);
    //bottom right arc
    drawCircle(rect.startX + rect.w, rect.startY + rect.h, closeEnough, dragBR);
    //bottom left arc
    drawCircle(rect.startX, rect.startY + rect.h, closeEnough, dragBL);
  }, [drawCircle]);

  const draw = useCallback(() => {
    ctx?.beginPath();
    if (ctx?.fillStyle) {
      ctx.fillStyle = "transparent";
    }
    ctx?.setLineDash([8]);
    ctx?.rect(rect.startX, rect.startY, rect.w, rect.h);
    if (ctx?.lineWidth) {
      ctx.lineWidth = 2;
    }
    ctx?.stroke();
    if (ctx?.fillStyle) {
      ctx.fillStyle = "black";
    }
    drawHandles();
  }, [ctx, drawHandles]);

  const mouseDown = useCallback(
    (e: any) => {
      startX = e.x - offsetX;
      startY = e.y - offsetY;
      mouseX = e.x - offsetX;
      mouseY = e.y - offsetY;

      // checks whether the mouse is hold inside the rectangale
      if (isMouseInShape(mouseX, mouseY)) {
        isDragging = true;
      }

      // if there isn't a rect yet
      if (rect.w === undefined) {
        rect.startX = mouseY;
        rect.startY = mouseX;
        dragBR = true;
      }

      // 4 cases:
      // 1. top left
      else if (
        checkCloseEnough(mouseX, rect.startX) &&
        checkCloseEnough(mouseY, rect.startY)
      ) {
        dragTL = true;
      }
      // 2. top right
      else if (
        checkCloseEnough(mouseX, rect.startX + rect.w) &&
        checkCloseEnough(mouseY, rect.startY)
      ) {
        dragTR = true;
      }
      // 3. bottom left
      else if (
        checkCloseEnough(mouseX, rect.startX) &&
        checkCloseEnough(mouseY, rect.startY + rect.h)
      ) {
        dragBL = true;
      }
      // 4. bottom right
      else if (
        checkCloseEnough(mouseX, rect.startX + rect.w) &&
        checkCloseEnough(mouseY, rect.startY + rect.h)
      ) {
        dragBR = true;
      }
      // (5.) none of them
      else {
        // handle not resizing
      }

      ctx?.clearRect(0, 0, width, height);
      draw();
    },
    [ctx, draw]
  );

  const mouseUp = useCallback(() => {
    dragTL = dragTR = dragBL = dragBR = isDragging = false;
  }, []);

  const checkMaxSize = (width: number, height: number) => {
    if (width < rectMaxWidth) {
      width = rectMaxWidth;
      return false;
    } else if (height < rectMaxHeight) {
      height = rectMaxHeight;
      return false;
    }
    return true;
  };

  const mouseMove = useCallback(
    (e: any) => {
      mouseX = e.x - offsetX;
      mouseY = e.y - offsetY;

      if (isDragging) {
        rect.startX += mouseX - startX;
        rect.startY += mouseY - startY;
      }

      if (
        dragTL &&
        checkMaxSize(
          rect.w + (rect.startX - mouseX),
          rect.h + (rect.startY - mouseY)
        )
      ) {
        rect.w += rect.startX - mouseX;
        rect.h += rect.startY - mouseY;
        rect.startX = mouseX;
        rect.startY = mouseY;
      } else if (
        dragTR &&
        checkMaxSize(
          Math.abs(rect.startX - mouseX),
          rect.h + (rect.startY - mouseY)
        )
      ) {
        rect.w = Math.abs(rect.startX - mouseX);
        rect.h += rect.startY - mouseY;
        rect.startY = mouseY;
      } else if (
        dragBL &&
        checkMaxSize(
          rect.w + (rect.startX - mouseX),
          Math.abs(rect.startY - mouseY)
        )
      ) {
        rect.w += rect.startX - mouseX;
        rect.h = Math.abs(rect.startY - mouseY);
        rect.startX = mouseX;
      } else if (
        dragBR &&
        checkMaxSize(
          Math.abs(rect.startX - mouseX),
          Math.abs(rect.startY - mouseY)
        )
      ) {
        rect.w = Math.abs(rect.startX - mouseX);
        rect.h = Math.abs(rect.startY - mouseY);
      }
      ctx?.clearRect(0, 0, width, height);
      if (dragBL || dragBR || dragTL || dragTR) {
      }
      draw();
      startX = mouseX;
      startY = mouseY;
    },
    [ctx, draw]
  );

  function checkCloseEnough(p1: number, p2: number) {
    return Math.abs(p1 - p2) < closeEnough;
  }

  const initCanvas = useCallback(() => {
    let currentCanvas = canvasRef.current;
    let ctx = currentCanvas?.getContext("2d");
    ctx?.clearRect(0, 0, width, height);
    setCtx(ctx);
    reOffset();
    window.onscroll = function () {
      reOffset();
    };
    window.onresize = function () {
      reOffset();
    };
    if (currentCanvas) {
      currentCanvas.onresize = function () {
        reOffset();
      };
    }
    canvasRef.current?.addEventListener("mousedown", mouseDown);
    canvasRef.current?.addEventListener("mouseup", mouseUp);
    canvasRef.current?.addEventListener("mousemove", mouseMove);
    draw();
  }, [mouseDown, mouseMove, mouseUp, draw]);

  const getEndorsementPreviewForCurrentItem = () => {
    setLoadingPreview(true);
    const widthRatio = imageDimensions!.width / canvasSize.width;
    const heightRatio = imageDimensions!.height / canvasSize.height;

    const adjustedRect = {
      startX: rect.startX * widthRatio,
      startY: rect.startY * heightRatio,
      width: rect.w * widthRatio,
      height: rect.h * heightRatio,
    };

    const request: EndorsementPreviewRequest = {
      imageId: imageId,
      accountId: accountId,
      rotate: rotation,
      coordinatesRequest: {
        startX: adjustedRect.startX,
        startY: adjustedRect.startY,
        width: adjustedRect.width,
        height: adjustedRect.height,
      },
    };
    getEndorsementPreview(request).then((response) => {
      if (response.data.endorsedImage) {
        setEndorsedImage(
          `data:image/png;base64,${response.data.endorsedImage}`
        );
        setPreview(true);
        setLoadingPreview(false);
        sendPositionRequest(request);
      }
    });
  };

  function sendPositionRequest(previewRequest: EndorsementPreviewRequest) {
    const previewCoordinates = previewRequest.coordinatesRequest;
    const request: EndorsementPositionRequest = {
      startX: previewCoordinates.startX,
      startY: previewCoordinates.startY,
      width: previewCoordinates.width,
      height: previewCoordinates.height,
    };
    onSuccessCallback?.(request);
  }

  useEffect(() => {
    if (showPreview) {
      const currentCanvas = canvasRef.current;
      const endorsedPreview = new Image();
      endorsedPreview.src = endorsedImage;
      const currentImg = imgRef.current;
      const ctx = currentCanvas?.getContext("2d");
      ctx?.clearRect(0, 0, width, height);
      if (currentImg) {
        currentImg.onload = () => {
          ctx?.drawImage(endorsedPreview, 0, 0, 700, 500);
        };
      }
    }
  }, [showPreview]);

  useEffect(() => {
    if (!showPreview) {
      initCanvas();
    }
  }, [initCanvas, showPreview, refresh, rotation]);

  useEffect(() => {
    (async () => {
      if (!showPreview) {
        return;
      }
      // Race condition, if we try to run this without the delay nothing will be drawn
      await new Promise((resolve) => setTimeout(resolve, 0));
      const currentCanvas = canvasRef.current;
      if (!currentCanvas) {
        return;
      }
    })();
  }, [showPreview]);

  return (
    <HotjarSuppressor>
      <Container className={styles.canvasContainer}>
        {/*if the image is already endorsed*/}
        {backImage?.endorsed && (
          <Icon
            width={width}
            height={height}
            imageId="source"
            type="images"
            url={base64Image}
          />
        )}
        {/*if image is not endorsed, and we want to display the preview*/}
        {!backImage?.endorsed && showPreview && (
          <>
            <Container className={styles.drawerContainer}>
              <Icon
                imageId="source"
                url={endorsedImage || base64Image}
                type="images"
                //reference={imgRef}
                boxClassName={styles.canvasImgContainer}
                width={width}
                height={height}
              />
              <canvas
                ref={canvasRef}
                width={width}
                height={height}
                style={{ zIndex: 1 }}
              />
            </Container>
            <Container className={styles.endorsementBtnContainer}>
              <Container className={styles.endorsementBtnContainer}>
                <Button
                  id={"Adjust endorsement"}
                  label={"Adjust endorsement"}
                  variant={"outlined"}
                  onClick={() => {
                    setEndorsement?.(false);
                    setPreview(!showPreview);
                  }}
                />
              </Container>
            </Container>
          </>
        )}

        {/*if the image is not endorsed, we display the endorse option*/}
        {!backImage?.endorsed && !showPreview && (
          <Container>
            <Container className={styles.drawerContainer}>
              <Icon
                imageId="source"
                url={base64Image}
                type="images"
                rotate={rotation ? `rotate(${180}deg)` : `rotate(${0}deg)`}
                boxClassName={styles.canvasImgContainerEdit}
                width={width}
                height={height}
                //objectFit={"fill"}
              />
              <canvas
                ref={canvasRef}
                width={width}
                height={height}
                style={{ zIndex: 1 }}
              />
            </Container>
            <Container className={styles.rotateBtn}>
              <Icon
                name="icon_rotate"
                color="black"
                size={40}
                boxClassName={styles.rotateIcon}
                onClick={() => {
                  setRotation((prevRotation) => {
                    const newRotation = !prevRotation;
                    isRotated?.(newRotation);
                    return newRotation;
                  });
                }}
                iconButton
              />
            </Container>
            <Container className={styles.endorsementBtnContainer}>
              <Button
                id={"Preview endorsement"}
                label={
                  !loadingPreview ? "Preview endorsement" : "Loading preview"
                }
                loading={loadingPreview}
                onClick={() => {
                  getEndorsementPreviewForCurrentItem();
                  setEndorsement?.(true);
                }}
              />
            </Container>
          </Container>
        )}
      </Container>
    </HotjarSuppressor>
  );
};

export default EndorseEditor;
