import { Container, Divider, Icon, TypoGraph } from "components";

import styles from "./footer.module.scss";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";
import { marketingLink } from "constants/marketingLink";

interface IFooterComponentProps {
  whitelabelConfig?: IWhitelabelConfiguration;
}

const FooterComponent = (props: IFooterComponentProps) => {
  const { whitelabelConfig } = props;
  const footerItems: any[] = [
    {
      icon: "icon_2_1M",
      size: 70,
      line1: "Secured",
      line2: "Transactions",
      type: "icons",
    },

    {
      icon: "icon_bank_black",
      size: 30,
      line1: "Check21",
      line2: "Compliant",
      type: "icons",
    },
    {
      icon: "icon_rs_black",
      size: 50,
      line1: "Protected by",
      line2: "RedSentry",
      type: "images",
    },
    {
      icon: "icon_shield_black",
      size: 30,
      line1: "SOC2",
      line2: "Compliant",
      type: "icons",
    },
  ];
  return (
    <Container className={styles.footerContainer}>
      <Divider sx={{ margin: 0 }} />
      <Container className={styles.footerIcons}>
        {footerItems.map((item) => {
          return (
            <Container>
              <Icon
                name={item.icon}
                size={item.size}
                type={item.type}
                height={item.size}
                boxClassName={styles[item.icon]}
              />

              <TypoGraph
                content={item.line1}
                align={"center"}
                color={"#212121"}
                sx={{ "font-size": "12px", "margin-bottom": "-5px" }}
              />
              <TypoGraph
                content={item.line2}
                align={"center"}
                color={"#212121"}
                sx={{ "font-size": "12px" }}
              />
            </Container>
          );
        })}
      </Container>
      <Container sx={{ "margin-top": "20px" }}>
        {
          // The custom preprocessor will change the color from the svg code only for the blue color from the Bank Shot logo
          whitelabelConfig?.backgroundColor && (
            <Icon
              name={"icon_b_logo_primary"}
              size={60}
              color={whitelabelConfig?.backgroundColor}
              customPreProcessor={(code: string) =>
                code.replace(
                  /fill=".*?"/g,
                  `fill='${whitelabelConfig?.backgroundColor}'`
                )
              }
              hover
              onClick={() => window.open(marketingLink, "_blank")}
            />
          )
        }
        {!whitelabelConfig?.backgroundColor && (
          <Icon
            name={"icon_b_logo_primary"}
            size={60}
            hover
            onClick={() => window.open(marketingLink, "_blank")}
          />
        )}

        <TypoGraph
          content={"© 2024, Bank Shot"}
          align={"center"}
          color={"#666666"}
          sx={{ "font-size": "14px", "margin-top": "8px" }}
        />
      </Container>
    </Container>
  );
};

export default FooterComponent;
