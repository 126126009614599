import React, { useEffect, useState } from "react";
import { Container, TypoGraph } from "components";
import strings from "strings";
import { FormProvider, useForm } from "react-hook-form";
import { DropdownOptions } from "interfaces";
import styles from "../multiplePassportDepositDialog.module.scss";
import DropdownFormField, {
  IDropdownFieldProps,
} from "components/hookForm/dropdownFormField";
import {
  IPaymentForDeposit,
  IReceivableAccount,
  ISelectedOptions,
} from "interfaces/IMultiplePassportDeposit";

interface IDepositFormRowProps {
  paymentId: number;
  payment: IPaymentForDeposit;
  accounts: IReceivableAccount[];
  onSelectCallback: (optionsSelected: ISelectedOptions) => void;
  onValidFormCallback: (valid: boolean) => void;
}

const DepositFormRow: React.FC<IDepositFormRowProps> = (props) => {
  const {
    paymentId,
    payment,
    accounts,
    onSelectCallback,
    onValidFormCallback,
  } = props;
  const methods = useForm<any>();
  const { watch, getValues, setValue } = methods;

  const [selectedAccountLabel, setSelectedAccountLabel] = useState<
    string | undefined | number
  >();
  const [selectedAccountId, setSelectedAccountId] = useState<any>();

  const availableAccounts: DropdownOptions[] = accounts.map(
    (account: IReceivableAccount) => {
      return {
        value: account.id,
        label: account.accountName,
        externalDepositPaymentMethods: account.externalDepositPaymentMethods,
      };
    }
  );

  // Handles account selection to load the payment methods.
  useEffect(() => {
    const accountId = getValues("accountId");
    if (selectedAccountId) {
      onValidFormCallback(false);
    }
    setSelectedAccountId(accountId);
    setSelectedAccountLabel(
      availableAccounts?.find((option) => option.value === accountId)?.label
    );

    setValue("paymentMethodId", undefined);
    if (availableAccounts) {
      const options: any = availableAccounts;
      const selectedOption = options.find(
        (option: any) => option.value === accountId
      );

      if (selectedOption) {
        const paymentMethodsOptions =
          selectedOption.externalDepositPaymentMethods.map((method: any) => {
            return {
              value: method.id,
              label: method.name,
            };
          });
        setAvailablePaymentMethods({
          disabled: paymentMethodsOptions.length == 0,
          options: paymentMethodsOptions,
          label: strings.PAYMENT_METHOD,
          name: "paymentMethodId",
          required: true,
        });
      }
    }
  }, [watch("accountId")]);

  // Handle payment method selection for create the request and send it.
  useEffect(() => {
    const paymentMethodId = getValues("paymentMethodId");
    const paymentMethodLabel = availablePaymentMethods?.options?.find(
      (option) => option.value === paymentMethodId
    )?.label;
    const selectedOptions: ISelectedOptions = {
      itemId: paymentId,
      accountLabel: selectedAccountLabel,
      destinationReceivableAccountId: selectedAccountId,
      methodLabel: paymentMethodLabel,
      paymentMethodId: paymentMethodId,
    };
    if (paymentMethodId != undefined) {
      onSelectCallback(selectedOptions);
    }
  }, [watch("paymentMethodId")]);

  const [availablePaymentMethods, setAvailablePaymentMethods] =
    useState<IDropdownFieldProps>({
      disabled: true,
      options: [],
      label: strings.PAYMENT_METHOD,
      name: "paymentMethodId",
      required: true,
    });

  return (
    <FormProvider {...methods}>
      <Container className={styles.depositRow}>
        <TypoGraph content={payment.paymentId.toString()}></TypoGraph>
        <TypoGraph
          content={payment.property ? payment.property : "-"}
        ></TypoGraph>
        <TypoGraph content={payment.paymentMethod.name}></TypoGraph>
        <TypoGraph content={payment.amountFormatted}></TypoGraph>

        <Container className={styles.accountDropdown}>
          <DropdownFormField
            {...{
              options: availableAccounts,
              label: strings.DESTINATION_ACCOUNT,
              defaultValue: undefined,
              name: "accountId",
              required: true,
            }}
          />
        </Container>
        <Container className={styles.accountDropdown}>
          <DropdownFormField {...availablePaymentMethods} />
        </Container>
      </Container>
    </FormProvider>
  );
};

export default DepositFormRow;
