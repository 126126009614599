import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Icon,
  Loader,
  TypoGraph,
} from "components";
import { ObjectStrings } from "interfaces";
import QRCode from "react-qr-code";
import { setup2fa } from "api";
import strings from "strings";

interface IResetProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const Setup2fa = (props: IResetProps) => {
  const { styles, handleStep } = props;
  const SETUP_TIMEOUT = 5 * 60 * 1000;
  const [email, setEmail] = useState("");
  const [secret, setSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [uri, setUri] = useState("");
  const issuer = "app.getbankshot.com";
  const t = strings.MULTIFACTOR_STRINGS;

  setTimeout(() => {
    handleStep(0);
  }, SETUP_TIMEOUT);

  useEffect(() => {
    setLoading(true);
    setup2fa()
      .then((response) => {
        setEmail(response.data.email);
        setSecret(response.data.secret);
        setUri(
          "otpauth://totp/" +
            encodeURIComponent(issuer) +
            ":" +
            encodeURIComponent(email) +
            "?secret=" +
            secret +
            "&issuer=" +
            encodeURIComponent(issuer)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, email, secret]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <Container className={styles.multiFactorContainer}>
      <Container className={styles.multiFactorContent}>
        <TypoGraph
          variant="h6"
          content={t.MULTIFACTOR_AUTHENTICATION}
          align="center"
        />
        <TypoGraph variant="caption" content={t.OPEN_APP} align="center" />
      </Container>
      <Container className={styles.multiCredentialContainer}>
        <Container className={styles.qrCodeContainer}>
          <Container className={styles.optionTitle}>
            <Icon name="icon_qrscanner" width={30} height={35} />
            <TypoGraph
              content="Scan QR Code"
              sx={{ fontWeight: "400" }}
              align="center"
            />
          </Container>
          <Container className={styles.qrCodeCenter}>
            <QRCode
              className={styles.qrContainer}
              value={uri}
              size={200}
              id="QRCode"
            />
          </Container>
          <Container>
            <TypoGraph variant="caption" content={t.STEP1} />
            <TypoGraph variant="caption" content={t.STEP2} />
            <TypoGraph variant="caption" content={t.STEP3} />
          </Container>
        </Container>
        <Divider orientation="vertical" title="OR" textAlign="center" />
        <Container className={styles.accountContainer}>
          <Container
            className={`${styles.optionTitle} ${styles.accountTittle}`}
          >
            <Icon name="icon_accountkey" width={30} height={20} />
            <TypoGraph
              content="Enter Setup Key"
              sx={{ fontWeight: "400" }}
              align="center"
            />
          </Container>
          <Container className={styles.accountBordered}>
            <TypoGraph
              variant="caption"
              content={strings.ACCOUNT}
              align="center"
            />
            <TypoGraph
              content={email}
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            />
            <TypoGraph variant="caption" content={strings.KEY} align="center" />
            <TypoGraph
              content={secret}
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
              id="SetUpKey"
            />
          </Container>
          <TypoGraph variant="caption" content={t.ENTER_CODE} />
        </Container>
      </Container>
      <Container className={styles.continueBtn}>
        <Button
          variant="outlined"
          onClick={() => handleStep(4)}
          label={strings.CONTINUE}
          id={strings.CONTINUE}
        />
      </Container>
    </Container>
  );
};
export default Setup2fa;
