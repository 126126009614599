import { DropdownOptions, IWhitelabelConfigurationField } from "interfaces";
import InputFormField from "./inputFormField";
import regex from "regex";
import strings from "strings";
import { amountIsValid } from "tools/validators";
import DropdownFormField from "./dropdownFormField";
import {
  ICustomLabels,
  ISection,
} from "interfaces/IWhitelabelConfigurationResponse";
import Container from "components/layout";
import { CustomDivider, PropertyForm, UserInfoForm } from "components";
import { useFormContext, useWatch } from "react-hook-form";
import { DATE_FORMAT_YYYY_MM_DD, todayDate } from "constants/datePicker";
import SelfManagedDatePickerFormField from "./selfManagedDatePickerFormField";
import React from "react";
import { formatDateToMMDDYYYY } from "tools/format";

function getCustomField(
  fieldDetail: IWhitelabelConfigurationField,
  getValues: any,
  statesOptions: DropdownOptions[],
  paymentTypeNeedsRenamedLabels: boolean,
  customLabels?: ICustomLabels
) {
  const { type } = fieldDetail || {};

  switch (type) {
    case "dropDown":
      return (
        <DropdownFormField
          options={fieldDetail.options || []}
          name={fieldDetail.name}
          label={fieldDetail.label}
          defaultValue={fieldDetail.defaultSelection}
          required={fieldDetail.required}
        />
      );
    case "number":
      return (
        <InputFormField
          name={fieldDetail.name}
          label={fieldDetail.label}
          type="number"
          required={fieldDetail.required}
        />
      );
    case "textField":
      return (
        <InputFormField
          name={fieldDetail.name}
          label={fieldDetail.label}
          required={fieldDetail.required}
        />
      );
    case "email":
      return (
        <InputFormField
          name={fieldDetail.name}
          label={fieldDetail.label}
          pattern={{
            value: regex.EMAIL_REGEX_PATTERN,
            message: strings.PLEASE_ENTER_A_VALID_EMAIL,
          }}
          required={fieldDetail.required}
        />
      );
    case "currency":
      return (
        <InputFormField
          name={fieldDetail.name}
          label={fieldDetail.label}
          maxLength={13}
          defaultValue={getValues(fieldDetail.name) || "0"}
          currency={true}
          validate={(textFieldValue: string) => amountIsValid(textFieldValue)}
          required
        />
      );
    case "date":
      return (
        <SelfManagedDatePickerFormField
          name={fieldDetail.name}
          label={fieldDetail.label}
          minDate={getMinDate(fieldDetail.minDate)}
          defaultValue={formatDateToMMDDYYYY(todayDate)}
          inputFormat={DATE_FORMAT_YYYY_MM_DD}
        />
      );
    case "address":
      return (
        <PropertyForm
          statesOptions={statesOptions}
          customLabels={customLabels?.address}
        />
      );
    case "yourInfo":
      return (
        <UserInfoForm
          selectedRole={
            paymentTypeNeedsRenamedLabels ? getValues("trrole") : undefined
          }
        />
      );
    default:
      return <></>;
  }
}

function getMinDate(minDate: any) {
  if (minDate === undefined) {
    return todayDate;
  } else if (minDate === "") {
    return undefined;
  } else {
    return new Date(minDate);
  }
}

interface ICustomFormFieldProps {
  sections: ISection[];
  styles: any;
  statesOptions: DropdownOptions[];
  customLabels?: ICustomLabels;
  paymentType: string;
}
export const CustomFormField: React.FC<ICustomFormFieldProps> = (props) => {
  const { sections, styles, statesOptions, customLabels, paymentType } = props;
  const { getValues, control } = useFormContext();

  const paymentTypesToBeRenamed = ["0", "7", "8", "9", "10", "14"];

  const selectedRoleWatch = useWatch({
    control,
    name: "trrole",
    defaultValue: getValues("trrole") || "",
  });

  const defaultCustomFormFields = (
    <Container className={styles.transactionDetailContainer}>
      {sections.map((section) => (
        <>
          {section.sectionName && <CustomDivider title={section.sectionName} />}
          <Container className={styles[section.style]}>
            {section.fields.map((field) =>
              getCustomField(
                field,
                getValues,
                statesOptions,
                false,
                customLabels
              )
            )}
          </Container>
        </>
      ))}
    </Container>
  );

  return (
    <Container className={styles.transactionDetailContainer}>
      {!paymentTypesToBeRenamed.includes(paymentType) &&
        defaultCustomFormFields}
      {paymentTypesToBeRenamed.includes(paymentType) &&
        sections.map((section) => {
          let dividerName = section.sectionName;
          if (section.sectionName === "Buyer Info") {
            dividerName = "Additional Contact Information";
          }
          if (section.sectionName === "Your Info") {
            if (selectedRoleWatch === "0") {
              dividerName = "Buyer's Info";
            } else {
              dividerName = "Agent's Info";
            }
          }
          return (
            <>
              {section.sectionName && <CustomDivider title={dividerName} />}
              <Container className={styles[section.style]}>
                {section.fields.map((field: IWhitelabelConfigurationField) => {
                  if (selectedRoleWatch === "0") {
                    if (field.name === "buyerFirstName") {
                      field.label = "Agent's First Name";
                    }
                    if (field.name === "buyerLastName") {
                      field.label = "Agent's Last Name";
                    }
                    if (field.name === "buyerEmail") {
                      field.label = "Agent's Email";
                    }
                    if (field.name === "buyerPhone") {
                      field.label = "Agent's Phone Number";
                    }
                  } else {
                    if (field.name === "buyerFirstName") {
                      field.label = "Buyer's First Name";
                    }
                    if (field.name === "buyerLastName") {
                      field.label = "Buyer's Last Name";
                    }
                    if (field.name === "buyerEmail") {
                      field.label = "Buyer's Email";
                    }
                    if (field.name === "buyerPhone") {
                      field.label = "Buyer's Phone Number";
                    }
                  }

                  return getCustomField(
                    field,
                    getValues,
                    statesOptions,
                    paymentTypesToBeRenamed.includes(paymentType),
                    customLabels
                  );
                })}
              </Container>
            </>
          );
        })}
    </Container>
  );
};
