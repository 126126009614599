import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  HeaderBar,
  Loader,
  SubmitDialog,
  TableComponent,
  Tabs,
  Toast as toast,
} from "components";
import { disbursementTableList } from "constants/tableData";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import {
  getDisbursementPaymentById,
  getDisbursementPaymentsPage,
  rejectDisbursementById,
  reviewApproveDisbursement,
  reviewRejectDisbursement,
  voidDisbursement,
} from "api";
import { IDisbursementPaymentItemResponse, IPageResponse } from "interfaces";
import strings from "strings";
import styles from "./disbursementList.module.scss";
import { useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  canRejectDisbursement,
  canReviewDisbursement,
  canUpdateDisbursement,
  canVoidDisbursements,
} from "constants/disbursementUpdateActions";
import colors from "theme/colors";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DisbursementPdf } from "./index";

interface IDisbursementListProps {}

const DisbursementList: React.FC<IDisbursementListProps> = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [disbursementListData, setDisbursementListData] =
    useState<IPageResponse<IDisbursementPaymentItemResponse>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [disbursementFilterName, setDisbursementFilterName] = useState<string>(
    disbursementTableList.tabFilters[0].label
  );

  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showReviewApproveDialog, setShowReviewApproveDialog] = useState(false);
  const [showReviewRejectDialog, setShowReviewRejectDialog] = useState(false);
  const [ShowVoidDialog, setShowVoidDialog] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const navigate = useNavigate();

  const { disbursementId } = useParams();
  const [showBlade, setShowBlade] = useState(!!disbursementId);
  const { data: detailsOfDisbursement, isLoading } = useQuery(
    ["disbursementId", disbursementId],
    () => getDisbursementPaymentById(disbursementId),
    {
      enabled: !!disbursementId,
    }
  );

  function handleColumnVisibility() {
    return disbursementFilterName !==
      disbursementTableList.tabFilters[3].label &&
      disbursementFilterName !== disbursementTableList.tabFilters[6].label
      ? { paymentSentDate: false }
      : {};
  }

  const rejectDraftPayment = useMutation(
    (id: string | undefined) => {
      return rejectDisbursementById(id);
    },
    {
      onError: (error) => {
        setShowBlade(false);
        setShowRejectDialog(false);
        navigate("/disbursements");
      },
      onSuccess: (response) => {
        setShowBlade(false);
        setShowRejectDialog(false);
        toast({
          title: response.data.message,
        });
        navigate("/disbursements");
      },
    }
  );
  const reviewApprovePayment = useMutation(
    (id: string | undefined) => {
      return reviewApproveDisbursement(id);
    },
    {
      onError: (error) => {
        setShowBlade(false);
        setShowReviewApproveDialog(false);
        navigate("/disbursements");
      },
      onSuccess: (response) => {
        setShowBlade(false);
        setShowReviewApproveDialog(false);
        toast({
          title: response.message,
        });
        navigate("/disbursements");
      },
    }
  );
  const reviewRejectPayment = useMutation(
    (id: string | undefined) => {
      return reviewRejectDisbursement(id);
    },
    {
      onError: (error) => {
        setShowBlade(false);
        setShowReviewRejectDialog(false);
        navigate("/disbursements");
      },
      onSuccess: (response) => {
        setShowBlade(false);
        setShowReviewRejectDialog(false);
        toast({
          title: response.message,
        });
        navigate("/disbursements");
      },
    }
  );

  const voidsDisbursement = useMutation(
    (id: string | undefined) => {
      return voidDisbursement(id);
    },
    {
      onError: (error) => {
        setShowBlade(false);
        setShowVoidDialog(false);
        navigate("/disbursements");
      },
      onSuccess: (response) => {
        setShowBlade(false);
        setShowVoidDialog(false);
        toast({
          title: response.message,
        });
        navigate("/disbursements");
      },
    }
  );

  // handle quick search from table component
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const loadDisbursement = useCallback(async () => {
    setLoading(true);
    setDisbursementFilterName(
      disbursementTableList.tabFilters[activeTab].label
    );
    try {
      const response = await getDisbursementPaymentsPage(
        currentPage,
        currentPageSize,
        disbursementTableList.tabFilters[activeTab].filterValue,
        searchValue,
        sortModel[0]?.field,
        sortModel[0]?.sort
      );
      setDisbursementListData(response?.data);
    } catch (err) {
      navigate("/disbursements");
    } finally {
      setLoading(false);
    }
  }, [
    navigate,
    sortModel,
    searchValue,
    activeTab,
    currentPageSize,
    currentPage,
  ]);

  useEffect(() => {
    loadDisbursement();
  }, [loadDisbursement]); // executes once

  const BladeContent = () => {
    const payload = { ...detailsOfDisbursement };
    if (payload.disbursementStatusId === 0) {
      payload.disbursementStatusName = "Sent";
    }

    if (!payload.memo) {
      payload.memo = strings.NO_MEMO;
    }

    if (!payload.checkNumber) {
      payload.checkNumber = strings.THIS_PAYMENT_HAS_NOT_CHECK_NUMBER;
    }
    if (isLoading) {
      return <Loader loading={isLoading} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid columns={disbursementTableList.details} data={payload} />
        {payload.reviewerUserName && (
          <DataGrid columns={disbursementTableList.reviewedBy} data={payload} />
        )}
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        {!!detailsOfDisbursement ? (
          <PDFDownloadLink
            style={{ color: colors.white }}
            document={
              <DisbursementPdf disbursementDetails={detailsOfDisbursement} />
            }
            fileName={`disbursement_${detailsOfDisbursement.id}`}
          >
            {({ loading }) => (
              <Button
                id={"Download"}
                label={"Download"}
                color={"primary"}
                variant={"outlined"}
                disabled={loading}
              />
            )}
          </PDFDownloadLink>
        ) : null}
        {canRejectDisbursement(
          detailsOfDisbursement?.disbursementStatusId,
          detailsOfDisbursement?.payingUserId
        ) ? (
          <Button
            id={"Reject"}
            variant="contained"
            label={"Reject"}
            color="error"
            onClick={() => {
              setShowRejectDialog(true);
            }}
          />
        ) : null}
        {canUpdateDisbursement(
          detailsOfDisbursement?.disbursementStatusId,
          detailsOfDisbursement?.payingUserId
        ) ? (
          <Button
            id={"Update"}
            variant="contained"
            label={"Update"}
            onClick={() => {
              navigate(`/disbursements/update/${disbursementId}`);
            }}
          />
        ) : null}
        {canReviewDisbursement(
          detailsOfDisbursement?.disbursementStatusId,
          detailsOfDisbursement?.payingUserId
        ) ? (
          <>
            <Button
              id={"Review Reject"}
              variant="contained"
              label={"Reject"}
              color="error"
              onClick={() => setShowReviewRejectDialog(true)}
            />
            <Button
              id={"Review Approve"}
              variant="contained"
              label={"Approve"}
              color="success"
              onClick={() => setShowReviewApproveDialog(true)}
            />
          </>
        ) : null}
        {canVoidDisbursements(detailsOfDisbursement?.canBeVoided) ? (
          <Container className={styles.buttonsContainer}>
            <Button
              id="Void"
              label={"Void"}
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={() => setShowVoidDialog(true)}
            />
          </Container>
        ) : null}
      </Container>
    );
  };

  return (
    <Container>
      <HeaderBar
        title="Disbursements"
        primaryBtnProps={
          userCan(Actions.CREATE, Resources.DISBURSEMENT_PAYMENTS)
            ? {
                label: "Create Disbursement",
                onClick: () => navigate("/disbursements/create"),
              }
            : undefined
        }
        secondaryBtnProps={
          userCan(Actions.CREATE, Resources.DISBURSEMENT_PAYMENTS)
            ? {
                label: strings.CREATE_MULTIPLE_DISBURSEMENTS,
                onClick: () => navigate("/disbursements/multiple"),
              }
            : undefined
        }
      />
      <Tabs
        tabData={disbursementTableList.tabFilters || []}
        value={activeTab}
        handleChange={(_e, val) => {
          setActiveTab(val);
        }}
      />
      <TableComponent
        title={`${disbursementFilterName} Disbursement ${strings.PAYMENTS}`}
        data={disbursementListData?.items || []}
        totalCount={disbursementListData?.totalCount}
        columns={disbursementTableList.columns}
        loading={loading}
        searchValue={searchValue}
        columnVisibilityModel={handleColumnVisibility()}
        currentPageSize={currentPageSize}
        handleQuickSearch={(value: string) => {
          setSearchValue(value);
        }}
        handleSortModelChange={(model: GridSortModel) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
          }
        }}
        handlePagination={(pageNumber, pageSize) => {
          setCurrentPageSize(pageSize);
          setCurrentPage(pageNumber);
        }}
        onRowClick={(row) => {
          navigate(`/disbursements/${row?.id}`);
          setShowBlade(true);
        }}
      />
      {showBlade && (
        <Blade
          show={showBlade}
          title={!isLoading ? strings.DISBURSEMENT_DETAILS : ""}
          handleClose={() => {
            navigate("/disbursements");
            setShowBlade(false);
          }}
          headerChipProp={{
            label: undefined,
            color: "success",
          }}
          content={<BladeContent />}
          footerContent={!isLoading && <BladeFooter />}
        />
      )}
      <SubmitDialog
        type="warning"
        title={strings.REJECT_TITLE_MODAL}
        open={showRejectDialog}
        body1={`${strings.REJECT_DRAFT_DISBURSEMENT_WITH_ID} ${detailsOfDisbursement?.id}?`}
        body2={strings.THIS_ACTION_CANNOT_BE_UNDONE}
        handleClose={() => setShowRejectDialog(false)}
        primaryBtnProps={{
          id: "Reject",
          label: "Reject",
          onClick: () => {
            rejectDraftPayment.mutate(disbursementId);
          },
          loading: rejectDraftPayment.isLoading,
          color: "error",
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.REVIEW_REJECT_DISBURSEMENT_TITLE_MODAL}
        open={showReviewRejectDialog}
        body1={`${strings.REJECT_DISBURSEMENT_WITH_ID} ${detailsOfDisbursement?.id}?`}
        body2={strings.THIS_ACTION_CANNOT_BE_UNDONE}
        handleClose={() => setShowReviewRejectDialog(false)}
        primaryBtnProps={{
          id: "Review Reject",
          label: "Reject",
          onClick: () => {
            reviewRejectPayment.mutate(disbursementId);
          },
          loading: reviewRejectPayment.isLoading,
          color: "error",
        }}
      />
      <SubmitDialog
        type="success"
        title={strings.REVIEW_APPROVE_DISBURSEMENT_TITLE_MODAL}
        open={showReviewApproveDialog}
        body1={`${strings.APPROVE_DISBURSEMENT_WITH_ID} ${detailsOfDisbursement?.id}?`}
        body2={strings.THIS_ACTION_CANNOT_BE_UNDONE}
        handleClose={() => setShowReviewApproveDialog(false)}
        primaryBtnProps={{
          id: "Approve",
          label: "Approve",
          onClick: () => {
            reviewApprovePayment.mutate(disbursementId);
          },
          loading: reviewApprovePayment.isLoading,
          color: "success",
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.VOID_DISBURSEMENT_TITLE_MODAL}
        open={ShowVoidDialog}
        body1={`${strings.VOID_DISBURSEMENT_WITH_ID} ${detailsOfDisbursement?.id}?`}
        body2={strings.THIS_ACTION_CANNOT_BE_UNDONE}
        handleClose={() => setShowVoidDialog(false)}
        primaryBtnProps={{
          id: "Void",
          label: "Void",
          onClick: () => {
            voidsDisbursement.mutate(disbursementId);
          },
          loading: voidsDisbursement.isLoading,
          color: "success",
        }}
      />
    </Container>
  );
};

export default DisbursementList;
