import React, { useEffect, useRef, useState } from "react";
import { Container } from "components";
import styles from "./creditCard.module.scss";
import { DropdownFormField, InputFormField } from "components/hookForm";
import { useFormContext } from "react-hook-form";
import { todayDate } from "constants/datePicker";
import Typograph from "components/typograph";
import { months, nextTenYears } from "constants/date";

interface CardLengths {
  [key: string]: number;
}

interface CardTypes {
  [key: string]: RegExp;
}

interface ICreditCardPaymentProps {}

interface CardTypeFee {
  id: string;
  transactionPercentage: number;
  perAuthorization: number;
}

const CreditCardPayment: React.FC<ICreditCardPaymentProps> = () => {
  const { setValue, getValues, watch } = useFormContext();
  const [expiryDateError, setExpiryDateError] = useState<string>("");
  const cardNumberError = watch("cardNumberError");
  const [cvvError, setCvvError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const getCardType = (number: string): string => {
    const cardTypes: CardTypes = {
      visa: /^4/,
      mastercard: /^5[1-5]/,
      amex: /^3[47]/,
      discover: /^6(?:011|5[0-9]{2})/,
      //diners: /^3(?:0[0-5]|[68][0-9])/,
      //jcb: /^(?:2131|1800|35\d{3})/,
      //unionpay: /^(62|88)/,
      //Last 3 credit card types disabled by now, as we don't know the transaction fee for them
    };

    return (
      Object.keys(cardTypes).find((type) => cardTypes[type].test(number)) ||
      "unknown"
    );
  };

  const cardTypeFees: CardTypeFee[] = [
    { id: "visa", transactionPercentage: 0.005, perAuthorization: 1.0 },
    { id: "mastercard", transactionPercentage: 0.005, perAuthorization: 1.0 },
    { id: "amex", transactionPercentage: 0.01, perAuthorization: 1.0 },
    { id: "discover", transactionPercentage: 0.005, perAuthorization: 1.0 },
  ];

  const [cardType, setCardType] = useState<string>(
    getCardType(getValues("cardNumber"))
  );

  const cardLengths: CardLengths = {
    visa: 19,
    mastercard: 16,
    amex: 15,
    discover: 19,
    //diners: 14,
    //jcb: 16,
    //unionpay: 19,
  };

  const validateCardNumber = (number: string): boolean => {
    const cardNumber = number.replace(/\s+/g, "");
    let sum = 0;
    let shouldDouble = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i));

      if (shouldDouble) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }
    if (!(sum % 10 === 0)) {
      setValue("creditCardFee", undefined);
      setValue("cardNumberError", "Invalid card number.");
      return false;
    }

    validateCardType();
    setValue("cardNumberError", "");

    return true;
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const currentCardType = cardType;
    const type = getCardType(rawValue);
    const maxLength = cardLengths[type] || 19;
    const trimmedValue = rawValue.slice(0, maxLength);

    validateCardNumber(trimmedValue);

    if (currentCardType != type) {
      setCardType(type);
      setValue("cvv", "");
    }

    const cardTypeFee = cardTypeFees.find((typeFee) => typeFee.id == type);
    if (type != "unknown" && cardTypeFee) {
      const amountAsNumber = parseFloat(getValues("amount"));
      const feePercentage = amountAsNumber * cardTypeFee.transactionPercentage;
      const feeAmount = cardTypeFee.perAuthorization + feePercentage;
      setValue("creditCardFee", feeAmount);
    } else if (type == "unknown") {
      setValue("creditCardFee", undefined);
    }

    setValue("cardNumber", trimmedValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    // Prevent the default paste action
    event.preventDefault();

    // Extract the pasted text from the clipboard
    const pastedText = event.clipboardData.getData("Text");

    // Create a mock event to pass to handleCardNumberChange
    const mockChangeEvent = {
      target: {
        value: pastedText,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    // Call the card number change handler with the pasted data
    handleCardNumberChange(mockChangeEvent);
  };

  const validateCardType = () => {
    if (cardType == "unknown") {
      setValue("creditCardFee", undefined);
      setValue(
        "cardNumberError",
        "Unsupported card type, try using Amex, Mastercard, Visa or Discover ."
      );
      return false;
    }
    setValue("cardNumberError", "");

    return true;
  };

  useEffect(() => {
    // Added to handle focus on credit card number input when cardType changes, without this it lost the focus from the field.
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [cardType]);

  const validateExpiryDate = (): boolean => {
    const monthDate = getValues("expiryMonth");
    const yearDate = getValues("expiryYear");

    if (isNaN(monthDate) || isNaN(yearDate)) {
      setExpiryDateError("Invalid date format.");
      return false;
    }

    if (
      monthDate < todayDate.getMonth() + 1 &&
      yearDate <= todayDate.getFullYear()
    ) {
      setExpiryDateError("Invalid expiry date.");
      return false;
    }

    setExpiryDateError("");
    return true;
  };

  const validateCvv = (cvv: string): boolean => {
    const maxLength = cardType === "amex" ? 4 : 3;
    const isValid = cvv.length === maxLength;

    setCvvError(isValid ? "" : `CVV must be ${maxLength} digits.`);
    return isValid;
  };

  const handleCvvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cvv = e.target.value.replace(/\D/g, "");

    setValue("cvv", cvv);

    validateCvv(cvv);
  };

  const validatePhoneNumber = (): boolean => {
    const phoneNumber = getValues("cardHolderPhoneNumber");
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    const isValid = phoneRegex.test(phoneNumber);

    setPhoneNumberError(isValid ? "" : `Phone number must be NNN-XXX-XXXX.`);

    return isValid;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneRaw = e.target.value.replace(/\D/g, "");

    let formattedPhoneNumber = phoneRaw;

    if (phoneRaw.length > 3 && phoneRaw.length <= 6) {
      formattedPhoneNumber = `${phoneRaw.slice(0, 3)}-${phoneRaw.slice(3)}`;
    } else if (phoneRaw.length > 6) {
      formattedPhoneNumber = `${phoneRaw.slice(0, 3)}-${phoneRaw.slice(
        3,
        6
      )}-${phoneRaw.slice(6, 10)}`;
    }

    setValue("cardHolderPhoneNumber", formattedPhoneNumber);
    if (formattedPhoneNumber.length == 12) {
      validatePhoneNumber();
    }
  };

  return (
    <Container
      className={`${styles.creditCardDetailsContainer} ${styles[cardType]}`}
    >
      <Container className={styles.fieldsInLine}>
        <InputFormField name="cardHolder" label="Card Holder Name" required />
        <Container>
          <InputFormField
            name="cardHolderPhoneNumber"
            label="Phone Number"
            required
            onChange={handlePhoneNumberChange}
            onBlur={validatePhoneNumber}
            validate={validatePhoneNumber}
          />
          {phoneNumberError && (
            <Typograph color="#F9635E" content={phoneNumberError}></Typograph>
          )}
        </Container>
      </Container>

      <Container className={styles.paymentDetails}>
        <Container>
          <InputFormField
            inputRef={inputRef}
            name="cardNumber"
            label="Credit Card Number"
            hideableText={true}
            required
            validate={validateCardNumber}
            onChange={handleCardNumberChange}
            maxLength={cardLengths[cardType] || 19}
            minLength={12}
            onPaste={handlePaste}
          />
          {cardNumberError && (
            <Typograph color="#F9635E" content={cardNumberError}></Typograph>
          )}
        </Container>

        <Container>
          <Container className={styles.paymentDetailsDate}>
            <DropdownFormField
              name="expiryMonth"
              label="Month"
              options={months}
              validate={validateExpiryDate}
              required
            />
            <DropdownFormField
              name="expiryYear"
              label="Year"
              options={nextTenYears()}
              validate={validateExpiryDate}
              required
            />
          </Container>
          {expiryDateError && (
            <Typograph color="#F9635E" content={expiryDateError}></Typograph>
          )}
        </Container>
        <Container>
          <InputFormField
            name="cvv"
            label="CVV"
            hideableText={true}
            validate={validateCvv}
            onChange={handleCvvChange}
            maxLength={cardType === "amex" ? 4 : 3}
            required
          />
          {cvvError && (
            <Typograph color="#F9635E" content={cvvError}></Typograph>
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default CreditCardPayment;
