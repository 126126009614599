import { useContext, useEffect } from "react";
import { BreadcrumbContext } from "./breadcrumb-context";

export const useBreadcrumbOptions = () => {
  return useContext(BreadcrumbContext);
};

/**
 * a hook that runs everytime the name changes and resets when the page changes
 * the breadcrumb populates the label from the url, this is used when wanting to
 * info that is not in the url, like name instead of id
 * @param {string} name the name to set the last section to
 */
export const useUpdateBreadcrumbLastSection = (name: string | null) => {
  const { setLastSectionName } = useBreadcrumbOptions();
  useEffect(() => {
    setLastSectionName(name);
    return () => {
      setLastSectionName(null);
    };
  }, [name, setLastSectionName]);
};
