import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { DropdownOptions } from "interfaces";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import {
  createNewUser,
  getAllPrivileges,
  getCompaniesForUser,
  resendInvite,
  reset2fa,
  resetUserPassword,
  undeleteUser,
  unlockUser,
  updateUser,
} from "api";
import {
  Blade,
  Button,
  Container,
  Dialog,
  Divider,
  SubmitDialog,
  SwitchCustomComp,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  CheckboxFormField,
  DropdownFormField,
  InputFormField,
  MultiSelectFormField,
} from "components/hookForm";
import regex from "regex";
import styles from "./userBlade.module.scss";
import strings from "strings";
import { roleNameLookup } from "../../../../constants/roleNameLookup";
import { useMutation } from "@tanstack/react-query";

interface IUserBladeForm {
  showBlade: boolean;
  userData: any;
  handleClose: () => void;
  reloadList: () => void;
}

interface ICompanySelectCompProps {
  name: string;
  label: string;
  hasAccess: boolean;
}

interface IPrivilegesCheckProps {
  id: number;
  name: string;
  editable?: boolean;
  active?: boolean;
}

const UserBladeForm: React.FC<IUserBladeForm> = (props) => {
  const { showBlade = false, userData, handleClose, reloadList } = props;
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [privilegesOptions, setPrivilegesOptions] =
    useState<DropdownOptions[]>();
  const [submitBtnLoader, setSubmitBtnLoader] = useState<boolean>(false);
  const methods = useForm();
  const [inactivateBtnLoader, setInactivateBtnLoader] =
    useState<boolean>(false);
  const [activateBtnLoader, setActivateBtnLoader] = useState<boolean>(false);
  const [undeleteBtnLoader, setUndeleteBtnLoader] = useState<boolean>(false);
  const [inactivateDialog, setInactivateDialog] = useState<boolean>(false);
  const [activateDialog, setActivateDialog] = useState<boolean>(false);
  const [undeleteDialog, setUndeleteDialog] = useState<boolean>(false);
  const [resetPasswordDialog, setResetPasswordDialog] =
    useState<boolean>(false);
  const [reset2faDialog, setReset2faDialog] = useState<boolean>(false);
  const [resetPasswordInfoDialog, setResetPasswordInfoDialog] =
    useState<boolean>(false);
  const [resetPasswordLoading, setResetPasswordLoading] =
    useState<boolean>(false);
  const [reset2faLoading, setReset2faLoading] = useState<boolean>(false);
  const [resettedPassword, setResettedPassword] = useState<string>();
  const [specificPrivsOptions, setSpecificPrivsOptions] =
    useState<IPrivilegesCheckProps[]>();
  const [resendInviteDialog, setResendInviteDialog] = useState<boolean>(false);
  const [resendInviteLoading, setResendInviteLoading] =
    useState<boolean>(false);
  const [unlockBtnLoader, setUnlockBtnLoader] = useState<boolean>(false);
  const [unlockDialog, setUnlockDialog] = useState<boolean>(false);
  const { handleSubmit, reset, watch, setValue, control } = methods;
  const { fields } = useFieldArray({
    control,
    name: "privileges",
  });

  const isEdit = userData ? true : false;

  const t = strings.USER_STRING;

  const hasMobileAccess = watch("mobileAccess");
  const hasWebAccess = watch("webAccess");

  // whenever mobile or web checkbox unchecked useEffect will empty the company list in the dropdown
  useEffect(() => {
    if (!hasMobileAccess) {
      setValue("userCompanies", []);
    }
    if (!hasWebAccess) {
      setValue("adminCompanies", []);
    }
  }, [hasMobileAccess, hasWebAccess, setValue]);

  const getCompanies = useCallback(async () => {
    const response = await getCompaniesForUser();
    const companyOptions = response?.data?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setCompaniesOptions(companyOptions);
  }, []);

  const activateUserById = () => {
    setActivateBtnLoader(true);
    userData.active = true;
    userData.deleted = 0;
    userData.newItemNotify = true;
    const formValues = {
      email: userData.email,
      firstName: userData.firstname,
      lastName: userData.lastname,
      priv: userData.privLevel,
      mobile2fa: userData.hasMobile2FA,
      active: userData.active,
      deleted: userData.deleted,
      emailNotifications: userData.newItemNotify,
      userCompanies: userData.userCompanies.map(
        (company: any): number => company.id
      ),
      adminCompanies: userData.adminCompanies.map(
        (company: any): number => company.id
      ),
      privileges:
        specificPrivsOptions
          ?.filter((privilege) => privilege.active)
          .map((privilege) => privilege.id) || [],
    };
    reset(formValues);
    updateUser(formValues, userData.id)
      .then(() => {
        handleClose();
        toast({
          title: "Success",
          subTitle: `The user ${userData?.firstname}, ${userData?.lastname} was successfully activated.`,
        });
        reloadList();
      })
      .finally(() => {
        setActivateDialog(false);
        setActivateBtnLoader(false);
      });
  };

  const undeleteUserById = () => {
    setUndeleteBtnLoader(true);
    undeleteUser(userData.id)
      .then(() => {
        handleClose();
        toast({
          title: "Success",
          subTitle: `The user ${userData?.firstname}, ${userData?.lastname} was successfully reactivated.`,
        });
        reloadList();
      })
      .finally(() => {
        setUndeleteDialog(false);
        setUndeleteBtnLoader(false);
      });
  };

  const inactivateUserById = () => {
    setInactivateBtnLoader(true);
    userData.active = false;
    userData.deleted = 1;
    userData.newItemNotify = false;
    const formValues = {
      email: userData.email,
      firstName: userData.firstname,
      lastName: userData.lastname,
      priv: userData.privLevel,
      mobile2fa: userData.hasMobile2FA,
      active: userData.active,
      deleted: userData.deleted,
      emailNotifications: userData.newItemNotify,
      userCompanies: userData.userCompanies.map(
        (company: any): number => company.id
      ),
      adminCompanies: userData.adminCompanies.map(
        (company: any): number => company.id
      ),
      privileges:
        specificPrivsOptions
          ?.filter((privilege) => privilege.active)
          .map((privilege) => privilege.id) || [],
    };
    reset(formValues);
    updateUser(formValues, userData.id)
      .then(() => {
        handleClose();
        toast({
          title: "Success",
          subTitle: `The user ${userData?.firstname}, ${userData?.lastname} was successfully deactivated.`,
        });
        reloadList();
      })
      .finally(() => {
        setInactivateBtnLoader(false);
        setInactivateDialog(false);
      });
  };

  const handleReset2fa = () => {
    if (userData) {
      setReset2faLoading(true);
      reset2fa(userData?.id)
        .then(() => {
          toast({
            title: `Reset 2fa Successfully`,
            subTitle: `User ${userData?.email}`,
          });
        })
        .finally(() => {
          setReset2faLoading(false);
          setReset2faDialog(false);
          userData.twoFactorConfigured = false;
        });
    }
  };

  const handleResetPassword = () => {
    if (userData) {
      resetUserPassword(userData?.id)
        .then((res) => {
          setResettedPassword(res.data.generatedPassword);
          setResetPasswordInfoDialog(true);
        })
        .finally(() => {
          setResetPasswordLoading(false);
          setResetPasswordDialog(false);
        });
    }
  };

  const unlockLockedUser = useMutation(
    ({ userId }: { userId: number }) => {
      return unlockUser(userId);
    },
    {
      onSuccess: () => {
        toast({
          title: `Unlocked successfully`,
          subTitle: `User ${userData?.email}`,
        });
        setUnlockBtnLoader(false);
        setUnlockDialog(false);
        userData.locked = false;
        reloadList();
      },
      onError: () => {
        setUnlockDialog(false);
      },
    }
  );
  const loadPrivileges = useCallback(() => {
    getAllPrivileges().then((response) => {
      const roleOptions = response?.data?.privs.map((item) => {
        return { value: item.id, label: roleNameLookup(item.id) };
      });
      setPrivilegesOptions(roleOptions);
      const userPrivileges = userData?.privileges || [];
      const loggedUserPrivileges = response?.data?.privileges || [];
      const allPrivileges = userPrivileges.concat(loggedUserPrivileges);
      let showablePrivileges = Array.from(
        new Set(allPrivileges.map((a: any) => a.id))
      ).map((id) => {
        return allPrivileges.find((a: any) => a.id === id);
      });
      if (userData) {
        if (userData.privLevel != 45) {
          //Only Acount admin users can be granted with Receivables Manager privilege
          showablePrivileges = showablePrivileges.filter(
            (privilege) => privilege.id != 5
          );
        }
        if (userData.privLevel < 40) {
          //Only Portal users and above can be granted with Receivables Approver privilege
          showablePrivileges = showablePrivileges.filter(
            (privilege) => privilege.id != 4
          );
        }
      }
      const privilegesOptions = showablePrivileges.map((item) => {
        return {
          id: item.id,
          name: item.name,
          editable: !!loggedUserPrivileges.find(
            (priv: any) => priv.id === item.id
          ),
          active: !!userPrivileges.find((priv: any) => priv.id === item.id),
        };
      });
      setSpecificPrivsOptions(privilegesOptions);
    });
  }, [userData]);

  // loads companies and privilege dropdown values
  useEffect(() => {
    loadPrivileges();
    getCompanies();
  }, [loadPrivileges, getCompanies]);

  // if it is edit form useEffect will reset the form values with user data
  useEffect(() => {
    if (isEdit) {
      const formValues = {
        email: userData.email,
        firstName: userData.firstname,
        lastName: userData.lastname,
        priv: userData.privLevel,
        mobile2fa: userData.hasMobile2FA,
        emailNotifications: userData.newItemNotify,
        userCompanies: userData.userCompanies.map(
          (company: any): number => company.id
        ),
        adminCompanies: userData.adminCompanies.map(
          (company: any): number => company.id
        ),
        mobileAccess: false,
        webAccess: false,
        privileges: specificPrivsOptions || [],
      };
      formValues["mobileAccess"] = formValues.userCompanies.length > 0;
      formValues["webAccess"] = formValues.adminCompanies.length > 0;
      reset(formValues);
    } else {
      reset({ privileges: specificPrivsOptions });
    }
  }, [isEdit, userData, reset, specificPrivsOptions]);

  const onSubmit = (userForm: any) => {
    delete userForm?.mobileAccess;
    delete userForm?.webAccess;
    let privileges: IPrivilegesCheckProps[] = userForm.privileges.filter(
      (privilege: IPrivilegesCheckProps) => privilege.active
    );
    userForm.privileges = privileges.map((privilege) => {
      return privilege.id;
    });
    setSubmitBtnLoader(true);
    if (isEdit) {
      const companiesManagedByCurrentUser = companiesOptions?.map(
        (c) => c.value
      );

      const originalUserCompanies = userData.userCompanies.map(
        (c: { id: any }) => c.id
      );
      const userCompaniesNotEditable = originalUserCompanies.filter(
        (e: number) => !companiesManagedByCurrentUser?.includes(e)
      );
      const userCompaniesResult = Array.from(
        new Set([...userForm.userCompanies, ...userCompaniesNotEditable])
      );
      userForm.userCompanies = userCompaniesResult;

      const originalAdminCompanies = userData.adminCompanies.map(
        (c: { id: any }) => c.id
      );
      const adminCompaniesNotEditable = originalAdminCompanies.filter(
        (e: number) => !companiesManagedByCurrentUser?.includes(e)
      );
      const adminCompaniesResult = Array.from(
        new Set([...userForm.adminCompanies, ...adminCompaniesNotEditable])
      );
      userForm.adminCompanies = adminCompaniesResult;

      updateUser(userForm, userData.id)
        .then(() => {
          toast({
            title: strings.UPDATED_SUCCESSFULLY,
            subTitle: "User details updated successfully",
          });
          reloadList();
          handleClose();
        })
        .finally(() => {
          setSubmitBtnLoader(false);
        });
    } else {
      createNewUser(userForm)
        .then((resp) => {
          toast({
            title: strings.CREATED_SUCCESSFULLY,
            subTitle: `email: ${resp?.data?.email}\npassword:${resp?.data?.generatedPassword}`,
          });
          reloadList();
          handleClose();
        })
        .finally(() => {
          setSubmitBtnLoader(false);
        });
    }
  };

  // sets all company list to form payload once add all company btn clicked
  const handleAddCompanies = (name: string) => {
    let companies: (string | number)[] = [];
    companies = companiesOptions?.map((item) => item.value) || [];
    setValue(name, companies);
  };

  const canReset2FA = () => {
    if (!userData?.twoFactorConfigured) {
      return false;
    }
    return userCan(Actions.RESET_2FA, Resources.USER);
  };

  const handleResendInvite = () => {
    if (userData) {
      setResendInviteLoading(true);
      resendUserInvite.mutate({
        userId: userData.id,
      });
    }
  };

  const handleUnlockUser = () => {
    if (userData) {
      setUnlockBtnLoader(true);
      unlockLockedUser.mutate({
        userId: userData.id,
      });
    }
  };

  const resendUserInvite = useMutation(
    ({ userId }: { userId: number }) => {
      return resendInvite(userId);
    },
    {
      onSuccess: () => {
        toast({
          title: strings.USER_STRING.RESEND_INVITE_SUCCESS_TITLE,
          subTitle: strings.USER_STRING.RESEND_INVITE_SUCCESS_SUBTITLE,
        });
        setResendInviteDialog(false);
        reloadList();
        handleClose();
      },
      onError: () => {
        setResendInviteDialog(false);
      },
    }
  );

  const ResetComponent = () => {
    return (
      <Container>
        <TypoGraph content="Password has been reset to:" />
        <TypoGraph
          mt={2}
          mb={2}
          variant="h1"
          content={resettedPassword}
          className={styles.passwordText}
          align="center"
        />
        <TypoGraph
          variant="body2"
          content={`Please advice it to ${userData?.email} in a secure way (not email).`}
        />
      </Container>
    );
  };

  const CompanySelectComp = (props: ICompanySelectCompProps) => {
    const { name, label, hasAccess } = props;
    return hasAccess ? (
      <Container className={styles.childSwitchContainer}>
        <Divider orientation="vertical" sx={{ height: "100%", margin: 0 }} />
        <Container>
          <MultiSelectFormField
            name={name}
            label={label}
            options={companiesOptions}
            required
          />
          <Container className={styles.addCompanyBtn}>
            <Button
              id={t.ADD_ALL_COMPANIES}
              label={t.ADD_ALL_COMPANIES}
              variant="outlined"
              onClick={() => handleAddCompanies(name)}
            />
          </Container>
        </Container>
      </Container>
    ) : (
      <></>
    );
  };

  const BladeContent = () => {
    return (
      <Container className={styles.bladeContainer}>
        <Divider title="User Information" />
        <FormProvider {...methods}>
          <Container className={styles.nameContainer}>
            <InputFormField
              id={strings.FIRST_NAME}
              name="firstName"
              label={strings.FIRST_NAME}
              required
              maxLength={128}
            />
            <InputFormField
              id={strings.LAST_NAME}
              name="lastName"
              label={strings.LAST_NAME}
              required
              maxLength={128}
            />
          </Container>
          <InputFormField
            id={strings.EMAIL_ADDRESS}
            name="email"
            label={strings.EMAIL_ADDRESS}
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: "Please enter a valid email",
            }}
            required
            maxLength={255}
          />
          <DropdownFormField
            id={strings.PRIVILEGE_LEVEL}
            name="priv"
            label={strings.PRIVILEGE_LEVEL}
            options={privilegesOptions}
            required
          />
          <SwitchCustomComp
            name="emailNotifications"
            title={t.EMAIL_NOTIFICATION}
            caption={t.RECIEVE_EMAIL}
            style={styles.switchContainer}
          />
          <SwitchCustomComp
            name="mobile2fa"
            title={t.MOBILE2FA}
            caption={t.ENABLE_MOBILE2FA}
            style={styles.switchContainer}
          />
          <Divider
            title={t.COMPANY_ACCESS}
            sx={{ marginTop: "2rem", marginBottom: "2rem" }}
          />
          <Container className={styles.switchContainer}>
            <Container>
              <TypoGraph variant="body1" content={t.MOBILE_WEB_ACCESS} />
              <TypoGraph variant="caption" content={t.HOW_USER_ACCESS} />
            </Container>
            <Container className={styles.checkboxColumnContainer}>
              <CheckboxFormField
                id={strings.MOBILE}
                name="mobileAccess"
                label={strings.MOBILE}
                labelPlacement="top"
              />
              <CheckboxFormField
                id={strings.WEB}
                name="webAccess"
                label={strings.WEB}
                labelPlacement="top"
              />
            </Container>
          </Container>
          <CompanySelectComp
            name="userCompanies"
            label={strings.USER_COMPANIES}
            hasAccess={hasMobileAccess}
          />
          <CompanySelectComp
            name="adminCompanies"
            label={strings.ADMIN_COMPANIES}
            hasAccess={hasWebAccess}
          />
          {specificPrivsOptions && (
            <Divider
              title={strings.INDIVIDUAL_PRIVILEGES}
              sx={{ marginTop: "2rem", marginBottom: "2rem" }}
            />
          )}
          {fields.map((field, index) => {
            const item = field as unknown as IPrivilegesCheckProps;
            return (
              <Container key={`key-${field.id}`}>
                <CheckboxFormField
                  id={field.id}
                  name={`privileges.${index}.active`}
                  label={item.name}
                  disabled={!item.editable}
                />
              </Container>
            );
          })}
        </FormProvider>
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={strings.CANCEL}
          variant="outlined"
          label={strings.CANCEL}
          onClick={handleClose}
        />
        <Button
          id={isEdit ? t.UPDATE_USER : "CreateUserBlade"}
          variant="contained"
          label={isEdit ? t.UPDATE_USER : t.CREATE_USER}
          loading={submitBtnLoader}
          onClick={handleSubmit((data) => onSubmit(data))}
        />
        {isEdit && (
          <>
            <Button
              id={strings.RESET_PASSWORD}
              label={strings.RESET_PASSWORD}
              onClick={() => setResetPasswordDialog(true)}
            />
            {canReset2FA() && (
              <Button
                id={t.RESET_2FA}
                label={t.RESET_2FA}
                onClick={() => setReset2faDialog(true)}
              />
            )}
            {userData?.deleted && (
              <Button
                id={"undeleUser"}
                color="success"
                label={"Reactivate User"}
                loading={activateBtnLoader}
                onClick={() => setUndeleteDialog(true)}
              />
            )}
            {!userData.deleted && (
              <>
                {(userData?.statusDescription === "Invite expired" ||
                  userData?.statusDescription === "Invite Pending") && (
                  <Button
                    id={strings.USER_STRING.RESEND_INVITE}
                    variant="contained"
                    color={"error"}
                    label={strings.USER_STRING.RESEND_INVITE}
                    loading={resendInviteLoading}
                    onClick={() => {
                      setResendInviteDialog(true);
                    }}
                  />
                )}
                {userData?.statusDescription === "Active" && (
                  <Button
                    id={t.DEACTIVATE_USER}
                    color="error"
                    label={t.DEACTIVATE_USER}
                    loading={inactivateBtnLoader}
                    onClick={() => setInactivateDialog(true)}
                  />
                )}
                {userData?.locked &&
                  userCan(Actions.RESET_2FA, Resources.USER) && (
                    <Button
                      id={t.UNLOCK}
                      color="error"
                      label={t.UNLOCK}
                      loading={unlockBtnLoader}
                      onClick={() => setUnlockDialog(true)}
                    />
                  )}
                {userData?.statusDescription === "Inactive" && (
                  <Button
                    id={t.ACTIVATE_USER}
                    color="success"
                    label={t.ACTIVATE_USER}
                    loading={activateBtnLoader}
                    onClick={() => setActivateDialog(true)}
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    );
  };

  return (
    <>
      <Blade
        show={showBlade}
        title={isEdit ? t.UPDATE_USER : t.CREATE_USER}
        handleClose={handleClose}
        content={<BladeContent />}
        footerContent={<BladeFooter />}
        headerLine
      />
      <SubmitDialog
        type="warning"
        open={resendInviteDialog}
        handleClose={() => setResendInviteDialog(false)}
        title={strings.USER_STRING.RESEND_INVITE}
        body1={strings.USER_STRING.RESEND_INVITE_BODY_1}
        body2={strings.USER_STRING.RESEND_INVITE_BODY_2}
        primaryBtnProps={{
          id: strings.USER_STRING.RESEND_INVITE,
          label: strings.USER_STRING.RESEND_INVITE,
          onClick: handleResendInvite,
          loading: resendInviteLoading,
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.CONFIRM_INACTIVATE}
        open={inactivateDialog}
        body1={`Are you sure you'd like to update the status of the user ${userData?.firstname}, ${userData?.lastname} from active to inactive?`}
        body2={strings.CONFIRM_INACTIVATE_CAPTION}
        handleClose={() => setInactivateDialog(false)}
        primaryBtnProps={{
          id: strings.DEACTIVATE,
          label: strings.DEACTIVATE,
          onClick: inactivateUserById,
          loading: inactivateBtnLoader,
          color: "error",
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.CONFIRM_ACTIVATE}
        open={activateDialog}
        body1={`Are you sure you'd like to update the status of the user ${userData?.firstname}, ${userData?.lastname} from inactive to active?`}
        body2={strings.CONFIRM_ACTIVATE_CAPTION}
        handleClose={() => setActivateDialog(false)}
        primaryBtnProps={{
          id: strings.ACTIVATE,
          label: strings.ACTIVATE,
          onClick: activateUserById,
          loading: activateBtnLoader,
          color: "success",
        }}
      />
      <SubmitDialog
        type="warning"
        title={"Confirm Reactivate"}
        open={undeleteDialog}
        body1={`Are you sure you'd like to reactivate the user ${userData?.firstname} ${userData?.lastname}?`}
        body2={"Once reactivated, the user will be able to log in"}
        handleClose={() => setUndeleteDialog(false)}
        primaryBtnProps={{
          id: "undeleteUser",
          label: "Reactivate User",
          loading: activateBtnLoader,
          onClick: activateUserById,
          color: "success",
        }}
      />
      <SubmitDialog
        type="warning"
        open={resetPasswordDialog}
        handleClose={() => setResetPasswordDialog(false)}
        title={strings.RESET_PASSWORD}
        body1={`Please confirm that you want to reset password for ${userData?.email}`}
        body2={`If you proceed, this user will no longer be able to use the old password.`}
        primaryBtnProps={{
          id: strings.CONFIRM_RESET_PASSWORD,
          label: "Continue",
          onClick: handleResetPassword,
          loading: resetPasswordLoading,
        }}
      />
      <SubmitDialog
        type="warning"
        open={reset2faDialog}
        handleClose={() => setReset2faDialog(false)}
        title={t.RESET_2FA}
        body1={`Please confirm that you want to reset 2fa for ${userData?.email}`}
        body2={t.CONFIRM_RESET_2FA_CAPTION}
        primaryBtnProps={{
          id: strings.CONFIRM_2FA_RESET,
          label: "Reset",
          onClick: handleReset2fa,
          loading: reset2faLoading,
        }}
      />
      <SubmitDialog
        type="warning"
        open={unlockDialog}
        handleClose={() => setUnlockDialog(false)}
        title={t.UNLOCK}
        body1={`Please confirm that you want to unlock user with email ${userData?.email}`}
        body2={t.CONFIRM_UNLOCK_USER}
        primaryBtnProps={{
          id: strings.CONFIRM,
          label: t.UNLOCK,
          onClick: handleUnlockUser,
          loading: unlockBtnLoader,
        }}
      />
      <Dialog
        open={resetPasswordInfoDialog}
        handleClose={() => setResetPasswordInfoDialog(false)}
        title={strings.RESET_PASSWORD}
        content={<ResetComponent />}
        secondaryBtnProps={{
          id: "resetPasswordInfoDialog",
          label: "Ok",
          variant: "contained",
        }}
      />
    </>
  );
};

export default UserBladeForm;
