import { produce } from "immer";
import { DataActionProperty, PaymentInitialState } from "interfaces/Iredux";
import { Types } from "actions/payments";

export const initialState: PaymentInitialState = {
  paymentsList: {
    listData: {
      data: [],
    },
  },
};

const paymentsReducer = (state = initialState, action: DataActionProperty) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.SET_PAYMENTS_LIST:
        draft.paymentsList.listData.data = action.data;
        break;
      default:
        break;
    }
  });
};

export default paymentsReducer;
