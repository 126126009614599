import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SwitchField, { ISwitchProps } from "components/controllers/switch";

interface ISwitchFieldProps extends ISwitchProps {
  name: string;
  required?: boolean;
}

const SwitchFormField: React.FC<ISwitchFieldProps> = (props) => {
  const { name, required = false } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <>
            <SwitchField
              {...props}
              value={value ? Boolean(value) : false}
              onChange={onChange}
              inputRef={ref}
              id={name}
            />
            <ErrorMessage errors={errors} name={name} />
          </>
        );
      }}
    />
  );
};

export default SwitchFormField;
