import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Toast as toast, TypoGraph } from "components";
import { TextField } from "components/controllers";
import {
  IChangePasswordWithResetIdRequest,
  IValidateResetRequest,
  ObjectStrings,
} from "interfaces";
import { changePasswordWithResetId, validateReset } from "api";
import strings from "strings";
import colors from "theme/colors";
import { useLocation } from "react-router-dom";

interface IResetProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const ResetPassword = (props: IResetProps) => {
  const { styles, handleStep } = props;
  const [formValues, setFormValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPage, setShowPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resetId } = useParams();
  const { newPassword, confirmPassword } = formValues;
  const location = useLocation();
  const isResetPassword = location.pathname.includes("reset-password");
  const navigate = useNavigate();

  useEffect(() => {
    if (resetId) {
      const validate: IValidateResetRequest = { resetId: resetId };
      validateReset(validate).then((response) => {
        setShowPage(true);
        localStorage.setItem("token", response?.data?.token);
      });
    }
  }, [resetId, isResetPassword]);

  const handleFormChange = (e: React.ChangeEvent<any>, name: string) => {
    setFormValues({
      ...formValues,
      [name]: e.target.value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    let flag = false;
    if (formValues.newPassword === formValues.confirmPassword) {
      flag = true;
    } else {
      toast({
        type: "error",
        title: "Error",
        subTitle: "Passwords don't match",
      });
    }
    if (resetId && flag) {
      setLoading(true);
      const changePasswordRequest: IChangePasswordWithResetIdRequest = {
        resetId: resetId,
        newPassword: formValues.newPassword,
      };
      changePasswordWithResetId(changePasswordRequest)
        .then(() => {
          if (isResetPassword) {
            toast({ title: "Password reset successfully!" });
          } else {
            toast({ title: "Password created successfully!" });
          }
          localStorage.removeItem("token");
          navigate("login");
        })
        .finally(() => setLoading(false));
    }
  };

  return showPage ? (
    <form onSubmit={handleSubmit}>
      <Container className={styles.formContainer}>
        <TextField
          id={strings.NEW_PASSWORD}
          label={strings.NEW_PASSWORD}
          type="password"
          onChange={(e) => handleFormChange(e, "newPassword")}
        ></TextField>
        <TextField
          id={strings.CONFIRM_PASSWORD}
          label={strings.CONFIRM_PASSWORD}
          type="password"
          onChange={(e) => handleFormChange(e, "confirmPassword")}
        ></TextField>
        <Container className={styles.buttonContainer}>
          <Button
            id={strings.BACKTO_LOGIN}
            type="submit"
            variant="contained"
            loading={loading}
            label={
              isResetPassword ? strings.RESET_PASSWORD : strings.CREATE_PASSWORD
            }
            disabled={newPassword === "" || confirmPassword === ""}
          />
        </Container>
      </Container>
    </form>
  ) : (
    <TypoGraph
      id={strings.BACKTO_LOGIN}
      content={strings.BACKTO_LOGIN}
      onClick={() => {
        handleStep(0);
        navigate("/login");
      }}
      align="center"
      color={colors.primary}
      link
    />
  );
};

export default ResetPassword;
