import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import DisbursementAccount from "pages/disbursementAccounts/disbursementAccounts";
import OauthDisbursementAccount from "pages/disbursementAccounts/partial/OauthDisbursementAccount";
import MaintenanceReport from "../pages/maintenanceReport";
import VerifyDisbursementAccount from "../pages/disbursementAccounts/partial/VerifyDisbursementAccount";

interface IDisbursementAccountRouteProps extends RouteProps {}

const DisbursementAccountRoutes: React.FunctionComponent<
  IDisbursementAccountRouteProps
> = () => {
  return (
    <Routes>
      <Route path="/" element={<DisbursementAccount />} />
      <Route path="/:disbursementAccountId" element={<DisbursementAccount />} />
      <Route path="/oauth" element={<OauthDisbursementAccount />} />
      <Route path="/verify" element={<VerifyDisbursementAccount />} />
      <Route
        path="/:disbursementAccountId/maintenance"
        element={<MaintenanceReport />}
      />
    </Routes>
  );
};

export default DisbursementAccountRoutes;
