import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, TypoGraph } from "components";
import { TextField } from "components/controllers";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import colors from "theme/colors";
import { login, loginWithGoogle, loginWithMicrosoft } from "api";
import regex from "regex";
import startIntercom from "tools/intercomConfig";
import {
  IndividualPrivileges,
  defineUserPriv,
  userHasAnyPriv,
  userIsCardholder,
} from "tools/privilegeChecker";
import startPendo from "tools/startPendo";
import { useLocation } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance } from "../../../constants/msalConfig";
import jwtDecode from "jwt-decode";

interface ILoginProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const { styles, handleStep } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false);
  const [loadingMicrosoftLogin, setLoadingMicrosoftLogin] = useState(false);
  const location = useLocation();
  const REDIRECT_URI = `${window.location.origin}/login/`;
  const { token } = useParams();

  const validateForm = () => {
    let isValid = true;
    if (!regex.EMAIL_REGEX_PATTERN.test(email)) {
      isValid = false;
      setErrors({
        email: "Please enter a valid email address",
      });
    }
    if (isValid) {
      setErrors({
        email: "",
        password: "",
      });
    }
    return isValid;
  };

  const onLoginWithGooglePressed = () => {
    setLoadingGoogleLogin(true);
    const scope = "email";
    const url = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${scope}&response_type=token`;
    window.location.href = url;
  };

  // Verify if there is Google Access Token in URL
  useEffect(() => {
    const hash = location.hash;
    if (hash == "#error=access_denied") {
      navigate("/login");
      return;
    }
    if (hash) {
      const token = hash.split("&")[0].split("=")[1];
      loginWithGoogleToken(token);
    }
  }, [location]);

  useEffect(() => {
    if (token) {
      try {
        // Added decoder to avoid non valid tokens on url.
        jwtDecode(token);
        localStorage.setItem("token", token);
      } catch {
      } finally {
        navigate("/login");
      }
    }
  }, [token]);

  const loginWithGoogleToken = async (accessToken: string) => {
    setLoadingGoogleLogin(true);
    loginWithGoogle(accessToken)
      .then((response) => {
        localStorage.setItem("token", response?.data?.token);
      })
      .finally(() => {
        setLoadingGoogleLogin(false);
        navigate("/login");
      });
  };

  const LoginMicrosoftButton: React.FC = () => {
    const { instance } = useMsal();

    const handleLoginWithMicrosoft = () => {
      setLoadingMicrosoftLogin(true);
      instance
        .loginPopup({
          scopes: ["User.Read"],
        })
        .then((response) => {
          loginWithMicrosoftToken(response.accessToken);
        })
        .catch((error) => {
          setLoadingMicrosoftLogin(false);
        });
    };

    return (
      <Button
        id={strings.LOGIN_WITH_MICROSOFT}
        startIcon="icon_microsoft"
        type="button"
        variant="contained"
        label={strings.LOGIN_WITH_MICROSOFT}
        loading={loadingMicrosoftLogin}
        disabled={loading || loadingGoogleLogin}
        onClick={() => {
          handleLoginWithMicrosoft();
        }}
      />
    );
  };

  const loginWithMicrosoftToken = async (accessToken: string) => {
    loginWithMicrosoft(accessToken)
      .then((response) => {
        localStorage.setItem("token", response?.data?.token);
      })
      .finally(() => {
        setLoadingMicrosoftLogin(false);
        navigate("/login");
      });
  };

  const onLoginPressed = async (
    e: FormEvent,
    email: string,
    password: string
  ) => {
    e.preventDefault();
    const isValidForm = validateForm();
    if (isValidForm) {
      setLoading(true);
      const loginRequest = { email, password };
      login(loginRequest)
        .then((response) => {
          localStorage.setItem("token", response?.data?.token);
          if (response?.data?.twoFactorSetupRequired) {
            handleStep(6);
          } else if (response?.data?.twoFactorRequired) {
            if (response?.data?.otpIsRequired) {
              handleStep(7);
            } else {
              handleStep(4);
            }
          } else {
            if (process.env.REACT_APP_INTERCOM_ENABLED === "true") {
              startIntercom(response?.data?.token);
            }
            startPendo(response?.data?.token);
            userIsCardholder();
            if (defineUserPriv() <= 30) {
              //If current login user is logged in and priv is lower or equal than 30 then redirect to payment request page
              navigate("/paymentrequest");
            } else if (
              userHasAnyPriv([
                IndividualPrivileges.RECEIVABLES_APPROVER,
                IndividualPrivileges.RECEIVABLE_MANAGER,
              ])
            ) {
              // If current user has any of the Receivables Privilege, it redirects to payments page
              navigate("/payments");
            } else {
              //If does not have any of these privileges, redirects to Help screen, to avoid the 404 error screen on login
              navigate("/help");
            }
          }
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Container className={styles.formContainer}>
      <form onSubmit={(e) => onLoginPressed(e, email, password)}>
        <TextField
          id={strings.EMAIL_ADDRESS}
          label={strings.EMAIL_ADDRESS}
          type="text"
          onChange={(e: React.ChangeEvent<any>) => setEmail(e.target.value)}
          helperText={errors?.email}
          error={Boolean(errors?.email)}
        />
        <TextField
          id={strings.PASSWORD}
          label={strings.PASSWORD}
          type="password"
          onChange={(e: React.ChangeEvent<any>) => setPassword(e.target.value)}
        ></TextField>
        <Container className={styles.contentLinkContainer}>
          <TypoGraph
            id={strings.FORGOT_PASSWORD}
            variant="body1"
            content={strings.FORGOT_PASSWORD}
            onClick={() => handleStep(1)}
            color={colors.primary}
            align="right"
            link
          />
        </Container>
        <Container className={styles.buttonContainer}>
          <Button
            id={strings.LOGIN}
            type="submit"
            variant="contained"
            label={strings.LOGIN}
            loading={loading}
            disabled={
              password === "" ||
              email === "" ||
              loadingGoogleLogin ||
              loadingMicrosoftLogin
            }
          />
        </Container>
        <TypoGraph
          variant="body2"
          content={"Or"}
          color={colors.primary}
          align="center"
          sx={{ margin: "8px" }}
        />
        <Container className={styles.buttonsContainer}>
          <Button
            id={strings.LOGIN_WITH_GOOGLE}
            startIcon="icon_google"
            type="button"
            variant="contained"
            label={strings.LOGIN_WITH_GOOGLE}
            loading={loadingGoogleLogin}
            disabled={loading || loadingMicrosoftLogin}
            onClick={() => {
              onLoginWithGooglePressed();
            }}
          />
          {process.env.REACT_APP_ENABLE_LOGIN_WITH_MICROSOFT === "true" && (
            <MsalProvider instance={msalInstance}>
              <LoginMicrosoftButton />
            </MsalProvider>
          )}
        </Container>
      </form>
    </Container>
  );
};

export default Login;
