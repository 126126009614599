import React from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Search } from "@mui/icons-material";

interface ISearchFieldProps {
  id?: string;
  label?: string;
  variant?: "outlined" | "filled" | "standard";
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FunctionComponent<ISearchFieldProps> = (
  props
) => {
  const { id, variant = "outlined", onChange, label } = props;
  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
    />
  );
};
