import { Controller, useFormContext, Validate } from "react-hook-form";
import DropdownField, { IDropdownProps } from "components/controllers/dropdown";

export interface IDropdownFieldProps extends IDropdownProps {
  name: string;
  required?: boolean;
  defaultValue?: string | number | undefined;
  validate?: Validate<any> | Record<string, Validate<any>> | undefined;
  onChanges?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
}

const DropdownFormField: React.FC<IDropdownFieldProps> = (props) => {
  const {
    name,
    required = false,
    options = [],
    defaultValue,
    validate,
    onChanges,
  } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: isRequired,
        validate: validate,
      }}
      render={({
        field: { onChange, onBlur, value = "", ref },
        fieldState: { error },
      }) => {
        return (
          <DropdownField
            id={name}
            options={options}
            value={value ? value : ""}
            onChange={onChanges || onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            {...props}
          />
        );
      }}
    />
  );
};

export default DropdownFormField;
