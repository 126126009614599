import { InputFormField, MultiSelectFormField } from "components/hookForm";
import { Container, SearchMyAdressComponent } from "components/index";
import strings from "strings";
import styles from "./propertyForm.module.scss";
import { DropdownOptions } from "interfaces";
import React from "react";

interface AddressFormComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: IPropertyCustomLabels;
  hideSearch?: boolean;
  customStyle?: string;
}

export interface IPropertyCustomLabels {
  add1?: string;
  add2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}
const PropertyForm: React.FC<AddressFormComponentProps> = ({
  statesOptions,
  customLabels,
  hideSearch = false,
  customStyle,
}) => {
  return (
    <Container className={styles.propertyAddressContainer}>
      {!hideSearch && (
        <Container className={styles.searchMyAddressContainer}>
          <SearchMyAdressComponent />
        </Container>
      )}
      <Container
        className={customStyle ? styles[customStyle] : styles.propertyContainer}
      >
        <InputFormField
          name="add1"
          label={customLabels?.add1 || "Street Address"}
          required
        />
        <InputFormField name="add2" label={customLabels?.add2 || "Address 2"} />
        <InputFormField
          name="city"
          label={customLabels?.city || "City"}
          required
        />
        <Container className={styles.stateZipContainer}>
          <MultiSelectFormField
            id={strings.STATE}
            name="state"
            label={customLabels?.state || strings.STATE}
            options={statesOptions}
            isMulti={false}
            required
            {...{ backgroundColor: styles.stateSelect }}
          />
          <InputFormField
            name="postalCode"
            type="number"
            label={customLabels?.postalCode || "Zip"}
            required
          />
        </Container>
      </Container>
    </Container>
  );
};

export default PropertyForm;
