import { Container, HotjarSuppressor, Icon, Toast as toast } from "components";
import TypoGraph from "components/typograph";
import React, { createRef } from "react";
import Dropzone from "react-dropzone";
import { useFormContext, Controller } from "react-hook-form";
import colors from "theme/colors";
import styles from "./dropzone.module.scss";
import { ErrorMessage } from "@hookform/error-message";
import Resizer from "react-image-file-resizer";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";

interface DropzoneControllerProps {
  name: "frontImageBase64" | "backImageBase64";
  label: string;
  defaultValue?: File[];
  required?: boolean;
  whitelabelConfiguration?: IWhitelabelConfiguration;
}

const DropzoneController: React.FC<DropzoneControllerProps> = ({
  name,
  label,
  defaultValue = [],
  required = false,
  whitelabelConfiguration,
}) => {
  const { control, setValue, getValues } = useFormContext();
  const dropzoneFrontRef: any = createRef();
  const isRequired = required ? "Field is Required" : undefined;
  const previewName = name + "preview";
  const maxWidth = 2560;
  const maxHeight = 1440;

  const resizeFile = async (file: File) =>
    new Promise<string>((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri.toString()),
        "base64"
      );
    });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: isRequired,
      }}
      render={({
        field: { onChange, value, onBlur },
        formState: { errors },
      }) => {
        return (
          <Container>
            {getValues(previewName) && (
              <HotjarSuppressor>
                <Icon
                  name="check"
                  type="images"
                  width={400}
                  height={200}
                  url={getValues(previewName)}
                  imageId={"frontImage"}
                  boxClassName={styles.checkImage}
                  objectFit="contain"
                />
              </HotjarSuppressor>
            )}
            <Dropzone
              ref={dropzoneFrontRef}
              accept={{ "image/*": [] }}
              multiple={false}
              onDrop={async (file: any) => {
                try {
                  const image = await resizeFile(file[0]);
                  setValue(
                    name,
                    image?.toString()?.replace("data:image/jpeg;base64,", "")
                  );
                  setValue(previewName, URL.createObjectURL(file[0]));
                } catch {
                  setValue(name, undefined);
                  setValue(previewName, undefined);
                  toast({ type: "error", title: "Error uploading image" });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {getValues(previewName) ? (
                    <div
                      {...getRootProps({
                        className: "dropzone",
                        onDrop: (event) => event.stopPropagation(),
                      })}
                    >
                      <input {...getInputProps()} />
                      <Container className={styles.changeImageContainer}>
                        <TypoGraph
                          variant={"h3"}
                          content={"Change Image"}
                          align="center"
                          color={whitelabelConfiguration?.backgroundColor}
                          className={styles.changeImageBtn}
                        />
                      </Container>
                    </div>
                  ) : (
                    <div
                      {...getRootProps({
                        className: "dropzone",
                        onDrop: (event) => event.stopPropagation(),
                      })}
                      className={styles.dropzoneContainer}
                    >
                      <input {...getInputProps()} />
                      <Container className={styles.fileUploadText}>
                        <Icon name="icon_camera" size={46} />
                        <TypoGraph
                          content={label}
                          color={colors.primary}
                          className={styles.dropzoneText}
                        />
                      </Container>
                    </div>
                  )}
                </>
              )}
            </Dropzone>
            <ErrorMessage errors={errors} name={name} />
          </Container>
        );
      }}
    />
  );
};

export default DropzoneController;
