import React, { useState } from "react";
import { profileTabs } from "constants/tabs";
import { Card, Container, HeaderBar, TabPanel, Tabs } from "components";
import strings from "strings";
import styles from "./profile.module.scss";
import PasswordTab from "./partial/passwordTab";
import ProfileTab from "./partial/profileTab";
import SignatureTab from "./partial/signatureTab";
import decodeToken from "tools/token";
import { getCurrentUserDetails } from "api";

const t = strings.PROFILE_STRING;
const userStrings = strings.USER_STRING;

const Profile: React.FunctionComponent = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentTwoFactorAuthMethod, setCurrentTwoFactorAuthMethod] =
    useState<number>();

  const getUserTwoFactorMethod = () => {
    const userDetails = decodeToken();
    if (userDetails) {
      getCurrentUserDetails().then((res) => {
        setCurrentTwoFactorAuthMethod(res?.data?.twoFactorAuthMethod);
      });
    }
  };

  return (
    <Container>
      <HeaderBar title={t.PROFILE} />
      <Tabs
        tabData={profileTabs || []}
        value={tabValue}
        handleChange={(_e: React.SyntheticEvent, val: number) =>
          setTabValue(val)
        }
      />
      <Card>
        <Container className={styles.tabPanelContainer}>
          <TabPanel value={tabValue} index={0}>
            <ProfileTab
              styles={styles}
              profileStrings={t}
              userStrings={userStrings}
              setTwoFactorAuthMethod={(params: number) =>
                setCurrentTwoFactorAuthMethod(params)
              }
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SignatureTab
              styles={styles}
              profileStrings={t}
              userStrings={userStrings}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PasswordTab
              styles={styles}
              passwordStrings={t}
              twoFactorAuthMethod={currentTwoFactorAuthMethod}
              onSuccess={() => getUserTwoFactorMethod()}
            />
          </TabPanel>
        </Container>
      </Card>
    </Container>
  );
};

export default Profile;
