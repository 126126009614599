import { useMutation, useQuery } from "@tanstack/react-query";
import { Container, PlaidContainer } from "components";
import { useNavigate } from "react-router";
import { getVerificationToken, verifyDisbursementAccount } from "api";
import { useSearchParams } from "react-router-dom";

const VerifyDisbursementAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const verificationTokenQuery = useQuery({
    queryKey: ["getVerificationToken"],
    queryFn: async () => {
      const disbursementAccountId = searchParams.get("disbursementAccountId");
      return getVerificationToken(
        disbursementAccountId ? +disbursementAccountId : 0
      );
    },
    refetchOnWindowFocus: false,
  });

  const verifyMutation = useMutation(async () => {
    const disbursementAccountId = searchParams.get("disbursementAccountId");
    try{
      await verifyDisbursementAccount(
        disbursementAccountId ? +disbursementAccountId : 0
      );
    }
    finally {
      navigate(`/settings/disbursementAccounts`);
    }
  });
  return (
    <Container>
      <Container>
        {verificationTokenQuery.isSuccess && (
          <PlaidContainer
            options={{
              onSuccess: (public_token, metadata) => {
                console.log("Successfuly verified token");
                verifyMutation.mutate();
              },
              onExit: (err, metadata) => {
                navigate(`/settings/disbursementAccounts`, {
                  state: {
                    err,
                    metadata,
                  },
                });
              },
              token: verificationTokenQuery.data.data.token,
            }}
          />
        )}
      </Container>
    </Container>
  );
};

export default VerifyDisbursementAccount;
