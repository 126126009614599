import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import BillingAccountsPage from "pages/billingAccounts/billingAccountsPage";
import BillingAccountForm from "pages/billingAccounts/billingAccountForm";
import { PrivDependantRoute } from "./PrivDependantRoute";
import { Actions, Resources } from "tools/privilegeChecker";

const BillingAccountRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <BillingAccountsPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:billingAccountId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <BillingAccountsPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/create"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.WRITE,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <BillingAccountForm />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:billingAccountId/edit"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.UPDATE,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <BillingAccountForm />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default BillingAccountRoutes;
