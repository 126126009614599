import { IPaymentImage } from "interfaces";
import React from "react";
import { Container, Icon } from "components";
import HotjarSuppressor from "components/hotjarSuppressor";

interface IDcNotFailedProps {
  frontImage: IPaymentImage | undefined;
  backImage: IPaymentImage | undefined;
  handleImageNode: (index: "front" | "back") => void;
}

const DcNotFailedComponent: React.FC<IDcNotFailedProps> = (props) => {
  const { frontImage, backImage, handleImageNode } = props;

  return (
    <HotjarSuppressor>
      <Container
        sx={{
          gridTemplateColumns: "auto auto",
          justifyContent: "space-between",
          padding: "5px 10px",
        }}
      >
        {frontImage && backImage && (
          <>
            <Icon
              name="check"
              type="images"
              width="90%"
              url={`data:image/png;base64,${frontImage?.base64Image}`}
              onClick={() => handleImageNode("front")}
              imageId={"frontImage"}
              iconButton
            />
            <Icon
              name="check"
              type="images"
              width="90%"
              url={`data:image/png;base64,${backImage?.base64Image}`}
              onClick={() => handleImageNode("back")}
              imageId={"backImage"}
              iconButton
            />
          </>
        )}
      </Container>
    </HotjarSuppressor>
  );
};

export { DcNotFailedComponent };
