import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import PaymentRequestList from "../pages/payments/request/requestList";

const PaymentRequestRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentRequestList />} />
      <Route path="/detail/:paymentId" element={<PaymentRequestList />} />
    </Routes>
  );
};

export default PaymentRequestRoutes;
