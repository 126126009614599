import * as React from "react";
import MuiContainer from "@mui/material/Container";
import styles from "./container.module.scss";

interface IContainerProps {
  className?: string;
  disableGutters?: boolean;
  children?: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  sx?: object;
  innerHtml?: {
    __html: string;
  };
}

const Container: React.FunctionComponent<IContainerProps> = (props) => {
  const {
    className,
    disableGutters = true,
    maxWidth = false,
    children,
    sx = {},
    innerHtml,
  } = props;
  return (
    <MuiContainer
      className={`${className} ${styles.containerWrapper} `}
      sx={{ display: "grid", ...sx }}
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      dangerouslySetInnerHTML={innerHtml}
    >
      {children}
    </MuiContainer>
  );
};

export default Container;
