import axios from "axios";
import { Toast as toast } from "components";

const PASSPORT_API_SDK = process.env.REACT_APP_PASSPORT_API_SDK;

const requestPassport = axios.create({
  baseURL: PASSPORT_API_SDK,
  timeout: 20000,
});

requestPassport.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast({
      type: "error",
      title: "Error",
      subTitle:
        error?.response?.data?.errorMessage ||
        "Error getting Credit card token",
    });

    return Promise.reject(error);
  }
);

export default requestPassport;
