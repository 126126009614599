import { Navigate } from "react-router-dom";
import { userCan } from "tools/privilegeChecker";
import { ISecurityRequirements } from "constants/menuBar";

export interface IPrivDependantRoute {
  requirements: ISecurityRequirements | ISecurityRequirements[];
  children: JSX.Element;
}

export function PrivDependantRoute(props: IPrivDependantRoute) {
  const { requirements, children } = props;
  const requirementsArray = Array.isArray(requirements)
    ? requirements
    : [requirements];

  const userCanView = requirementsArray.some((requirement) =>
    userCan(requirement.action, requirement.resource)
  );

  if (!userCanView) {
    return <Navigate to="/" />;
  }

  return children;
}
