import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  createStripeCustomerPortalSessionUrl,
  getBillingAccountsForCardholder,
} from "api";
import { IBillingAccountResponse, IPageResponse } from "interfaces";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Loader,
  TableComponent,
} from "components";
import { myBillingColumns } from "constants/tableData";
import strings from "strings";
import styles from "./myBillingPage.module.scss";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";

const MyBillingPage: React.FunctionComponent = () => {
  const [billingAccountPage, setBillingAccountPage] =
    useState<IPageResponse<IBillingAccountResponse>>();
  const [loading, setLoading] = useState(false);
  const [showBlade, setShowBlade] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [selectedBillingAccount, setSelectedBillingAccount] =
    useState<IBillingAccountResponse>();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const navigate = useNavigate();
  const { billingAccountId } = useParams();

  const t = strings.MY_BILLING_STRING;

  useEffect(() => {
    if (billingAccountId) {
      setShowBlade(true);
      setBladeLoader(true);
      const selectedBA = billingAccountPage?.items.find(
        (billingAccount) => billingAccount.id === +billingAccountId
      );
      if (selectedBA) {
        setSelectedBillingAccount(selectedBA);
      }
      setBladeLoader(false);
    } else {
      setShowBlade(false);
    }
  }, [billingAccountId, billingAccountPage?.items]);

  const loadBillingAccounts = useCallback(
    async (
      pageNumber: number = 0,
      pageSize: number = 10,
      searchValue: string = ""
    ) => {
      try {
        setLoading(true);
        const response = await getBillingAccountsForCardholder(
          pageNumber,
          pageSize,
          searchValue,
          sortModel[0]?.field,
          sortModel[0]?.sort
        );
        setBillingAccountPage(response.data);
      } finally {
        setLoading(false);
      }
    },
    [sortModel]
  );
  myBillingColumns[0].handleClick = (value: IBillingAccountResponse) => {
    navigate(`/settings/mybilling/${value.id}`);
  };

  useEffect(() => {
    loadBillingAccounts();
  }, [loadBillingAccounts]);

  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <Divider title="Details" />
        <DataGrid
          columns={[
            { name: "name", label: "Name" },
            { name: "customerId", label: "Customer Id" },
            {
              data: selectedBillingAccount?.cardholder.email,
              name: "",
              label: "Cardholder",
            },
            {
              data: selectedBillingAccount?.priceProducts?.length?.toString(),
              name: "",
              label: "Price products",
            },
          ]}
          data={selectedBillingAccount}
        />
        <Divider title="Associated Companies" />
        {selectedBillingAccount?.companies.map((company) => {
          return (
            <DataGrid
              columns={[
                {
                  name: "companyName",
                  label: "",
                },
              ]}
              data={company}
            />
          );
        })}
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={t.SET_IN_STRIPE}
          variant="outlined"
          label={t.SET_IN_STRIPE}
          onClick={() => {
            if (selectedBillingAccount) {
              createStripeCustomerPortalSessionUrl(
                selectedBillingAccount?.id
              ).then((response) =>
                window.open(response?.data?.url, "_blank", "noreferrer")
              );
            }
          }}
        />
        <Button
          id={t.VIEW_INVOICES}
          color="primary"
          label={t.VIEW_INVOICES}
          loading={false}
          onClick={() => {
            navigate(`invoices`);
          }}
        />
      </Container>
    );
  };

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadBillingAccounts(0, 10, value);
  };

  return (
    <Container>
      <HeaderBar title={t.MY_BYLLING_ACCOUNTS} />
      <TableComponent
        title={t.BILLING_ACCOUNTS}
        data={billingAccountPage?.items || []}
        totalCount={billingAccountPage?.totalCount}
        columns={myBillingColumns}
        loading={loading}
        handlePagination={(currentPage, pageSize) =>
          loadBillingAccounts(currentPage, pageSize, searchValue)
        }
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        handleSortModelChange={(s) => {
          if (JSON.stringify(s) !== JSON.stringify(sortModel)) {
            setSortModel(s);
          }
        }}
      />
      <Blade
        show={showBlade}
        title={!bladeLoader ? t.BILLING_ACCOUNT_DETAILS : ""}
        handleClose={() => navigate("/settings/mybilling")}
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
    </Container>
  );
};

export default MyBillingPage;
