import { Controller, useFormContext } from "react-hook-form";
import MultiSelectField, {
  IMultiSelectDropdownProps,
} from "components/controllers/multiSelectDropdown";

interface IMultiselectFormFieldProps extends IMultiSelectDropdownProps {
  name: string;
  required?: boolean;
}

const MultiSelectFormField: React.FC<IMultiselectFormFieldProps> = (props) => {
  const { name, required = false } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate: {},
      }}
      render={({
        field: { onChange, value },
        formState: { errors },
        fieldState: { error },
      }) => {
        return (
          <MultiSelectField
            id={name}
            value={value ? value : ""}
            onChange={onChange}
            errors={errors}
            error={Boolean(error)}
            required={required}
            {...props}
          />
        );
      }}
    />
  );
};

export default MultiSelectFormField;
