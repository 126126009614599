import styles from "./searchMyAddressInput.module.scss";
import { useFormContext } from "react-hook-form";
import strings from "strings";
import { Autocomplete } from "@react-google-maps/api";

interface SearchMyAdressComponentProps {
  customAddress1?: string;
  customAddress2?: string;
  customCity?: string;
  customState?: string;
  customZipCode?: string;
}

const SearchMyAdressComponent: React.FC<SearchMyAdressComponentProps> = (
  props
) => {
  const { setValue } = useFormContext();
  const {
    customAddress1,
    customAddress2,
    customCity,
    customState,
    customZipCode,
  } = props;

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (!place.address_components) {
      return;
    }
    const addressComponents = place.address_components;
    const address1Component = addressComponents.find((component) =>
      component.types.includes("street_number")
    );
    const routeComponent = addressComponents.find((component) =>
      component.types.includes("route")
    );
    const address2Component = addressComponents.find((component) =>
      component.types.includes("subpremise")
    );
    const cityComponent = addressComponents.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const zipCodeComponent = addressComponents.find((component) =>
      component.types.includes("postal_code")
    );

    const address1 = `${address1Component?.long_name || ""} ${
      routeComponent?.long_name || ""
    }`;

    const address2 = address2Component?.long_name || "";
    const city = cityComponent?.long_name || "";
    const state = stateComponent?.short_name || "";
    const zipCode = zipCodeComponent?.long_name || "";

    setValue(customAddress1 || "add1", address1 != " " ? address1 : undefined);
    setValue(customAddress2 || "add2", address2);
    setValue(customCity || "city", city);
    setValue(customState || "state", state);
    setValue(customZipCode || "postalCode", zipCode);
  };

  return (
    <>
      <Autocomplete
        restrictions={{ country: "us" }}
        onLoad={(autocomplete) => {
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            handlePlaceSelect(place);
          });
        }}
      >
        <input
          type="text"
          placeholder={strings.SEARCH_MY_ADDRESS}
          className={styles.inputTextField}
        />
      </Autocomplete>
    </>
  );
};

export default SearchMyAdressComponent;
