import { Container, CustomDivider, UserInfoForm } from "components";
import { InputFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import regex from "regex";
import strings from "strings";
import { DropdownOptions } from "interfaces";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";

interface InvoiceCollectionComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const InvoiceCollectionComponent: React.FC<InvoiceCollectionComponentProps> = (
  props
) => {
  const { statesOptions, customLabels } = props;

  return (
    <Container className={styles.transactionDetailContainer}>
      <Container>
        <CustomDivider title="Your Info" />
        <UserInfoForm />
        <CustomDivider title="Payer Info" />
        <Container className={styles.buyersContainer}>
          <InputFormField
            name="buyerFirstName"
            label="Payer's First Name"
            required
          />
          <InputFormField
            name="buyerLastName"
            label="Payer's Last Name"
            required
          />
          <InputFormField
            name="buyerEmail"
            label="Payer's Email"
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: strings.PLEASE_ENTER_A_VALID_EMAIL,
            }}
            required
          />
        </Container>
        <CustomDivider title="Invoice Details" />
        <Container className={styles.singleInputContainer}>
          <InputFormField name="invoiceNumber" label="Invoice #" required />
        </Container>
      </Container>
    </Container>
  );
};

export default InvoiceCollectionComponent;
