import { IDecodedToken } from "interfaces/IdecodedToken";
import jwt_Decode from "jwt-decode";

export default function startPendo(token: string) {
  const decodedToken: IDecodedToken = jwt_Decode(token);

  // @ts-ignore
  window.pendo.initialize({
    visitor: {
      id: decodedToken.puid, // Required if user is logged in, default creates anonymous ID
      email: decodedToken.pemail1,
      // full_name:    // Recommended if using Pendo Feedback
      role: decodedToken.userpriv,

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: decodedToken.puid, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
}
