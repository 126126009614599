import * as React from "react";
import Typography from "@mui/material/Typography";

export interface ITypoGraphProps {
  id?: string;
  children?: React.ReactNode;
  content?: string;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "overline";
  align?: "center" | "inherit" | "justify" | "left" | "right";
  classes?: object;
  gutterBottom?: boolean;
  paragraph?: boolean;
  component?: React.ElementType;
  onClick?: React.FormEventHandler;
  mb?: number;
  mt?: number;
  color?: string;
  sx?: object;
  link?: boolean;
  className?: string;
}

const TypoGraph: React.FunctionComponent<ITypoGraphProps> = (props) => {
  const {
    id,
    children,
    variant,
    sx,
    align = "left",
    gutterBottom = true,
    paragraph = true,
    classes,
    mb = 0,
    color,
    link = false,
    content,
    className,
    mt,
    component = "div",
    onClick = () => {},
  } = props;
  let typoSx = { ...sx };

  if (link) {
    typoSx = { ...typoSx, cursor: "pointer" };
  }

  if (color) {
    typoSx = { ...typoSx, color: color };
  }

  return (
    <Typography
      id={id}
      className={className}
      sx={typoSx}
      variant={variant}
      classes={classes}
      align={align}
      gutterBottom={gutterBottom}
      paragraph={paragraph}
      component={component}
      onClick={onClick}
      mb={mb}
      mt={mt}
    >
      {children || content}
    </Typography>
  );
};

export default TypoGraph;
