import { FormEvent, useState } from "react";
import { Button, Container, Toast as toast, TypoGraph } from "components";
import { TextField } from "components/controllers";
import { IForgotPasswordRequest, ObjectStrings } from "interfaces";
import { forgotPassword } from "api";
import strings from "strings";
import colors from "theme/colors";
import regex from "regex";

interface IProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const ForgotPassword = (props: IProps) => {
  const { styles, handleStep } = props;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!regex.EMAIL_REGEX_PATTERN.test(email)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
      setLoading(true);
      const request: IForgotPasswordRequest = { email: email };
      forgotPassword(request)
        .then(() =>
          toast({
            type: "success",
            title: "Mail sent successfully!",
          })
        )
        .finally(() => {
          setLoading(false);
          handleStep(0);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container className={styles.formContainer}>
        <TextField
          id={strings.EMAIL_ADDRESS}
          label={strings.EMAIL_ADDRESS}
          type="text"
          onChange={(e: React.ChangeEvent<any>) => setEmail(e.target.value)}
          helperText={error}
          error={Boolean(error)}
        ></TextField>
        <TypoGraph
          id={strings.BACKTO_LOGIN}
          content={strings.BACKTO_LOGIN}
          onClick={() => handleStep(0)}
          align="center"
          color={colors.primary}
          link
        />
        <Container className={styles.buttonContainer}>
          <Button
            id={strings.RECOVER_PASSWORD}
            type="submit"
            loading={loading}
            variant="contained"
            label={strings.RECOVER_PASSWORD}
            disabled={email === ""}
          />
        </Container>
      </Container>
    </form>
  );
};

export default ForgotPassword;
