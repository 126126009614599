import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CheckBoxField, { ICheckBoxProps } from "components/controllers/checkBox";
import Container from "components/layout";
import { TypoGraph } from "components";
import colors from "theme/colors";
import styles from "./checkbox.module.scss";

interface ICheckboxFieldProps extends ICheckBoxProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  componentLabel?: JSX.Element;
  labelPlacement?: "bottom" | "top" | "end" | "start";
  checkboxStyle?: string;
  onChanges?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  checkeds?: boolean;
  values?: boolean;
}

const CheckboxFormField: React.FC<ICheckboxFieldProps> = (props) => {
  const {
    name,
    required = false,
    disabled = false,
    componentLabel = <></>,
    labelPlacement = "end",
    checkboxStyle,
    onChanges,
    checkeds,
  } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <Container className={!checkboxStyle ? styles.columnContainer : ""}>
            <Container className={!checkboxStyle ? styles.rowContainer : ""}>
              <CheckBoxField
                {...props}
                id={name}
                value={
                  checkeds !== undefined ? Boolean(checkeds) : Boolean(value)
                }
                onChange={onChanges || onChange}
                inputRef={ref}
                labelPlacement={labelPlacement}
                checkBoxStyle={checkboxStyle}
                checked={checkeds}
              />
              {componentLabel}
            </Container>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <TypoGraph color={colors.error} content={message} />
              )}
            />
          </Container>
        );
      }}
    />
  );
};

export default CheckboxFormField;
