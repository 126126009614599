import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MICROSOFT_MSAL_CLIENT_ID}`,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${window.location.origin}/login/`,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
