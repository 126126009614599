import { IPayment } from "interfaces";

export default function formatAmount(cents: number): string {
  return "$" + withCommas(formatAmountDecimal(cents));
}

function withCommas(str: string): string {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatAmountDecimal(totalCents: number): string {
  const dollars = Math.trunc(totalCents / 100);
  const cents = totalCents % 100;
  return dollars + "." + (cents < 10 ? "0" : "") + cents.toString();
}

export const listToString = (list: any) => {
  return list.filter((elem: any) => elem && true).join(", ");
};

export const propertyAddress = (formData: any) => {
  return listToString([formData.add1, formData.add2]);
};

export const locatedInData = (formData: any) => {
  return listToString([formData.city, formData.postalCode, formData.state]);
};

export const formatDateToMMDDYYYY = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}${day}${year}`;
};

//format from YYYY-MM-DD to MMDDYYYY
export function formatDateToMMDDYYYYFromYYYY_MM_DD(
  originalDate: string | undefined
) {
  if (!originalDate) {
    return;
  }
  var datePicker = originalDate.split("-");
  var year = datePicker[0];
  var month = datePicker[1];
  var day = datePicker[2];

  return month + day + year;
}

export function formatDateToMM_DD_YYYYFromMM_YYYY(
  originalDate: string | undefined
) {
  if (!originalDate) {
    return;
  }
  var datePicker = originalDate.split("-");
  var year = datePicker[0];
  var month = datePicker[1];

  return month + "01" + year;
}

export function camelCaseToSeparatedWords(
  camelCaseString: string | undefined | null
): string {
  if (!camelCaseString) {
    return "";
  }

  const wordsArray = camelCaseString.split(/(?=[A-Z])/);

  return wordsArray.join(" ").toLowerCase();
}

export function maskPropertyAddress(payment?: IPayment) {
  if (!payment) {
    return;
  }
  const propertyAddress = `${payment?.address?.address1}, ${payment?.address?.state} ${payment?.address?.pcode}`;
  payment.addressMask = propertyAddress;
  return payment;
}
