import { useState } from "react";
import Select, { MultiValue } from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import { Container, TypoGraph } from "components";
import { DropdownOptions, ObjectAnyKeys } from "interfaces";
import styles from "./multiSelect.module.scss";
import colors from "theme/colors";

export interface IMultiSelectDropdownProps {
  id?: string;
  label: string;
  options?: DropdownOptions[];
  onChange?: (selectedOption: MultiValue<any>) => void;
  value?: any;
  isSearchable?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  errors?: any;
  name?: string;
  error?: boolean;
  required?: boolean;
  isMulti?: boolean;
  backgroundColor?: string;
}

const MultiSelectDropdown: React.FC<IMultiSelectDropdownProps> = (props) => {
  const {
    value,
    options,
    label,
    isSearchable = true,
    isClearable = true,
    onChange,
    disabled = false,
    errors,
    name,
    error = false,
    required = false,
    id,
    isMulti = true,
    backgroundColor = "white",
  } = props;

  const [focused, setFocused] = useState(false);

  // value for `react-select` has to be the whole object not just the value:
  // ex:
  // value={label: 'Foo', value: 1} // correct
  // value=1 //wrong
  let selectedOption = [];

  if (!isMulti) {
    const selectedValue =
      options && value && options?.find((o) => o.value === value);
    selectedOption = selectedValue ? selectedValue : [];
  } else {
    selectedOption =
      options &&
      value &&
      value?.map((id: string | number) => options?.find((o) => o.value === id));
  }

  let dropDownLabel = label;

  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: 180,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "100%",
      zIndex: 20,
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 20,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor,
    }),
  };

  const handleChange = (selectedList: MultiValue<any>) => {
    const selectedItems = selectedList?.map((item) => item.value);
    onChange && onChange(selectedItems);
  };

  const handleSingleSelect = (selectedValue: ObjectAnyKeys) => {
    if (selectedValue === null) {
      onChange && onChange([]);
    } else {
      onChange && onChange(selectedValue.value);
    }
  };

  if (required) {
    dropDownLabel = `${label} *`;
  }

  return (
    <Container
      className={`${styles.multiSelectContainer} ${error ? styles.error : ""}`}
    >
      {(selectedOption?.length > 0 ||
        (!isMulti ? Object?.keys(selectedOption)?.length > 0 : false) ||
        focused) && (
        <TypoGraph
          content={dropDownLabel}
          className={`${styles.materialLabel}`}
        />
      )}
      <Select
        isMulti={isMulti}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        options={options}
        value={selectedOption}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={disabled}
        onChange={(selectedList) =>
          isMulti
            ? handleChange(selectedList)
            : handleSingleSelect(selectedList)
        }
        placeholder={!focused ? dropDownLabel : " "}
        styles={customStyles}
        name={name}
        id={id}
        menuPlacement="auto"
        classNamePrefix="searchable-select"
      />
      <ErrorMessage
        errors={errors}
        name={name ? name : ""}
        render={({ message }) => (
          <TypoGraph
            variant="caption"
            color={colors.error}
            content={message}
            sx={{
              margin: "3px 14px 0 14px",
            }}
          />
        )}
      />
    </Container>
  );
};

export default MultiSelectDropdown;
