import { deviceDetect } from "react-device-detect";

export function getMobileDeviceInformation() {
  let deviceInfo = deviceDetect(navigator.userAgent);

  if (
    deviceInfo.isBrowser === true ||
    deviceInfo.os.toUpperCase() === "MAC OS"
  ) {
    return {
      osFamily: "BROWSER",
      osVersion: deviceInfo.osName + " " + deviceInfo.osVersion,
      applicationVersion: undefined,
      description: "Using Browser: " + deviceInfo.browserName,
      captureMode: "UPLOADED",
    };
  }
  return {
    osFamily: deviceInfo.os ? deviceInfo.os.toUpperCase() : "",
    osVersion: deviceInfo.osVersion,
    applicationVersion: undefined,
    description: deviceInfo.model,
    captureMode: "UPLOADED",
  };
}
