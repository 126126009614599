import MyBillingPage from "pages/mybilling";
import BillingAccountInvoices from "pages/mybilling/billingAccountInvoices";
import { Route, RouteProps, Routes } from "react-router-dom";
import { Actions, Resources } from "tools/privilegeChecker";
import { PrivDependantRoute } from "./PrivDependantRoute";

const myBillingRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.MY_BILLING_ACCOUNTS,
            }}
          >
            <MyBillingPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:billingAccountId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.MY_BILLING_ACCOUNTS,
            }}
          >
            <MyBillingPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:billingAccountId/invoices"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.MY_BILLING_ACCOUNTS,
            }}
          >
            <BillingAccountInvoices />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:billingAccountId/invoices/:invoiceId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.MY_BILLING_ACCOUNTS,
            }}
          >
            <BillingAccountInvoices />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default myBillingRoutes;
