import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getUserDetails, getUsers, getUserSummary } from "api";
import { IPageResponse, IUserResponse, ObjectAnyKeys } from "interfaces";
import {
  Blade,
  Button,
  CompanyFilterDialog,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Loader,
  TableComponent,
  Tabs,
} from "components";
import { userDetailsLabel, userFilters, usersList } from "constants/tableData";
import { UserBladeForm } from "./partial";
import { defineUserPriv } from "tools/privilegeChecker";
import strings from "strings";
import styles from "./usersPage.module.scss";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { roleNameLookup } from "../../constants/roleNameLookup";

const UsersPage: React.FunctionComponent = () => {
  const [userPage, setUserPage] = useState<IPageResponse<IUserResponse>>();
  const [user, setUser] = useState<IUserResponse>();
  const [loading, setLoading] = useState(false);
  const [showBlade, setShowBlade] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [showBladeForm, setBladeForm] = useState<boolean>(false);
  const [userFilterTab, setUserFilterTab] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [disableActions, setDisableActions] = useState<number[]>([]);
  const [userFilterName, setUserFilterName] = useState<string>(
    userFilters[0].label
  );
  const [openCompanyModal, setOpenCompanyModal] = React.useState(false);
  const [selectedCompaniesId, setSelectedCompaniesId] = useState<number[]>([]);

  const navigate = useNavigate();
  const { userId } = useParams();

  const t = strings.USER_STRING;

  const { userColumns } = usersList;

  const toggleOption = useCallback(
    (option: number) => {
      if (selectedCompaniesId.includes(option)) {
        setSelectedCompaniesId(
          selectedCompaniesId.filter((o: any) => o !== option)
        );
      } else {
        setSelectedCompaniesId([...selectedCompaniesId, option]);
      }
    },
    [selectedCompaniesId]
  );

  useEffect(() => {
    (async () => {
      if (!userId) {
        setShowBlade(false);
        return;
      }
      try {
        setShowBlade(true);
        setBladeLoader(true);
        const res = await getUserDetails(userId);
        setUser(res?.data);
      } finally {
        setBladeLoader(false);
      }
    })();
  }, [userId]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  const loadUsers = useCallback(
    async (
      page: number = 0,
      pageSize: number = 10,
      statusFilter: string = "",
      filter: string = "",
      companiesFilterId: number[] = []
    ) => {
      setLoading(true);
      const priv = defineUserPriv();
      const isOwner = priv === 120;
      const users = await getUsers(
        page,
        pageSize,
        statusFilter,
        filter,
        companiesFilterId,
        sortModel[0]?.field,
        sortModel[0]?.sort
      );
      try {
        const userList = { ...users?.data };
        const nonEditableUsersIds: number[] = [];
        userList?.items?.map((item) => {
          item.priv = roleNameLookup(item.privLevel);
          if (item.privLevel > priv) {
            nonEditableUsersIds.push(item.id);
          }
          return item;
        });
        if (!isOwner) {
          setDisableActions(nonEditableUsersIds);
        }
        setUserPage(userList);
      } finally {
        setLoading(false);
      }

      const userSummary = await getUserSummary();
      userFilters[3].count = userSummary.data.invitePending;
      userFilters[4].count = userSummary.data.inviteExpired;
    },
    [sortModel]
  );

  useEffect(() => {
    loadUsers(
      0,
      currentPageSize,
      userFilters[userFilterTab].filterValue,
      searchValue,
      selectedCompaniesId
    );
    setUserFilterName(userFilters[userFilterTab].label);
  }, [userFilterTab, loadUsers, currentPageSize, searchValue]); // executes on tab change

  const handleCreateNewUser = () => {
    setBladeForm(true);
    setUser(undefined);
  };

  const getCompanyName = (companies: ObjectAnyKeys[]) => {
    return companies.map((item) => item.name);
  };

  const BladeContent = () => {
    const payload = { ...user };
    payload.userCompanies = getCompanyName(user?.userCompanies || []);
    payload.adminCompanies = getCompanyName(user?.adminCompanies || []);
    if (user) {
      payload.priv = roleNameLookup(user.privLevel);
    }
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid columns={userDetailsLabel.details} data={payload} />
        <Divider title={strings.USER_COMPANIES} />
        <DataGrid columns={userDetailsLabel.userCompanies} data={payload} />
        <Divider title={strings.ADMIN_COMPANIES} />
        <DataGrid columns={userDetailsLabel.adminCompanies} data={payload} />
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={strings.MAINTENANCE_REPORT}
          variant="outlined"
          label={strings.MAINTENANCE_REPORT}
          onClick={() => {
            navigate(`maintenance`);
          }}
        />

        {disableActions?.includes(Number(userId)) ? (
          <></>
        ) : (
          <Button
            id={strings.UPDATE}
            label={strings.UPDATE}
            onClick={() => {
              setShowBlade(false);
              setBladeForm(true);
              const userData = userPage?.items.filter(
                (item) => item.id === Number(userId)
              );
              userData && setUser(userData[0]);
            }}
          />
        )}
      </Container>
    );
  };

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadUsers(
      0,
      currentPageSize,
      userFilters[userFilterTab].filterValue,
      value,
      selectedCompaniesId
    );
  };

  const handleClickOpenCompanyModal = () => {
    setOpenCompanyModal(true);
  };

  const handleApplyCompanyFilter = () => {
    loadUsers(
      0,
      currentPageSize,
      userFilters[userFilterTab].filterValue,
      searchValue,
      selectedCompaniesId
    );
    setOpenCompanyModal(false);
  };

  const onCleanFilter = () => {
    setSelectedCompaniesId([]);
    loadUsers(
      0,
      currentPageSize,
      userFilters[userFilterTab].filterValue,
      searchValue,
      []
    );
  };

  return (
    <>
      <HeaderBar
        title={strings.USER_HEADER_TITLE}
        primaryBtnProps={{
          id: t.CREATE_USER,
          label: t.CREATE_USER,
          onClick: handleCreateNewUser,
        }}
      />
      <Tabs
        tabData={userFilters || []}
        value={userFilterTab}
        handleChange={(_e, val) => setUserFilterTab(val)}
      />
      <TableComponent
        title={`${userFilterName} ${strings.USER_TABLE_TITLE}`}
        data={userPage?.items || []}
        totalCount={userPage?.totalCount}
        columns={userColumns}
        loading={loading}
        searchValue={searchValue}
        currentPageSize={currentPageSize}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(currentPage, pageSize) => {
          setCurrentPageSize(pageSize);
          loadUsers(
            currentPage,
            pageSize,
            userFilters[userFilterTab].filterValue,
            searchValue,
            selectedCompaniesId
          );
        }}
        handleSortModelChange={handleSortChange}
        handleCustomFilterButton={handleClickOpenCompanyModal}
        onRowClick={(row) => {
          navigate(`/settings/users/${row?.id}`);
          setBladeForm(false);
          setShowBlade(true);
        }}
      />
      {showBlade && (
        <Blade
          show={showBlade}
          title={!bladeLoader ? strings.USER_DETAILS : ""}
          handleClose={() => {
            navigate("/settings/users");
            setShowBlade(false);
            setBladeForm(false);
          }}
          headerChipProp={{
            label:
              !bladeLoader && user?.deleted
                ? "Deleted"
                : !bladeLoader && user?.statusDescription
                ? user.statusDescription
                : undefined,
            className:
              user?.deleted || user?.statusDescription != "Active"
                ? "errorChip"
                : "successChip",
          }}
          content={<BladeContent />}
          footerContent={!bladeLoader && <BladeFooter />}
        />
      )}
      {showBladeForm && (
        <UserBladeForm
          showBlade={showBladeForm}
          handleClose={() => {
            navigate("/settings/users");
            setShowBlade(false);
            setBladeForm(false);
          }}
          reloadList={() =>
            loadUsers(
              0,
              currentPageSize,
              userFilters[userFilterTab].filterValue,
              searchValue,
              selectedCompaniesId
            )
          }
          userData={user}
        />
      )}

      <CompanyFilterDialog
        open={openCompanyModal}
        onClose={() => setOpenCompanyModal(false)}
        selectedOptions={selectedCompaniesId}
        toggleOption={toggleOption}
        onCleanFilter={onCleanFilter}
        onApplyFilter={handleApplyCompanyFilter}
      />
    </>
  );
};

export default UsersPage;
