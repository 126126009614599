import { useNavigate } from "react-router";
import {
  Button,
  Card,
  Chip,
  Container,
  HeaderBar,
  Icon,
  Loader,
  TypoGraph,
} from "components";
import colors from "theme/colors";
import styles from "./paymentsCategory.module.scss";
import strings from "strings";
import { useEffect, useState } from "react";
import { IPaymentSummary } from "interfaces";
import { getPaymentSummary } from "api";

const t = strings.PAYMENTS_STRING;

export interface IPaymentSummaryCardProps {
  item: IPaymentSummary;
  key?: string;
}

const PaymentsCategory = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentSummary, setPaymentSummary] = useState<IPaymentSummary[]>();

  const navigate = useNavigate();

  useEffect(() => {
    loadPaymentSummary();
  }, []);

  const loadPaymentSummary = () => {
    getPaymentSummary()
      .then((response) => {
        setPaymentSummary(response.data);
      })
      .finally(() => setLoading(false));
  };
  const navigateToPaymentList = (typeId: number) => {
    navigate(`${typeId}`);
  };

  const ALL_PAYMENT_TYPES = 99;

  const PaymentSummaryCards = (props: IPaymentSummaryCardProps) => {
    const { item } = props;
    const icons = [
      "icon_paymentsCategory",
      "icon_securityDeposit",
      "icon_commision",
      "icon_rent",
      "icon_hud",
      "icon_other",
      "icon_remittance",
      "icon_optionFee",
      "icon_closingFunds",
      "icon_paymentsCategory",
      "icon_paymentsCategory",
      "icon_paymentsCategory",
      "icon_paymentsCategory",
      "icon_paymentsCategory",
      "icon_paymentsCategory",
    ];
    return (
      <>
        <Container>
          <Card sxContent={{ display: "grid", gridGap: "10px" }}>
            <Container>
              <Icon
                name={icons[item.typeId == ALL_PAYMENT_TYPES ? 0 : item.typeId]}
                type="icons"
                size={48}
                boxClassName={styles.icon}
              />
              <TypoGraph
                variant="h3"
                content={item.typeId === 2 ? "Commission Check" : item.typeName}
                align="center"
              />
            </Container>
            <Container className={styles.chipContainer}>
              <Chip
                label={item.newCount || "0"}
                variant="filled"
                className={styles.chip}
                style={{ backgroundColor: `${colors.success}` }}
              />
              <Chip
                label={item.dueCount || "0"}
                variant="filled"
                className={styles.chip}
                style={{ backgroundColor: `${colors.due}` }}
              />
              <TypoGraph variant="overline" content="New" align="center" />
              <TypoGraph variant="overline" content="Due" align="center" />
            </Container>
            <Container className={styles.btnContainer}>
              <Button
                id={`View ${item.typeName}`}
                variant="outlined"
                label="View"
                onClick={() => navigateToPaymentList(item.typeId)}
                className={styles.viewBtn}
              />
            </Container>
          </Card>
        </Container>
      </>
    );
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <Container>
      <HeaderBar title={t.RECEIVABLES} />
      <Container className={styles.paymentCategoryContainer}>
        {paymentSummary?.map((item, index) => (
          <PaymentSummaryCards item={item} key={`key-${index}`} />
        ))}
      </Container>
    </Container>
  );
};

export default PaymentsCategory;
