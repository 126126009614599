import MenuItem from "@mui/material/MenuItem";
import TypoGraph from "components/typograph";
import { DropdownOptions } from "interfaces";
import TextField, { ITextFieldProps } from "../input";

export interface IDropdownProps extends ITextFieldProps {
  options?: DropdownOptions[];
}

const DropdownField: React.FunctionComponent<IDropdownProps> = (props) => {
  const { options, name } = props;
  return (
    <TextField id={name} select={true} {...props}>
      {options ? (
        options.map((option) => (
          <MenuItem
            id={option.label.toString()}
            key={option.value}
            value={option.value}
            sx={{ display: option?.hide ? "none" : "block" }}
          >
            {option.label === "Commission Earned"
              ? "Comission Check"
              : option.label}
          </MenuItem>
        ))
      ) : (
        <TypoGraph content="No options found" align="center" />
      )}
    </TextField>
  );
};

export default DropdownField;
