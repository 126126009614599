import React from "react";
import Isvg from "react-inlinesvg";
import IconButton from "@mui/material/IconButton";
import styles from "./icon.module.scss";

export interface IAppProps {
  keyName?: string;
  name?: string;
  type?: "icons" | "images";
  boxClassName?: string;
  iconClassName?: string | object | undefined;
  hover?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  color?: string;
  colorStroke?: string;
  size?: number | string;
  height?: number | string;
  width?: number | string;
  alt?: string;
  children?: React.ReactNode;
  iconButton?: boolean;
  reference?: any;
  imageId?: string;
  url?: string;
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  rotate?: string;
  customPreProcessor?: (code: string) => string;
}

export const Icon: React.FunctionComponent<IAppProps> = (props) => {
  const {
    keyName = "",
    title,
    name,
    url,
    boxClassName,
    iconClassName,
    hover,
    height,
    width,
    size = 20,
    color,
    colorStroke,
    iconButton,
    type = "icons",
    onClick = () => {},
    alt = "vector_images",
    children,
    reference,
    imageId,
    objectFit,
    rotate,
    customPreProcessor,
  } = props;

  const node = (isIconButton?: boolean) => {
    try {
      return type === "icons" ? (
        <span
          key={keyName}
          title={title}
          className={`${styles.iconContainer} ${hover ? styles.pointer : ""}
              ${boxClassName}`}
          onClick={(!isIconButton && onClick) || undefined}
        >
          <Isvg
            className={`${iconClassName} ${styles.iconSvg}`}
            src={require(`../../assets/${type}/${name}.svg`)}
            width={width || size}
            height={height || size}
            preProcessor={
              customPreProcessor
                ? customPreProcessor
                : (code) =>
                    code
                      .replace(
                        color ? /fill=".*?"/g : 'fill=".*?"',
                        `fill='${color}'`
                      )
                      .replace(
                        colorStroke ? /stroke=".*?"/g : 'stroke=".*?"',
                        `stroke='${colorStroke}'`
                      )
                      .replace(
                        colorStroke ? /stop-color=".*?"/g : 'stop-color=".*?"',
                        `stop-color='${colorStroke}'`
                      )
            }
          />
        </span>
      ) : (
        <div className={boxClassName} onClick={onClick}>
          <img
            id={imageId}
            width={width}
            height={height}
            src={url ? url : require(`../../assets/${type}/${name}.png`)}
            alt={alt}
            ref={reference}
            style={{ transform: rotate, objectFit: objectFit }}
          />
        </div>
      );
    } catch (e: any) {
      const err = `Icon Error: ${
        !name ? "name is missing in prop" : e.message
      }`;
      return <span title={err}>icon error</span>;
    }
  };

  const isIconButton = Boolean(children || iconButton);

  return (
    <>
      {isIconButton ? (
        <IconButton
          id={imageId}
          onClick={onClick}
          className={boxClassName}
          key={keyName}
        >
          {children ? children : node(isIconButton)}
        </IconButton>
      ) : (
        node()
      )}
    </>
  );
};

export default Icon;
