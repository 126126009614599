import { Container, CustomDivider } from "components";
import { InputFormField } from "components/hookForm";
import styles from "./transactionDetails.module.scss";
import { amountIsValid } from "tools/validators";
import { useFormContext } from "react-hook-form";
import { DropdownOptions, IWebReceivablePaymentData } from "interfaces";
import EarnestMoneyComponent from "./paymentTypes/earnestMoney";
import CommissionEarnedComponent from "./paymentTypes/commissionEarned";
import SecurityDepositComponent from "./paymentTypes/securityDeposit";
import RentPaymentComponent from "./paymentTypes/rentPayment";
import EarnestMoneyPlusOptionComponent from "./paymentTypes/earnestMoneyPlusOption";
import ClosingFundsComponent from "./paymentTypes/closingFunds";
import OtherPaymentComponent from "./paymentTypes/otherPayment";
import OptionFeeComponent from "./paymentTypes/optionFee";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";
import { CustomFormField } from "components/hookForm/customFormField";
import SurveyFeesComponent from "./paymentTypes/surveyFees";
import InvoiceCollectionComponent from "./paymentTypes/invoiceCollection";
import React, { useEffect } from "react";
import Decimal from "decimal.js";
import ClientPaymentComponent from "./paymentTypes/clientPayment";
import AgentFeesComponent from "./paymentTypes/agentFees";
import Typograph from "components/typograph";
import { paymentMethods } from "constants/paymentMethods";
import HOAComponent from "./paymentTypes/hoa";
import {
  electronicPaymentAmountLimit,
  electronicPaymentAmountLimitInDollars,
} from "../authorizePayment/authorizePayment";

interface ITransactionDetailsProps {
  paymentData: IWebReceivablePaymentData;
  whitelabelConfiguration?: IWhitelabelConfiguration;
  statesOptions?: DropdownOptions[];
}

const TransactionDetails: React.FC<ITransactionDetailsProps> = (props) => {
  const { paymentData, whitelabelConfiguration, statesOptions } = props;
  const { getValues, setValue, watch } = useFormContext();
  const amountValue = watch("amount", "");
  useEffect(() => {
    if (!getValues().amount) {
      return;
    }
    const amountInCent = new Decimal(getValues().amount).times(100).toNumber();
    if (amountInCent > electronicPaymentAmountLimit) {
      setValue("paymentMethod", undefined);
    }
  }, [watch("amount")]);

  const paymentTypeDetails = () => {
    switch (paymentData.paymentTypeId) {
      case 0:
        if (whitelabelConfiguration?.paymentTypes?.earnestMoney) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.earnestMoney}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <EarnestMoneyComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 1:
        if (whitelabelConfiguration?.paymentTypes?.securityDeposit) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.securityDeposit}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <SecurityDepositComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 2:
        if (whitelabelConfiguration?.paymentTypes?.commissionEarned) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.commissionEarned}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <CommissionEarnedComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 3:
        if (whitelabelConfiguration?.paymentTypes?.rentPayment) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.rentPayment}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <RentPaymentComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 5:
        if (whitelabelConfiguration?.paymentTypes?.other) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.other}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return <OtherPaymentComponent statesOptions={statesOptions} />;
      case 7:
        if (whitelabelConfiguration?.paymentTypes?.optionFee) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.optionFee}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <OptionFeeComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 8:
        if (whitelabelConfiguration?.paymentTypes?.closingFunds) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.closingFunds}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <ClosingFundsComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 9:
        if (whitelabelConfiguration?.paymentTypes?.earnestMoneyPlusOption) {
          return (
            <CustomFormField
              sections={
                whitelabelConfiguration.paymentTypes.earnestMoneyPlusOption
              }
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <EarnestMoneyPlusOptionComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 10:
        if (whitelabelConfiguration?.paymentTypes?.surveyFees) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.surveyFees}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <SurveyFeesComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 11:
        if (whitelabelConfiguration?.paymentTypes?.invoiceCollection) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.invoiceCollection}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <InvoiceCollectionComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 12:
        if (whitelabelConfiguration?.paymentTypes?.clientPayment) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.clientPayment}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <ClientPaymentComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 13:
        if (whitelabelConfiguration?.paymentTypes?.agentFees) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.agentFees}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <AgentFeesComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      case 14:
        if (whitelabelConfiguration?.paymentTypes?.hoa) {
          return (
            <CustomFormField
              sections={whitelabelConfiguration.paymentTypes.hoa}
              styles={styles}
              statesOptions={statesOptions || []}
              customLabels={whitelabelConfiguration.customLabels}
              paymentType={paymentData.paymentTypeId.toString()}
            />
          );
        }
        return (
          <HOAComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
      default:
        return (
          <EarnestMoneyComponent
            statesOptions={statesOptions}
            customLabels={whitelabelConfiguration?.customLabels}
          />
        );
    }
  };
  return (
    <Container className={styles.transactionDetailContainer}>
      {paymentTypeDetails()}
      <CustomDivider title="Payment Amount" />
      <Container className={styles.singleInputContainer}>
        <InputFormField
          name="amount"
          label="Payment Amount"
          maxLength={13}
          defaultValue={getValues("amount") ? getValues("amount") : "0"}
          currency={true}
          validate={(textFieldValue: string) => amountIsValid(textFieldValue)}
          disabled={paymentData.paymentRequested ? true : false}
          required
        />
      </Container>
      {parseInt(amountValue) > electronicPaymentAmountLimitInDollars &&
        paymentData.availablePaymentMethods.some(
          (method) => method.id === paymentMethods.ELECTRONIC_PAYMENT
        ) &&
        !paymentData.availablePaymentMethods.some(
          (method) => method.id === paymentMethods.CHECK_CAPTURE
        ) && (
          <Typograph
            color="#F9635E"
            content={`Payment amount exceeds the ACH limit of $10,000.00. Please split this transaction into smaller payments or contact ${paymentData.companyName}`}
          ></Typograph>
        )}
    </Container>
  );
};

export default TransactionDetails;
