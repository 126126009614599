import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
} from "react-hook-form";
import DatePicker, {
  IDatePickerProps,
} from "components/controllers/datePicker";

export interface IDatePickerFieldProps extends IDatePickerProps {
  name: string;
  required?: boolean;
  maxLength?: number | { value: number; message: string };
  minLength?: number | { value: number; message: string };
  pattern?: ValidationRule<RegExp> | undefined;
  defaultValue?: string | number | undefined;
  validate?: Validate<any> | Record<string, Validate<any>> | undefined;
  views?: Array<"day" | "month" | "year">;
  disabled?: boolean;
}

const DatePickerFormField: React.FC<IDatePickerFieldProps> = (props) => {
  const {
    name,
    required = false,
    maxLength,
    minLength,
    pattern,
    defaultValue,
    validate,
    views,
    disabled,
  } = props;
  const { control } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required: isRequired,
        validate: validate,
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
      }}
      render={({
        field: { onChange, onBlur, value = "", ref },
        fieldState: { error },
      }) => {
        return (
          <DatePicker
            id={name}
            value={value ? value : ""}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            views={views}
            disabled={disabled}
            {...props}
          />
        );
      }}
    />
  );
};

export default DatePickerFormField;
