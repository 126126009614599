import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { Chip, TypoGraph } from "components";
import { LightTooltip } from "components/toolTip/lightTooltip";
import { List, ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";

export const CompaniesCellRenderer = (params: GridRenderCellParams) => {
  const userCompaniesNames = params?.row?.userCompanies // checking if exists
    ? params?.row?.userCompanies.map((company: any) => company.name)
    : [];
  const adminCompaniesNames = params?.value
    ? params?.value.map((company: any) => company.name)
    : [];
  const allAsociatedCompanies = userCompaniesNames.concat(adminCompaniesNames);
  const allAsociatedCompaniesNoDuplicates = allAsociatedCompanies.filter(
    (item: any, index: number) => allAsociatedCompanies.indexOf(item) === index
  ); // removing duplicate company names

  return (
    <>
      {!!allAsociatedCompaniesNoDuplicates.length ? (
        <TypoGraph
          variant={"body2"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {allAsociatedCompaniesNoDuplicates[0]}
        </TypoGraph>
      ) : (
        <TypoGraph variant={"body2"}>No Associated companies</TypoGraph>
      )}
      {allAsociatedCompaniesNoDuplicates.length > 1 && (
        <LightTooltip
          title={
            <List dense={true}>
              {allAsociatedCompaniesNoDuplicates
                .slice(1)
                .map((company: any) => {
                  return (
                    <>
                      <ListItem disableGutters>
                        <ListItemText primary={company} />
                      </ListItem>
                    </>
                  );
                })}
            </List>
          }
          color={"primary"}
          arrow={true}
        >
          <IconButton>
            <Chip
              label={`+${allAsociatedCompaniesNoDuplicates.length - 1}`}
              color={"info"}
            ></Chip>
          </IconButton>
        </LightTooltip>
      )}
    </>
  );
};
