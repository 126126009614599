export const DATE_FORMAT_YYYY_MM_DD = "yyyy/MM/dd";
export const DATE_FORMAT_MM_DD_YYYY = "MM-dd-yyyy";
export const MONT_YEAR_FORMAT = "MMMM yyyy";
export const todayDate = new Date();
export const tomorrowDate: Date = new Date(
  new Date().setDate(todayDate.getDate() + 1)
);
export const todayDatePlus365Days: Date = new Date(
  new Date().setDate(todayDate.getDate() + 365)
);
export function getCurrentDateInFormatMMDDYYYY() {
  const date = new Date();
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  return month + "-" + day + "-" + year;
}
