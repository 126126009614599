import { InputFormField, MultiSelectFormField } from "components/hookForm";
import {
  Container,
  CustomDivider,
  SearchMyAdressComponent,
} from "components/index";
import strings from "strings";
import styles from "./billingAddressForm.module.scss";
import { DropdownOptions } from "interfaces";
import { useFormContext } from "react-hook-form";
import Typograph from "components/typograph";
import React, { useState } from "react";

interface AddressFormComponentProps {
  statesOptions?: DropdownOptions[];
}

const BillingAddressForm: React.FC<AddressFormComponentProps> = ({
  statesOptions,
}) => {
  const { setValue, getValues } = useFormContext();
  const [zipCodeError, setZipCodeError] = useState<string>("");

  const validateZipCode = (): boolean => {
    const zipCode = getValues("zipCode");

    const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;

    const isValid = zipCodeRegex.test(zipCode);
    setZipCodeError(isValid ? "" : "Zip code must be XXXXX or XXXXX-XXXX.");

    return isValid;
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const zipRaw = e.target.value.replace(/\D/g, "");

    let formattedZipCode = zipRaw;

    if (zipRaw.length > 5) {
      formattedZipCode = `${zipRaw.slice(0, 5)}-${zipRaw.slice(5, 9)}`;
    }

    setValue("zipCode", formattedZipCode);
    if (formattedZipCode.length === 5 || formattedZipCode.length === 10) {
      validateZipCode();
    }
  };

  return (
    <Container className={styles.propertyAddressContainer}>
      <CustomDivider title="Billing Address" />
      <Container className={styles.searchMyAddressContainer}>
        <SearchMyAdressComponent
          customAddress1="addressLine1"
          customAddress2="addressLine2"
          customCity="billingUserCity"
          customState="billingUserState"
          customZipCode="zipCode"
        />
      </Container>
      <Container className={styles.propertyContainer}>
        <InputFormField name="addressLine1" label="Address Line 1" required />
        <InputFormField name="addressLine2" label="Address Line 2" />
        <InputFormField name="billingUserCity" label="City" required />
        <Container className={styles.stateZipContainer}>
          <MultiSelectFormField
            id={strings.STATE}
            name="billingUserState"
            label={strings.STATE}
            options={statesOptions}
            isMulti={false}
            required
            {...{ backgroundColor: styles.stateSelect }}
          />
          <Container>
            <InputFormField
              name="zipCode"
              label="Zip"
              required
              onChange={handleZipCodeChange}
              onBlur={validateZipCode}
              validate={validateZipCode}
            />
            {zipCodeError && (
              <Typograph color="#F9635E" content={zipCodeError}></Typograph>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default BillingAddressForm;
