import React, { useState } from "react";
import styles from "./mobileNavBar.module.scss";
import { Container, Icon, Popover, TypoGraph } from "components";
import { INavBarActionItem } from "interfaces/IWhitelabelConfigurationResponse";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

interface DesktopNavBarProps {
  item: INavBarActionItem;
  index: number;
}

const ItemWithSubList: React.FC<DesktopNavBarProps> = ({ item, index }) => {
  const [subListVisible, setSublistVisible] = useState<boolean>(false);

  return (
    <>
      <Container className={styles.navItems}>
        <ListItemButton
          sx={{ padding: "0" }}
          onClick={(e: any) => {
            setSublistVisible(!subListVisible);
          }}
        >
          <TypoGraph
            key={index}
            content={item.content}
            className={styles.navItem}
          />
          <Icon name="icon_sliderDownArrow" size="12" />
        </ListItemButton>
      </Container>
      {subListVisible && (
        <Container>
          {item.subItems.map((subItem) => (
            <ListItem sx={{ padding: "0" }}>
              <ListItemButton
                id={"profile"}
                onClick={() => {
                  window.open(subItem.url);
                }}
              >
                <ListItemText primary={subItem.content} />
              </ListItemButton>
            </ListItem>
          ))}
        </Container>
      )}
    </>
  );
};

export default ItemWithSubList;
