import { useCallback, useEffect, useState } from "react";
import { getCompaniesForUser } from "api";
import { Button } from "components";
import { FormControl } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { SearchField } from "components/controllers/searchField";

interface ICompanyFilterDialog {
  onClose: () => void;
  open: boolean;
  selectedOptions: number[];
  toggleOption: (option: number) => void;
  onCleanFilter: () => void;
  onApplyFilter: () => void;
}
interface ICompanySelectProps {
  value: number;
  label: string;
}
const CompanyFilterDialog: React.FC<ICompanyFilterDialog> = (props) => {
  const {
    onClose,
    open,
    selectedOptions,
    toggleOption,
    onCleanFilter,
    onApplyFilter,
  } = props;

  const [companiesOptions, setCompaniesOptions] = useState<
    ICompanySelectProps[]
  >([]);
  const getCompanies = useCallback(() => {
    getCompaniesForUser().then((response) => {
      const companyOptions = response?.data?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setCompaniesOptions(companyOptions);
    });
  }, []);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const [searchInput, setSearchInput] = useState("");

  const handleCleanFilterAndClose = () => {
    setSearchInput("");
    onCleanFilter();
    onClose();
  };

  const onChangeSearch = (e: {
    preventDefault: () => void;
    target: { value: string };
  }) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <Dialog open={open} maxWidth={"md"}>
      <DialogContent>
        <FormControl>
          <SearchField
            id={"searchFieldCompany"}
            label={"Search"}
            variant={"filled"}
            onChange={onChangeSearch}
          />
          {companiesOptions
            .filter((company: ICompanySelectProps) =>
              company.label.toLowerCase().match(searchInput.toLowerCase())
            )
            .map((option: ICompanySelectProps) => (
              <FormControlLabel
                control={
                  <Checkbox
                    key={`key-${option.value}`}
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => toggleOption(option.value)}
                  />
                }
                label={option.label}
              />
            ))}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCleanFilterAndClose}
          label={"Clean Filter"}
          size={"small"}
        />
        <Button onClick={onApplyFilter} label={"Apply Filter"} size={"small"} />
      </DialogActions>
    </Dialog>
  );
};
export default CompanyFilterDialog;
