import {
  Card,
  Container,
  HeaderBar,
  Loader,
  TableComponent,
  TypoGraph,
  Toast,
  Tabs,
} from "components";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import {
  getAllDisbursementAccountsForCompany,
  getAllReceivablesAccounts,
  getPlaidLinkToken,
} from "api";
import styles from "./disbursementAccounts.module.scss";
import strings from "strings";
import {
  disbursementAccountColumns,
  receivablesBankAccountsTableColumns,
  companyAccountsFilters,
} from "constants/tableData";
import {
  IDisbursementAccountResponse,
  IPageResponse,
  IReceivableAccountResponse,
} from "interfaces";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import DisbursementAccountBladeForm from "./partial/DisbursementAccountBladeForm/disbursementAccountBladeForm";
import ReceivableAccountDetailBlade from "./partial/ReceivableDetailBlade/receivableAccountDetailBlade";
import ReceivableAccountBladeForm from "./partial/ReceivableAccountBladeForm/receivableAccountBladeForm";
import {
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid/models/gridSortModel";

const DisbursementAccount = () => {
  const navigate = useNavigate();

  const [disbursementAccountData, setDisbursementAccountData] =
    useState<IDisbursementAccountResponse>();
  const [receivableAccountData, setReceivableAccountData] =
    useState<IReceivableAccountResponse>();
  const [showDisbursementBladeForm, setShowDisbursementBladeForm] =
    useState<boolean>(false);
  const [
    showReceivableAccountCreateBladeForm,
    setShowReceivableAccountCreateBladeForm,
  ] = useState<boolean>(false);
  const [showReceivableDetailBlade, setShowReceivableDetailBlade] =
    useState<boolean>(false);

  const [linkToken, setLinkToken] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [linkTokenError, setLinkTokenError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [disbursementAccountSearchValue, setDisbursementAccountSearchValue] =
    useState<string>("");
  const [receivableAccountSearchValue, setReceivableAccountSearchValue] =
    useState<string>("");
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [companyAccountsFiltersName, setCompanyAccountsFiltersName] =
    useState<string>(companyAccountsFilters[0].label);
  const [paymentFilterTab, setPaymentFilterTab] = useState(0);

  const [disbursementAccountPage, setDisbursementAccountPage] =
    useState<IPageResponse<IDisbursementAccountResponse>>();

  const [receivableAccountPage, setReceivableAccountPage] =
    useState<IPageResponse<IReceivableAccountResponse>>();

  const [selectedReceivableAccountId, setSelectedReceivableAccountId] =
    useState<number>();

  const [
    sortModelForDisbursementAccounts,
    setSortModelForDisbursementAccounts,
  ] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc",
    },
  ]);
  const [
    sortModelForReceivableAccounts,
    setSortModelForReceivableAccountsAccount,
  ] = useState<GridSortModel>([
    {
      field: "id",
      sort: "asc",
    },
  ]);

  const canCreateDisbursementAccounts = userCan(
    Actions.CREATE,
    Resources.DISBURSEMENT_ACCOUNTS
  );
  const canReadDisbursementAccounts = userCan(
    Actions.READ,
    Resources.DISBURSEMENT_ACCOUNTS
  );
  const canCreateReceivablesAccounts = userCan(
    Actions.CREATE,
    Resources.RECEIVABLES_ACCOUNTS
  );

  const handleDisbursementAccountSortChange = (model: GridSortModel) => {
    if (
      JSON.stringify(model) !== JSON.stringify(sortModelForDisbursementAccounts)
    ) {
      setSortModelForDisbursementAccounts(model);
    }
  };
  const handleReceivableAccountSortChange = (model: GridSortModel) => {
    if (
      JSON.stringify(model) !== JSON.stringify(sortModelForReceivableAccounts)
    ) {
      setSortModelForReceivableAccountsAccount(model);
    }
  };

  const createDisbursementBtnProps = {
    id: "Create Disbursement Account",
    label: "Create Disbursement Account",
    onClick: () => {
      setShowDisbursementBladeForm(true);
      setDisbursementAccountData(undefined);
    },
  };

  const createReceivableBtnProps = {
    id: "Create Receivable Account",
    label: "Create Receivable Account",
    onClick: () => {
      setReceivableAccountData(undefined);
      setShowReceivableAccountCreateBladeForm(true);
    },
  };

  useEffect(() => {
    if (canReadDisbursementAccounts) {
      loadDisbursementAccounts(
        currentPageNumber,
        currentPageSize,
        disbursementAccountSearchValue,
        sortModelForDisbursementAccounts[0]?.field,
        sortModelForDisbursementAccounts[0]?.sort
      );
    }
  }, [sortModelForDisbursementAccounts]);

  useEffect(() => {
    if (canCreateReceivablesAccounts) {
      loadReceivableAccounts(
        currentPageNumber,
        currentPageSize,
        receivableAccountSearchValue,
        sortModelForReceivableAccounts[0]?.field,
        sortModelForReceivableAccounts[0]?.sort
      );
    }
  }, [sortModelForReceivableAccounts]);

  const loadDisbursementAccounts = useCallback(
    (
      pageNumber: number = 0,
      pageSize: number = 10,
      filter = "",
      sortBy?: string,
      order?: GridSortDirection
    ) => {
      setLoading(true);
      getAllDisbursementAccountsForCompany(
        pageNumber,
        pageSize,
        filter,
        sortBy || "",
        order
      )
        .then((response) => {
          setDisbursementAccountPage(response?.data);
        })
        .catch(() => {
          Toast({
            type: "error",
            title: "Error",
            subTitle: "Error loading Disbursement Accounts",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, sortModelForDisbursementAccounts]
  );

  const loadReceivableAccounts = useCallback(
    (
      pageNumber: number = 0,
      pageSize: number = 10,
      filter = "",
      sortBy?: string,
      order?: GridSortDirection
    ) => {
      setLoading(true);
      getAllReceivablesAccounts(
        pageNumber,
        pageSize,
        filter,
        sortBy || "",
        order
      )
        .then((response) => {
          setReceivableAccountPage(response?.data);
        })
        .catch(() => {
          Toast({
            type: "error",
            title: "Error",
            subTitle: "Error loading Receivable Accounts",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, sortModelForReceivableAccounts]
  );

  useEffect(() => {
    setCurrentPageNumber(0);
    setCurrentPageSize(10);
    setDisbursementAccountSearchValue("");
    setReceivableAccountSearchValue("");
  }, [companyAccountsFiltersName]);

  useEffect(() => {
    switch (paymentFilterTab) {
      case 0:
        if (canReadDisbursementAccounts) {
          loadDisbursementAccounts();
          getPlaidLinkToken()
            .then((resp) => {
              setLinkToken(resp.data.token);
              localStorage.setItem("plaidLinkToken", resp.data.token);
            })
            .catch((err) => {
              setLinkTokenError(true);
            })
            .finally(() => setIsLoading(false));
        } else {
          if (canCreateReceivablesAccounts) {
            loadReceivableAccounts();
          }
        }
        break;
      case 1:
        if (canCreateReceivablesAccounts) {
          loadReceivableAccounts();
        }
        break;
      default:
        break;
    }
  }, [paymentFilterTab]);

  const calculatePrimaryBtnProps = () => {
    if (canCreateDisbursementAccounts && canCreateReceivablesAccounts) {
      if (companyAccountsFiltersName === "Disbursement") {
        return createDisbursementBtnProps;
      } else if (companyAccountsFiltersName === "Receivables") {
        return createReceivableBtnProps;
      }
    }
    if (canCreateReceivablesAccounts) {
      return createReceivableBtnProps;
    }
    if (canCreateDisbursementAccounts) {
      return createDisbursementBtnProps;
    }
  };

  disbursementAccountColumns[0].handleClick = (data) => {
    setDisbursementAccountData(data);
    setShowDisbursementBladeForm(true);
  };

  receivablesBankAccountsTableColumns[0].handleClick = (
    data: IReceivableAccountResponse
  ) => {
    setSelectedReceivableAccountId(data.id);
    setShowReceivableAccountCreateBladeForm(false);
    setShowReceivableDetailBlade(true);
  };

  const handleQuickSearchForDisbursementAccount = (value: string) => {
    setDisbursementAccountSearchValue(value);
    loadDisbursementAccounts(
      currentPageNumber,
      currentPageSize,
      value,
      sortModelForDisbursementAccounts[0]?.field,
      sortModelForDisbursementAccounts[0]?.sort
    );
  };

  const handleQuickSearchForReceivableAccount = (value: string) => {
    setReceivableAccountSearchValue(value);
    loadReceivableAccounts(
      currentPageNumber,
      currentPageSize,
      value,
      sortModelForReceivableAccounts[0]?.field,
      sortModelForReceivableAccounts[0].sort
    );
  };

  return (
    <Container>
      <HeaderBar
        title={strings.DISBURSEMENT_STRING.COMPANY_ACCOUNTS}
        primaryBtnProps={calculatePrimaryBtnProps()}
      />
      {userCan(Actions.READ, Resources.RECEIVABLES_ACCOUNTS) &&
      userCan(Actions.READ, Resources.DISBURSEMENT_ACCOUNTS) ? (
        <Tabs
          tabData={companyAccountsFilters || []}
          value={paymentFilterTab}
          handleChange={(_e, val) => {
            setPaymentFilterTab(val);
            setCompanyAccountsFiltersName(companyAccountsFilters[val].label);
          }}
        />
      ) : userCan(Actions.READ, Resources.DISBURSEMENT_PAYMENTS) ? (
        <Tabs
          tabData={[companyAccountsFilters[0]]}
          value={paymentFilterTab}
          handleChange={(_e, val) => {
            setPaymentFilterTab(0);
            setCompanyAccountsFiltersName(companyAccountsFilters[0].label);
          }}
        />
      ) : userCan(Actions.READ, Resources.RECEIVABLES_ACCOUNTS) ? (
        <Tabs
          tabData={[companyAccountsFilters[1]]}
          value={paymentFilterTab}
          handleChange={(_e, val) => {
            setPaymentFilterTab(1);
            setCompanyAccountsFiltersName(companyAccountsFilters[1].label);
          }}
        />
      ) : undefined}
      <Container>
        {companyAccountsFiltersName === "Disbursement" &&
        canReadDisbursementAccounts ? (
          <TableComponent
            key={companyAccountsFiltersName}
            title={`Disbursement Bank Accounts`}
            data={disbursementAccountPage?.items || []}
            totalCount={disbursementAccountPage?.totalCount}
            columns={disbursementAccountColumns}
            loading={loading}
            searchValue={disbursementAccountSearchValue}
            currentPageSize={currentPageSize}
            handleQuickSearch={handleQuickSearchForDisbursementAccount}
            handlePagination={(pageNumber, pageSize) => {
              setCurrentPageSize(pageSize);
              setCurrentPageNumber(pageNumber);
              loadDisbursementAccounts(
                pageNumber,
                pageSize,
                disbursementAccountSearchValue,
                sortModelForDisbursementAccounts[0]?.field,
                sortModelForDisbursementAccounts[0]?.sort
              );
            }}
            handleSortModelChange={handleDisbursementAccountSortChange}
          />
        ) : (
          <TableComponent
            key={companyAccountsFiltersName}
            title={`Receivables Bank Accounts`}
            data={receivableAccountPage?.items || []}
            totalCount={receivableAccountPage?.totalCount}
            columns={receivablesBankAccountsTableColumns}
            loading={loading}
            searchValue={receivableAccountSearchValue}
            currentPageSize={currentPageSize}
            handleQuickSearch={handleQuickSearchForReceivableAccount}
            handlePagination={(pageNumber, pageSize) => {
              setCurrentPageSize(pageSize);
              setCurrentPageNumber(pageNumber);
              loadReceivableAccounts(
                pageNumber,
                pageSize,
                receivableAccountSearchValue,
                sortModelForReceivableAccounts[0]?.field,
                sortModelForReceivableAccounts[0].sort
              );
            }}
            handleSortModelChange={handleReceivableAccountSortChange}
          />
        )}
      </Container>
      <Container>
        {isLoading && <Loader loading={true} />}
        {!isLoading && linkTokenError && (
          <Container className={styles.container}>
            <Card
              sxContainer={{ width: 400, margin: "auto" }}
              sxContent={{ textAlign: "center" }}
            >
              <TypoGraph variant="h1" align="center">
                {"Error connecting to bank."}
              </TypoGraph>
              <TypoGraph align="center">{"Don't worry!"}</TypoGraph>
              <TypoGraph variant="caption" align="center">
                {
                  "The bank integration system may be temporarily offline. Please try again later."
                }
              </TypoGraph>
            </Card>
          </Container>
        )}
      </Container>
      <DisbursementAccountBladeForm
        showBlade={showDisbursementBladeForm}
        handleClose={() => setShowDisbursementBladeForm(false)}
        reloadList={() =>
          loadDisbursementAccounts(
            currentPageNumber,
            currentPageSize,
            disbursementAccountSearchValue,
            sortModelForDisbursementAccounts[0]?.field,
            sortModelForDisbursementAccounts[0]?.sort
          )
        }
        disbursementAccountData={disbursementAccountData}
      />
      <ReceivableAccountBladeForm
        showBlade={showReceivableAccountCreateBladeForm}
        receivableAccountData={receivableAccountData}
        handleClose={() => {
          setReceivableAccountData(undefined);
          setShowReceivableAccountCreateBladeForm(false);
          setSelectedReceivableAccountId(undefined);
        }}
        handleDataChange={() =>
          loadReceivableAccounts(
            currentPageNumber,
            currentPageSize,
            receivableAccountSearchValue,
            sortModelForReceivableAccounts[0]?.field,
            sortModelForReceivableAccounts[0].sort
          )
        }
      />

      <ReceivableAccountDetailBlade
        showBlade={showReceivableDetailBlade}
        handleClose={() => {
          setShowReceivableDetailBlade(false);
          setReceivableAccountData(undefined);
          setSelectedReceivableAccountId(undefined);
        }}
        handleReceivableAccount={(
          receivableAccount: IReceivableAccountResponse
        ) => setReceivableAccountData(receivableAccount)}
        handleSuccess={() => {
          setShowReceivableDetailBlade(false);
          setReceivableAccountData(undefined);
          setSelectedReceivableAccountId(undefined);
          loadReceivableAccounts(
            currentPageNumber,
            currentPageSize,
            receivableAccountSearchValue,
            sortModelForReceivableAccounts[0]?.field,
            sortModelForReceivableAccounts[0].sort
          );
        }}
        handleUpdate={() => {
          setShowReceivableDetailBlade(false);
          setShowReceivableAccountCreateBladeForm(true);
        }}
        selectedReceivableAccountId={selectedReceivableAccountId}
      />
    </Container>
  );
};

export default DisbursementAccount;
