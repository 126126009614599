import { IDecodedToken } from "interfaces/IdecodedToken";
import jwt_Decode from "jwt-decode";

const decodeToken = () => {
  const token: string | null = localStorage.getItem("token");
  if (token) {
    const decodedToken: IDecodedToken = jwt_Decode(token);
    return decodedToken;
  }
  return;
};

export default decodeToken;
