import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  deleteBillingAccount,
  getBillingAccounts,
  getBillingAccountStripeSubscription,
  getBillingReport,
} from "api";
import {
  IBillingAccountReportResponse,
  IBillingAccountResponse,
  IPageResponse,
  IStripeSubscription,
  ObjectAnyKeys,
} from "interfaces";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Loader,
  SubmitDialog,
  TableComponent,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  billingAccountDetailsLabel,
  billingAccountsList,
} from "constants/tableData";
import { MONT_YEAR_FORMAT } from "constants/datePicker";
import strings from "strings";
import styles from "./billingAccountsPage.module.scss";
import { DatePicker } from "components/controllers";
import {
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid/models/gridSortModel";
import colors from "theme/colors";
import UpdateCustomer from "./partial/updateCustomer";

const BillingAccountsPage: React.FunctionComponent = () => {
  const { billingAccountColumns } = billingAccountsList;
  const [billingAccountPage, setBillingAccountPage] =
    useState<IPageResponse<IBillingAccountResponse>>();
  const [billingAccount, setBillingAccount] =
    useState<IBillingAccountResponse>();
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<
    number | string
  >(0);
  const [billingAccountStripeSubs, setBillingAccountStripeSubs] =
    useState<IStripeSubscription[]>();
  const currentDate = new Date(),
    currentYear = currentDate.getFullYear(),
    currentMonth = currentDate.getMonth();
  const [loading, setLoading] = useState(false);
  const [bladeLoading, setBladeLoading] = useState(false);
  const [month, setMonth] = useState<number>(currentMonth + 1);
  const [year, setYear] = useState<number>(currentYear);
  const [usageMonth, setUsageMonth] = useState<Date>(new Date());
  const [searchValue, setSearchValue] = useState<string>("");
  const [billingReport, setBillingReport] =
    useState<IBillingAccountReportResponse>();
  const [showBlade, setShowBlade] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [showUpdateCustomerModal, setShowUpdateCustomerModal] =
    useState<boolean>(false);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadBillingAccounts(
      currentPageNumber,
      currentPageSize,
      value,
      sortModel[0]?.field,
      sortModel[0]?.sort
    );
  };
  billingAccountColumns[0].handleClick = (id) => {
    setBladeLoading(true);
    navigate(`/settings/billingaccounts/${id}`);
    setSelectedBillingAccount(id);
    setShowBlade(true);
    setBladeLoading(false);
  };

  useEffect(() => {
    if (selectedBillingAccount !== 0 && month && year) {
      getBillingReport(selectedBillingAccount, month, year).then((r) => {
        setBillingReport(r.data);
      });
    }
  }, [selectedBillingAccount, month, year]);

  useEffect(() => {
    setBladeLoading(true);

    if (selectedBillingAccount !== 0) {
      setBillingAccount(
        billingAccountPage?.items?.find(
          (billingAccount) => billingAccount.id === selectedBillingAccount
        )
      );

      getBillingAccountStripeSubscription(selectedBillingAccount)
        .then((response) => {
          setBillingAccountStripeSubs(response?.data);
        })
        .catch((err) => {
          setBillingAccountStripeSubs([]);
        })
        .finally(() => setBladeLoading(false));
    }
  }, [selectedBillingAccount, billingAccountPage]);

  const loadBillingAccounts = useCallback(
    (
      page: number = 0,
      pageSize: number = 10,
      searchValue: string = "",
      sortBy?: string,
      order?: GridSortDirection
    ) => {
      setLoading(true);
      getBillingAccounts(page, pageSize, searchValue, sortBy || "", order)
        .then((billingAccounts) => {
          setBillingAccountPage(billingAccounts.data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [sortModel]
  );

  useEffect(() => {
    loadBillingAccounts(
      currentPageNumber,
      currentPageSize,
      searchValue,
      sortModel[0]?.field,
      sortModel[0]?.sort
    );
  }, [loadBillingAccounts]);

  const handleCreateNewBillingAccount = () => {
    navigate("/settings/billingaccounts/create");
  };

  const handleActions = (id: number | string, actionName: string) => {
    setSelectedBillingAccount(id);
    switch (actionName) {
      case strings.VIEW:
        setSelectedBillingAccount(id);
        setShowBlade(true);
        break;
      case strings.EDIT:
        navigate(`/settings/billingaccounts/edit/${id}`);
        break;
      default:
        break;
    }
  };

  const getCompaniesNames = (companies: ObjectAnyKeys[]) => {
    return companies.map((item) => item.companyName);
  };

  const getCardholderName = (cardholder: ObjectAnyKeys) => {
    return cardholder.email;
  };

  const openStripeCustomerView = (url: string | undefined) => {
    window.open(`${process.env.REACT_APP_STRIPE_DASHBOARD}customers/${url}`);
  };

  const BladeContent = () => {
    const payload = { ...billingAccount };
    payload.companies = getCompaniesNames(billingAccount?.companies || []);
    payload.billingReportSummary =
      billingReport?.accountReports[0].productReports;
    payload.cardholder = getCardholderName(billingAccount?.cardholder || {});
    if (bladeLoading) {
      return <Loader loading={bladeLoading} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid columns={billingAccountDetailsLabel.details} data={payload} />
        <Divider title="Associated Companies" />
        <DataGrid
          columns={billingAccountDetailsLabel.companies}
          data={payload}
        />
        <Divider title="Usage per month" />
        <Container>
          <Container className={styles.usagePerMonthContainer}>
            <DatePicker
              views={["year", "month"]}
              label={strings.YEAR_MONTH}
              value={usageMonth}
              inputFormat={MONT_YEAR_FORMAT}
              minDate={new Date("01/01/2015")}
              maxDate={new Date()}
              onAccept={(val: any) => {
                if (val) {
                  setUsageMonth(val);
                  setMonth(val.getMonth() + 1);
                  setYear(val.getFullYear());
                }
              }}
            />
          </Container>
          <Divider title="Billing Report" />
          {payload.billingReportSummary?.map((item, i) => {
            item.count = item?.count?.toString();
            return (
              <Container
                className={styles.pricingContainer}
                key={`report-${i}`}
              >
                <DataGrid
                  data={item}
                  columns={billingAccountDetailsLabel.usageReportTableColumns}
                />
                <Divider></Divider>
              </Container>
            );
          })}

          <Divider title="Stripe Subscriptions" />
          <Container className={styles.pricingContainer} key={`report-error`}>
            <TypoGraph
              color={colors.error}
              content={
                !billingAccountStripeSubs ||
                billingAccountStripeSubs.length === 0
                  ? strings.BILLING_ACCOUNT_HAS_NO_STRIPE_SUB
                  : !billingAccountHasActiveSub()
                  ? `${strings.BILLING_ACCOUNT_HAS_NO_ACTIVE_STRIPE_SUB}`
                  : undefined
              }
            />
          </Container>
          {billingAccountStripeSubs?.map((sub, i) => (
            <Container
              className={styles.pricingContainer}
              key={`stripe-sub-${i}`}
            >
              <DataGrid
                data={sub}
                columns={
                  billingAccountDetailsLabel.stripeSubscriptionsTableColumns
                }
              />
              <Divider></Divider>
            </Container>
          ))}
        </Container>
      </Container>
    );
  };

  const billingAccountHasActiveSub = () => {
    if (!billingAccountStripeSubs || billingAccountStripeSubs.length === 0) {
      return false;
    }
    const activeSubs = billingAccountStripeSubs.filter(
      (sub) => sub.status === "active"
    );
    return activeSubs.length > 0;
  };

  const deleteSelectedBillingAccount = () => {
    setDeleteBtnLoader(true);
    deleteBillingAccount(+selectedBillingAccount)
      .then(() => {
        toast({
          title: "Billing account deleted Successfully",
          subTitle: `Id: ${selectedBillingAccount}`,
        });
        setShowBlade(false);
        loadBillingAccounts();
        setDeleteModal(false);
      })
      .finally(() => {
        setDeleteBtnLoader(false);
      });
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={"delete"}
          color={"error"}
          label={strings.DELETE}
          onClick={() => setDeleteModal(true)}
        />
        <Button
          id={"viewInStripe"}
          variant="outlined"
          label={"View in stripe"}
          onClick={() => openStripeCustomerView(billingAccount?.customerId)}
        />
        <Button
          id={strings.UPDATE}
          variant="outlined"
          label={strings.UPDATE}
          disabled={!billingAccountHasActiveSub()}
          onClick={() => {
            navigate(`/settings/billingaccounts/${billingAccount?.id}/edit`);
          }}
        />
        <Button
          id={strings.UPDATE_CUSTOMER}
          variant="outlined"
          label={strings.UPDATE_CUSTOMER}
          onClick={() => {
            setShowUpdateCustomerModal(true);
          }}
        />
      </Container>
    );
  };

  return (
    <>
      <HeaderBar
        title={strings.BILLING_ACCOUNT_STRING.BILLING_ACCOUNTS}
        primaryBtnProps={{
          id: strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT,
          label: strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT,
          onClick: handleCreateNewBillingAccount,
        }}
      />
      <TableComponent
        title={strings.BILLING_ACCOUNT_TABLE_TITLE}
        data={billingAccountPage?.items || []}
        totalCount={billingAccountPage?.totalCount}
        columns={billingAccountColumns}
        loading={loading}
        handleActions={handleActions}
        handleCheckboxSelection={() => {}}
        handleSelectionActions={() => {}}
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(currentPage, pageSize) => {
          setCurrentPageSize(pageSize);
          setCurrentPageNumber(currentPage);
          loadBillingAccounts(
            currentPage,
            pageSize,
            searchValue,
            sortModel[0]?.field,
            sortModel[0]?.sort
          );
        }}
        handleSortModelChange={handleSortChange}
      />
      <Blade
        show={showBlade}
        title={strings.BILLING_ACCOUNT_STRING.BILLING_ACCOUNT_DETAILS}
        handleClose={() => {
          setShowBlade(false);
          setSelectedBillingAccount(0);
          navigate(`/settings/billingaccounts`);
        }}
        content={<BladeContent />}
        footerContent={<BladeFooter />}
      />
      <SubmitDialog
        type="delete"
        title={strings.CONFIRM_DELETE}
        open={deleteModal}
        body1={`Are you sure you want to delete the billing account with ID ${selectedBillingAccount} ?`}
        body2={strings.CONFIRM_DELETE_CAPTION}
        handleClose={() => setDeleteModal(false)}
        primaryBtnProps={{
          id: strings.DELETE,
          label: strings.DELETE,
          loading: deleteBtnLoader,
          onClick: deleteSelectedBillingAccount,
        }}
      />

      <UpdateCustomer
        open={showUpdateCustomerModal}
        handleClose={() => setShowUpdateCustomerModal(false)}
        billingAccountId={selectedBillingAccount}
      />
    </>
  );
};

export default BillingAccountsPage;
