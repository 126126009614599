import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, HeaderBar, TableComponent } from "components";
import {
  paymentRequestColumns,
  receivablesRequesterTab,
} from "constants/tableData";
import styles from "./paymentsList.module.scss";
import strings from "strings";
import { getPaymentPage } from "api";
import { IPageResponse } from "interfaces";
import { IPayment } from "interfaces/IPayment";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { RequestPaymentBladeComponent } from "../../paymentsList/partial/requestPaymentBlade";
import { enabledPaymentTypesForRequests } from "constants/enabledPaymentTypesForRequests";
import { RequestBladeComponent } from "./partial/requestBlade";

const PaymentRequestList: React.FunctionComponent = () => {
  //Default type is 0 for earnest money
  const [paymentFilterTab, setPaymentFilterTab] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [paymentPage, setPaymentPage] = useState<IPageResponse<IPayment>>();
  const [paymentFilterName, setPaymentFilterName] = useState<string>(
    receivablesRequesterTab[0].label
  );
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [paymentTypeName, setPaymentTypeName] = useState<string>();
  const navigate = useNavigate();
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const { paymentId } = useParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc",
    },
  ]);
  const typeId = "0";
  const [showRequestPaymentBlade, setShowRequestPaymentBlade] =
    useState<boolean>(false);

  const loadPayments = useCallback(async () => {
    setLoading(true);
    if (!typeId) {
      return;
    }
    try {
      setPaymentTypeName(
        enabledPaymentTypesForRequests.find(
          (paymentType) => paymentType.id === +typeId
        )?.name
      );
      const payments = (
        await getPaymentPage(
          typeId ? +typeId : 0,
          currentPageNumber,
          currentPageSize,
          receivablesRequesterTab[paymentFilterTab].filterValue,
          searchValue,
          sortModel[0]?.field,
          sortModel[0]?.sort
        )
      )?.data;
      setPaymentPage(payments);
    } catch {
      navigate("/payments");
    } finally {
      setLoading(false);
    }
  }, [
    typeId,
    paymentFilterTab,
    sortModel,
    searchValue,
    currentPageSize,
    currentPageNumber,
  ]);

  useEffect(() => {
    loadPayments();
  }, [
    typeId,
    paymentFilterTab,
    sortModel,
    searchValue,
    currentPageSize,
    currentPageNumber,
    loadPayments,
  ]);

  paymentRequestColumns[0].handleClick = (value: IPayment) => {
    navigate(`/paymentrequest/detail/${value.id}`);
  };

  useEffect(() => {
    (async () => {
      if (!(paymentId && typeId)) {
        setShowPaymentDetails(false);
        return;
      }
      setShowPaymentDetails(true);
    })();
  }, [paymentId, typeId]);

  return (
    <Container className={styles.paymentsContainer}>
      <HeaderBar
        title={paymentTypeName}
        primaryBtnProps={
          typeId === "0"
            ? {
                label: "Request Payment",
                onClick: () => {
                  setShowRequestPaymentBlade(true);
                },
                disabled: loading,
              }
            : {}
        }
      />
      <TableComponent
        title={
          paymentTypeName &&
          `${paymentFilterName} ${paymentTypeName} ${strings.RECEIVABLES}`
        }
        data={paymentPage?.items || []}
        totalCount={paymentPage?.totalCount}
        columns={paymentRequestColumns}
        loading={loading}
        searchValue={searchValue}
        currentPageSize={currentPageSize}
        handleQuickSearch={(searchValue: string) => {
          setSearchValue(searchValue);
        }}
        handlePagination={(pageNumber, pageSize) => {
          setCurrentPageSize(pageSize);
          setCurrentPageNumber(pageNumber);
        }}
        handleSortModelChange={(model: GridSortModel) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
          }
        }}
      />

      {showRequestPaymentBlade && (
        <RequestPaymentBladeComponent
          handleClose={() => {
            setShowRequestPaymentBlade(false);
          }}
          handleDataChange={loadPayments}
          typeId={typeId}
          paymentTypeName={paymentTypeName}
        />
      )}
      {showPaymentDetails && (
        <RequestBladeComponent
          typeId={typeId}
          paymentId={paymentId}
          handleBladeSuccessCallBack={() => {
            navigate(`/paymentrequest`);
          }}
        />
      )}
    </Container>
  );
};

export default PaymentRequestList;
