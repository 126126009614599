import strings from "strings";

const paymtypestring = strings.PAYMENTS_TYPES_STRING;
const companyFormFields = {
  paymentTypesCheckbox: [
    {
      name: "hasFeatureEarnestMoney",
      label: paymtypestring.EARNEST_MONEY,
    },
    {
      name: "hasFeatureSecurityDeposit",
      label: paymtypestring.SECURITY_DEPOSIT,
    },
    {
      name: "hasFeatureCommission",
      label: paymtypestring.FEATURE_COMMISSION,
    },
    {
      name: "hasFeatureRentPayment",
      label: paymtypestring.RENT_PAYMENT,
    },
    {
      name: "hasFeatureHudEarnestMoney",
      label: paymtypestring.HUD_EARNEST_MONEY,
    },
    {
      name: "hasFeatureOther",
      label: paymtypestring.OTHER,
    },
    {
      name: "hasFeatureRemittance",
      label: paymtypestring.REMITTANCE,
    },
    {
      name: "hasFeatureClosingFunds",
      label: paymtypestring.CLOSING_FUNDS,
    },
    {
      name: "hasFeatureOptionFee",
      label: paymtypestring.OPTION_FEE,
    },
    {
      name: "hasFeatureEarnestMoneyPlusOption",
      label: paymtypestring.EARNEST_MONEY_PLUS_OPTION,
    },
    {
      name: "hasFeatureSurveyFees",
      label: paymtypestring.SURVEY_FEES,
    },
    {
      name: "hasFeatureInvoiceCollection",
      label: paymtypestring.INVOICE_COLLECTION,
    },
    {
      name: "hasFeatureClientPayment",
      label: paymtypestring.CLIENT_PAYMENT,
    },
    {
      name: "hasFeatureAgentFees",
      label: paymtypestring.AGENT_FEES,
    },
    {
      name: "hasFeatureHOA",
      label: paymtypestring.HOA,
    },
  ],
  securityCheckbox: [
    {
      name: "require2FA",
      label: strings.REQUIRE_2FA,
    },
    {
      name: "hasMobile2FA",
      label: strings.MOBILE_2FA,
    },
    {
      name: "allowAppSignup",
      label: strings.ALLOW_APP_SIGNUP,
    },
  ],
};

export { companyFormFields };
