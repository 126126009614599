import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "theme/themeOverride";
import configureStore from "store/configureStore";
import App from "./App";
import "./App.scss";
import { LoadScript } from "@react-google-maps/api";

const store = configureStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>
        <LoadScript
          id={"google-map-script"}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
          libraries={["places"]}
        >
          <App />
        </LoadScript>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
