import { Container, TypoGraph } from "components";
import styles from "./checkCapturePayment.module.scss";
import { DropzoneController } from "components/hookForm";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";

interface ICheckCaptureProps {
  whitelabelConfiguration?: IWhitelabelConfiguration;
}

const CheckCapturePayment: React.FC<ICheckCaptureProps> = (props) => {
  const { whitelabelConfiguration } = props;

  return (
    <Container className={styles.checkCaptureContainer}>
      <Container className={styles.textContainer}>
        <TypoGraph
          variant="h2"
          content="Please remember to fill out the check before submitting. Do not submit a blank check"
        />
        <TypoGraph variant="h1" content="Warning. Do not sign back of check" />
      </Container>
      <Container className={styles.checkContainer}>
        <Container>
          <TypoGraph content="Front of Check" className={styles.checkTitle} />
          <DropzoneController
            name={"frontImageBase64"}
            label="Upload Front of Check Image"
            whitelabelConfiguration={whitelabelConfiguration}
            required
          ></DropzoneController>
        </Container>
        <Container>
          <TypoGraph content="Back of Check" className={styles.checkTitle} />
          <DropzoneController
            name={"backImageBase64"}
            label="Upload Back of Check Image"
            whitelabelConfiguration={whitelabelConfiguration}
            required
          ></DropzoneController>
        </Container>
      </Container>
    </Container>
  );
};

export default CheckCapturePayment;
