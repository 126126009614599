import { Actions, Resources, userCan } from "tools/privilegeChecker";
import decodeToken from "tools/token";

const SCHEDULED_STATUS = 2;
const DRAFT_STATUS = 3;
const PENDING_APPROVAL_STATUS = 6;
const DEPOSITED_STATUS = 0;

const CAN_BE_REJECTED = [DRAFT_STATUS, SCHEDULED_STATUS];
const CAN_BE_UPDATED = [DRAFT_STATUS];
const CAN_BE_REVIEWED = [PENDING_APPROVAL_STATUS];
const canUpdateDisbursement = (itemStatus: any, payingUserId: any) => {
  const disbursementIsUpdatable = CAN_BE_UPDATED.includes(itemStatus);
  if (!disbursementIsUpdatable) {
    return false;
  }

  const userCanUpdateDisbursements = userCan(
    Actions.UPDATE,
    Resources.DISBURSEMENT_PAYMENTS
  );
  const userOwnsDisbursement = payingUserId === decodeToken()?.puid;
  if (userCanUpdateDisbursements && userOwnsDisbursement) {
    return true;
  }

  return false;
};

const canRejectDisbursement = (itemStatus: any, payingUserId: any) => {
  const disbursementCanBeRejected = CAN_BE_REJECTED.includes(itemStatus);
  if (!disbursementCanBeRejected) {
    return false;
  }

  const userCanRejectAnyDraft = userCan(
    Actions.REJECT_ANY_DRAFT,
    Resources.DISBURSEMENT_PAYMENTS
  );
  if (userCanRejectAnyDraft && itemStatus === DRAFT_STATUS) {
    return true;
  }
  const userCanRejectOwnDisbursement = userCan(
    Actions.REJECT_OWN_DRAFT,
    Resources.DISBURSEMENT_PAYMENTS
  );

  const userOwnsDisbursement = payingUserId === decodeToken()?.puid;
  if (userCanRejectOwnDisbursement && userOwnsDisbursement) {
    return true;
  }

  return false;
};

const canReviewDisbursement = (itemStatus: any, payingUserId: any) => {
  const disbursementCanBeReviewed = CAN_BE_REVIEWED.includes(itemStatus);
  if (!disbursementCanBeReviewed) {
    return false;
  }

  const userCanReviewDisbursements = userCan(
    Actions.REVIEW,
    Resources.DISBURSEMENT_PAYMENTS
  );
  const userOwnsDisbursement = payingUserId === decodeToken()?.puid;
  if (userCanReviewDisbursements && !userOwnsDisbursement) {
    return true;
  }

  return false;
};

const canVoidDisbursements = (canBeVoided: any) => {
  if (!canBeVoided) {
    return false;
  }
  const userCanVoidDisbursements = userCan(
    Actions.VOID,
    Resources.DISBURSEMENT_PAYMENTS
  );
  if (userCanVoidDisbursements) {
    return true;
  }

  return false;
};

export {
  canUpdateDisbursement,
  canRejectDisbursement,
  canReviewDisbursement,
  canVoidDisbursements,
};
